import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { post } from "../../api_helper";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ReplayIcon from "@mui/icons-material/Replay";
import ClearIcon from "@mui/icons-material/Clear";
import { loadingPanel } from "../../components/CommonComponents/CommonFunction";
import { ShowSucess, ShowError } from "../../_helper/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Header from "../../components/CommonComponents/Header";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Card, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import {
  Box,
  TextField,
  MenuItem,
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Container,
  Button,
  Step,
  StepLabel,
  Stepper,
  Alert,
} from "@mui/material";

import { HdRounded } from "@mui/icons-material";
import MailIcon from "@mui/icons-material/Mail";
import dayjs from "dayjs";
import { Col } from "reactstrap";
import duration from "dayjs/plugin/duration";

const steps = [
  "Personal Information",
  "Professional Information",
  "Upload Documents",
];

dayjs.extend(duration);

const baseURL = process.env.REACT_APP_API_URL;
export default function DoctorProfileEdit(props) {
  const navigate = useNavigate();
  const [isEdited, setIsEdited] = useState(false);
  const { registerDrModal, setRegisterDrModal } = props;
  const location = useLocation();
  const showBackButton = location.key !== "default";
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [experiences, setExperiences] = useState([
    {
      institution: "",
      role: "",
      start_date: "",
      end_date: "",
      total_year: "",
      description: "",
    },
  ]);
  const [educations, setEducations] = useState([
    { degree: "", institution: "", year_of_graduation: "" },
  ]);
  const [formData, setFormData] = React.useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    blood_type: "",
    contact_no: "",
    email: "",
    medical_license_number: "",
    specialization: "",
    years_of_experience: "",
    experience: "",
    Education: "",
    expdetails: "",
    department: 1,
    charge: "",
    hospital_clinic_affiliation: "",
    medical_school: "",
    graduation_year: "",
    residency: "",
    fellowships: "",
    image: null,
    medical_license: "",
    certifications: null,
    street_address: "",
    city: "",
    state_province: "",
    postal_code: "",
    country: "",
    signature: null,
    // username: "",
    active: true,
    description: "testing",
    location: "test",
    latitude: 28.535517,
    longitude: 77.391029,
  });
  const [errors, setErrors] = React.useState({});
  const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setIsEdited(true);
  };
  const handleDocClick = (documentURL, e) => {
    e.preventDefault();
    window.open(documentURL, "_blank");
  };
  const handleFileChange = (event) => {
    // Handle file inputs
    const { name, files: fileList } = event.target;
    const file = fileList[0];
    // URL.createObjectURL()
    if (file) {
      const fileSize = file.size; // Size in bytes

      // Convert file size from bytes to KB and MB
      const fileSizeInKB = fileSize / 1024;
      const fileSizeInMB = fileSize / (1024 * 1024);

      // Validation logic
      if (fileSizeInMB > 2) {
        setErrors({
          ...prevErrors,
          [name]: "The maximum file size that can be uploaded is 2MB",
        });
      } else if (fileSizeInKB < 100) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "The file size is too small. Minimum size is 100KB",
        }));
      } else if (file?.name?.length > 100) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "file Name should be at least of 100 charactor length",
        }));
      } else {
        setErrors({}); // Clear any previous errors
      }
    }
    setFormData({
      ...formData,
      [name]: file ? file : null,
    });
    setIsEdited(true);

    // setFormData({
    //   ...formData,
    //   [name]: file ? file : null,
    // });
  };
  const handleAddExperience = () => {
    setExperiences([
      ...experiences,
      {
        institution: "",
        role: "",
        start_date: "",
        end_date: "",
        total_year: "",
        description: "",
      },
    ]);
  };

  const allKeysHaveValues = (arr) => {
    if (arr.length === 0) return true; // Return true for empty array

    // Get all keys from the first object
    const keys = Object.keys(arr[0]);

    return arr.every((obj) =>
      keys.every(
        (key) => obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== ""
      )
    );
  };

  function calculateDateDifference(startDate, endDate) {
    // Convert inputs to Date objects
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the total difference in years, months, and days
    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    let days = end.getDate() - start.getDate();

    // Adjust months and years if necessary
    if (days < 0) {
      months--; // Borrow one month
      days += new Date(end.getFullYear(), end.getMonth(), 0).getDate(); // Get days in previous month
    }

    if (months < 0) {
      years--; // Borrow one year
      months += 12; // Normalize months to positive value
    }

    // Return the result as an object
    return { years, months, days };
  }

  // const handleCalculate = () => {
  //   if (startDate && endDate) {
  //     const { duration, error } = calculateTotalDuration(startDate, endDate);
  //     if (error) {
  //       setValidationMessage(error);
  //     } else {
  //       setTotalDuration(duration);
  //     }
  //   }
  // };

  const handleCalculate = (index) => {
    const startDate = experiences[index]["start_date"];
    const endDate = experiences[index]["end_date"];
    if (startDate && endDate) {
      const { years, months, days } = calculateDateDifference(
        startDate,
        endDate
      );
      const difference = `${
        years ? `${years} ${years > 1 ? "years " : "year "}` : ""
      }  ${months ? `${months} ${months > 1 ? "months " : "month "}` : ""} ${
        days ? `${days} ${days > 1 ? "days" : "day"}` : ""
      }`;
      const updatedExpriences = [...experiences];
      updatedExpriences[index]["total_year"] =
        difference.trim() !== "" ? difference : "0";
      setExperiences(updatedExpriences);
      // ShowError(error);
    }
  };
  const handleAddEducation = () => {
    setEducations([
      ...educations,
      { degree: "", institution: "", year_of_graduation: "" },
    ]);
  };
  const resetExperience = (index) => {
    setExperiences((prevExperiences) => {
      return prevExperiences.map(
        (experience, i) =>
          i === index
            ? { institution: "", role: "", duration: "", description: "" }
            : // Reset specific fields
              experience // Keep other experiences unchanged
      );
    });
  };
  const resetEducation = (index) => {
    setEducations((prevEducations) => {
      return prevEducations.map(
        (education, i) =>
          i === index
            ? { degree: "", institution: "", year_of_graduation: "" }
            : // Reset specific fields
              education // Keep other experiences unchanged
      );
    });
  };

  const handleKeyDown = (e, index, type) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault(); // Prevent default behavior of Enter or Tab
      const isAllFieldsFilledForExperience = Object.values(
        experiences[index]
      ).every((value) => value.trim() !== "");
      const isAllFieldsFilledForEducation = Object.values(
        educations[index]
      ).every((value) => value.trim() !== "");
      if (isAllFieldsFilledForExperience && type === "experience") {
        handleAddExperience();
      } else if (isAllFieldsFilledForEducation && type === "education") {
        handleAddEducation();
      }
    }
  };

  const updateDoctorDetails = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("accessToken");

      // Initialize a Map to track keys and their first occurrence
      const keyTracker = new Map();
      const formDataToSend = new FormData();

      // Utility function to add data to FormData and track the key
      const addData = (key, value) => {
        if (!keyTracker.has(key)) {
          keyTracker.set(key, true);
          formDataToSend.append(key, value);
        }
      };

      // Process formData fields
      if (formData.image && typeof formData?.image !== "string")
        addData("image", formData.image);
      if (formData.signature && typeof formData?.signature !== "string")
        addData("signature", formData.signature);
      if (
        formData.medical_license &&
        typeof formData?.medical_license !== "string"
      )
        addData("medical_license", formData.medical_license);
      if (
        formData.certifications &&
        typeof formData?.certifications !== "string"
      )
        addData("certifications", formData.certifications);
      addData("department", 1);
      addData("experience", JSON.stringify(experiences));
      addData("Education", JSON.stringify(educations));

      if (typeof formData?.specialities === "string") {
        addData(
          "specialities",
          JSON.stringify([{ description: formData.specialities }])
        );
      }

      // Append other fields, excluding specified keys
      for (const key in formData) {
        if (
          ![
            "image",
            "medical_license",
            "certifications",
            "experience",
            "department",
            "specialities",
            "timetables",
            "signature",
            "Education",
          ].includes(key)
        ) {
          addData(key, formData[key]);
        }
      }

      const res = await (
        await fetch(`${baseURL}/doctors/${doctorDetailsResults?.id}/`, {
          method: "PUT",
          body: formDataToSend,
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "multipart/form-data", // Do not set Content-Type for FormData; the browser will handle it
          },
        })
      ).json();

      if (res?.user) {
        setLoading(false);
        toast(`Successfully Updated!`, {
          theme: "light",
          position: "top-right",
        });
        // ShowSucess("Successfully Updated!");
        // setRegisterDrModal(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      ShowError("Something Went Wrong!");
      console.log("error", error);
    }
  };

  const handleClose = () => {
    setRegisterDrModal(false);
    handleReset();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateField() && isEdited) {
      updateDoctorDetails();
    }

    // Perform form submission logic
    // Example: validate form data, submit API request, etc.
  };

  // const isStepOptional = (step) => {
  //   return step === 3; // Example: Address Information step is optional
  // };

  const isStepSkipped = (step) => {
    return false; // Placeholder for skipped steps logic if needed
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const validateField = () => {
    let error = "";
    let isValid = true;

    // Check if formData.image and formData.signature exist
    if (formData?.image) {
      const ImagefileSize = formData.image.size; // Size in bytes
      const imgfileSizeInMB = ImagefileSize / (1024 * 1024);
      const imgfileSizeInKB = ImagefileSize / 1024;

      if (imgfileSizeInMB > 2) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: "The maximum file size that can be uploaded is 2MB",
        }));
        isValid = false;
      } else if (imgfileSizeInKB < 100) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: "The file size is too small. Minimum size is 100KB",
        }));
        isValid = false;
      } else if (formData?.image?.name?.length > 100) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          image: "File name should not exceed 100 characters",
        }));
        isValid = false;
      }
    }

    if (formData?.signature) {
      const signaturefileSize = formData.signature.size;
      const sigfileSizeInMB = signaturefileSize / (1024 * 1024);
      const sigfileSizeInKB = signaturefileSize / 1024;

      if (sigfileSizeInMB > 2) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          signature: "The maximum file size that can be uploaded is 2MB",
        }));
        isValid = false;
      } else if (sigfileSizeInKB < 100) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          signature: "The file size is too small. Minimum size is 100KB",
        }));
        isValid = false;
      } else if (formData?.signature?.name?.length > 100) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          signature: "File name should not exceed 100 characters",
        }));
        isValid = false;
      }
    }
    if (!/\S+@\S+\.\S+/.test(formData?.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email address is invalid",
      }));
      isValid = false;
    }
    // If no errors, clear any previous errors
    if (isValid) {
      setErrors({});
    }

    return isValid;
  };

  const validateForm = () => {
    let valid = true;
    Object.keys(formData).forEach((key) => {
      validateField(key, formData[key]);
      if (!formData[key] || errors[key]) {
        valid = false;
      }
    });
    return valid;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    validateField(name, value);
    setIsEdited(true);
  };
  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      name: "",
      date_of_birth: "",
      gender: "",
      contact_no: "",
      email: "",
      medical_license_number: "",
      specialization: "",
      years_of_experience: "",
      experience: "",
      expdetails: "",
      department: null,
      hospital_clinic_affiliation: "",
      medical_school: "",
      graduation_year: "",
      residency: "",
      fellowships: "",
      image: "",
      medical_license: "",
      certifications: "",
      address: "",
      street_address: "",
      city: "",
      state_province: "",
      postal_code: "",
      country: "",
      username: "",
      password: "",
    });
    setErrors({});
  };
  const renderTextInput = useCallback(
    (
      type,
      state,
      label,
      disabled = false,
      required = false,
      multiline = false
    ) => {
      const showError = !!errors[state];
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      };
      return (
        <TextField
          required={required}
          size="small"
          fullWidth
          margin="dense"
          variant="outlined"
          label={label}
          name={state}
          id={state}
          type={state === "phone" ? "number" : type}
          disabled={disabled}
          error={showError}
          helperText={showError ? errors[state] : undefined}
          multiline={multiline}
          rows={multiline ? 3 : 1}
          value={formData[state] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    },
    [formData, errors, handleBlur]
  );
  const handleExperienceChange = (index, field, value) => {
    if (field === "start_date" || field === "end_date") {
      handleCalculate(index);
    }
    const updatedMedicines = [...experiences];
    updatedMedicines[index][field] = value;
    setExperiences(updatedMedicines);
    setIsEdited(true);
  };
  const handleRemoveExperience = (index) => {
    const updatedMedicines = experiences.filter((_, i) => i !== index);
    setExperiences(updatedMedicines);
    setIsEdited(true);
  };
  const handleEducationChange = (index, field, value) => {
    const updatedEducations = [...educations];
    updatedEducations[index][field] = value;
    setEducations(updatedEducations);
    setIsEdited(true);
  };
  const handleRemoveEducation = (index) => {
    const updatedEducations = educations.filter((_, i) => i !== index);
    setEducations(updatedEducations);
    setIsEdited(true);
  };
  useEffect(() => {
    if (doctorDetailsResults && Object.keys(doctorDetailsResults)?.length > 0) {
      // Create a new object to avoid mutating the original one
      const updatedDoctorDetails = { ...doctorDetailsResults };

      // Replace null values with default values for specific keys
      Object.keys(updatedDoctorDetails).forEach((key) => {
        if (updatedDoctorDetails[key] === null) {
          // Set a default value based on the key's expected type
          updatedDoctorDetails[key] =
            typeof updatedDoctorDetails[key] === "object" ? [] : "";
        }
      });

      // Update the form data
      setFormData(updatedDoctorDetails);

      // Check and set experiences if it exists and is not null or empty
      if (
        updatedDoctorDetails?.experience !== null &&
        updatedDoctorDetails?.experience?.length > 0
      ) {
        setExperiences(updatedDoctorDetails?.experience);
      }

      // Check and set education if it exists and is not null or empty
      if (
        updatedDoctorDetails?.Education &&
        updatedDoctorDetails?.Education?.length > 0
      ) {
        setEducations(updatedDoctorDetails?.Education);
      }
    }
  }, [doctorDetailsResults]);

  const isFormValid = () => {
    switch (activeStep) {
      case 0:
        return (
          formData.first_name !== "" &&
          formData.last_name !== "" &&
          formData.date_of_birth !== "" &&
          formData.gender !== "" &&
          formData.contact_no !== "" &&
          formData.email !== "" &&
          /\S+@\S+\.\S+/.test(formData?.email)
          // Add more validations as needed for each step
        );
      case 1:
        return (
          formData.medical_license_number !== "" &&
          formData.specialization !== "" &&
          formData.years_of_experience !== "" &&
          formData.hospital_clinic_affiliation !== "" &&
          formData.medical_school !== "" &&
          formData.graduation_year !== "" &&
          formData.residency !== "" &&
          formData.fellowships !== ""
        );
      case 2:
        return (
          formData.image !== "" &&
          formData.medical_license !== "" &&
          formData.certifications !== ""
        );
      // case 3:
      //   return (
      //     formData.address !== "" &&
      //     formData.city !== "" &&
      //     formData.country !== ""
      //   );
      //   case 3:
      //     return (
      //       formData.username !== "" && formData.password !== ""
      //       // formData.confirmPassword !== "" &&
      //       // formData.password === formData.confirmPassword
      //     );
      default:
        return false;
    }
  };
  const country = [
    { value: "USA", label: "United States" },
    { value: "Canada", label: "Canada" },
    { value: "Australia", label: "Australia" },
  ];
  const options = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];
  const specializationMenu = [
    { value: "Cardiologist", label: "Cardiologist" },
    { value: "Dermatology", label: "Dermatology" },
    { value: "Neurology", label: "Neurology" },
    { value: "Pediatrics", label: "Pediatrics" },
    { value: "Other", label: "Other" },
  ];
  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container-fluid"
      />
      {/* <Container> */}
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 mt-sm-0 pt-sm-0">
              <Header
                title="Edit Profile Details" // Replace with the title you want
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              <Card>
                {loading && loadingPanel()}

                <Card.Body>
                  <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      // if (isStepOptional(index)) {
                      //   labelProps.optional = (
                      //     <Typography variant="caption">Optional</Typography>
                      //   );
                      // }
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <Container style={{ marginTop: "25px" }}>
                    <form onSubmit={handleSubmit}>
                      {activeStep === 0 && (
                        <div>
                          {/* <Typography variant="h6" gutterBottom>
                            Personal Information
                          </Typography> */}
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "first_name",
                                "First Name",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "last_name",
                                "Last Name",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                type="date"
                                name="date_of_birth"
                                fullWidth
                                margin="dense"
                                label="DOB"
                                InputLabelProps={{ shrink: true }}
                                size="small"
                                placeholder="Date of Birth"
                                value={
                                  formData.date_of_birth ||
                                  dayjs(new Date()).format("MM/DD/YYYY")
                                }
                                onChange={handleInputChange}
                                error={!!errors.date_of_birth}
                                helperText={errors.date_of_birth}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                fullWidth
                                select={true}
                                id="outlined-select-requestType"
                                size="small"
                                label="Gender"
                                margin="dense"
                                name="gender"
                                value={formData.gender}
                                onChange={handleInputChange}
                                error={!!errors.gender}
                                helperText={errors.gender}
                              >
                                {options.map((item) => (
                                  <MenuItem value={item.value} key={item.value}>
                                    {item?.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "blood_type",
                                "Blood Type",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "contact_no",
                                "Contact Number",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput("text", "email", "Email", false)}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "address",
                                "Address",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "street_address",
                                "Street Address",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput("text", "city", "City", false)}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "state_province",
                                "State/Province",
                                false
                              )}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "postal_code",
                                "Postal Code",
                                false
                              )}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <TextField
                                select
                                name="country"
                                fullWidth
                                margin="dense"
                                size="small"
                                label="Country"
                                value={formData.country}
                                onChange={handleInputChange}
                                error={!!errors.country}
                                helperText={errors.country}
                              >
                                {country.map((item) => (
                                  <MenuItem value={item.value} key={item.value}>
                                    {item?.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                      {activeStep === 1 && (
                        <div>
                          {/* <Typography variant="h6" gutterBottom>
                            Professional Information
                          </Typography> */}
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                select
                                name="specialization"
                                fullWidth
                                margin="dense"
                                size="small"
                                label="Specialization"
                                value={formData.specialization}
                                onChange={handleInputChange}
                                error={!!errors.specialization}
                                helperText={errors.specialization}
                              >
                                {specializationMenu.map((item) => (
                                  <MenuItem value={item.value} key={item.value}>
                                    {item?.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "number",
                                "years_of_experience",
                                "Years Of Experience",
                                false
                              )}
                            </Grid>
                            {educations && educations?.length > 0 && (
                              <Grid item xs={12} sm={12}>
                                <div>
                                  <h5 style={{ marginBottom: "15px" }}>
                                    Education
                                  </h5>
                                  {/* <CardBody> */}
                                  {educations.map((education, index) => (
                                    <div
                                      key={`education_${index}`}
                                      className="row mb-3 align-items-center"
                                    >
                                      <Grid container spacing={1}>
                                        <Grid
                                          item
                                          style={{
                                            margin: "auto 0",
                                            paddingRight: "5px",
                                          }}
                                        >
                                          <Badge pill bg="primary">
                                            {`${index + 1}`}
                                          </Badge>{" "}
                                        </Grid>
                                        <Grid item xs={11} sm={11}>
                                          <Grid container spacing={1}>
                                            <Grid item xs={6} sm={6}>
                                              <TextField
                                                type="text"
                                                placeholder="Degree Name"
                                                label="Degree"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                value={education?.degree}
                                                error={!education?.degree}
                                                onChange={(e) =>
                                                  handleEducationChange(
                                                    index,
                                                    "degree",
                                                    e.target.value
                                                  )
                                                }
                                                required
                                              />
                                            </Grid>

                                            <Grid item xs={6} sm={6}>
                                              <TextField
                                                type="text"
                                                placeholder="Graduation Year"
                                                label="Graduation Year"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                value={
                                                  education?.year_of_graduation
                                                }
                                                error={
                                                  !education?.year_of_graduation
                                                }
                                                onChange={(e) =>
                                                  handleEducationChange(
                                                    index,
                                                    "year_of_graduation",
                                                    e.target.value
                                                  )
                                                }
                                                required
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid container spacing={1}>
                                            <Grid
                                              item
                                              xs={11}
                                              sm={11}
                                              style={{ marginTop: "10px" }}
                                            >
                                              <TextField
                                                type="text"
                                                fullWidth
                                                size="small"
                                                placeholder="Institute Name"
                                                label="Institute Name"
                                                variant="outlined"
                                                value={education?.institution}
                                                error={!education?.institution}
                                                onChange={(e) =>
                                                  handleEducationChange(
                                                    index,
                                                    "institution",
                                                    e.target.value
                                                  )
                                                }
                                                onKeyDown={(e) =>
                                                  handleKeyDown(
                                                    e,
                                                    index,
                                                    "education"
                                                  )
                                                }
                                                required
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={1}
                                              sm={1}
                                              style={{
                                                marginTop: "10px",
                                              }}
                                            >
                                              {/* {index !== 0 && ( */}
                                              <Grid container>
                                                <Grid item>
                                                  <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                      <Tooltip
                                                        id={`tooltip-${index}`}
                                                      >
                                                        Remove Entity
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <ClearIcon
                                                      color="primary"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        if (
                                                          educations &&
                                                          educations?.length > 1
                                                        ) {
                                                          handleRemoveEducation(
                                                            index
                                                          );
                                                        } else {
                                                          ShowError(
                                                            "At least one entry must remain!"
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </OverlayTrigger>
                                                  <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                      <Tooltip
                                                        id={`tooltip-${index}`}
                                                      >
                                                        Reset Entity
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <ReplayIcon
                                                      color="primary"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        resetEducation(index)
                                                      }
                                                    />
                                                    {/* <Button
                                                      onClick={() =>
                                                        setExperiences
                                                      }
                                                      size="small"
                                                      sx={{ ml: 1 }}
                                                      startIcon={}
                                                    ></Button> */}
                                                  </OverlayTrigger>
                                                </Grid>
                                              </Grid>

                                              {/* )} */}
                                            </Grid>
                                          </Grid>
                                        </Grid>

                                        {/* </div> */}
                                      </Grid>
                                    </div>
                                  ))}

                                  <div style={{ marginLeft: "35px" }}>
                                    <Button
                                      variant="contained"
                                      disabled={!allKeysHaveValues(educations)}
                                      onClick={() => handleAddEducation()}
                                      size="small"
                                      sx={{ mr: 1 }}
                                    >
                                      + Add New Entry
                                    </Button>
                                  </div>
                                  {/* </CardBody> */}
                                </div>
                              </Grid>
                            )}
                            {experiences && experiences?.length > 0 && (
                              <Grid item xs={12} sm={12}>
                                <Card>
                                  <h5 style={{ marginBottom: "15px" }}>
                                    Experience
                                  </h5>
                                  {/* <CardBody> */}
                                  {experiences.map((experience, index) => (
                                    <div
                                      key={index}
                                      className="row mb-3 align-items-center"
                                    >
                                      <Grid container spacing={1}>
                                        <Grid
                                          item
                                          style={{
                                            margin: "auto 0",
                                            paddingRight: "5px",
                                          }}
                                        >
                                          <Badge pill bg="primary">
                                            {`${index + 1}`}
                                          </Badge>
                                        </Grid>
                                        <Grid item xs={11} sm={11}>
                                          <Grid container spacing={1}>
                                            <Grid item xs={6} sm={6}>
                                              <TextField
                                                type="text"
                                                fullWidth
                                                size="small"
                                                placeholder="Hospital Name"
                                                label="Hospital Name"
                                                value={experience?.institution}
                                                error={!experience?.institution}
                                                onChange={(e) =>
                                                  handleExperienceChange(
                                                    index,
                                                    "institution",
                                                    e.target.value
                                                  )
                                                }
                                                required
                                                variant="outlined"
                                              />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                              <TextField
                                                type="text"
                                                placeholder="Role"
                                                label="Role"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                value={experience?.role}
                                                error={!experience?.role}
                                                onChange={(e) =>
                                                  handleExperienceChange(
                                                    index,
                                                    "role",
                                                    e.target.value
                                                  )
                                                }
                                                required
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            container
                                            spacing={1}
                                            style={{ marginTop: "1px" }}
                                          >
                                            <Grid item xs={4} sm={4}>
                                              <TextField
                                                type="date"
                                                name="start_date"
                                                fullWidth
                                                margin="dense"
                                                label="Start Date"
                                                size="small"
                                                placeholder="Start Date"
                                                error={!experience?.start_date}
                                                value={
                                                  experience?.start_date || ""
                                                }
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                onChange={(e) => {
                                                  if (
                                                    experience?.end_date &&
                                                    e?.target?.value >
                                                      experience?.end_date
                                                  ) {
                                                    ShowError(
                                                      "Start Date must be less than End date!"
                                                    );
                                                    return;
                                                  }
                                                  handleExperienceChange(
                                                    index,
                                                    "start_date",
                                                    e?.target?.value
                                                  );
                                                }}
                                              />
                                            </Grid>
                                            <Grid item xs={4} sm={4}>
                                              <TextField
                                                type="date"
                                                name="end_date"
                                                fullWidth
                                                margin="dense"
                                                label="End Date"
                                                size="small"
                                                placeholder="End Date"
                                                InputLabelProps={{
                                                  shrink: true,
                                                }}
                                                error={!experience?.end_date}
                                                value={
                                                  experience?.end_date || ""
                                                }
                                                onChange={(e) => {
                                                  if (
                                                    experience?.start_date &&
                                                    e?.target?.value <
                                                      experience?.start_date
                                                  ) {
                                                    ShowError(
                                                      "End Date must be greater than Start Date!"
                                                    );
                                                    return;
                                                  }
                                                  handleExperienceChange(
                                                    index,
                                                    "end_date",
                                                    e?.target?.value
                                                  );
                                                }}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={4}
                                              sm={4}
                                              style={{ marginTop: "7px" }}
                                            >
                                              <TextField
                                                type="text"
                                                placeholder="Total"
                                                label="Total"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                value={
                                                  experience?.total_year || ""
                                                }
                                                error={!experience?.total_year}
                                                onChange={(e) =>
                                                  handleExperienceChange(
                                                    index,
                                                    "total_year",
                                                    e.target.value
                                                  )
                                                }
                                                required
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid container spacing={1}>
                                            <Grid
                                              item
                                              xs={11}
                                              sm={11}
                                              style={{ marginTop: "10px" }}
                                            >
                                              <TextField
                                                multiline
                                                fullWidth
                                                // size="small"
                                                placeholder="description"
                                                label="Description"
                                                variant="outlined"
                                                value={experience?.description}
                                                error={!experience?.description}
                                                onChange={(e) =>
                                                  handleExperienceChange(
                                                    index,
                                                    "description",
                                                    e.target.value
                                                  )
                                                }
                                                rows={2}
                                                onKeyDown={(e) =>
                                                  handleKeyDown(
                                                    e,
                                                    index,
                                                    "experience"
                                                  )
                                                }
                                                required
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={1}
                                              sm={1}
                                              style={{
                                                margin: "auto 0",
                                              }}
                                            >
                                              <Grid container>
                                                <Grid item>
                                                  <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                      <Tooltip
                                                        id={`tooltip-${index}`}
                                                      >
                                                        Remove Entity
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <ClearIcon
                                                      color="primary"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        if (
                                                          experiences &&
                                                          experiences?.length >
                                                            1
                                                        ) {
                                                          handleRemoveExperience(
                                                            index
                                                          );
                                                        } else {
                                                          ShowError(
                                                            "At least one entry must remain!"
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </OverlayTrigger>
                                                  <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                      <Tooltip
                                                        id={`tooltip-${index}`}
                                                      >
                                                        Reset Entity
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <ReplayIcon
                                                      color="primary"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        resetExperience(index)
                                                      }
                                                    />
                                                  </OverlayTrigger>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}
                                  {/* </CardBody> */}
                                  <div style={{ marginLeft: "35px" }}>
                                    <Button
                                      variant="contained"
                                      disabled={!allKeysHaveValues(experiences)}
                                      onClick={() => handleAddExperience()}
                                      size="small"
                                      sx={{ mr: 1 }}
                                    >
                                      + Add New Entry
                                    </Button>
                                  </div>
                                </Card>
                              </Grid>
                            )}

                            {/* <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "number",
                                "years_of_experience",
                                "Years Of Experience",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              {renderTextInput(
                                "text",
                                "experience",
                                "Experience",
                                false
                              )}
                            </Grid> */}
                            <Grid item xs={12} sm={12}>
                              {renderTextInput(
                                "text",
                                "expdetails",
                                "Experience Details",
                                false,
                                false,
                                true
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "number",
                                "charge",
                                "Charge",
                                false
                              )}
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                              <TextField
                                select
                                name="department"
                                fullWidth
                                margin="dense"
                                size="small"
                                label="Department"
                                value={formData.department}
                                onChange={handleInputChange}
                                error={!!errors.department}
                                helperText={errors.department}
                              >
                                {departmentMenu.map((item) => (
                                  <MenuItem value={item.value} key={item.value}>
                                    {item?.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid> */}
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "medical_license_number",
                                "Medical License Number",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "hospital_clinic_affiliation",
                                "Affiliation",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "medical_school",
                                "Medical School",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "graduation_year",
                                "Graduation Year",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "residency",
                                "Residency",
                                false
                              )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              {renderTextInput(
                                "text",
                                "fellowships",
                                "Fellowships",
                                false
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      )}
                      {activeStep === 2 && (
                        <div
                          style={{
                            margin: "35px 0px",
                          }}
                        >
                          {/* <Typography variant="h6" gutterBottom>
                        Upload Documents
                      </Typography> */}

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Col>
                              {formData?.image && (
                                <div
                                  style={{
                                    height: formData?.image ? "135px" : 0,
                                  }}
                                >
                                  {/* <Typography variant="subtitle1">
                                Preview:
                              </Typography> */}
                                  <img
                                    src={
                                      formData?.image &&
                                      typeof formData?.image === "string"
                                        ? formData?.image
                                        : formData?.image instanceof Blob
                                        ? URL.createObjectURL(formData?.image)
                                        : ""
                                    }
                                    alt="Preview"
                                    style={{
                                      width: "100%",
                                      maxWidth: 200,
                                      maxHeight: 150,
                                    }}
                                  />
                                </div>
                              )}
                              <Button
                                component="label"
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                              >
                                Upload Profile Image
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="image"
                                  id="image"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                />
                                {/* <VisuallyHiddenInput type="file" /> */}
                              </Button>
                              <br />
                              {errors?.image ? (
                                <span
                                  style={{
                                    // marginBottom: "-9px",
                                    // marginTop: "3px",
                                    marginTop: 0,
                                    color: "#d32f2f",
                                    fontSize: "0.75rem",
                                  }}
                                  // className="error"
                                >
                                  {errors?.image}
                                  {/* Image file Name should be at least of 100
                                  charactor length */}
                                </span>
                              ) : null}
                            </Col>
                            <Col>
                              <div
                                style={{
                                  height: formData?.signature
                                    ? "135px"
                                    : formData?.image
                                    ? "135px"
                                    : 0,
                                }}
                              >
                                {/* <Typography variant="subtitle1">
                                Preview:
                              </Typography> */}
                                {formData?.signature && (
                                  <img
                                    src={
                                      formData?.signature &&
                                      typeof formData?.signature === "string"
                                        ? formData?.signature
                                        : formData?.signature instanceof Blob
                                        ? URL.createObjectURL(
                                            formData?.signature
                                          )
                                        : ""
                                    }
                                    alt="Preview"
                                    style={{
                                      width: "100%",
                                      maxWidth: 230,
                                      height: formData?.signature
                                        ? "130px"
                                        : "130px",
                                    }}
                                  />
                                )}
                              </div>
                              <Button
                                component="label"
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                                style={{ marginLeft: "10px" }}
                              >
                                Upload Signature
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="signature"
                                  id="signature"
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                />
                                {/* <VisuallyHiddenInput type="file" /> */}
                              </Button>
                              <br />
                              {errors?.signature ? (
                                <span
                                  style={{
                                    // marginBottom: "-9px",
                                    // marginTop: "3px",
                                    marginTop: 0,
                                    color: "#d32f2f",
                                    fontSize: "0.75rem",
                                  }}
                                  // className="error"
                                >
                                  {errors?.signature}
                                  {/* Signature file Name should be at least of 100
                                  charactor length */}
                                </span>
                              ) : null}
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: formData?.medical_license?.name
                                    ? "112px"
                                    : formData?.image
                                    ? "93px"
                                    : 0,
                                }}
                              >
                                {formData?.medical_license?.name ? (
                                  <div>{formData.medical_license?.name}</div>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<VisibilityIcon />}
                                    onClick={(e) =>
                                      handleDocClick(
                                        formData?.medical_license,
                                        e
                                      )
                                    }
                                    style={{ marginBottom: "5px" }}
                                    href={formData?.medical_license}
                                    target="_blank" // Opens the link in a new tab
                                    rel="noopener noreferrer"
                                  >
                                    {" "}
                                    <a
                                      style={{ color: "#fff" }}
                                      href={formData?.medical_license}
                                      onClick={(e) =>
                                        handleDocClick(
                                          formData?.medical_license,
                                          e
                                        )
                                      }
                                    >
                                      Medical License.pdf{" "}
                                    </a>{" "}
                                  </Button>
                                )}
                                <Button
                                  component="label"
                                  variant="outlined"
                                  tabIndex={-1}
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Medical License Doc
                                  <input
                                    type="file"
                                    id="medical_license"
                                    name="medical_license"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                  {/* <VisuallyHiddenInput type="file" /> */}
                                </Button>
                              </div>
                            </Col>
                            <Col>
                              <div
                                style={{
                                  marginTop: formData?.certifications?.name
                                    ? "112px"
                                    : formData?.image
                                    ? "93px"
                                    : 0,
                                }}
                              >
                                {formData?.certifications?.name ? (
                                  <div> {formData.certifications?.name}</div>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<VisibilityIcon />}
                                    onClick={(e) =>
                                      handleDocClick(
                                        formData?.certifications,
                                        e
                                      )
                                    }
                                    style={{ marginBottom: "5px" }}
                                    href={formData?.certifications}
                                    target="_blank" // Opens the link in a new tab
                                    rel="noopener noreferrer"
                                  >
                                    {" "}
                                    <a
                                      style={{ color: "#fff" }}
                                      href={formData?.certifications}
                                      onClick={(e) =>
                                        handleDocClick(
                                          formData?.certifications,
                                          e
                                        )
                                      }
                                    >
                                      Certificate.pdf{" "}
                                    </a>{" "}
                                  </Button>
                                )}
                                <Button
                                  component="label"
                                  variant="outlined"
                                  tabIndex={-1}
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Certifications Doc
                                  <input
                                    type="file"
                                    name="certifications"
                                    id="certifications"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                </Button>
                              </div>
                            </Col>
                          </div>
                        </div>
                      )}

                      <div style={{ marginTop: "24px" }}>
                        <Button
                          disabled={activeStep === 0}
                          variant="contained"
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                        {activeStep !== steps.length - 1 && (
                          <Button
                            variant="contained"
                            // disabled={!isFormValid()}
                            onClick={handleNext}
                            sx={{ mr: 1 }}
                          >
                            Next
                          </Button>
                        )}
                        {activeStep === steps.length - 1 && (
                          <Button
                            type="submit"
                            variant="contained"
                            disabled={!isFormValid()}
                            sx={{ mr: 1 }}
                          >
                            Update
                          </Button>
                        )}
                        {/* <Button variant="contained" onClick={handleReset}>
                          Reset
                        </Button> */}
                      </div>
                    </form>
                  </Container>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
