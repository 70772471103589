// import React from "react";

// import { patientsData } from "../data/data";

// import TinySlider from "tiny-slider-react";
// import 'tiny-slider/dist/tiny-slider.css';

// const settings = {
//     container: '.client-review-slider',
//     items: 1,
//     controls: false,
//     mouseDrag: true,
//     loop: true,
//     rewind: true,
//     autoplay: true,
//     autoplayButtonOutput: false,
//     autoplayTimeout: 3000,
//     navPosition: "bottom",
//     speed: 400,
//     gutter: 16,
//   };

// export default function Patients(){
//     return(
//         <div className="client-review-slider">
//             <TinySlider settings={settings}>
//                 {patientsData.map((item, index) => (
//                     <div className="tiny-slide text-center" key={index}>
//                         <p className="text-muted fw-normal fst-italic">{item.desc}</p>
//                         <img src={item.image} className="img-fluid avatar avatar-small rounded-circle mx-auto shadow my-3" alt=""/>
//                         <ul className="list-unstyled mb-0">
//                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                         </ul>
//                         <h6 className="text-primary">{item.name} <small className="text-muted">{item.title}</small></h6>
//                     </div>
//                 ))}
//             </TinySlider>
//         </div>
//     )
// }

// import React, { useEffect, useState } from "react";
// import TinySlider from "tiny-slider-react";
// import 'tiny-slider/dist/tiny-slider.css';
// import data from "../assets/data.json";  // Make sure this path is correct

// const settings = {
//     container: '.client-review-slider',
//     items: 1,
//     controls: false,
//     mouseDrag: true,
//     loop: true,
//     rewind: true,
//     autoplay: true,
//     autoplayButtonOutput: false,
//     autoplayTimeout: 3000,
//     navPosition: "bottom",
//     speed: 400,
//     gutter: 16,
// };

// export default function Patients() {
//     const [doctordata,setDoctorData ] = useState([])
//     useEffect(() => {
//         // Fetch doctor data from API
//         fetch(`${process.env.REACT_APP_API_URL}/ratings/display/`)
//             .then(response => response.json())
//             .then(data => setDoctorData(data))
//             .catch(error => console.error('Error fetching doctor data:', error));
//     }, []);
//     const { patientsData } = data;  // Destructuring patientsData from data
// console.log(patientsData)
// if (!patientsData || !Array.isArray(patientsData)) {
//     console.error("patientsData is not defined or not an array");
//     return <p>Error loading patient data.</p>;
// }

//     return (
//         <div className="client-review-slider">
//             <TinySlider settings={settings}>
//                 {patientsData.map((item, index) => (
//                     <div className="tiny-slide text-center" key={index}>
//                         <p className="text-muted fw-normal fst-italic">{item.desc}</p>
//                         <img src={require(`../assets/images/client/${item.image}`)} className="img-fluid avatar avatar-small rounded-circle mx-auto shadow my-3" alt={item.name} />
//                         <ul className="list-unstyled mb-0">
//                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                         </ul>
//                         <h6 className="text-primary">{item.name} <small className="text-muted">{item.title}</small></h6>
//                     </div>
//                 ))}
//             </TinySlider>
//         </div>
//     );
// }

import React, { useEffect, useState } from "react";
import TinySlider from "tiny-slider-react";
import 'tiny-slider/dist/tiny-slider.css';

const settings = {
    container: '.client-review-slider',
    items: 1,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 16,
};

export default function Patients() {
    const [doctorData, setDoctorData] = useState([]);

    useEffect(() => {
        // Fetch doctor data from API
        fetch(`${process.env.REACT_APP_API_URL}/ratings/display/`)
            .then(response => response.json())
            .then(data => setDoctorData(data))
            .catch(error => console.error('Error fetching doctor data:', error));
    }, []);

    if (!doctorData || !Array.isArray(doctorData)) {
        console.error("doctorData is not defined or not an array");
        return <p>Error loading patient data.</p>;
    }

    return (
        <div className="client-review-slider">
            <TinySlider settings={settings}>
                {doctorData.slice(0,10).map((item) => (
                    <div className="tiny-slide text-center" key={item.id}>
                        <p className="text-muted fw-normal fst-italic">{item.review_msg}</p>
                        {/* Replace the following image path with a placeholder or an actual image if available */}
                        {/* <img src={require(`../assets/images/client/placeholder.png`)} className="img-fluid avatar avatar-small rounded-circle mx-auto shadow my-3" alt={item.user_name} /> */}
                        <ul className="list-unstyled mb-0">
                            {[...Array(item.rating)].map((_, index) => (
                                <li className="list-inline-item" key={index}>
                                    <i className="mdi mdi-star text-warning"></i>
                                </li>
                            ))}
                        </ul>
                        <h6 className="text-primary">{item.user_name} 
                        {/* <small className="text-muted">Rating</small> */}
                        </h6>
                    </div>
                ))}
            </TinySlider>
        </div>
    );
}
