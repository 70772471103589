import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material';
import { convertTo12HourFormat } from '../../components/CommonComponents/CommonFunction';

const TimetableModal = ({ id, handleCloseModal }) => {
  const [timetables, setTimetables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [noTimetables, setNoTimetables] = useState(false); // State for checking if there are no timetables

  const daysOfWeekOrder = [
    'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
  ];

  useEffect(() => {
    const fetchTimetables = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/doctors/details/${id}/`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const sortedTimetables = data.timetables.sort((a, b) => 
          daysOfWeekOrder.indexOf(a.day_of_week) - daysOfWeekOrder.indexOf(b.day_of_week)
        );
        if (sortedTimetables.length === 0) {
          setNoTimetables(true);
        }
        setTimetables(sortedTimetables);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTimetables();
  }, [id]);

  const formatTime = (timeStr) => convertTo12HourFormat(timeStr);

  const renderTimetable = (timetable) => (
    <tr key={timetable.id} style={{ borderBottom: '1px solid #ddd' }}>
      <td style={{ padding: '10px', borderRight: '1px solid #ddd', whiteSpace: 'nowrap' }}>{timetable.clinic_name || 'N/A'}</td>
      <td style={{ padding: '10px', borderRight: '1px solid #ddd', whiteSpace: 'nowrap' }}>{timetable.clinic_address || 'N/A'}</td>
      <td style={{ padding: '10px', borderRight: '1px solid #ddd', whiteSpace: 'nowrap' }}>{timetable.day_of_week || 'N/A'}</td>
      <td style={{ padding: '10px', borderRight: '1px solid #ddd', whiteSpace: 'nowrap' }}>{`${formatTime(timetable.start_time)} - ${formatTime(timetable.end_time)}`}</td>
      <td style={{ padding: '10px', borderRight: '1px solid #ddd', whiteSpace: 'nowrap' }}>{`${formatTime(timetable.break_start)} - ${formatTime(timetable.break_end)}`}</td>
    </tr>
  );

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        padding: '10px', // Add padding for smaller screens
      }}
    >
      <div
        style={{
          position: 'relative',
          background: 'white',
          padding: '20px',
          borderRadius: '8px',
          maxWidth: '100%',
          width: '100%',
          maxWidth: '600px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          overflowX: 'auto', // Ensure table doesn't overflow
        }}
      >
        <span
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            fontSize: '24px',
            color: '#333',
          }}
          onClick={handleCloseModal}
        >
          &times;
        </span>
        <h2 style={{ marginTop: 0, marginBottom: '15px' }}>Timetable</h2>
        {loading ? (
          <div>
            <Skeleton height={40} count={1} style={{ marginBottom: '10px' }} />
            {[...Array(5)].map((_, index) => (
              <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
                <Skeleton width={120} height={30} style={{ marginRight: '10px' }} />
                <Skeleton width={100} height={30} style={{ marginRight: '10px' }} />
                <Skeleton width={150} height={30} style={{ marginRight: '10px' }} />
                <Skeleton width={150} height={30} />
              </div>
            ))}
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : noTimetables ? (
          <p>No timetable for this doctor yet. Please wait, we will update shortly.</p>
        ) : (
          <div style={{ overflowX: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr style={{ backgroundColor: '#f4f4f4' }}>
                  <th style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'left', minWidth: '120px' }}>Clinic</th>
                  <th style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'left', minWidth: '120px' }}>Address</th>
                  <th style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'left', minWidth: '100px' }}>Day</th>
                  <th style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'left', minWidth: '150px' }}>Timing</th>
                  <th style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'left', minWidth: '150px' }}>Break Time</th>
                </tr>
              </thead>
              <tbody>
                {timetables.map(renderTimetable)}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimetableModal;
