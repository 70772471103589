import { combineReducers } from "redux";

import DoctorReducer from "../store/doctor/reducer";
import CommonReducer from "./common/reducer";
import PatientReducer from "../store/patient/reducer";

const rootReducer = () => {
  return combineReducers({
    DoctorReducer,
    CommonReducer,
    PatientReducer,
  });
};

export default rootReducer;
