// import React from "react";
// import { Link } from "react-router-dom";

// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import Chart from "../../components/dashboard/chart";
// import ReviewOne from "../../components/review/reviewOne";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";

// import { latestAppointment, upcomingAppointment, partners, adminFeature } from "../../data/data";

// import {FiCalendar, FiArrowRight, RiCalendar2Line} from '../../assets/icons/vander'

// export default function DoctorDashBoard(){
//     return(
//         <>
//         <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container-fluid"/>
//         <section className="bg-dashboard">
//             <div className="container-fluid">
//                 <div className="row">
//                     <Sidebar colClass ="col-xl-3 col-lg-4 col-md-5 col-12"/>
//                     <div className="col-xl-9 col-lg-8 col-md-7 mt-4 mt-sm-0">
//                         <h5 className="mb-0">Dashboard</h5>
//                         <Chart/>

//                         <div className="row">
//                             <div className="col-xl-4 col-lg-6 mt-4">
//                                 <div className="card border-0 shadow rounded">
//                                     <div className="d-flex justify-content-between p-4 border-bottom">
//                                         <h6 className="mb-0 d-flex align-items-center"><FiCalendar className="text-primary me-2 h5 mb-0"/> Latest Appointment</h6>
//                                         <h6 className="text-muted mb-0">55 Patients</h6>
//                                     </div>

//                                     <ul className="list-unstyled mb-0 p-4 pt-0">
//                                         {latestAppointment.map((item,index) =>{
//                                             return(
//                                                 <li className="pt-4 ms-0" key={index}>
//                                                     <Link to="#">
//                                                         <div className="d-flex align-items-center justify-content-between">
//                                                             <div className="d-inline-flex">
//                                                                 <img src={item.image} className="avatar avatar-md-sm rounded-circle border shadow" alt=""/>
//                                                                 <div className="ms-3">
//                                                                     <h6 className="text-dark mb-0 d-block">{item.name}</h6>
//                                                                     <small className="text-muted">{item.date}</small>
//                                                                 </div>
//                                                             </div>
//                                                             <FiArrowRight className="h5 text-dark"/>
//                                                         </div>
//                                                     </Link>
//                                                 </li>
//                                             )
//                                         })}
//                                     </ul>
//                                 </div>
//                             </div>

//                             <div className="col-xl-4 col-lg-6 mt-4">
//                                 <div className="card border-0 shadow rounded">
//                                     <div className="d-flex justify-content-between p-4 border-bottom">
//                                         <h6 className="mb-0"><RiCalendar2Line className="text-primary me-1 h5 mb-0"/> Upcoming Appointment</h6>
//                                         <h6 className="text-muted mb-0">55 Patients</h6>
//                                     </div>

//                                     <ul className="list-unstyled mb-0 p-4 pt-0">
//                                         {upcomingAppointment.map((item,index)=>{
//                                             return(
//                                                 <li className="mt-4 ms-0" key={index}>
//                                                     <Link to="#">
//                                                         <div className="d-flex align-items-center justify-content-between">
//                                                             <div className="d-inline-flex">
//                                                                 <img src={item.image} className="avatar avatar-md-sm rounded-circle border shadow" alt=""/>
//                                                                 <div className="ms-3">
//                                                                     <h6 className="text-dark mb-0 d-block">{item.name}</h6>
//                                                                     <small className="text-muted">{item.date}</small>
//                                                                 </div>
//                                                             </div>
//                                                             <FiArrowRight className="h5 text-dark"/>
//                                                         </div>
//                                                     </Link>
//                                                 </li>
//                                             )
//                                         })}
//                                     </ul>
//                                 </div>
//                             </div>

//                             <div className="col-xl-4 col-lg-12 mt-4">
//                                 <div className="card border-0 shadow rounded">
//                                     <div className="p-4 border-bottom">
//                                         <h6 className="mb-0">Patient's Review</h6>
//                                     </div>

//                                     <div className="p-4">
//                                        <ReviewOne/>

//                                         <div className="row justify-content-center mt-3">
//                                             {partners.slice(0,4).map((item,index) =>{
//                                                 return(
//                                                     <div className="col-md col-6 text-center pt-3" key={index}>
//                                                         <img src={item} className="avatar avatar-client" alt=""/>
//                                                     </div>
//                                                 )
//                                             })}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="row">
//                             {adminFeature.map((item,index) =>{
//                                 let Icon = item.icon
//                                 return(
//                                     <div className="col-xl-3 col-md-6 mt-4" key={index}>
//                                         <div className="card features feature-primary text-center border-0 p-4 rounded shadow">
//                                             <div className="icon text-center rounded-lg mx-auto">
//                                                 <Icon className="align-middle h3 mb-0"/>
//                                             </div>
//                                             <div className="card-body p-0 mt-3">
//                                                 <Link to="#" className="title text-dark h5">{item.title}</Link>
//                                                 <p className="text-muted my-2">{item.desc}</p>
//                                                 <Link to="#" className="link">Read more <FiArrowRight className="align-middle"/></Link>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )
//                             })}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//         <AdminFooter/>
//         <ScrollTop/>
//         </>
//     )
// }

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import Chart from "../../components/dashboard/chart";
// import ReviewOne from "../../components/review/reviewOne";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import { FiCalendar, FiArrowRight, RiCalendar2Line } from '../../assets/icons/vander';
// import {  partners, adminFeature } from "../../data/data";
// export default function DoctorDashBoard() {
//     const [latestAppointments, setLatestAppointments] = useState([]);
//     const [upcomingAppointments, setUpcomingAppointments] = useState([]);
//     const [patientCount, setPatientCount] = useState(0);

//     const doctorId = localStorage.getItem("doctorId");

//     useEffect(() => {
//         const fetchAppointments = async () => {
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`);
//                 const data = await response.json();

//                 const today = new Date();
//                 const tomorrow = new Date();
//                 tomorrow.setDate(today.getDate() + 1);

//                 const filteredLatestAppointments = data.filter(appointment => {
//                     const appointmentDate = new Date(appointment.appointment_date);
//                     return (
//                         appointmentDate.toDateString() === today.toDateString() ||
//                         appointmentDate.toDateString() === tomorrow.toDateString()
//                     );
//                 });

//                 const filteredUpcomingAppointments = data.filter(appointment => {
//                     const appointmentDate = new Date(appointment.appointment_date);
//                     return (
//                         appointmentDate > tomorrow
//                     );
//                 });

//                 setLatestAppointments(filteredLatestAppointments);
//                 setUpcomingAppointments(filteredUpcomingAppointments);
//                 setPatientCount(filteredLatestAppointments.length);
//             } catch (error) {
//                 console.error("Error fetching appointments:", error);
//             }
//         };

//         fetchAppointments();
//     }, [doctorId]);

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container-fluid" />
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 mt-sm-0">
//                             <h5 className="mb-0">Dashboard</h5>
//                             <Chart />

//                             <div className="row">
//                                 <div className="col-xl-4 col-lg-6 mt-4">
//                                     <div className="card border-0 shadow rounded">
//                                         <div className="d-flex justify-content-between p-4 border-bottom">
//                                             <h6 className="mb-0 d-flex align-items-center">
//                                                 <FiCalendar className="text-primary me-2 h5 mb-0" /> Latest Appointment
//                                             </h6>
//                                             <h6 className="text-muted mb-0">{patientCount} Patients</h6>
//                                         </div>
//                                         <ul className="list-unstyled mb-0 p-4 pt-0">
//                                             {latestAppointments.map((item, index) => (
//                                                 <li className="pt-4 ms-0" key={index}>
//                                                     <Link to="#">
//                                                         <div className="d-flex align-items-center justify-content-between">
//                                                             <div className="d-inline-flex">
//                                                                 <img src={item.patient_image} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
//                                                                 <div className="ms-3">
//                                                                     <h6 className="text-dark mb-0 d-block">{item.patient_name}</h6>
//                                                                     <small className="text-muted">{item.appointment_date}</small>
//                                                                 </div>
//                                                             </div>
//                                                             <FiArrowRight className="h5 text-dark" />
//                                                         </div>
//                                                     </Link>
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     </div>
//                                 </div>

//                                 <div className="col-xl-4 col-lg-6 mt-4">
//                                     <div className="card border-0 shadow rounded">
//                                         <div className="d-flex justify-content-between p-4 border-bottom">
//                                             <h6 className="mb-0">
//                                                 <RiCalendar2Line className="text-primary me-1 h5 mb-0" /> Upcoming Appointment
//                                             </h6>
//                                             <h6 className="text-muted mb-0">{upcomingAppointments.length} Patients</h6>
//                                         </div>
//                                         <ul className="list-unstyled mb-0 p-4 pt-0">
//                                             {upcomingAppointments.map((item, index) => (
//                                                 <li className="mt-4 ms-0" key={index}>
//                                                     <Link to="#">
//                                                         <div className="d-flex align-items-center justify-content-between">
//                                                             <div className="d-inline-flex">
//                                                                 <img src={item.patient_image} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
//                                                                 <div className="ms-3">
//                                                                     <h6 className="text-dark mb-0 d-block">{item.patient_name}</h6>
//                                                                     <small className="text-muted">{item.appointment_date}</small>
//                                                                 </div>
//                                                             </div>
//                                                             <FiArrowRight className="h5 text-dark" />
//                                                         </div>
//                                                     </Link>
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     </div>
//                                 </div>

//                                 <div className="col-xl-4 col-lg-12 mt-4">
//                                     <div className="card border-0 shadow rounded">
//                                         <div className="p-4 border-bottom">
//                                             <h6 className="mb-0">Patient's Review</h6>
//                                         </div>
//                                         <div className="p-4">
//                                             <ReviewOne />
//                                             <div className="row justify-content-center mt-3">
//                                                 {partners.slice(0, 4).map((item, index) => (
//                                                     <div className="col-md col-6 text-center pt-3" key={index}>
//                                                         <img src={item} className="avatar avatar-client" alt="" />
//                                                     </div>
//                                                 ))}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="row">
//                                 {adminFeature.map((item, index) => {
//                                     let Icon = item.icon;
//                                     return (
//                                         <div className="col-xl-3 col-md-6 mt-4" key={index}>
//                                             <div className="card features feature-primary text-center border-0 p-4 rounded shadow">
//                                                 <div className="icon text-center rounded-lg mx-auto">
//                                                     <Icon className="align-middle h3 mb-0" />
//                                                 </div>
//                                                 <div className="card-body p-0 mt-3">
//                                                     <Link to="#" className="title text-dark h5">{item.title}</Link>
//                                                     <p className="text-muted my-2">{item.desc}</p>
//                                                     <Link to="#" className="link">Read more <FiArrowRight className="align-middle" /></Link>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     );
//                                 })}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <AdminFooter />
//             <ScrollTop />
//         </>
//     );
// }

// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import Chart from "../../components/dashboard/chart";
// import ReviewOne from "../../components/review/reviewOne";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import { FiCalendar, FiArrowRight, RiCalendar2Line } from '../../assets/icons/vander';
// import {  partners, adminFeature } from "../../data/data";
// export default function DoctorDashBoard() {
//     const [latestAppointments, setLatestAppointments] = useState([]);
//     const [upcomingAppointments, setUpcomingAppointments] = useState([]);
//     const [patientCount, setPatientCount] = useState(0);

//     const doctorId = localStorage.getItem("doctorId");

//     useEffect(() => {
//         const fetchAppointments = async () => {
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`);
//                 const data = await response.json();

//                 const today = new Date();
//                 const tomorrow = new Date();
//                 tomorrow.setDate(today.getDate() + 1);

//                 console.log('Today:', today);
//                 console.log('Tomorrow:', tomorrow);

//                 // Filter and sort for latest appointments
//                 const filteredLatestAppointments = data
//                     .filter(appointment => {
//                         const appointmentDate = new Date(appointment.appointment_date);
//                         console.log(`Appointment Date (${appointment.patient_name}):`, appointmentDate);
//                         return (
//                             appointmentDate.toDateString() === today.toDateString() ||
//                             appointmentDate.toDateString() === tomorrow.toDateString()
//                         );
//                     })
//                     .filter(appointment => appointment.status === "confirmed")
//                     .sort((a, b) => {
//                         const dateA = new Date(`${a.appointment_date}T${a.appointment_time}`);
//                         const dateB = new Date(`${b.appointment_date}T${b.appointment_time}`);
//                         return dateA - dateB;
//                     });

//                 // Filter and sort for upcoming appointments
//                 const filteredUpcomingAppointments = data
//                     .filter(appointment => {
//                         const appointmentDate = new Date(appointment.appointment_date);
//                         console.log(`Upcoming Appointment Date (${appointment.patient_name}):`, appointmentDate);
//                         return (
//                             appointmentDate > tomorrow
//                         );
//                     })
//                     .filter(appointment => appointment.status === "confirmed")
//                     .sort((a, b) => {
//                         const dateA = new Date(`${a.appointment_date}T${a.appointment_time}`);
//                         const dateB = new Date(`${b.appointment_date}T${b.appointment_time}`);
//                         return dateA - dateB;
//                     });

//                 setLatestAppointments(filteredLatestAppointments);
//                 setUpcomingAppointments(filteredUpcomingAppointments);
//                 setPatientCount(filteredLatestAppointments.length);
//             } catch (error) {
//                 console.error("Error fetching appointments:", error);
//             }
//         };

//         fetchAppointments();
//     }, [doctorId]);

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container-fluid" />
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 mt-sm-0">
//                             <h5 className="mb-0">Dashboard</h5>
//                             <Chart />

//                             <div className="row">
//                                 <div className="col-xl-4 col-lg-6 mt-4">
//                                     <div className="card border-0 shadow rounded">
//                                         <div className="d-flex justify-content-between p-4 border-bottom">
//                                             <h6 className="mb-0 d-flex align-items-center">
//                                                 <FiCalendar className="text-primary me-2 h5 mb-0" /> Latest Appointment
//                                             </h6>
//                                             <h6 className="text-muted mb-0">{patientCount} Patients</h6>
//                                         </div>
//                                         <ul className="list-unstyled mb-0 p-4 pt-0">
//                                             {latestAppointments.map((item, index) => (
//                                                 <li className="pt-4 ms-0" key={index}>
//                                                     <Link to="#">
//                                                         <div className="d-flex align-items-center justify-content-between">
//                                                             <div className="d-inline-flex">
//                                                                 <img src={item.patient_image} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
//                                                                 <div className="ms-3">
//                                                                     <h6 className="text-dark mb-0 d-block">{item.patient_name}</h6>
//                                                                     <small className="text-muted">{item.appointment_date}</small>
//                                                                 </div>
//                                                             </div>
//                                                             <FiArrowRight className="h5 text-dark" />
//                                                         </div>
//                                                     </Link>
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     </div>
//                                 </div>

//                                 <div className="col-xl-4 col-lg-6 mt-4">
//                                     <div className="card border-0 shadow rounded">
//                                         <div className="d-flex justify-content-between p-4 border-bottom">
//                                             <h6 className="mb-0">
//                                                 <RiCalendar2Line className="text-primary me-1 h5 mb-0" /> Upcoming Appointment
//                                             </h6>
//                                             <h6 className="text-muted mb-0">{upcomingAppointments.length} Patients</h6>
//                                         </div>
//                                         <ul className="list-unstyled mb-0 p-4 pt-0">
//                                             {upcomingAppointments.map((item, index) => (
//                                                 <li className="mt-4 ms-0" key={index}>
//                                                     <Link to="#">
//                                                         <div className="d-flex align-items-center justify-content-between">
//                                                             <div className="d-inline-flex">
//                                                                 <img src={item.patient_image} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
//                                                                 <div className="ms-3">
//                                                                     <h6 className="text-dark mb-0 d-block">{item.patient_name}</h6>
//                                                                     <small className="text-muted">{item.appointment_date}</small>
//                                                                 </div>
//                                                             </div>
//                                                             <FiArrowRight className="h5 text-dark" />
//                                                         </div>
//                                                     </Link>
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     </div>
//                                 </div>

//                                 <div className="col-xl-4 col-lg-12 mt-4">
//                                     <div className="card border-0 shadow rounded">
//                                         <div className="p-4 border-bottom">
//                                             <h6 className="mb-0">Patient's Review</h6>
//                                         </div>
//                                         <div className="p-4">
//                                             <ReviewOne />
//                                             <div className="row justify-content-center mt-3">
//                                                 {partners.slice(0, 4).map((item, index) => (
//                                                     <div className="col-md col-6 text-center pt-3" key={index}>
//                                                         <img src={item} className="avatar avatar-client" alt="" />
//                                                     </div>
//                                                 ))}
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="row">
//                                 {adminFeature.map((item, index) => {
//                                     let Icon = item.icon;
//                                     return (
//                                         <div className="col-xl-3 col-md-6 mt-4" key={index}>
//                                             <div className="card features feature-primary text-center border-0 p-4 rounded shadow">
//                                                 <div className="icon text-center rounded-lg mx-auto">
//                                                     <Icon className="align-middle h3 mb-0" />
//                                                 </div>
//                                                 <div className="card-body p-0 mt-3">
//                                                     <Link to="#" className="title text-dark h5">{item.title}</Link>
//                                                     <p className="text-muted my-2">{item.desc}</p>
//                                                     <Link to="#" className="link">Read more <FiArrowRight className="align-middle" /></Link>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     );
//                                 })}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <AdminFooter />
//             <ScrollTop />
//         </>
//     );
// }

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Chart from "../../components/dashboard/chart";
import ReviewOne from "../../components/review/reviewOne";
import AdminFooter from "../../components/dashboard/adminFooter";
import { getUserData } from "../../_helper/helper";
import ScrollTop from "../../components/scrollTop";
import {
  FiCalendar,
  FiArrowRight,
  RiCalendar2Line,
} from "../../assets/icons/vander";
import { partners, adminFeature } from "../../data/data";
import dr1 from "../../assets/images/doctors/01.jpg";
import Header from "../../components/CommonComponents/Header";
export default function DoctorDashBoard() {
  const [latestAppointments, setLatestAppointments] = useState([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [patientCount, setPatientCount] = useState(0);
  const { role, assigned_doctor_id, doctor_id } = getUserData();
  const doctorId = role === "staff" ? assigned_doctor_id : doctor_id;
  const navigate = useNavigate();
  const location = useLocation();
  const showBackButton = location.key !== "default";
  const token = localStorage.getItem("accessToken"); 
  useEffect(() => {
    if (doctorId) {
      const fetchAppointments = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`,
            {
              method: 'GET', // You can specify the method explicitly
              headers: {
                'Authorization': `Bearer ${token}`, // Add the Authorization header
                'Content-Type': 'application/json' // Optional: Set the content type if needed
              },
            }
          );
          const data = await response.json();

          const today = new Date();
          const tomorrow = new Date();
          tomorrow.setDate(today.getDate() + 1);

          // Define the range for latest appointments (today and tomorrow)
          const dayAfterTomorrow = new Date();
          dayAfterTomorrow.setDate(today.getDate() + 2);

          // Define the range for upcoming appointments (2 days from today to next 30 days)
          const startOfUpcoming = new Date();
          startOfUpcoming.setDate(today.getDate() + 2);
          const endOfUpcoming = new Date();
          endOfUpcoming.setDate(today.getDate() + 32); // Next 30 days

          // Filter and sort for latest appointments (today and tomorrow)
          const filteredLatestAppointments = data
            .filter((appointment) => {
              const appointmentDate = new Date(appointment.appointment_date);
              return (
                appointmentDate.toDateString() === today.toDateString() ||
                appointmentDate.toDateString() === tomorrow.toDateString()
              );
            })
            .filter((appointment) => appointment.status === "confirmed")
            .sort((a, b) => {
              const dateA = new Date(
                `${a.appointment_date}T${a.appointment_time}`
              );
              const dateB = new Date(
                `${b.appointment_date}T${b.appointment_time}`
              );
              return dateA - dateB;
            });

          // Extract appointment IDs or timestamps for upcoming appointments exclusion
          const excludedAppointmentIds = filteredLatestAppointments.map(
            (appointment) => appointment.id
          );

          // Filter and sort for upcoming appointments (2 days from today to next 30 days, excluding latest appointments)
          const filteredUpcomingAppointments = data
            .filter((appointment) => {
              const appointmentDate = new Date(appointment.appointment_date);
              return (
                appointmentDate >= startOfUpcoming &&
                appointmentDate <= endOfUpcoming &&
                !excludedAppointmentIds.includes(appointment.id)
              );
            })
            .filter((appointment) => appointment.status === "confirmed")
            .sort((a, b) => {
              const dateA = new Date(
                `${a.appointment_date}T${a.appointment_time}`
              );
              const dateB = new Date(
                `${b.appointment_date}T${b.appointment_time}`
              );
              return dateA - dateB;
            });

          setLatestAppointments(filteredLatestAppointments);
          setUpcomingAppointments(filteredUpcomingAppointments);
          setPatientCount(filteredLatestAppointments.length);
        } catch (error) {
          console.error("Error fetching appointments:", error);
        }
      };
      fetchAppointments();
    }
  }, [doctorId]);
  // useEffect(() => {
  //     const fetchAppointments = async () => {
  //         try {
  //             const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`);
  //             const data = await response.json();

  //             const today = new Date();
  //             const dayAfterTomorrow = new Date();
  //             dayAfterTomorrow.setDate(today.getDate() + 2);

  //             // Define the start and end of the next month
  //             const startOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  //             const endOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

  //             // Filter and sort for latest appointments
  //             const filteredLatestAppointments = data
  //                 .filter(appointment => {
  //                     const appointmentDate = new Date(appointment.appointment_date);
  //                     return (
  //                         appointmentDate.toDateString() === today.toDateString() ||
  //                         appointmentDate.toDateString() === dayAfterTomorrow.toDateString()
  //                     );
  //                 })
  //                 .filter(appointment => appointment.status === "confirmed")
  //                 .sort((a, b) => {
  //                     const dateA = new Date(`${a.appointment_date}T${a.appointment_time}`);
  //                     const dateB = new Date(`${b.appointment_date}T${b.appointment_time}`);
  //                     return dateA - dateB;
  //                 });

  //             // Filter and sort for upcoming appointments in the next month
  //             const filteredUpcomingAppointments = data
  //                 .filter(appointment => {
  //                     const appointmentDate = new Date(appointment.appointment_date);
  //                     return (
  //                         appointmentDate >= dayAfterTomorrow &&
  //                         appointmentDate <= endOfNextMonth
  //                     );
  //                 })
  //                 .filter(appointment => appointment.status === "confirmed")
  //                 .sort((a, b) => {
  //                     const dateA = new Date(`${a.appointment_date}T${a.appointment_time}`);
  //                     const dateB = new Date(`${b.appointment_date}T${b.appointment_time}`);
  //                     return dateA - dateB;
  //                 });

  //             setLatestAppointments(filteredLatestAppointments);
  //             setUpcomingAppointments(filteredUpcomingAppointments);
  //             setPatientCount(filteredLatestAppointments.length);
  //         } catch (error) {
  //             console.error("Error fetching appointments:", error);
  //         }
  //     };

  //     fetchAppointments();
  // }, [doctorId]);

  // useEffect(() => {
  //     const fetchAppointments = async () => {
  //         try {
  //             const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`);
  //             const data = await response.json();

  //             const today = new Date();
  //             const tomorrow = new Date();
  //             tomorrow.setDate(today.getDate() + 1);

  //             console.log('Today:', today);
  //             console.log('Tomorrow:', tomorrow);

  //             // Filter and sort for latest appointments
  //             const filteredLatestAppointments = data
  //                 .filter(appointment => {
  //                     const appointmentDate = new Date(appointment.appointment_date);
  //                     console.log(`Appointment Date (${appointment.patient_name}):`, appointmentDate);
  //                     return (
  //                         appointmentDate.toDateString() === today.toDateString() ||
  //                         appointmentDate.toDateString() === tomorrow.toDateString()
  //                     );
  //                 })
  //                 .filter(appointment => appointment.status === "confirmed")
  //                 .sort((a, b) => {
  //                     const dateA = new Date(`${a.appointment_date}T${a.appointment_time}`);
  //                     const dateB = new Date(`${b.appointment_date}T${b.appointment_time}`);
  //                     return dateA - dateB;
  //                 });

  //             // Filter and sort for upcoming appointments
  //             const filteredUpcomingAppointments = data
  //                 .filter(appointment => {
  //                     const appointmentDate = new Date(appointment.appointment_date);
  //                     console.log(`Upcoming Appointment Date (${appointment.patient_name}):`, appointmentDate);
  //                     return (
  //                         appointmentDate > tomorrow
  //                     );
  //                 })
  //                 .filter(appointment => appointment.status === "confirmed")
  //                 .sort((a, b) => {
  //                     const dateA = new Date(`${a.appointment_date}T${a.appointment_time}`);
  //                     const dateB = new Date(`${b.appointment_date}T${b.appointment_time}`);
  //                     return dateA - dateB;
  //                 });

  //             setLatestAppointments(filteredLatestAppointments);
  //             setUpcomingAppointments(filteredUpcomingAppointments);
  //             setPatientCount(filteredLatestAppointments.length);
  //         } catch (error) {
  //             console.error("Error fetching appointments:", error);
  //         }
  //     };

  //     fetchAppointments();
  // }, [doctorId]);
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // "en-GB" gives the format "dd/mm/yyyy"
  };
  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
        // navDark={true}
        // manuClass="navigation-menu nav-left"
        // containerClass="container-fluid"
      />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 mt-sm-0">
              {/* <h5 className="mb-0">Dashboard</h5> */}
              <Header
                title="Dashboard"
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              {/* <Chart /> */}

              <div className="row">
                <div className="col-xl-4 col-lg-6 mt-4">
                  <div className="card border-0 shadow rounded"
                  style={{ height:"300px"}}>
                    <div className="d-flex justify-content-between p-4 border-bottom">
                      <h6 className="mb-0 d-flex align-items-center">
                        <FiCalendar className="text-primary me-2 h5 mb-0" />{" "}
                        Latest Appointment
                      </h6>
                      <h6 className="text-muted mb-0">
                        {patientCount} Patients
                      </h6>
                    </div>
                    <ul
                      className="list-unstyled mb-0 p-4 pt-0"
                      style={{
                        maxHeight: "400px" /* Adjust as needed */,
                        overflowY: "auto",
                      }}
                    >
                      {latestAppointments.map((item, index) => (
                        <li className="pt-4 ms-0" key={index}>
                          <Link to="#">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-inline-flex">
                                <img
                                  src={dr1}
                                  className="avatar avatar-md-sm rounded-circle border shadow"
                                  alt=""
                                />
                                {/* <div className="ms-3">
                                                                    <h6 className="text-dark mb-0 d-block">{item.patient_name}</h6>
                                                                    <small className="text-muted">{item.appointment_date}</small>
                                                                    <small className="text-muted">{formatTime(item.appointment_time)}</small>
                                                                </div> */}
                                <div className="ms-3">
                                  <h6 className="text-dark mb-0 d-block">
                                    {item.patient_name}
                                  </h6>
                                  <small
                                    className="text-muted"
                                    style={{
                                      display: "block",
                                      marginBottom: "4px",
                                    }}
                                  >
                                    {formatDate(item.appointment_date)}{" "}
                                    <span style={{ marginLeft: "10px" }}>
                                      {formatTime(item.appointment_time)}
                                    </span>
                                  </small>
                                </div>
                              </div>
                              <FiArrowRight className="h5 text-dark" />
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 mt-4">
                  <div className="card border-0 shadow rounded"
                  style={{ height:"300px"}}>
                    <div className="d-flex justify-content-between align-items-center p-4 border-bottom">
                      {/* <div className="d-flex justify-content-between p-4 border-bottom"> */}
                      <h6 className="mb-0" style={{ whiteSpace: "nowrap" }}>
                        <RiCalendar2Line className="text-primary me-1 h5 mb-0" />
                        <span className="mx-1">
                        Upcoming Appointment
                        </span>
                      </h6>
                      <h6
                        className="text-muted mb-0"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {upcomingAppointments.length} Patients
                      </h6>
                      {/* <h6 className="mb-0">
                                                <RiCalendar2Line className="text-primary me-1 h5 mb-0" /> Upcoming Appointment
                                            </h6> */}
                      {/* <h6 className="text-muted mb-0">{upcomingAppointments.length} Patients</h6> */}
                    </div>
                    <ul
                      className="list-unstyled mb-0 p-4 pt-0"
                      style={{
                        maxHeight: "400px" /* Adjust as needed */,
                        overflowY: "auto",
                      }}
                    >
                      {upcomingAppointments.map((item, index) => (
                        <li className="mt-4 ms-0" key={index}>
                          <Link to="#">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-inline-flex">
                                <img
                                  src={dr1}
                                  className="avatar avatar-md-sm rounded-circle border shadow"
                                  alt=""
                                />
                                <div className="ms-3">
                                  <h6 className="text-dark mb-0 d-block">
                                    {item.patient_name}
                                  </h6>
                                  <small
                                    className="text-muted"
                                    style={{
                                      display: "block",
                                      marginBottom: "4px",
                                    }}
                                  >
                                    {formatDate(item.appointment_date)}{" "}
                                    <span style={{ marginLeft: "10px" }}>
                                      {formatTime(item.appointment_time)}
                                    </span>
                                  </small>
                                </div>
                              </div>
                              <FiArrowRight className="h5 text-dark" />
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <div className="col-xl-4 col-lg-6 mt-4">
                                    <div className="card border-0 shadow rounded">
                                        <div className="d-flex justify-content-between p-4 border-bottom">
                                            <h6 className="mb-0">
                                                <RiCalendar2Line className="text-primary me-1 h5 mb-0" /> Upcoming Appointment
                                            </h6>
                                            <h6 className="text-muted mb-0">{upcomingAppointments.length} Patients</h6>
                                        </div>
                                        <ul className="list-unstyled mb-0 p-4 pt-0">
                                            {upcomingAppointments.map((item, index) => (
                                                <li className="mt-4 ms-0" key={index}>
                                                    <Link to="#">
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <div className="d-inline-flex">
                                                                <img src={dr1} className="avatar avatar-md-sm rounded-circle border shadow" alt="" />
                                                                <div className="ms-3">
  <h6 className="text-dark mb-0 d-block">{item.patient_name}</h6>
  <small className="text-muted" style={{ display: 'block', marginBottom: '4px' }}>
    {formatDate(item.appointment_date)} <span style={{ marginLeft: '10px' }}>{formatTime(item.appointment_time)}</span>
  </small>
</div>
                                                            </div>
                                                            <FiArrowRight className="h5 text-dark" />
                                                        </div>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div> */}

                <div className="col-xl-4 col-lg-12 mt-4">
                  <div className="card border-0 shadow rounded"
                  style={{ height:"300px"}}>
                    <div className="p-4 border-bottom">
                      <h6 className="mb-0">Patient's Review</h6>
                    </div>
                    <div className="p-4">
                      <ReviewOne />
                      {/* <div className="row justify-content-center mt-3">
                                                {partners.slice(0, 4).map((item, index) => (
                                                    <div className="col-md col-6 text-center pt-3" key={index}>
                                                        <img src={item} className="avatar avatar-client" alt="" />
                                                    </div>
                                                ))}
                                            </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <Chart />
              {/* <div className="row">
                {adminFeature.map((item, index) => {
                  let Icon = item.icon;
                  return (
                    <div className="col-xl-3 col-md-6 mt-4" key={index}>
                      <div className="card features feature-primary text-center border-0 p-4 rounded shadow">
                        <div className="icon text-center rounded-lg mx-auto">
                          <Icon className="align-middle h3 mb-0" />
                        </div>
                        <div className="card-body p-0 mt-3">
                          <Link to="#" className="title text-dark h5">
                            {item.title}
                          </Link>
                          <p className="text-muted my-2">{item.desc}</p>
                          <Link to="#" className="link">
                            Read more <FiArrowRight className="align-middle" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <AdminFooter />
      <ScrollTop />
    </>
  );
}
