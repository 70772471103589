import React, { useState, useEffect, useRef } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ReBookingAppointment({  
   show,
  handleClose,
  selectedDoctor,
  selectedPatientName,
  parentAppointmentId, }) {
    const [formData, setFormData] = useState({
      doctor: selectedDoctor,
      patient: selectedPatientName, // Set patient name
      appointment_date: "",
      appointment_time: "",
      parent_appointment: parentAppointmentId,
    });
    
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("accessToken");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      doctor: selectedDoctor,
      patient: localStorage.getItem("userId"), // Assuming patient ID is stored here
      appointment_date: formData.appointment_date,
      appointment_time: formData.appointment_time,
      parent_appointment: parentAppointmentId,
      patient_name: formData.name,
    };

    fetch(`${process.env.REACT_APP_API_URL}/appointments/book/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success("Appointment booked successfully!");
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        console.error("Error booking appointment:", error);
        toast.error("Failed to book appointment. Please try again.");
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Book an Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">
                Patient Name <span className="text-danger">*</span>
              </label>
              <input
                name="patient_name"
                id="patient_name"
                type="text"
                className="form-control"
                value={formData.patient} // Display patient name
                readOnly
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Date <span className="text-danger">*</span>
              </label>
              <input
                name="appointment_date"
                type="date"
                className="form-control"
                value={formData.appointment_date}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Time <span className="text-danger">*</span>
              </label>
              <input
                name="appointment_time"
                type="time"
                className="form-control"
                value={formData.appointment_time}
                onChange={handleChange}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loading}
              style={{ margin: "auto", width: "300px" }}
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" className="me-2" />
                  Booking...
                </>
              ) : (
                "Book An Appointment"
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
  
}
