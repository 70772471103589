import {
  PATIENT_DETAILS,
  PATIENT_DETAILS_SUCCESS,
  PATIENT_DETAILS_FAIL,
} from "./actionTypes";
export const getPatientDetails = () => {
  return {
    type: PATIENT_DETAILS,
  };
};
export const getPatientDetailsSuccess = (results) => {
  return {
    type: PATIENT_DETAILS_SUCCESS,
    payload: results,
  };
};
export const getPatientDetailsFail = (error) => {
  return {
    type: PATIENT_DETAILS_FAIL,
    payload: error,
  };
};
