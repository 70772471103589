export const DOCTOR_DETAILS = "DOCTOR_DETAILS";
export const DOCTOR_DETAILS_SUCCESS = "DOCTOR_DETAILS_SUCCESS";
export const DOCTOR_DETAILS_FAIL = "DOCTOR_DETAILS_FAIL";

export const GET_POSITIONS_DETAILS = "GET_POSITIONS_DETAILS";
export const GET_POSITIONS_DETAILS_SUCCESS = "GET_POSITIONS_DETAILS_SUCCESS";
export const GET_POSITIONS_DETAILS_FAIL = "GET_POSITIONS_DETAILS_FAIL";

export const POSITION_UPDATE = "POSITION_UPDATE";
export const POSITION_UPDATE_SUCCESS = "POSITION_UPDATE_SUCCESS";
export const POSITION_UPDATE_FAIL = "POSITION_UPDATE_FAIL";

export const POSITION_CREATE = "POSITION_CREATE";
export const POSITION_CREATE_SUCCESS = "POSITION_CREATE_SUCCESS";
export const POSITION_CREATE_FAIL = "POSITION_CREATE_FAIL";

export const POSITION_DELETE = "POSITION_DELETE";
export const POSITION_DELETE_SUCCESS = "POSITION_DELETE_SUCCESS";
export const POSITION_DELETE_FAIL = "POSITION_DELETE_FAIL";
export const RESET = "RESET";

export const GET_LOOKUP_POSITIONS = "GET_LOOKUP_POSITIONS";
export const GET_LOOKUP_POSITIONS_SUCCESS = "GET_LOOKUP_POSITIONS_SUCCESS";
export const GET_LOOKUP_POSITIONS_FAIL = "GET_LOOKUP_POSITIONS_FAIL";
export const STORED_USER_GEO_LOCATION = "STORED_USER_GEO_LOCATION";
