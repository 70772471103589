import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { takeLatest, put, call } from "redux-saga/effects";
import { ShowError, ShowSucess } from "../../_helper/helper";

import { PATIENT_DETAILS } from "./actionTypes";

import { getPatientDetailsSuccess, getPatientDetailsFail } from "./actions";

import { patientDetails } from "../../_helper/services/patient";
function* onGetPatientDetails() {
  try {
    const response = yield call(patientDetails);
    yield put(getPatientDetailsSuccess(response));
  } catch (error) {
    yield put(getPatientDetailsFail(error.response));
  }
}

function* CartSaga() {
  yield takeLatest(PATIENT_DETAILS, onGetPatientDetails);
}

export default CartSaga;
