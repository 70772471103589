// SkeletonLoader.js
import React from 'react';
import './SkeletonLoader.css';

const SkeletonLoader = () => {
    return (
        <div className="skeleton-loader">
            <div className="skeleton-item skeleton-avatar"></div>
            <div className="skeleton-item skeleton-text skeleton-title"></div>
            <div className="skeleton-item skeleton-text"></div>
            <div className="skeleton-item skeleton-text"></div>
        </div>
    );
};

export default SkeletonLoader;
