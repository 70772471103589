import axios from "axios";
// import { toast } from "react-toastify";
//apply base url for axios
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const axiosApi = axios.create({
  baseURL: REACT_APP_API_URL,
});
// axiosApi.defaults.headers.common["x-access-token"] =
// localStorage.getItem("jwt_access_token") || "";
// sessionStorage.getItem("jwt_access_token") || "";
axiosApi.defaults.headers.common[
  "Authorization"
] = `Bearer ${localStorage.getItem("accessToken")}`;
export const setApiToken = (token) => {
  axiosApi.defaults.headers.common["x-access-token"] = token;
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const instance = axios
  .create()
  .interceptors.request.use((axiosconfig) => {
    axiosconfig.baseURL = process.env.REACT_APP_WEB_API_URL;

    return new Promise((resolve) => {
      const token = window.localStorage.getItem("jwt_access_token");
      axiosconfig.headers.Authorization = `Bearer ${token}`;
      resolve(axiosconfig);
    });
  });
axios.interceptors.request.use(function (config) {
  // const d = store.getState().session;
  if (config.headers.common) {
    // config.headers.common["x-access-token"] =
    //   localStorage.getItem("jwt_access_token");
    // config.headers.Authorization = `Bearer ${localStorage.getItem(
    //   "jwt_access_token"
    // )}`;
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    axiosApi.defaults.headers.common["role"] =
      sessionStorage.getItem("currentRole");
  }

  return config;
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config) {
  return await axiosApi
    .get(url, {
      ...config,
      headers: {
        ...(config?.headers ?? {}),
        role: sessionStorage.getItem("currentRole"),
      },
    })
    .then((response) => response.data);
}
export async function post(url, payload, config) {
  return await axiosApi
    .post(url, payload, {
      ...config,
      headers: {
        ...(config?.headers ?? {}),
        role: sessionStorage.getItem("currentRole"),
      },
    })
    .then((response) => response.data);
}
export async function postReturnResponse(url, payload, config) {
  // debugger;
  return await axiosApi
    .post(url, payload, {
      ...config,
      headers: {
        ...(config?.headers ?? {}),
        role: sessionStorage.getItem("currentRole"),
      },
    })
    .then((response) => response);
}
export async function put(url, payload, config) {
  return await axiosApi
    .put(url, payload, {
      ...config,
      headers: {
        ...(config?.headers ?? {}),
        role: sessionStorage.getItem("currentRole"),
      },
    })
    .then((response) => response.data);
}
export async function remove(url, payload, config) {
  return await axiosApi
    .delete(
      url,
      { data: payload },
      {
        ...config,
        headers: {
          ...(config?.headers ?? {}),
          role: sessionStorage.getItem("currentRole"),
        },
      }
    )
    .then((response) => response);
}
