// import React from 'react';
// import { Link } from 'react-router-dom';
// import { FiArrowRight } from 'react-icons/fi';

// function JoinHealthBridge() {
//   return (
//     <div className="join-healthbridge">
//       <div className="content">
//         <h4 className="title mb-4">Join healthBridge</h4>
//         <p className="description mb-4">
//           Become a part of our growing network. Whether you're a patient looking for quality care or a doctor aiming to expand your reach, healthBridge is here to support you.
//         </p>
//         <Link to="/signup" className="cta-button">
//           Sign Up Now <FiArrowRight className="icon" />
//         </Link>
//       </div>

//       <style jsx>{`
//         .join-healthbridge {
//           background-color: #f5f5f5; /* Light gray background */
//           padding: 5rem 0;
//           text-align: center;
//           color: #333;
//         }
//         .content {
//           max-width: 800px;
//           margin: 0 auto;
//           padding: 0 1.5rem;
//         }
//         .title {
//           font-size: 2.5rem;
//           font-weight: bold;
//           margin-bottom: 1rem;
//         }
//         .description {
//           font-size: 1.25rem;
//           margin-bottom: 2rem;
//         }
//         .cta-button {
//           display: inline-flex;
//           align-items: center;
//           background-color: #007bff; /* Bold button color */
//           color: #fff;
//           padding: 1rem 2rem;
//           font-size: 1.25rem;
//           border-radius: 0.5rem;
//           text-decoration: none;
//           transition: background-color 0.3s ease;
//           position: relative;
//         }
//         .cta-button:hover {
//           background-color: #0056b3; /* Darker button color on hover */
//         }
//         .icon {
//           margin-left: 0.5rem;
//           font-size: 1.5rem;
//         }
//       `}</style>
//     </div>
//   );
// }

// export default JoinHealthBridge;


// import React from 'react';
// import { Link } from 'react-router-dom';
// import { FiArrowRight } from 'react-icons/fi';

// function JoinHealthBridge() {
//   return (
//     <div className="join-healthbridge">
//       <div className="content">
//         <h4 className="title mb-4">Join healthBridge</h4>
//         <p className="text-muted para-desc mb-0">
//           Become a part of our growing network. Whether you're a patient looking for quality care or a doctor aiming to expand your reach, healthBridge is here to support you.
//         </p>
//         <Link to="/signup" className="cta-button">
//           Sign Up Now <FiArrowRight className="icon" />
//         </Link>
//       </div>

//       <style jsx>{`
//         .join-healthbridge {
//           background-color: #f8f9fa; /* Matching background color from FeatureSection */
//           padding: 5rem 0;
//           text-align: center;
//           color: #333;
//         }
//         .content {
//           max-width: 800px;
//           margin: 0 auto;
//           padding: 0 1.5rem;
//         }
      
        
//         .cta-button {
//           display: inline-flex;
//           align-items: center;
//           background-color: #007bff; /* Bold button color */
//           color: #fff;
//           padding: 1rem 2rem;
//           font-size: 1.25rem;
//           border-radius: 0.5rem;
//           text-decoration: none;
//           transition: background-color 0.3s ease;
//           position: relative;
//         }
//         .cta-button:hover {
//           background-color: #0056b3; /* Darker button color on hover */
//         }
//         .icon {
//           margin-left: 0.5rem;
//           font-size: 1.5rem;
//         }
//       `}</style>
//     </div>
//   );
// }

// export default JoinHealthBridge;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import RegistrationDoctorForm from '../RegisterDoctor/RegistrationDoctorForm';

function JoinHealthBridge() {
  const [registerDrModal, setRegisterDrModal] = useState(false);

  return (
    <div className="join-healthbridge">
      <div className="content">
        <h4 className="title mb-4">Join healthBridge</h4>
        <p className="text-muted para-desc mb-4">
          Become a part of our growing network. Whether you're a patient looking for quality care or a doctor aiming to expand your reach, healthBridge is here to support you.
        </p>
        <Link 
         onClick={(e) => {
          e.preventDefault(); // Prevent the default navigation
          setRegisterDrModal(true); // Open the modal
        }}
        
        className="cta-button">
          Sign Up Now <FiArrowRight className="icon" />
        </Link>
      </div>
      <RegistrationDoctorForm
        registerDrModal={registerDrModal}
        setRegisterDrModal={setRegisterDrModal}
      />
      <style jsx>{`
        .join-healthbridge {
          background-color: #f8f9fa; /* Light background color */
          padding: 5rem 0;
          text-align: center;
          color: #333;
        }
        .content {
          max-width: 800px;
          margin: 0 auto;
          padding: 0 1.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%; /* Ensure content is vertically centered */
        }
       
        .text-muted {
          font-size: .9rem;
          margin-bottom: 2rem;
          color: #666; /* Muted text color */
        }
        .cta-button {
          display: inline-flex;
          align-items: center;
          background-color: #007bff; /* Button color */
          color: #fff;
          padding: 1rem 2rem;
          font-size: 1.25rem;
          border-radius: 0.5rem;
          text-decoration: none;
          transition: background-color 0.3s ease, transform 0.3s ease;
          position: relative;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adding shadow for depth */
        }
        .cta-button:hover {
          background-color: #0056b3; /* Darker color on hover */
          transform: translateY(-2px); /* Slight lift effect on hover */
        }
        .icon {
          margin-left: 0.5rem;
          font-size: 1.5rem;
        }
      `}</style>
    </div>
  );
}

export default JoinHealthBridge;

