import React from "react";

const Pagination = ({ currentPage, totalPages, pageSize, onPageChange, onPageSizeChange }) => {
    const pageNumbers = [];

    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    let startPage = currentPage - halfMaxPagesToShow;
    let endPage = currentPage + halfMaxPagesToShow;

    if (startPage <= 1) {
        startPage = 1;
        endPage = maxPagesToShow;
    } else if (endPage >= totalPages) {
        startPage = totalPages - maxPagesToShow + 1;
        endPage = totalPages;
    }

    if (startPage < 1) startPage = 1;
    if (endPage > totalPages) endPage = totalPages;

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    return (
        <nav aria-label="Page navigation">
            <ul className="pagination justify-content-center">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button 
                        className="page-link" 
                        onClick={() => onPageChange(1)}
                        title="First Page"
                    >
                        &laquo;&laquo;
                    </button>
                </li>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button 
                        className="page-link" 
                        onClick={() => onPageChange(currentPage - 1)}
                        title="Previous Page"
                    >
                        &laquo;
                    </button>
                </li>
                {startPage > 1 && (
                    <li className="page-item disabled">
                        <span className="page-link">...</span>
                    </li>
                )}
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button 
                            className="page-link" 
                            onClick={() => onPageChange(number)}
                        >
                            {number}
                        </button>
                    </li>
                ))}
                {endPage < totalPages && (
                    <>
                        <li className="page-item disabled">
                            <span className="page-link">...</span>
                        </li>
                        <li className="page-item">
                            <button 
                                className="page-link" 
                                onClick={() => onPageChange(totalPages)}
                                title="Last Page"
                            >
                                {totalPages}
                            </button>
                        </li>
                    </>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button 
                        className="page-link" 
                        onClick={() => onPageChange(currentPage + 1)}
                        title="Next Page"
                    >
                        &raquo;
                    </button>
                </li>
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <button 
                        className="page-link" 
                        onClick={() => onPageChange(totalPages)}
                        title="Last Page"
                    >
                        &raquo;&raquo;
                    </button>
                </li>
                <li className="page-item">
                    <select 
                        className="form-select" 
                        value={pageSize} 
                        onChange={onPageSizeChange}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
