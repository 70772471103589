import React, { useState } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";

export default function AppointmentLogin({ onLoginSuccess, bookingType }) {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [showOtpVerification, setShowOtpVerification] = useState(false);

  const handleMobileLogin = async () => {
    if (!mobileNumber) {
      setOtpError("Please enter your mobile number.");
      return;
    }

    setOtpError("");
    setOtpLoading(true);

    try {
      const fullNumber = `+91${mobileNumber}`;
      await axios.post(`${process.env.REACT_APP_API_URL}/request-otp/`, {
        phone_number: fullNumber,
      });

      setShowOtpVerification(true); // Show OTP verification
    } catch (err) {
      console.error("Error:", err);
      if (err.response && err.response.data) {
        setOtpError(
          err.response.data.detail || "Failed to send OTP. Please try again."
        );
      } else {
        setOtpError("Failed to send OTP. Please try again.");
      }
    } finally {
      setOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setOtpError("Please enter OTP.");
      return;
    }

    setOtpError("");
    setOtpLoading(true);

    try {
      const fullNumber = `+91${mobileNumber}`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-otp/`,
        {
          phone_number: fullNumber,
          otp,
        }
      );
      const { access } = response.data;
console.log("accessdata",access)
console.log("accessdata1",response.data)
console.log("accessdata2",response)
      // Store the access token
      localStorage.setItem("accessToken", access);
      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("userRole", response.data.user.role);
      localStorage.setItem("userEmail", response.data.user.email);
      localStorage.setItem("firstName", response.data.user.first_name);
      localStorage.setItem("lastName", response.data.user.last_name);
      localStorage.setItem("userData", JSON.stringify(response.data));

      if (onLoginSuccess) {
        onLoginSuccess(); // Call the callback to notify successful login
      }

      // Optionally, reset fields if needed
      setMobileNumber("");
      setOtp("");
      setShowOtpVerification(false); // Hide OTP verification
    } catch (err) {
      console.error("Error:", err);
      if (err.response && err.response.data) {
        setOtpError(
          err.response.data.detail || "Failed to verify OTP. Please try again."
        );
      } else {
        setOtpError("Failed to verify OTP. Please try again.");
      }
    } finally {
      setOtpLoading(false);
    }
  };

  return (
    <div className="mobile-login">
      {!showOtpVerification ? (
        <div className="">
          <div>
            <input
              type="tel"
              className="form-control"
              placeholder="Enter your mobile number"
              value={mobileNumber}
              // onChange={(e) => setMobileNumber(e.target.value)}
              onChange={(e) => {
                const newValue = e.target.value.replace(/\D/g, "");
                setMobileNumber(newValue);
              }}
              pattern="[0-9]{10}"
              maxLength="10"
            />
            {otpError && <div className="text-danger mt-2">{otpError}</div>}
          </div>
          <Button
            variant="primary"
            onClick={handleMobileLogin}
            disabled={otpLoading}
            className="w-100 mt-2"
          >
            {otpLoading ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
              "Send OTP"
            )}
          </Button>
        </div>
      ) : (
        <>
          <input
            type="text"
            className="form-control"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          {otpError && <div className="text-danger mt-2">{otpError}</div>}
          <Button
            variant="primary"
            onClick={handleVerifyOtp}
            disabled={otpLoading}
            className="w-100 mt-2"
          >
            {otpLoading ? (
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
              "Verify OTP"
            )}
          </Button>
        </>
      )}
    </div>
  );
}
