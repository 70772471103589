import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Modal, Button } from "react-bootstrap";
import BookingAppointment from "../../pages/patient/booking-appointment";

import "bootstrap/dist/css/bootstrap.min.css";
import bg1 from "../../assets/images/bg/bg-lines-one.png";
// import bg1 from "../assets/images/bg/bg-lines-one.png";
import logoDark from "../../assets/images/logo-dark.png";

import { FiHome, SlSocialGoogle } from "../../assets/icons/vander";

export default function Login({
  showLoginSignUpModal,
  setShowLoginSignUpModal,
  selectedDoctor,
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotError, setForgotError] = useState("");
  const [forgotLoading, setForgotLoading] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const navigate = useNavigate();

  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  // States for mobile login
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false); // Added state for OTP modal
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);

  const handleCloseMobileModal = () => {
    setShowMobileModal(false);
    setMobileNumber(""); // Clear mobile number input
    setOtpError(""); // Clear any OTP error
  };

  const handleShowMobileModal = () => {
    setMobileNumber(""); // Clear mobile number input
    setOtpError(""); // Clear any OTP error
    setShowMobileModal(true);
  };

  const handleCloseOtpModal = () => {
    setShowLoginSignUpModal(false);
    setShowOtpModal(false);
    setOtp(""); // Clear OTP input
    setOtpError(""); // Clear any OTP error
  };

  const handleShowOtpModal = () => {
    setOtp(""); // Clear OTP input
    setOtpError(""); // Clear any OTP error
    setShowOtpModal(true);
  };
  console.log("showBookingModal", showBookingModal);
  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      // if (window.location.reload()) {
      setShowBookingModal(true);
      setShowLoginSignUpModal(false);

      // }
      // navigate("/doctor-team-two");
    }
  }, [isAuthenticated, isLoading, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login/`,
        {
          email,
          password,
        }
      );

      console.log("Full response object:", response);
      console.log("accessToken", response.data.access);
      console.log("Response data:", response.data);
      const { token, user: details } = response.data;
      localStorage.setItem("authToken", token);
      localStorage.setItem("accessToken", response.data.access);
      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("doctorId", response.data.user.doctor_id);
      localStorage.setItem("userEmail", details.email);
      localStorage.setItem("firstName", details.first_name);
      localStorage.setItem("lastName", details.last_name);
      localStorage.setItem("userData", JSON.stringify(response.data));
      localStorage.setItem("userRole", response.data.user.role);
      console.log(response.data.user.role);
      console.log(details);
      console.log(details.first_name);
      if (response.data.user.doctor_id) {
        setShowBookingModal(true);

        // navigate("/doctor-dashboard");
      } else {
        // if (window.location.reload()) {
        setShowBookingModal(true);
        // }
        // navigate("/");
      }

      // navigate("/");
    } catch (err) {
      console.log("Error:", err);
      if (err.response && err.response.data) {
        setError(err.response.data.error || "Login failed. Please try again.");
      } else {
        setError("Login failed. Please try again.");
      }
    } finally {
      setLoading(false);
    }
    setShowLoginSignUpModal(false);
  };

  const handleForgotPassword = async () => {
    if (!forgotEmail) {
      setForgotError("Please enter your email.");
      return;
    }

    setForgotError("");
    setForgotLoading(true);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/password-reset-request/`,
        {
          email: forgotEmail,
        }
      );

      setForgotError(
        "If the email is registered, you will receive a password reset link."
      );
    } catch (err) {
      console.error("Error:", err);
      if (err.response && err.response.data) {
        setForgotError(
          err.response.data.detail ||
            "Failed to send reset email. Please try again."
        );
      } else {
        setForgotError("Failed to send reset email. Please try again.");
      }
    } finally {
      setForgotLoading(false);
    }
  };
  const handleMobileLogin = async () => {
    if (!mobileNumber) {
      setOtpError("Please enter your mobile number.");
      return;
    }

    setOtpError("");
    setOtpLoading(true);

    try {
      const fullnumber = `+91${mobileNumber}`;
      await axios.post(`${process.env.REACT_APP_API_URL}/request-otp/`, {
        phone_number: fullnumber,
      });

      setShowMobileModal(false);
      setShowOtpModal(true); // Show OTP verification modal
    } catch (err) {
      console.error("Error:", err);
      if (err.response && err.response.data) {
        setOtpError(
          err.response.data.detail || "Failed to send OTP. Please try again."
        );
      } else {
        setOtpError("Failed to send OTP. Please try again.");
      }
    } finally {
      setOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setOtpError("Please enter OTP.");
      return;
    }

    setOtpError("");
    setOtpLoading(true);

    try {
      const fullnumber = `+91${mobileNumber}`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-otp/`,
        {
          phone_number: fullnumber,
          otp,
        }
      );
      const { access } = response.data;
      console.log(access);
      // Store the access token in localStorage
      console.log("Access Token (Email Login):", access);
      localStorage.setItem("accessToken", access);
      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("userRole", response.data.user.role);
      localStorage.setItem("userEmail", response.data.user.email);
      localStorage.setItem("firstName", response.data.user.first_name);
      localStorage.setItem("lastName", response.data.user.last_name);
      localStorage.setItem("userData", JSON.stringify(response.data));
      // Optionally, you might want to store other data as well
      localStorage.setItem("user", JSON.stringify(response.data.user));
      // Handle success, e.g., navigate to home page
      navigate("/");
    } catch (err) {
      console.error("Error:", err);
      if (err.response && err.response.data) {
        setOtpError(
          err.response.data.detail || "Failed to verify OTP. Please try again."
        );
      } else {
        setOtpError("Failed to verify OTP. Please try again.");
      }
    } finally {
      setOtpLoading(false);
    }
  };
  // const handleClose = () => setShowModal(false);
  const handleClose = () => {
    setShowModal(false);
    setForgotError("");
    setForgotEmail("");
  };
  const handleShow = () => setShowModal(true);

  return (
    <>
      <div className="back-to-home rounded d-none d-sm-block">
        <Link to="/" className="btn btn-icon btn-primary">
          <FiHome className="icons" />
        </Link>
      </div>

      <Modal
        show={showLoginSignUpModal}
        centered
        onHide={() => {
          setShowLoginSignUpModal(false);
          setShowOtpModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ width: "500px" }}>
            {showOtpModal
              ? "Verify OTP"
              : showMobileModal
              ? "Login with Mobile Number"
              : "Sign In"}
          </Modal.Title>
        </Modal.Header>
        {showOtpModal ? (
          <>
            <Modal.Body>
              <input
                type="text"
                className="form-control"
                placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              {otpError && <div className="text-danger mt-2">{otpError}</div>}
            </Modal.Body>
            <Modal.Footer>
              {/* <Button variant="secondary" onClick={handleCloseOtpModal}>
                Close
              </Button> */}
              <Button
                variant="primary"
                onClick={handleVerifyOtp}
                disabled={otpLoading}
              >
                {otpLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Verify OTP"
                )}
              </Button>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              {/* <section
            className="bg-home d-flex bg-light align-items-center"
            style={{
              backgroundImage: `url(${bg1})`,
              backgroundPosition: "center",
            }}
          > */}
              {/* <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-8"> */}
              {/* <img
                src={logoDark}
                height="22"
                className="mx-auto d-block"
                alt=""
              /> */}
              <div className="card login-page shadow rounded border-0">
                <div className="card-body">
                  {/* <h4 className="text-center">Sign In</h4> */}
                  <form className="login-form" onSubmit={handleSubmit}>
                    <div className="row">
                      {showMobileModal ? (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your mobile number"
                            value={mobileNumber}
                            onChange={(e) => setMobileNumber(e.target.value)}
                          />

                          <Button
                            variant="primary"
                            onClick={handleMobileLogin}
                            disabled={otpLoading}
                            style={{
                              margin: "25px 0px",
                              // marginBottom: "5px",
                            }}
                          >
                            {otpLoading ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Send OTP"
                            )}
                          </Button>
                          <button
                            type="button"
                            className="btn btn-link p-0"
                            onClick={() => setShowMobileModal(false)}
                          >
                            Login with Email
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">
                                Your Email{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-3 d-flex justify-content-between">
                              <label className="form-label">
                                Password <span className="text-danger">*</span>
                              </label>
                            </div>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-link p-0"
                            onClick={handleShow}
                          >
                            Forgot Password?
                          </button>
                          <div className="col-lg-12 mb-0">
                            <div className="d-grid">
                              <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  "Sign in"
                                )}
                              </button>
                              <Button
                                variant="link"
                                className="mt-3 d-block mx-auto"
                                onClick={handleShowMobileModal}
                              >
                                Login with Mobile Number
                              </Button>
                            </div>
                          </div>
                        </>
                      )}

                      {error && (
                        <div className="col-lg-12">
                          <p className="text-danger text-center">{error}</p>
                        </div>
                      )}

                      <div className="col-lg-12 mt-1 text-center">
                        <h6 className="text-muted">Or</h6>
                      </div>

                      <div className="col-12 mt-1 text-center">
                        <button
                          className="btn btn-soft-primary"
                          onClick={() =>
                            loginWithRedirect({
                              connection: "google-oauth2",
                            })
                          }
                        >
                          <SlSocialGoogle className="mb-0" /> Social Login
                        </button>
                      </div>

                      <div className="col-12 text-center">
                        <p className="mb-0 mt-3">
                          <small className="text-dark me-2">
                            Don't have an account ?
                          </small>{" "}
                          <Link to="/signup" className="text-dark fw-bold">
                            Sign Up
                          </Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* </div>
              </div>
            </div> */}
              {/* </section> */}
            </Modal.Body>
          </>
        )}
      </Modal>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">
              Your Email <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={forgotEmail}
              onChange={(e) => setForgotEmail(e.target.value)}
              required
            />
          </div>
          {forgotError && (
            <p className="text-danger text-center">{forgotError}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleForgotPassword}
            disabled={forgotLoading}
          >
            {forgotLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Send Reset Link"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal
        show={showMobileModal}
        onHide={handleCloseMobileModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Login with Mobile Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter your mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
          {otpError && <div className="text-danger mt-2">{otpError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMobileModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleMobileLogin}
            disabled={otpLoading}
          >
            {otpLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Send OTP"
            )}
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* OTP Verification Modal */}
      {/* <Modal
        show={showOtpModal}
        onHide={handleCloseOtpModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          {otpError && <div className="text-danger mt-2">{otpError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOtpModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleVerifyOtp}
            disabled={otpLoading}
          >
            {otpLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Verify OTP"
            )}
          </Button>
        </Modal.Footer>
      </Modal> */}
      {showBookingModal && (
        <BookingAppointment
          show={showBookingModal}
          handleClose={() => setShowBookingModal(false)}
          selectedDoctor={selectedDoctor}
        />
      )}
    </>
  );
}
