import React, { useCallback, useState } from "react";
import { post } from "../../api_helper";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { loadingPanel } from "../CommonComponents/CommonFunction";
import { ShowSucess, ShowError } from "../../_helper/helper";
import {
  Box,
  TextField,
  MenuItem,
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Container,
  Button,
  Step,
  StepLabel,
  Stepper,
  Alert,
} from "@mui/material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { Col } from "reactstrap";
const steps = [
  "Personal Information",
  "Professional Information",
  "Upload Documents",
  // "Address Information",
  "Account Information",
];

const baseURL = process.env.REACT_APP_API_URL;
export default function RegistrationDoctorForm(props) {
  const { registerDrModal, setRegisterDrModal } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [formData, setFormData] = React.useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: "",
    blood_type: "",
    contact_number: "",
    email: "",
    medical_license_number: "",
    specialization: "",
    years_of_experience: "",
    experience: "",
    expdetails: "",
    department: "",
    charge: "",
    hospital_clinic_affiliation: "",
    medical_school: "",
    graduation_year: "",
    residency: "",
    fellowships: "",
    photo_id: "",
    medical_license: "",
    certifications: "",
    street_address: "",
    city: "",
    state_province: "",
    postal_code: "",
    country: "",
    // username: "",
    password: "",
    active: false,
    description: "testing",
    location: "test",
  });
  const [errors, setErrors] = React.useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    // Handle file inputs
    const { name, files: fileList } = event.target;
    const file = fileList[0];
    setFormData({
      ...formData,
      [name]: file ? file : null,
    });
  };
  const createDoctorRegistration = async () => {
    try {
      setLoading(true);
      // const token = localStorage.getItem("jwt_access_token");
      const token = localStorage.getItem("accessToken");

      const formDataToSend = new FormData();

      // Append file fields
      if (formData.photo_id) {
        formDataToSend.append("photo_id", formData.photo_id);
      }
      if (formData.medical_license) {
        formDataToSend.append("medical_license", formData.medical_license);
      }
      if (formData.certifications) {
        formDataToSend.append("certifications", formData.certifications);
      }
      if (formData?.experience) {
        formDataToSend.append(
          "experience",
          JSON.stringify([
            {
              // job_title: "Cardiologist",
              // institution: "NYC Heart Clinic",
              // start_date: "2010-01-01",
              // end_date: "2023-01-01",
              description: formData?.experience,
            },
          ])
        );
      }

      // Append other fields
      for (const key in formData) {
        if (
          key !== "photo_id" &&
          key !== "medical_license" &&
          key !== "certifications" &&
          key !== "experience"
        ) {
          formDataToSend.append(key, formData[key]);
        }
      }

      const res = await (
        await fetch(`${baseURL}/register/doctor/`, {
          method: "POST",
          body: formDataToSend,
          // headers: {
          //   // Authorization: `Bearer ${token}`,
          //   // "x-access-token": token,
          //   "Content-Type": "multipart/form-data",
          // },
        })
      ).json();
      if (res?.user) {
        setLoading(false);
        setIsRegister(true);
        ShowSucess("SuccessFully Registered!");
        // setRegisterDrModal(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      ShowError("Something Went Wrong!");
      console.log("error", error);
    }
  };
  const handleClose = () => {
    setRegisterDrModal(false);
    handleReset();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // if (validateForm()) {
    createDoctorRegistration();
    // }

    // Perform form submission logic
    // Example: validate form data, submit API request, etc.
  };

  // const isStepOptional = (step) => {
  //   return step === 3; // Example: Address Information step is optional
  // };

  const isStepSkipped = (step) => {
    return false; // Placeholder for skipped steps logic if needed
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const validateField = (name, value) => {
    let error = "";
    if (!value) {
      error = "This field is required";
    } else {
      switch (name) {
        case "email":
          if (!/\S+@\S+\.\S+/.test(value)) {
            error = "Email address is invalid";
          }
          break;
        // case "contact_number":
        //   if (!/^\d{10}$/.test(value)) {
        //     error = "Contact number is invalid";
        //   }
        //   break;
        case "password":
          if (value.length < 6) {
            error = "Password must be at least 6 characters";
          }
          break;
        // case "confirmPassword":
        //   if (value !== formData.password) {
        //     error = "Passwords do not match";
        //   }
        //   break;
        default:
          break;
      }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const validateForm = () => {
    let valid = true;
    Object.keys(formData).forEach((key) => {
      validateField(key, formData[key]);
      if (!formData[key] || errors[key]) {
        valid = false;
      }
    });
    return valid;
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    validateField(name, value);
  };
  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      name: "",
      date_of_birth: "",
      gender: "",
      contact_number: "",
      email: "",
      medical_license_number: "",
      specialization: "",
      years_of_experience: "",
      experience: "",
      expdetails: "",
      department: null,
      hospital_clinic_affiliation: "",
      medical_school: "",
      graduation_year: "",
      residency: "",
      fellowships: "",
      photo_id: "",
      medical_license: "",
      certifications: "",
      address: "",
      street_address: "",
      city: "",
      state_province: "",
      postal_code: "",
      country: "",
      username: "",
      password: "",
    });
    setErrors({});
  };
  const renderTextInput = useCallback(
    (
      type,
      state,
      label,
      disabled = false,
      required = false,
      multiline = false
    ) => {
      const showError = !!errors[state];
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      };
      return (
        <TextField
          required={required}
          size="small"
          fullWidth
          margin="dense"
          variant="outlined"
          label={label}
          name={state}
          id={state}
          type={state === "phone" ? "number" : type}
          disabled={disabled}
          error={showError}
          helperText={showError ? errors[state] : undefined}
          multiline={multiline}
          rows={multiline ? 3 : 1}
          value={formData[state] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    },
    [formData, errors, handleBlur]
  );

  const isFormValid = () => {
    switch (activeStep) {
      case 0:
        return (
          formData.first_name !== "" &&
          formData.last_name !== "" &&
          formData.date_of_birth !== "" &&
          formData.gender !== "" &&
          formData.contact_number !== "" &&
          formData.email !== ""
          // Add more validations as needed for each step
        );
      case 1:
        return (
          formData.medical_license_number !== "" &&
          formData.specialization !== "" &&
          formData.years_of_experience !== "" &&
          formData.hospital_clinic_affiliation !== "" &&
          formData.medical_school !== "" &&
          formData.graduation_year !== "" &&
          formData.residency !== "" &&
          formData.fellowships !== ""
        );
      case 2:
        return (
          formData.photo_id !== "" &&
          formData.medical_license !== "" &&
          formData.certifications !== ""
        );
      // case 3:
      //   return (
      //     formData.address !== "" &&
      //     formData.city !== "" &&
      //     formData.country !== ""
      //   );
      case 3:
        return (
          formData.username !== "" && formData.password !== ""
          // formData.confirmPassword !== "" &&
          // formData.password === formData.confirmPassword
        );
      default:
        return false;
    }
  };
  const country = [
    { value: "United States", label: "United States" },
    { value: "Canada", label: "Canada" },
    { value: "Australia", label: "Australia" },
  ];
  const options = [
    { value: "M", label: "Male" },
    { value: "F", label: "Female" },
    { value: "O", label: "Other" },
  ];
  const specializationMenu = [
    { value: "Cardiology", label: "Cardiology" },
    { value: "Dermatology", label: "Dermatology" },
    { value: "Neurology", label: "Neurology" },
    { value: "Pediatrics", label: "Pediatrics" },
    { value: "Other", label: "Other" },
  ];
  return (
    <>
      {registerDrModal && (
        <Dialog
          open={registerDrModal}
          // onClose={() => setRegisterDrModal(false)}
          fullWidth
          maxWidth="lg"
        >
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            {" "}
            <Box sx={{ width: "98%" }}>
              {loading && loadingPanel()}
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  // if (isStepOptional(index)) {
                  //   labelProps.optional = (
                  //     <Typography variant="caption">Optional</Typography>
                  //   );
                  // }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <Container maxWidth="md">
                <form onSubmit={handleSubmit}>
                  {activeStep === 0 && (
                    <div>
                      <Typography variant="h6" gutterBottom>
                        Personal Information
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "first_name",
                            "First Name",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "last_name",
                            "Last Name",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            type="date"
                            name="date_of_birth"
                            fullWidth
                            margin="dense"
                            label="DOB"
                            size="small"
                            placeholder="Date of Birth"
                            value={
                              formData.date_of_birth ||
                              dayjs(new Date()).format("MM/DD/YYYY")
                            }
                            onChange={handleInputChange}
                            error={!!errors.date_of_birth}
                            helperText={errors.date_of_birth}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            select={true}
                            id="outlined-select-requestType"
                            size="small"
                            label="Gender"
                            margin="dense"
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                            error={!!errors.gender}
                            helperText={errors.gender}
                          >
                            {options.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {item?.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "blood_type",
                            "Blood Type",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "contact_number",
                            "Contact Number",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput("text", "email", "Email", false)}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput("text", "address", "Address", false)}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "street_address",
                            "Street Address",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput("text", "city", "City", false)}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "state_province",
                            "State/Province",
                            false
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "postal_code",
                            "Postal Code",
                            false
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            select
                            name="country"
                            fullWidth
                            margin="dense"
                            size="small"
                            label="Country"
                            value={formData.country}
                            onChange={handleInputChange}
                            error={!!errors.country}
                            helperText={errors.country}
                          >
                            {country.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {item?.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {activeStep === 1 && (
                    <div>
                      <Typography variant="h6" gutterBottom>
                        Professional Information
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            select
                            name="specialization"
                            fullWidth
                            margin="dense"
                            size="small"
                            label="Specialization"
                            value={formData.specialization}
                            onChange={handleInputChange}
                            error={!!errors.specialization}
                            helperText={errors.specialization}
                          >
                            {specializationMenu.map((item) => (
                              <MenuItem value={item.value} key={item.value}>
                                {item?.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "number",
                            "years_of_experience",
                            "Years Of Experience",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {renderTextInput(
                            "text",
                            "experience",
                            "Experience",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          {renderTextInput(
                            "text",
                            "expdetails",
                            "Experience Details",
                            false,
                            false,
                            true
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput("number", "charge", "Charge", false)}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "medical_license_number",
                            "Medical License Number",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "hospital_clinic_affiliation",
                            "Affiliation",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "medical_school",
                            "Medical School",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "graduation_year",
                            "Graduation Year",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "residency",
                            "Residency",
                            false
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {renderTextInput(
                            "text",
                            "fellowships",
                            "Fellowships",
                            false
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {activeStep === 2 && (
                    <div
                      style={{
                        margin: "35px 0px",
                      }}
                    >
                      {/* <Typography variant="h6" gutterBottom>
                        Upload Documents
                      </Typography> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          {formData?.photo_id && (
                            <div>
                              {/* <Typography variant="subtitle1">
                                Preview:
                              </Typography> */}
                              <img
                                src={URL.createObjectURL(formData?.photo_id)}
                                alt="Preview"
                                style={{
                                  width: "100%",
                                  maxWidth: 230,
                                }}
                              />
                            </div>
                          )}
                          <Button
                            component="label"
                            variant="outlined"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                          >
                            Upload Profile Image
                            <input
                              type="file"
                              accept="image/*"
                              name="photo_id"
                              id="photo_id"
                              onChange={handleFileChange}
                              style={{ display: "none" }}
                            />
                            {/* <VisuallyHiddenInput type="file" /> */}
                          </Button>
                        </Col>
                        <Col>
                          <div
                            style={{
                              marginTop: formData?.medical_license?.name
                                ? formData?.medical_license?.name.length > 15
                                  ? "91px"
                                  : "113px"
                                : formData?.photo_id
                                ? "135px"
                                : 0,
                            }}
                          >
                            {formData?.medical_license?.name && (
                              <div>{formData.medical_license?.name}</div>
                            )}
                            <Button
                              component="label"
                              variant="outlined"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                            >
                              Medical License Doc
                              <input
                                type="file"
                                id="medical_license"
                                name="medical_license"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                              {/* <VisuallyHiddenInput type="file" /> */}
                            </Button>
                          </div>
                        </Col>
                        <Col>
                          <div
                            style={{
                              marginTop: formData?.certifications?.name
                                ? formData?.certifications?.name.length > 15
                                  ? "91px"
                                  : "113px"
                                : formData?.photo_id
                                ? "135px"
                                : 0,
                            }}
                          >
                            {formData?.certifications?.name && (
                              <div> {formData.certifications?.name}</div>
                            )}
                            <Button
                              component="label"
                              variant="outlined"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                            >
                              Certifications Doc
                              <input
                                type="file"
                                name="certifications"
                                id="certifications"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                              />
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </div>
                  )}

                  {activeStep === 3 && (
                    <div>
                      <Typography variant="h6" gutterBottom>
                        Account Information
                      </Typography>
                      <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={12}>
                          {renderTextInput(
                            "text",
                            "username",
                            "Username",
                            false
                          )}
                        </Grid> */}
                        <Grid item xs={12} sm={12}>
                          {renderTextInput(
                            "password",
                            "password",
                            "Password",
                            false
                          )}
                        </Grid>
                        {/* <Grid item xs={12} sm={12}>
                          {renderTextInput(
                            "password",
                            "confirmPassword",
                            "Confirm Password",
                            false
                          )}
                        </Grid> */}
                      </Grid>
                    </div>
                  )}
                  <div style={{ marginTop: "24px" }}>
                    <Button
                      disabled={activeStep === 0}
                      variant="contained"
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Back
                    </Button>
                    {activeStep !== steps.length - 1 && (
                      <Button
                        variant="contained"
                        disabled={!isFormValid()}
                        onClick={handleNext}
                        sx={{ mr: 1 }}
                      >
                        Next
                      </Button>
                    )}
                    {activeStep === steps.length - 1 && (
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={!isFormValid()}
                        sx={{ mr: 1 }}
                      >
                        Submit
                      </Button>
                    )}
                    <Button variant="contained" onClick={handleReset}>
                      Reset
                    </Button>
                  </div>
                </form>
                {isRegister && (
                  <Alert severity="success" style={{ marginTop: "15px" }}>
                    {" "}
                    Successfully Registered! Please check your email to verify
                    your account.
                  </Alert>
                )}
                {/* <footer> */}
                <p style={{ textAlign: "center" }}>
                  Already registered?{" "}
                  <a style={{ fontSize: "medium" }} href="/login">
                    Login here
                  </a>
                </p>
                {/* </footer> */}
              </Container>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}


// import React, { useState, useCallback } from "react";
// import { Box, TextField, MenuItem, IconButton, Dialog, DialogContent, Typography, Grid, Container, Button, Step, StepLabel, Stepper, Alert } from "@mui/material";
// import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import CloseIcon from "@mui/icons-material/Close";
// import dayjs from "dayjs";

// const steps = [
//   "Personal Information",
//   "Professional Information",
//   "Upload Documents",
//   "Account Information",
// ];

// const baseURL = process.env.REACT_APP_API_URL;

// export default function RegistrationDoctorForm() {
//   const [activeStep, setActiveStep] = useState(0);
//   const [loading, setLoading] = useState(false);
//   const [isRegister, setIsRegister] = useState(false);
//   const [formData, setFormData] = useState({
//     first_name: "John",
//     last_name: "Doe",
//     date_of_birth: "1990-01-01",
//     gender: "M",
//     blood_type: "O",
//     contact_number: "1234567890",
//     email: "john.doe@example.com",
//     medical_license_number: "ML123456",
//     specialization: "Cardiology",
//     years_of_experience: "10",
//     experience: "10 years in Cardiology",
//     expdetails: "Detailed experience in Cardiology.",
//     department: "Cardiology",
//     charge: "100",
//     hospital_clinic_affiliation: "Heart Clinic",
//     medical_school: "Harvard Medical School",
//     graduation_year: "2010",
//     residency: "Internal Medicine",
//     fellowships: "Cardiology Fellowship",
//     photo_id: null,
//     medical_license: null,
//     certifications: null,
//     street_address: "123 Main St",
//     city: "Anytown",
//     state_province: "State",
//     postal_code: "12345",
//     country: "United States",
//     password: "password123",
//     active: false,
//     description: "testing",
//     location: "test",
//   });
//   const [errors, setErrors] = useState({});

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleFileChange = (event) => {
//     const { name, files: fileList } = event.target;
//     const file = fileList[0];
//     setFormData({ ...formData, [name]: file ? file : null });
//   };

//   const createDoctorRegistration = async () => {
//     try {
//       setLoading(true);
//       const token = localStorage.getItem("accessToken");
//       const formDataToSend = new FormData();

//       // Append file fields
//       if (formData.photo_id) formDataToSend.append("photo_id", formData.photo_id);
//       if (formData.medical_license) formDataToSend.append("medical_license", formData.medical_license);
//       if (formData.certifications) formDataToSend.append("certifications", formData.certifications);
//       if (formData.experience) {
//         formDataToSend.append("experience", JSON.stringify([{ description: formData.experience }]));
//       }

//       // Append other fields
//       for (const key in formData) {
//         if (!["photo_id", "medical_license", "certifications", "experience"].includes(key)) {
//           formDataToSend.append(key, formData[key]);
//         }
//       }

//       const res = await (await fetch(`${baseURL}/register/doctor/`, {
//         method: "POST",
//         body: formDataToSend,
//       })).json();
//       if (res?.user) {
//         setLoading(false);
//         setIsRegister(true);
//         // Optionally close modal or navigate
//       } else {
//         setLoading(false);
//       }
//     } catch (error) {
//       setLoading(false);
//       console.error("Error:", error);
//     }
//   };

//   const handleClose = () => {
//     // Reset form data if needed
//     setActiveStep(0);
//     setErrors({});
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     createDoctorRegistration();
//   };

//   const isStepSkipped = (step) => false;

//   const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

//   const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

//   const handleReset = () => {
//     setActiveStep(0);
//     setFormData({
//       first_name: "",
//       last_name: "",
//       date_of_birth: "",
//       gender: "",
//       blood_type: "",
//       contact_number: "",
//       email: "",
//       medical_license_number: "",
//       specialization: "",
//       years_of_experience: "",
//       experience: "",
//       expdetails: "",
//       department: "",
//       charge: "",
//       hospital_clinic_affiliation: "",
//       medical_school: "",
//       graduation_year: "",
//       residency: "",
//       fellowships: "",
//       photo_id: null,
//       medical_license: null,
//       certifications: null,
//       street_address: "",
//       city: "",
//       state_province: "",
//       postal_code: "",
//       country: "",
//       password: "",
//       active: false,
//       description: "testing",
//       location: "test",
//     });
//     setErrors({});
//   };

//   const renderTextInput = useCallback((type, state, label, disabled = false, required = false, multiline = false) => {
//     const showError = !!errors[state];
//     const handleChange = (event) => {
//       const { name, value } = event.target;
//       setFormData((prevValues) => ({
//         ...prevValues,
//         [name]: value,
//       }));
//     };
//     return (
//       <TextField
//         required={required}
//         size="small"
//         fullWidth
//         margin="dense"
//         variant="outlined"
//         label={label}
//         name={state}
//         id={state}
//         type={type}
//         disabled={disabled}
//         error={showError}
//         helperText={showError ? errors[state] : undefined}
//         multiline={multiline}
//         rows={multiline ? 3 : 1}
//         value={formData[state] || ""}
//         onChange={handleChange}
//       />
//     );
//   }, [formData, errors]);

//   const isFormValid = () => {
//     switch (activeStep) {
//       case 0:
//         return (
//           formData.first_name &&
//           formData.last_name &&
//           formData.date_of_birth &&
//           formData.gender &&
//           formData.contact_number &&
//           formData.email
//         );
//       case 1:
//         return (
//           formData.medical_license_number &&
//           formData.specialization &&
//           formData.years_of_experience &&
//           formData.hospital_clinic_affiliation &&
//           formData.medical_school &&
//           formData.graduation_year &&
//           formData.residency &&
//           formData.fellowships
//         );
//       case 2:
//         return (
//           formData.photo_id &&
//           formData.medical_license &&
//           formData.certifications
//         );
//       case 3:
//         return formData.password;
//       default:
//         return false;
//     }
//   };

//   const country = [
//     { value: "United States", label: "United States" },
//     { value: "Canada", label: "Canada" },
//     { value: "Australia", label: "Australia" },
//   ];

//   const options = [
//     { value: "M", label: "Male" },
//     { value: "F", label: "Female" },
//     { value: "O", label: "Other" },
//   ];

//   const specializationMenu = [
//     { value: "Cardiology", label: "Cardiology" },
//     { value: "Dermatology", label: "Dermatology" },
//     { value: "Neurology", label: "Neurology" },
//     { value: "Pediatrics", label: "Pediatrics" },
//     { value: "Other", label: "Other" },
//   ];

//   return (
//     <Dialog
//       open={true} // Always open for demonstration
//       fullWidth
//       maxWidth="lg"
//     >
//       <IconButton
//         aria-label="close"
//         onClick={handleClose}
//         sx={{
//           position: "absolute",
//           right: 8,
//           top: 8,
//           color: (theme) => theme.palette.grey[500],
//         }}
//       >
//         <CloseIcon />
//       </IconButton>
//       <DialogContent>
//         <Box sx={{ width: "98%" }}>
//           {loading && <div>Loading...</div>}
//           <Stepper activeStep={activeStep}>
//             {steps.map((label, index) => (
//               <Step key={label} completed={isStepSkipped(index)}>
//                 <StepLabel>{label}</StepLabel>
//               </Step>
//             ))}
//           </Stepper>
//           <Container maxWidth="md">
//             <form onSubmit={handleSubmit}>
//               {activeStep === 0 && (
//                 <>
//                   <Typography variant="h6">Personal Information</Typography>
//                   <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "first_name", "First Name", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "last_name", "Last Name", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("date", "date_of_birth", "Date of Birth", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <TextField
//                         select
//                         fullWidth
//                         label="Gender"
//                         name="gender"
//                         value={formData.gender || ""}
//                         onChange={handleInputChange}
//                         required
//                       >
//                         {options.map((option) => (
//                           <MenuItem key={option.value} value={option.value}>
//                             {option.label}
//                           </MenuItem>
//                         ))}
//                       </TextField>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "contact_number", "Contact Number", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("email", "email", "Email", false, true)}
//                     </Grid>
//                   </Grid>
//                 </>
//               )}
//               {activeStep === 1 && (
//                 <>
//                   <Typography variant="h6">Professional Information</Typography>
//                   <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "medical_license_number", "Medical License Number", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <TextField
//                         select
//                         fullWidth
//                         label="Specialization"
//                         name="specialization"
//                         value={formData.specialization || ""}
//                         onChange={handleInputChange}
//                         required
//                       >
//                         {specializationMenu.map((option) => (
//                           <MenuItem key={option.value} value={option.value}>
//                             {option.label}
//                           </MenuItem>
//                         ))}
//                       </TextField>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "years_of_experience", "Years of Experience", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "hospital_clinic_affiliation", "Hospital/Clinic Affiliation", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "medical_school", "Medical School", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "graduation_year", "Graduation Year", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "residency", "Residency", false, true)}
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("text", "fellowships", "Fellowships", false, true)}
//                     </Grid>
//                   </Grid>
//                 </>
//               )}
//               {activeStep === 2 && (
//                 <>
//                   <Typography variant="h6">Upload Documents</Typography>
//                   <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                       <Button variant="outlined" component="label">
//                         Upload Photo ID
//                         <input
//                           type="file"
//                           name="photo_id"
//                           accept="image/*"
//                           hidden
//                           onChange={handleFileChange}
//                         />
//                       </Button>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <Button variant="outlined" component="label">
//                         Upload Medical License
//                         <input
//                           type="file"
//                           name="medical_license"
//                           accept="image/*"
//                           hidden
//                           onChange={handleFileChange}
//                         />
//                       </Button>
//                     </Grid>
//                     <Grid item xs={12} sm={6}>
//                       <Button variant="outlined" component="label">
//                         Upload Certifications
//                         <input
//                           type="file"
//                           name="certifications"
//                           accept="image/*"
//                           hidden
//                           onChange={handleFileChange}
//                         />
//                       </Button>
//                     </Grid>
//                   </Grid>
//                 </>
//               )}
//               {activeStep === 3 && (
//                 <>
//                   <Typography variant="h6">Account Information</Typography>
//                   <Grid container spacing={2}>
//                     <Grid item xs={12} sm={6}>
//                       {renderTextInput("password", "password", "Password", false, true)}
//                     </Grid>
//                   </Grid>
//                 </>
//               )}
//               <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
//                 {activeStep > 0 && (
//                   <Button onClick={handleBack} sx={{ mr: 1 }}>
//                     Back
//                   </Button>
//                 )}
//                 {activeStep === steps.length - 1 ? (
//                   <Button
//                     type="submit"
//                     variant="contained"
//                     color="primary"
//                     disabled={!isFormValid()}
//                   >
//                     Submit
//                   </Button>
//                 ) : (
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleNext}
//                     disabled={!isFormValid()}
//                   >
//                     Next
//                   </Button>
//                 )}
//               </Box>
//             </form>
//           </Container>
//         </Box>
//       </DialogContent>
//     </Dialog>
//   );
// }
