import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeUserLocation } from "../../store/common/actions";
import axios from "axios";
import { FaUserMd } from "react-icons/fa";
import data from "../../assets/data.json";

import {
  RiEyeFill,
  RiPsychotherapyFill,
  RiStethoscopeFill,
  RiCapsuleFill,
  RiMicroscopeFill,
  RiPulseFill,
  RiEmpathizeFill,
  RiMindMap,
  RiMentalHealthLine,
  RiAppleLine,
  RiInfraredThermometerLine,
} from "../../assets/icons/vander";

import {
  TextField,
  Box,
  IconButton,
  InputAdornment,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { medicalServices,  blogData,partners } from "../../data/data";
const baseURL = process.env.REACT_APP_API_URL;

export default function SearchDoctor() {
  const [doctorData, setDoctorData] = useState([]);
  const userRole = localStorage.getItem("userRole");
  const [searchQuery, setSearchQuery] = useState({ params1: "", params2: "" });
  const searchBoxRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showCardioSuggestions, setShowCardioSuggestions] = useState(false);
  const [showLocationSuggestions, setShowLocationSuggestions] = useState(false);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { search } = useLocation();
  const dispatch = useDispatch();
  // const secondSectionRef = React.useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const paramsArray = [...queryParams.values()];
    setSearchQuery({
      params1: paramsArray[0] || "",
      params2: paramsArray[1] || "",
    });
  }, [search]);
  const handleSuggestionClick = (name, suggestion) => {
    setSearchQuery({ ...searchQuery, [name]: suggestion });
    if (name === "params1") {
      setShowCardioSuggestions(false); // Hide suggestions after clicking
    } else {
      setShowLocationSuggestions(false);
    }
  };

  useEffect(() => {
    const requestLocationAccess = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Handle successful location access
            const { latitude, longitude } = position.coords;
            dispatch(
              storeUserLocation({ latitude: latitude, longitude: longitude })
            );
            console.log("Latitude:", latitude);
            console.log("Longitude:", longitude);
            // You can store this data in state or send it to the server
          },
          (error) => {
            // Handle location access error
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.log("User denied the request for Geolocation.");
                break;
              case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable.");
                break;
              case error.TIMEOUT:
                console.log("The request to get user location timed out.");
                break;
              case error.UNKNOWN_ERROR:
                console.log("An unknown error occurred.");
                break;
              default:
                console.log("An unknown error occurred.");
            }
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };

    requestLocationAccess();
  }, []); // Empty dependency array to trigger only on component mount

  const fetchSuggestionList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseURL}/specializations/list/`);
      if (
        response &&
        response?.data &&
        response?.data?.specializations &&
        response?.data?.specializations?.length > 0
      ) {
        setSuggestions(response?.data?.specializations);
      }
    } catch (error) {
      console.error("Error fetching doctor data:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const searchDoctors = async (query) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/doctors/search/?search=${encodeURIComponent(query)}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setDoctorData(data.length ? data : []);
      setError(null);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError(error.message);
      setDoctorData([]);
    } finally {
      setLoading(false);
    }
  };

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value);
  //   // Scroll to the second section if there is a search query
  //   // if (e.target.value) {
  //   //     secondSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   // }
  // };
  const handleSearchChange = async (e) => {
    const { name, value: query } = e.target;
    setSearchQuery({ ...searchQuery, [name]: query });
    //
    setShowLocationSuggestions(true);

    if (query?.length > 3) {
      // Fetch suggestions only if the input is more than 2 characters
      try {
        const response = await axios.get(
          `${baseURL}/doctors/autocomplete/?search=${query}`
        );

        setLocationSuggestions(response.data?.search_results || []);
      } catch (error) {
        toast.error(error?.message);
        console.error("Error fetching autocomplete suggestions:", error);
      }
    } else {
      setLocationSuggestions([]); // Clear suggestions if input is too short
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const {
    heroSection,
    doctordetails,
    logoIcon,
    sectionTitle,
    medicalServices,
    doctorData1,
    patient,
    news,
    blogData,
    partners,
  } = data;
  const icons = {
    RiEyeFill,
    RiPsychotherapyFill,
    RiStethoscopeFill,
    RiCapsuleFill,
    RiMicroscopeFill,
    RiPulseFill,
    RiEmpathizeFill,
    RiMindMap,
    RiMentalHealthLine,
    RiAppleLine,
    RiInfraredThermometerLine,
  };
  const bgImage = require(`../../assets/images/${heroSection.backgroundImage}.webp`);
  const handleSearchClick = (searchParams) => {
    // Navigate to the 'doctor-team-two' URL
    // navigate('/doctor-team-two');
    const { params1, params2 } = searchQuery || {};
    navigate(
      `/doctor-search?${
        params1 && params2
          ? `search=${encodeURIComponent(params1)}&search=${encodeURIComponent(
              params2
            )}`
          : `search=${encodeURIComponent(params1 ? params1 : params2)}`
      }`
    );
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target)
      ) {
        setShowCardioSuggestions(false);
        setShowLocationSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBoxRef]);

  useEffect(() => {
    fetchSuggestionList();
  }, []);
  return (
    <>
      {/* <Navbar
        manuClass="navigation-menu nav-left nav-light"
        containerClass="container"
      /> */}

      <div ref={searchBoxRef}>
        {" "}
        <Box sx={{ backgroundColor: "#002855", padding: 2 }}>
          <Grid container spacing={2}>
            {/* Cardiology Search Box */}
            <Grid item xs={12} md={5}>
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "4px 8px",
                  backgroundColor: "#f8f9fa",
                  borderRadius: 2,
                  position: "relative",
                }}
                elevation={0}
              >
                <SearchIcon sx={{ color: "#002855", marginRight: 1 }} />
                <FormControl fullWidth variant="standard">
                  {/* <InputLabel id="params1-label">
                                Cardiology (cardio)
                              </InputLabel> */}
                  <Select
                    labelId="params1-label"
                    name="params1"
                    placeholder="Cardiology (cardio)"
                    value={searchQuery?.params1 || ""}
                    // onChange={handleSearchChange}
                    fullWidth
                    displayEmpty
                    disableUnderline
                  >
                    <MenuItem value="" disabled>
                      Select Specialization
                    </MenuItem>
                    {suggestions && suggestions.length > 0 ? (
                      suggestions.map((suggestion, i) => (
                        <MenuItem
                          key={`edw${i}`}
                          value={suggestion}
                          onClick={(e) => {
                            e.preventDefault();
                            handleSuggestionClick("params1", suggestion);
                          }}
                        >
                          {suggestion}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No suggestions available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Paper>
            </Grid>

            {/* Hospital Location Search Box */}
            <Grid item xs={12} md={5}>
              <Paper
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "4px 8px",
                  backgroundColor: "#f8f9fa",
                  borderRadius: 2,
                  position: "relative",
                }}
                elevation={0}
              >
                <LocationOnIcon sx={{ color: "#002855", marginRight: 1 }} />
                <TextField
                  variant="standard"
                  placeholder="Find Doctor by Name or Address"
                  name="params2"
                  value={searchQuery?.params2}
                  onChange={handleSearchChange}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <IconButton
                  size="small"
                  onClick={() => {
                    setSearchQuery({ ...searchQuery, params2: "" });
                    setShowLocationSuggestions(false);
                  }}
                >
                  <CloseIcon sx={{ color: "#002855" }} />
                </IconButton>
                {/* Custom suggestion menu */}
                {showLocationSuggestions &&
                  locationSuggestions &&
                  locationSuggestions?.length > 0 && (
                    <List
                      sx={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        width: "100%",
                        backgroundColor: "#fff",
                        marginTop: "4px",
                        borderRadius: "5px",
                        zIndex: 10,
                        boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                      }}
                    >
                      {locationSuggestions?.map((suggestion, i) => {
                        const {
                          match_type,
                          doctor_id,
                          name,
                          specialization,
                          address,
                          location,
                        } = suggestion || {};
                        const searchParams =
                          match_type === "name"
                            ? name?.trim()
                            : match_type === "specialization"
                            ? specialization?.trim()
                            : match_type === "address"
                            ? address?.trim()
                            : location;
                        return (
                          <ListItem
                            button
                            key={`loc${i}`}
                            onClick={() =>
                              handleSuggestionClick("params2", searchParams)
                            }
                          >
                            {match_type === "name" && (
                              <FaUserMd style={{ marginRight: "8px" }} />
                            )}
                            {searchParams}
                            {/* {suggestion} */}
                          </ListItem>
                        );
                      })}
                    </List>
                  )}
              </Paper>
            </Grid>

            {/* Search Again Button */}
            <Grid item xs={12} md={2}>
              <Button
                variant="outlined"
                onClick={handleSearchClick}
                sx={{
                  color: "#fff",
                  backgroundColor: "#002855",
                  border: "1px solid #fff",
                  textTransform: "none",
                  padding: "8px 16px",
                  "&:hover": {
                    backgroundColor: "#003b88",
                  },
                }}
                fullWidth
              >
                Search Now
              </Button>
            </Grid>
          </Grid>
        </Box>{" "}
      </div>
    </>
  );
}
