// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-default/dist/all.css";

// Replace 'YOUR_GOOGLE_CLIENT_ID' with your actual Google Client ID
const googleClientId =
  "66786585196-2p55aus7bvrpnrsn2ms3n0s44k1te71i.apps.googleusercontent.com";
const redirectUri = `${window.location.origin}/doctor-team-two`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <div>
    <BrowserRouter>
      {/* <GoogleOAuthProvider clientId={googleClientId}> */}
      <Auth0Provider
        domain="dev-hxqr7tb881k3f0l1.us.auth0.com"
        clientId="juY6qWoECCsbF2XczaceFn7Hh0rhgHjZ"
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        {/* <Auth0Provider 
     authorizationParams={{ connection: 'google-oauth2' }}
      domain="dev-hxqr7tb881k3f0l1.us.auth0.com"
      clientId="cbYPt19kynwXcb0kwsQw0IgdZ78GivVa"
      redirectUri={redirectUri}> */}
        {/* <App /> */}
        <>
          <App />
        </>
        
      </Auth0Provider>

      {/* </GoogleOAuthProvider> */}
    </BrowserRouter>
    <ToastContainer />
  </div>
);

reportWebVitals();

// this is the token passed by auth0 for social login
// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter } from "react-router-dom";
// import { GoogleOAuthProvider } from "@react-oauth/google";
// import { Auth0Provider } from "@auth0/auth0-react";
// import { AuthTokenProvider } from "./components/AuthContext";

// // Replace 'YOUR_GOOGLE_CLIENT_ID' with your actual Google Client ID
// const googleClientId =
//   "66786585196-2p55aus7bvrpnrsn2ms3n0s44k1te71i.apps.googleusercontent.com";
// const redirectUri = `${window.location.origin}/doctor-team-two`;

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <BrowserRouter>
//     {/* <GoogleOAuthProvider clientId={googleClientId}> */}
//     <Auth0Provider
//       domain="dev-hxqr7tb881k3f0l1.us.auth0.com"
//       clientId="juY6qWoECCsbF2XczaceFn7Hh0rhgHjZ"
//       authorizationParams={{
//         redirect_uri: window.location.origin,
//       }}
//       useRefreshTokens={true}
//       cacheLocation="localstorage"
//     >
//       {/* <Auth0Provider
//      authorizationParams={{ connection: 'google-oauth2' }}
//       domain="dev-hxqr7tb881k3f0l1.us.auth0.com"
//       clientId="cbYPt19kynwXcb0kwsQw0IgdZ78GivVa"
//       redirectUri={redirectUri}> */}
//       <AuthTokenProvider>
//       <App />
//       </AuthTokenProvider>
//     </Auth0Provider>
//     {/* </GoogleOAuthProvider> */}
//   </BrowserRouter>
// );

// reportWebVitals();
