import React from 'react';
import { Link } from "react-router-dom";
import { FiArrowRight, RiSearchLine, RiCalendarCheckLine, RiVideoChatLine, RiPrescription2Line } from '../../assets/icons/vander';
import { FiSearch, FiCalendar, FiVideo, FiFileText } from 'react-icons/fi';
const iconMap = {
    "Search for a Doctor": FiSearch,
    "Book an Appointment": FiCalendar,
    "Consult Online": FiVideo,
    "Get Your Prescription": FiFileText
};

function HowItWorks() {
  const steps = [
    {
      title: "Search for a Doctor",
      description: "Browse through our list of verified doctors using filters for specialization, location, and ratings.",
      link: { href: "/search", text: "Search now" }
    },
    {
      title: "Book an Appointment",
      description: "Select a time that fits your schedule and confirm your appointment with a few simple steps.",
      link: { href: "/appointments", text: "Book now" }
    },
    {
      title: "Consult Online",
      description: "Connect with your doctor through a secure video call or chat for personalized medical advice.",
      link: { href: "/consultations", text: "Start consultation" }
    },
    {
      title: "Get Your Prescription",
      description: "Receive digital prescriptions and health advice right to your inbox, making follow-ups easy.",
      link: { href: "/prescriptions", text: "View prescriptions" }
    }
  ];

  return (
    <div className="container my-5">
  <div className="row">
    <div className="col-12 text-center">
      <h4 className="title mb-4">
        How healthBridge Works
      </h4>
      <p style={{ fontSize: '1.25rem', color: '#666' }}>
        Step-by-Step Process:
      </p>
    </div>
  </div>

  <div className="row mt-4">
    <div className="col-12">
      <div className="d-flex justify-content-between align-items-center">
        {steps.map((step, index) => {
          const Icon = iconMap[step.title];
          return (
            <React.Fragment key={index}>
              <div className="feature-card">
                <div className="icon-container mb-2">
                  <Icon className="text-primary h2" />
                </div>
                <h5 className="mt-2" style={{ fontSize: '1.1rem', fontWeight: 'bold', color: '#333' }}>
                  {step.title}
                </h5>
                <p className="text-muted para-desc mb-0">
                  {step.description}
                </p>
              </div>
              {index < steps.length - 1 && <div className="arrow">→</div>}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  </div>

  <style jsx>{`
    .feature-card {
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      flex: 1 1 22%; 
      max-width: 22%;
      min-width: 250px;
      text-align: center;
      margin: 0 10px;
    }
    .feature-card:hover {
      transform: scale(1.05);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
    }
    .arrow {
      font-size: 2rem;
      color: #007bff;
    }
    .d-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
    }
    .row {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 768px) {
      .d-flex {
        flex-direction: column;
        align-items: center;
      }
      .arrow {
        transform: rotate(90deg); /* Rotate arrow to point down */
        margin: 10px 0; /* Add margin for spacing */
      }
    }
  `}</style>
</div>

  );
}

export default HowItWorks;
