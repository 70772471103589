// import React from "react";

// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from '../../components/scrollTop'

// import FullCalendar from '@fullcalendar/react'
// import dayGridPlugin from '@fullcalendar/daygrid'

// export default function DoctorSchedule(){

//     let  events = [
//         {
//             title: 'Business Lunch',
//             start: '2024-05-03T13:00:00',
//             constraint: 'businessHours'
//         },
//         {
//             title: 'Meeting',
//             start: '2024-02-13T11:00:00',
//             constraint: 'availableForMeeting',
//             color: '#53c797'
//         },
//         {
//             title: 'Conference',
//             start: '2024-03-18',
//             end: '2022-04-20'
//         },
//         {
//             title: 'Party',
//             start: '2024-04-29T20:00:00'
//         },
//     ]

//     return(
//         <>
//         <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container-fluid"/>
//         <section className="bg-dashboard">
//             <div className="container-fluid">
//                 <div className="row">
//                     <Sidebar colClass ="col-xl-3 col-lg-4 col-md-5 col-12"/>

//                     <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                         <h5 className="mb-0">Schedule Timing</h5>

//                         <div className="row">
//                             <div className="col-xl-2 col-lg-4 col-12 mt-4">
//                                 <div id="external-events">
//                                     <div className="card border-0 p-4 shadow rounded">
//                                         <span className="h6">All Events</span>

//                                         <div className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
//                                             <div className="fc-event-main">Metting</div>
//                                         </div>
//                                         <div className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
//                                             <div className="fc-event-main">Operations</div>
//                                         </div>
//                                         <div className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
//                                             <div className="fc-event-main">Lunch</div>
//                                         </div>
//                                         <div className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
//                                             <div className="fc-event-main">Conference</div>
//                                         </div>
//                                         <div className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
//                                             <div className="fc-event-main">Business Metting</div>
//                                         </div>

//                                         <div className="mt-2">
//                                             <div className="form-check">
//                                                 <input className="form-check-input" type="checkbox" value="" id="drop-remove"/>
//                                                 <label className="form-check-label" htmlFor="drop-remove">Remove after drop</label>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="col-xl-10 col-lg-8 col-12 mt-4">
//                                 <div id="calendar-container" className="card rounded border-0 shadow p-4">
//                                 <FullCalendar
//                                     defaultView="dayGridMonth"
//                                     plugins={[dayGridPlugin]}
//                                     events={events}
//                                     headerToolbar = {
//                                         {
//                                             left: 'prev,next today addEventButton',
//                                             center: 'title',
//                                             right :'dayGridMonth,dayGridWeek,dayGridDay'
//                                         }
//                                     }
//                                       customButtons={
//                                         {
//                                             addEventButton : {
//                                                 text:'Add Event',
//                                                 click : function (){
//                                                     var dateStr = prompt('Enter a date in YYYY-MM-DD format');
//                                                 }
//                                             }
//                                         }
//                                      }

//                                 />
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//         <AdminFooter/>
//         <ScrollTop/>
//         </>
//     )
// }

// import React, { useState, useEffect } from "react";
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from '../../components/scrollTop';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import axios from 'axios';

// export default function DoctorSchedule() {
//     const [events, setEvents] = useState([]);
//     const [filteredEvents, setFilteredEvents] = useState([]);
//     const [filter, setFilter] = useState('all'); // 'all', 'confirmed', or 'pending'
//     const doctorId = localStorage.getItem("doctorId");

//     useEffect(() => {
//         // Fetch appointments from API
//         axios.get(`${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`)
//             .then(response => {
//                 const appointments = response.data;

//                 // Format data for FullCalendar
//                 const formattedEvents = appointments.map(app => ({
//                     title: `${app.status}`,
//                     start: `${app.appointment_date}`,
//                     end: `${app.appointment_date}T${app.appointment_time}`, // You can adjust end time if needed
//                     color: getStatusColor(app.status),
//                     extendedProps: {
//                         reason: app.reason,
//                         patient_email: app.patient_email,
//                         appointment_type: app.appointment_type,
//                         department: app.department,
//                         gender: app.gender,
//                         age: app.age
//                     }
//                 }));

//                 setEvents(formattedEvents);
//                 setFilteredEvents(formattedEvents); // Initially, show all events
//             })
//             .catch(error => {
//                 console.error("There was an error fetching the appointments!", error);
//             });
//     }, [doctorId]);

//     useEffect(() => {
//         // Filter events based on the selected filter
//         if (filter === 'all') {
//             setFilteredEvents(events);
//         } else {
//             setFilteredEvents(events.filter(event => event.title.includes(filter)));
//         }
//     }, [filter, events]);

//     // Function to determine color based on status
//     const getStatusColor = (status) => {
//         switch (status) {
//             case 'confirmed':
//                 return 'green';
//             case 'completed':
//                 return 'purple';
//             case 'pending':
//                 return 'blue';
//             case 'rejected':
//                 return 'red';
//             case 'rescheduled':
//                 return 'orange';
//             default:
//                 return 'gray';
//         }
//     };

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container-fluid" />
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />

//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                             <h5 className="mb-0">Schedule Timing</h5>

//                             <div className="row">
//                                 <div className="col-xl-2 col-lg-4 col-12 mt-4">
//                                     <div id="external-events">
//                                         <div className="card border-0 p-4 shadow rounded">
//                                             <span className="h6">All Events</span>

//                                             {/* Static events if needed */}
//                                             <div className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
//                                                 <div className="fc-event-main">Meeting</div>
//                                             </div>
//                                             {/* Other static events here */}

//                                             <div className="mt-2">
//                                                 <div className="form-check">
//                                                     <input className="form-check-input" type="checkbox" value="" id="drop-remove" />
//                                                     <label className="form-check-label" htmlFor="drop-remove">Remove after drop</label>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-xl-10 col-lg-8 col-12 mt-4">
//                                     {/* Filter buttons */}
//                                     <div className="mb-3">
//                                         <button
//                                             className={`btn btn-primary ${filter === 'confirmed' ? 'active' : ''}`}
//                                             onClick={() => setFilter('confirmed')}
//                                         >
//                                             Confirmed
//                                         </button>
//                                         <button
//                                             className={`btn btn-secondary ${filter === 'pending' ? 'active' : ''}`}
//                                             onClick={() => setFilter('pending')}
//                                         >
//                                             Pending
//                                         </button>
//                                         <button
//                                             className={`btn btn-light ${filter === 'all' ? 'active' : ''}`}
//                                             onClick={() => setFilter('all')}
//                                         >
//                                             All
//                                         </button>
//                                     </div>

//                                     <div id="calendar-container" className="card rounded border-0 shadow p-4">
//                                         <FullCalendar
//                                             plugins={[dayGridPlugin]}
//                                             events={filteredEvents}
//                                             headerToolbar={{
//                                                 left: 'prev,next today addEventButton',
//                                                 center: 'title',
//                                                 right: 'dayGridMonth,dayGridWeek,dayGridDay'
//                                             }}
//                                             customButtons={{
//                                                 addEventButton: {
//                                                     text: 'Add Event',
//                                                     click: function () {
//                                                         var dateStr = prompt('Enter a date in YYYY-MM-DD format');
//                                                     }
//                                                 }
//                                             }}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <AdminFooter />
//             <ScrollTop />
//         </>
//     );
// }

// import React, { useState, useEffect } from "react";
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from '../../components/scrollTop';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import axios from 'axios';

// // Function to format time from 24-hour to 12-hour format with A.M./P.M.
// const formatTime = (time) => {
//     const [hours, minutes] = time.split(':');
//     const hoursInt = parseInt(hours, 10);
//     const period = hoursInt >= 12 ? 'P.M.' : 'A.M.';
//     const formattedHours = hoursInt % 12 || 12; // Convert 24-hour format to 12-hour format
//     const formattedMinutes = minutes.padStart(2, '0'); // Ensure minutes are two digits

//     return `${formattedHours}:${formattedMinutes} ${period}`;
// };

// export default function DoctorSchedule() {
//     const [events, setEvents] = useState([]);
//     const [filteredEvents, setFilteredEvents] = useState([]);
//     const [filter, setFilter] = useState('all'); // 'all', 'confirmed', or 'pending'
//     const doctorId = localStorage.getItem("doctorId");

//     useEffect(() => {
//         // Fetch appointments from API
//         axios.get(`${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`)
//             .then(response => {
//                 const appointments = response.data;

//                 // Format data for FullCalendar
//                 const formattedEvents = appointments.map(app => ({
//                     title: `(${app.status})`,
//                     start: `${app.appointment_date}T${app.appointment_time}`,
//                     end: `${app.appointment_date}T${app.appointment_time}`, // You can adjust end time if needed
//                     color: getStatusColor(app.status),
//                     extendedProps: {
//                         reason: app.reason,
//                         patient_email: app.patient_email,
//                         appointment_type: app.appointment_type,
//                         department: app.department,
//                         gender: app.gender,
//                         age: app.age,
//                         formattedTime: formatTime(app.appointment_time) // Add formatted time here
//                     }
//                 }));

//                 setEvents(formattedEvents);
//                 setFilteredEvents(formattedEvents); // Initially, show all events
//             })
//             .catch(error => {
//                 console.error("There was an error fetching the appointments!", error);
//             });
//     }, [doctorId]);

//     // useEffect(() => {
//     //     // Filter events based on the selected filter
//     //     if (filter === 'all') {
//     //         setFilteredEvents(events);
//     //     } else {
//     //         setFilteredEvents(events.filter(event => event.title.includes(filter)));
//     //     }
//     // }, [filter, events]);
//     useEffect(() => {
//         // Filter events based on the selected filter
//         if (filter === 'all') {
//             // Only show 'pending' and 'confirmed' when 'all' is selected
//             setFilteredEvents(events.filter(event => event.title.includes('pending') || event.title.includes('confirmed')));
//         } else {
//             setFilteredEvents(events.filter(event => event.title.includes(filter)));
//         }
//     }, [filter, events]);

//     // Function to determine color based on status
//     const getStatusColor = (status) => {
//         switch (status) {
//             case 'confirmed':
//                 return 'green';
//             case 'completed':
//                 return 'purple';
//             case 'pending':
//                 return 'blue';
//             case 'rejected':
//                 return 'red';
//             case 'rescheduled':
//                 return 'orange';
//             default:
//                 return 'gray';
//         }
//     };

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container-fluid" />
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />

//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                             <h5 className="mb-0">Schedule Timing</h5>

//                             <div className="row">
//                                 <div className="col-xl-2 col-lg-4 col-12 mt-4">
//                                     <div id="external-events">
//                                         <div className="card border-0 p-4 shadow rounded">
//                                             <span className="h6">All Events</span>

//                                             {/* Static events if needed */}
//                                             <div className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
//                                                 <div className="fc-event-main">Meeting</div>
//                                             </div>
//                                             {/* Other static events here */}

//                                             <div className="mt-2">
//                                                 <div className="form-check">
//                                                     <input className="form-check-input" type="checkbox" value="" id="drop-remove" />
//                                                     <label className="form-check-label" htmlFor="drop-remove">Remove after drop</label>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>

//                                 <div className="col-xl-10 col-lg-8 col-12 mt-4">
//                                     {/* Filter buttons */}
//                                     <div className="mb-3">
//                                         <button
//                                             className={`btn btn-primary ${filter === 'confirmed' ? 'active' : ''}`}
//                                             onClick={() => setFilter('confirmed')}
//                                         >
//                                             Confirmed
//                                         </button>
//                                         <button
//                                             className={`btn btn-secondary ${filter === 'pending' ? 'active' : ''}`}
//                                             onClick={() => setFilter('pending')}
//                                         >
//                                             Pending
//                                         </button>
//                                         <button
//                                             className={`btn btn-light ${filter === 'all' ? 'active' : ''}`}
//                                             onClick={() => setFilter('all')}
//                                         >
//                                             All
//                                         </button>
//                                     </div>

//                                     <div id="calendar-container" className="card rounded border-0 shadow p-4">
//                                         <FullCalendar
//                                             plugins={[dayGridPlugin]}
//                                             events={filteredEvents}
//                                             eventContent={function(arg) {
//                                                 const { event } = arg;
//                                                 return (
//                                                     <div>
//                                                         {event.extendedProps.formattedTime} {event.title}
//                                                     </div>
//                                                 );
//                                             }}
//                                             headerToolbar={{
//                                                 left: 'prev,next today addEventButton',
//                                                 center: 'title',
//                                                 right: 'dayGridMonth,dayGridWeek,dayGridDay'
//                                             }}
//                                             customButtons={{
//                                                 addEventButton: {
//                                                     text: 'Add Event',
//                                                     click: function () {
//                                                         var dateStr = prompt('Enter a date in YYYY-MM-DD format');
//                                                     }
//                                                 }
//                                             }}
//                                         />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <AdminFooter />
//             <ScrollTop />
//         </>
//     );
// }

// import React, { useState, useEffect } from "react";
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import axios from "axios";
// import { getDoctorId, getUserData } from "../../_helper/helper";
// import { useLocation, useNavigate } from "react-router-dom";
// import Header from "../../components/CommonComponents/Header";

// // Function to format time from 24-hour to 12-hour format with A.M./P.M.
// const formatTime = (time) => {
//   const [hours, minutes] = time.split(":");
//   const hoursInt = parseInt(hours, 10);
//   const period = hoursInt >= 12 ? "P.M." : "A.M.";
//   const formattedHours = hoursInt % 12 || 12; // Convert 24-hour format to 12-hour format
//   const formattedMinutes = minutes.padStart(2, "0"); // Ensure minutes are two digits

//   return `${formattedHours}:${formattedMinutes} ${period}`;
// };
// const aggregateAppointments = (appointments) => {
//   const summary = {};

//   appointments.forEach((app) => {
//     const date = app.appointment_date;
//     const status = app.status;

//     if (!summary[date]) {
//       summary[date] = {};
//     }

//     if (!summary[date][status]) {
//       summary[date][status] = 0;
//     }

//     summary[date][status]++;
//   });

//   // Transform aggregated data into events for FullCalendar
//   const formattedEvents = [];
//   Object.keys(summary).forEach((date) => {
//     Object.keys(summary[date]).forEach((status) => {
//       formattedEvents.push({
//         title: `${summary[date][status]} ${
//           status.charAt(0).toUpperCase() + status.slice(1)
//         }`,
//         start: `${date}T00:00:00`,
//         end: `${date}T23:59:59`,
//         color: getStatusColor(status),
//         extendedProps: {
//           date: date,
//           status: status,
//           statusCount: summary[date][status],
//         },
//       });
//     });
//   });

//   return formattedEvents;
// };

// // const aggregateAppointments = (appointments) => {
// //     const summary = {};

// //     appointments.forEach(app => {
// //         const date = app.appointment_date;
// //         const status = app.status;

// //         if (!summary[date]) {
// //             summary[date] = {};
// //         }

// //         if (!summary[date][status]) {
// //             summary[date][status] = 0;
// //         }

// //         summary[date][status]++;
// //     });

// //     console.log(summary); // Log the aggregated summary

// //     // Transform aggregated data into events for FullCalendar
// //     const formattedEvents = [];
// //     Object.keys(summary).forEach(date => {
// //         Object.keys(summary[date]).forEach(status => {
// //             formattedEvents.push({
// //                 title: `${summary[date][status]} ${status.charAt(0).toUpperCase() + status.slice(1)}`,
// //                 start: `${date}T00:00:00`,
// //                 end: `${date}T23:59:59`,
// //                 color: getStatusColor(status),
// //                 extendedProps: {
// //                     statusCount: summary[date][status]
// //                 }
// //             });
// //         });
// //     });

// //     return formattedEvents;
// // };

// // Function to determine color based on status
// const getStatusColor = (status) => {
//   switch (status) {
//     case "confirmed":
//       return "green";
//     case "completed":
//       return "purple";
//     case "pending":
//       return "blue";
//     case "rejected":
//       return "red";
//     case "rescheduled":
//       return "orange";
//     default:
//       return "gray";
//   }
// };

// export default function DoctorSchedule() {
//   const [events, setEvents] = useState([]);
//   const [filteredEvents, setFilteredEvents] = useState([]);
//   const [filter, setFilter] = useState("all"); // 'all', 'confirmed', or 'pending'
//   // const doctorId = getDoctorId();
//   const { role, assigned_doctor_id, doctor_id } = getUserData();

//   const navigate = useNavigate();
//   const location = useLocation();
//   const doctorId = role === "staff" ? assigned_doctor_id : doctor_id;
  
//     // Fetch appointments from API
//     const fetchAppointments = async () => {
//       try {
//         const token = localStorage.getItem("accessToken"); // Replace with your token retrieval logic
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`,
//           {
//             method: 'GET', // You can specify the method explicitly
//             headers: {
//               'Authorization': `Bearer ${token}`, // Add the Authorization header
//               'Content-Type': 'application/json' // Optional: Set the content type if needed
//             },
//           }
//         );
        

//         console.log(response.data); // Log the API response
//         const appointments = response.data;
//         const aggregatedEvents = aggregateAppointments(appointments);

//         setEvents(aggregatedEvents);
//         setFilteredEvents(aggregatedEvents); // Initially, show all events
//       } catch (error) {
//         console.error("There was an error fetching the appointments!", error);
//       }
//     };
//     useEffect(() => {
//     fetchAppointments();
//   }, [doctorId]);
//   // useEffect(() => {
//   //   // Fetch appointments from API
//   //   axios
//   //     .get(`${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`)
//   //     .then((response) => {
//   //       console.log(response.data); // Log the API response
//   //       const appointments = response.data;
//   //       const aggregatedEvents = aggregateAppointments(appointments);

//   //       setEvents(aggregatedEvents);
//   //       setFilteredEvents(aggregatedEvents); // Initially, show all events
//   //     })
//   //     .catch((error) => {
//   //       console.error("There was an error fetching the appointments!", error);
//   //     });
//   // }, [doctorId]);

//   useEffect(() => {
//     if (filter === "all") {
//       // Ensure that we're filtering correctly based on status
//       setFilteredEvents(
//         events.filter(
//           (event) =>
//             event.title.includes("Pending") || event.title.includes("Confirmed")
//         )
//       );
//     } else {
//       // Apply the filter based on the selected status
//       setFilteredEvents(
//         events.filter((event) =>
//           event.title.toLowerCase().includes(filter.toLowerCase())
//         )
//       );
//     }
//   }, [filter, events]);

//   // useEffect(() => {
//   //     // Filter events based on the selected filter
//   //     if (filter === 'all') {
//   //         setFilteredEvents(events);
//   //     } else {
//   //         setFilteredEvents(events.filter(event => event.title.toLowerCase().includes(filter)));
//   //     }
//   // }, [filter, events]);
//   //   const handleEventClick = (clickInfo) => {
//   //     const { extendedProps } = clickInfo.event;
//   //     const date = extendedProps.date; // Get the date from extendedProps
//   //     navigate(`/doctor/appointments/${doctorId}`);
//   // };
//   // const handleEventClick = (clickInfo) => {
//   //     const { extendedProps } = clickInfo.event;
//   //     const { date, status } = extendedProps; // Extract date and status

//   //     // Navigate to the URL with date and status as query parameters
//   //     navigate(`/doctor/appointments/${doctorId}?date=${date}&status=${status}`);
//   // };
//   const handleDateClick = (clickInfo) => {
//     const { extendedProps } = clickInfo.event;
//     const { date, status } = extendedProps; // Extract date and status from extendedProps

//     // Navigate to the URL with date and status as query parameters
//     navigate(
//       `/doctor/appointments/${doctorId}?date=${encodeURIComponent(
//         date
//       )}&status=${encodeURIComponent(status)}`
//     );
//   };
//   const showBackButton = location.key !== "default";
//   return (
//     <>
//       <Navbar
//         navDark={true}
//         manuClass="navigation-menu nav-left"
//         containerClass="container"
//       />
//       <section className="bg-dashboard">
//         <div className="container-fluid">
//           <div className="row">
//             <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />

//             <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//               {/* <h5 className="mb-0">Schedule Timing</h5> */}
//               <Header
//                 title="Schedule Timing"
//                 onBackClick={() => navigate(-1)} // Go back to the previous page
//                 showBackButton={showBackButton}
//               />

//               <div className="row">
//                 <div className="col-xl-2 col-lg-4 col-12 mt-4">
//                   <div id="external-events">
//                     <div className="card border-0 p-4 shadow rounded">
//                       <span className="h6">All Events</span>

//                       {/* Static events if needed */}
//                       <div className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
//                         <div className="fc-event-main">Meeting</div>
//                       </div>
//                       {/* Other static events here */}

//                       <div className="mt-2">
//                         <div className="form-check">
//                           <input
//                             className="form-check-input"
//                             type="checkbox"
//                             value=""
//                             id="drop-remove"
//                           />
//                           <label
//                             className="form-check-label"
//                             htmlFor="drop-remove"
//                           >
//                             Remove after drop
//                           </label>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-xl-10 col-lg-8 col-12 mt-4">
//                   {/* Filter buttons */}
//                   <div className="mb-3">
//                     {/* <button
//                       className={`btn btn-primary ${
//                         filter === "confirmed" ? "active" : ""
//                       }`}
//                       onClick={() => setFilter("confirmed")}
//                     >
//                       Confirmed
//                     </button> */}
//                     <button
//                       style={{
//                         backgroundColor:
//                           filter === "confirmed" ? "#28a745" : "#f8f9fa",
//                         color: filter === "confirmed" ? "#fff" : "#000",
//                         borderColor:
//                           filter === "confirmed" ? "#28a745" : "#ced4da",
//                         borderRadius: "0",
//                         margin: "0",
//                         borderRight: "1px solid #ced4da",
//                         padding: "0.5rem 1rem", // Adjust padding for larger button
//                         fontSize: ".7rem", // Increase font size for larger text
//                       }}
//                       className="btn btn-success btn-sm me-0 mb-0"
//                       onClick={() => setFilter("confirmed")}
//                     >
//                       Confirmed
//                     </button>
//                     {/* <button
//                       className={`btn btn-secondary ${
//                         filter === "pending" ? "active" : ""
//                       }`}
//                       onClick={() => setFilter("pending")}
//                     >
//                       Pending
//                     </button> */}
//                     <button
//                       style={{
//                         backgroundColor:
//                           filter === "pending" ? "#007bff" : "#f8f9fa",
//                         color: filter === "pending" ? "#fff" : "#000",
//                         borderColor:
//                           filter === "pending" ? "#007bff" : "#ced4da",
//                         borderRadius: "0", // Remove border radius for a seamless look
//                         margin: "0", // Remove margin to group buttons together
//                         borderRight: "1px solid #ced4da", // Add right border to separate buttons
//                         padding: "0.5rem 1rem", // Adjust padding for larger button
//                         fontSize: ".7rem", // Increase font size for larger text
//                       }}
//                       className="btn btn-primary btn-sm me-0 mb-0"
//                       onClick={() => setFilter("pending")}
//                     >
//                       Pending
//                     </button>
//                     {/* <button
//                       className={`btn btn-light ${
//                         filter === "all" ? "active" : ""
//                       }`}
//                       onClick={() => setFilter("all")}
//                     >
//                       All
//                     </button> */}
//                     <button
//                       style={{
//                         backgroundColor: !filter ? "#6c757d" : "#f8f9fa",
//                         color: !filter ? "#fff" : "#000",
//                         borderColor: !filter ? "#6c757d" : "#ced4da",
//                         borderRadius: "0",
//                         margin: "0",
//                         padding: "0.5rem 1rem", // Adjust padding for larger button
//                         fontSize: ".7rem", // Increase font size for larger texts
//                       }}
//                       className="btn btn-secondary btn-sm me-0 mb-0"
//                       // onClick={clearFilters}
//                       onClick={() => setFilter("all")}
//                     >
//                       All
//                     </button>
//                   </div>

//                   <div
//                     id="calendar-container"
//                     className="card rounded border-0 shadow p-4"
//                   >
//                     <FullCalendar
//                       plugins={[dayGridPlugin]}
//                       events={filteredEvents}
//                       eventContent={function (arg) {
//                         const { event } = arg;
//                         return <div>{event.title}</div>;
//                       }}
//                       headerToolbar={{
//                         left: "prev,next today addEventButton",
//                         center: "title",
//                         right: "dayGridMonth,dayGridWeek,dayGridDay",
//                       }}
//                       customButtons={{
//                         addEventButton: {
//                           text: "Add Event",
//                           click: function () {
//                             var dateStr = prompt(
//                               "Enter a date in YYYY-MM-DD format"
//                             );
//                           },
//                         },
//                       }}
//                       eventClick={handleDateClick} // Add eventClick handler
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <AdminFooter />
//       <ScrollTop />
//     </>
//   );
// }



import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { getDoctorId, getUserData } from "../../_helper/helper";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/CommonComponents/Header";

// Function to determine color based on status
const getStatusColor = (status) => {
  switch (status) {
    case "confirmed":
      return "green";
    case "completed":
      return "purple";
    case "pending":
      return "blue";
    case "rejected":
      return "red";
    case "rescheduled":
      return "orange";
    default:
      return "gray";
  }
};

// Function to format appointments for FullCalendar
const aggregateAppointments = (appointments) => {
  const summary = {};

  appointments.forEach((app) => {
    const date = app.appointment_date;
    const status = app.status;

    if (!summary[date]) {
      summary[date] = {};
    }

    if (!summary[date][status]) {
      summary[date][status] = 0;
    }

    summary[date][status]++;
  });

  const formattedEvents = [];
  Object.keys(summary).forEach((date) => {
    Object.keys(summary[date]).forEach((status) => {
      formattedEvents.push({
        title: `${summary[date][status]} ${status.charAt(0).toUpperCase() + status.slice(1)}`,
        start: `${date}T00:00:00`,
        end: `${date}T23:59:59`,
        color: getStatusColor(status),
        extendedProps: {
          date: date,
          status: status,
          statusCount: summary[date][status],
        },
      });
    });
  });

  return formattedEvents;
};

export default function DoctorSchedule() {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [filter, setFilter] = useState("all");
  const { role, assigned_doctor_id, doctor_id } = getUserData();
  const navigate = useNavigate();
  const location = useLocation();
  const doctorId = role === "staff" ? assigned_doctor_id : doctor_id;

  const fetchAppointments = async () => {
    try {
      const token = localStorage.getItem("accessToken"); // Adjust as necessary
      const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const appointments = await response.json(); // Parse the JSON response
      console.log(appointments); // Log the API response
      const aggregatedEvents = aggregateAppointments(appointments);

      setEvents(aggregatedEvents);
      setFilteredEvents(aggregatedEvents); // Initially, show all events
    } catch (error) {
      console.error("There was an error fetching the appointments!", error);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, [doctorId]);

  // useEffect(() => {
  //   if (filter === "all") {
  //     setFilteredEvents(events);
  //   } else {
  //     setFilteredEvents(
  //       events.filter((event) => event.title.toLowerCase().includes(filter.toLowerCase()))
  //     );
  //   }
  // }, [filter, events]);
  useEffect(() => {
    if (filter === "all") {
      setFilteredEvents(
        events.filter((event) =>
          ["rescheduled", "confirmed", "pending"].includes(event.extendedProps.status)
        )
      );
    } else {
      setFilteredEvents(
        events.filter((event) => event.title.toLowerCase().includes(filter.toLowerCase()))
      );
    }
  }, [filter, events]);
  

  const handleDateClick = (clickInfo) => {
    const { extendedProps } = clickInfo.event;
    const { date, status } = extendedProps;

    navigate(`/doctor/appointments/${doctorId}?date=${encodeURIComponent(date)}&status=${encodeURIComponent(status)}`);
  };

  const showBackButton = location.key !== "default";

  return (
    <>
      <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header title="Schedule Timing" onBackClick={() => navigate(-1)} showBackButton={showBackButton} />
              <div className="row">
                <div className="col-xl-2 col-lg-4 col-12 mt-4">
                  <div id="external-events">
                    <div className="card border-0 p-4 shadow rounded">
                      <span className="h6">All Events</span>
                      <div className="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event">
                        <div className="fc-event-main">Meeting</div>
                      </div>
                      <div className="mt-2">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" id="drop-remove" />
                          <label className="form-check-label" htmlFor="drop-remove">Remove after drop</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-10 col-lg-8 col-12 mt-4">
                  <div className="mb-3">
                    <button
                      style={{
                        backgroundColor: filter === "confirmed" ? "#28a745" : "#f8f9fa",
                        color: filter === "confirmed" ? "#fff" : "#000",
                        borderColor: filter === "confirmed" ? "#28a745" : "#ced4da",
                        borderRadius: "0",
                        margin: "0",
                        borderRight: "1px solid #ced4da",
                        padding: "0.5rem 1rem",
                        fontSize: ".7rem",
                      }}
                      className="btn btn-success btn-sm me-0 mb-0"
                      onClick={() => setFilter("confirmed")}
                    >
                      Confirmed
                    </button>
                    <button
                      style={{
                        backgroundColor: filter === "pending" ? "#007bff" : "#f8f9fa",
                        color: filter === "pending" ? "#fff" : "#000",
                        borderColor: filter === "pending" ? "#007bff" : "#ced4da",
                        borderRadius: "0",
                        margin: "0",
                        borderRight: "1px solid #ced4da",
                        padding: "0.5rem 1rem",
                        fontSize: ".7rem",
                      }}
                      className="btn btn-primary btn-sm me-0 mb-0"
                      onClick={() => setFilter("pending")}
                    >
                      Pending
                    </button>
                    <button
                      style={{
                        backgroundColor: filter === "all" ? "#6c757d" : "#f8f9fa",
                        color: filter === "all" ? "#fff" : "#000",
                        borderColor: filter === "all" ? "#6c757d" : "#ced4da",
                        borderRadius: "0",
                        margin: "0",
                        padding: "0.5rem 1rem",
                        fontSize: ".7rem",
                      }}
                      className="btn btn-secondary btn-sm me-0 mb-0"
                      onClick={() => setFilter("all")}
                    >
                      All
                    </button>
                  </div>

                  <div id="calendar-container" className="card rounded border-0 shadow p-4">
                    <FullCalendar
                      plugins={[dayGridPlugin]}
                      events={filteredEvents}
                      eventContent={(arg) => <div>{arg.event.title}</div>}
                      headerToolbar={{
                        left: "prev,next today addEventButton",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay",
                      }}
                      customButtons={{
                        addEventButton: {
                          text: "Add Event",
                          click: function () {
                            var dateStr = prompt("Enter a date in YYYY-MM-DD format");
                          },
                        },
                      }}
                      eventClick={handleDateClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AdminFooter />
      <ScrollTop />
    </>
  );
}
