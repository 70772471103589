import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

export default function MobileLogin({ show, handleClose }) {
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const navigate = useNavigate();

  const handleCloseMobileModal = () => {
    setShowMobileModal(false);
    handleClose();
    setMobileNumber("");
    setOtpError("");
  };

  const handleShowMobileModal = () => {
    setMobileNumber("");
    setOtpError("");
    setShowMobileModal(true);
  };

  const handleCloseOtpModal = () => {
    setShowOtpModal(false);
    handleClose();
    setOtp("");
    setOtpError("");
  };

  const handleShowOtpModal = () => {
    setOtp("");
    setOtpError("");
    setShowOtpModal(true);
  };

  const handleMobileLogin = async () => {
    if (!mobileNumber) {
      setOtpError("Please enter your mobile number.");
      return;
    }

    setOtpError("");
    setOtpLoading(true);
    handleClose();

    try {
      const fullnumber = `+91${mobileNumber}`;
      await axios.post(`${process.env.REACT_APP_API_URL}/request-otp/`, {
        phone_number: fullnumber,
      });

      setShowMobileModal(false);
      setShowOtpModal(true);
    } catch (err) {
      console.error("Error:", err);
      setOtpError(
        err.response?.data.detail || "Failed to send OTP. Please try again."
      );
    } finally {
      setOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setOtpError("Please enter OTP.");
      return;
    }

    setOtpError("");
    setOtpLoading(true);

    try {
      const fullnumber = `+91${mobileNumber}`;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-otp/`,
        {
          phone_number: fullnumber,
          otp,
        }
      );

      localStorage.setItem("accessToken", response.data.access);
      localStorage.setItem("userId", response.data.user.id);
      localStorage.setItem("userRole", response.data.user.role);
      localStorage.setItem("userEmail", response.data.user.email);
      localStorage.setItem("firstName", response.data.user.first_name);
      localStorage.setItem("lastName", response.data.user.last_name);
      localStorage.setItem("userData", JSON.stringify(response.data));
      localStorage.setItem("user", JSON.stringify(response.data.user));

      // navigate("/doctor-team-two");
      window.location.href = "/doctor-search";
    } catch (err) {
      console.error("Error:", err);
      setOtpError(
        err.response?.data.detail || "Failed to verify OTP. Please try again."
      );
    } finally {
      setOtpLoading(false);
      handleCloseOtpModal();
    }
  };

  return (
    <>
      {/* <Button variant="link" className="mt-3 d-block mx-auto" onClick={handleShowMobileModal}>
        Login with Mobile Number
      </Button> */}

      <Modal
        show={show}
        onHide={handleCloseMobileModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Login with Mobile Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter your mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
          {otpError && <div className="text-danger mt-2">{otpError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMobileModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleMobileLogin}
            disabled={otpLoading}
          >
            {otpLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Send OTP"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showOtpModal}
        onHide={handleCloseOtpModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          {otpError && <div className="text-danger mt-2">{otpError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseOtpModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleVerifyOtp}
            disabled={otpLoading}
          >
            {otpLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Verify OTP"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
