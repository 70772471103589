import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import client1 from "../../assets/images/client/01.jpg";
import { useNavigate } from "react-router-dom";
import {
  FaEllipsisH,
  FiUser,
  FiSettings,
  FiTrash,
} from "../../assets/icons/vander";
import Paging from "../../components/CommonComponents/Paging";
import Header from "../../components/CommonComponents/Header";
import AddPatient from "../../components/patient/AddPatient";
// import debounce from "lodash.debounce"; // Import debounce from lodash
import { getDoctorId, getUserData } from "../../_helper/helper";

export default function PatientList() {
  const [show, setShow] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [appointmentsPerPage] = useState(30); // Number of items per page
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [openPatientModal, setOpenPatientModal] = useState(false);
  const userRole = localStorage.getItem("userRole"); // Assuming you have userRole in localStorage
  const { role, assigned_doctor_id, doctor_id } = getUserData();
  // const assigned_doctor_id=localStorage.getItem("assigndoctorId")
  // const doctor_id=localStorage.getItem("doctorId")
  const doctorId = role === "staff" ? assigned_doctor_id : doctor_id;
  // const doctorId = getDoctorId();
  const navigate = useNavigate();
  const location = useLocation();
  const showBackButton = location.key !== "default";
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };
  const debouncedFetchAppointments = async (query) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/patientAppointment/search/?search=${query}`
      );
      const data = await response.json();

      // const allAppointments = data || [];
      const allAppointments = Array.isArray(data) ? data : []; // Ensure it's an array

      setAppointments(allAppointments);
      setFilteredAppointments(allAppointments);

      // Calculate total pages
      const totalItems = allAppointments.length;
      setTotalPages(Math.ceil(totalItems / appointmentsPerPage));
    } catch (error) {
      console.error(error);
      setAppointments([]);
      setFilteredAppointments([]);
      setTotalPages(1); // Default to 1 page on error
    }
  }; // Debounce for 300ms

  useEffect(() => {
    // Fetch all appointments if search query is empty
    if (!searchQuery) {
      fetchAppointments();
    } else {
      debounce(() => {
        debouncedFetchAppointments(searchQuery);
      }, [100]);
    }
  }, [searchQuery]);
  const fetchAppointments = async () => {
    try {
      const token = localStorage.getItem("accessToken"); // Replace with your token retrieval logic
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`,
        {
          method: 'GET', // You can specify the method explicitly
          headers: {
            'Authorization': `Bearer ${token}`, // Add the Authorization header
            'Content-Type': 'application/json' // Optional: Set the content type if needed
          },
        }
      );
      const data = await response.json();

      // const allAppointments = data || [];
      const allAppointments = Array.isArray(data) ? data : []; // Ensure it's an array

      setAppointments(allAppointments);
      setFilteredAppointments(allAppointments);

      // Calculate total pages
      const totalItems = allAppointments.length;
      setTotalPages(Math.ceil(totalItems / appointmentsPerPage));
    } catch (error) {
      console.error(error);
      setAppointments([]);
      setFilteredAppointments([]);
      setTotalPages(1); // Default to 1 page on error
    }
  };
  // useEffect(() => {
  //   const fetchAppointments = async () => {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`
  //       );
  //       const data = await response.json();

  //       const allAppointments = data || [];
  //       setAppointments(allAppointments);
  //       setFilteredAppointments(allAppointments);

  //       // Calculate total pages
  //       const totalItems = allAppointments.length;
  //       setTotalPages(Math.ceil(totalItems / appointmentsPerPage));
  //     } catch (error) {
  //       console.error(error);
  //       setAppointments([]);
  //       setFilteredAppointments([]);
  //       setTotalPages(1); // Default to 1 page on error
  //     }
  //   };

  //   fetchAppointments();

  //   const modalClose = () => {
  //     setShow(false);
  //   };

  //   // document.addEventListener("mousedown", modalClose);
  //   // return () => {
  //   //   document.removeEventListener("mousedown", modalClose);
  //   // };
  // }, [doctorId]);

  useEffect(() => {
    // Filter appointments based on search query
    if (searchQuery) {
      const filtered = appointments.filter((appointment) =>
        appointment.patient_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
      setFilteredAppointments(filtered);
    } else {
      setFilteredAppointments(appointments);
    }
    // Update total pages after filtering
    const totalItems = filteredAppointments.length;
    setTotalPages(Math.ceil(totalItems / appointmentsPerPage));
    setCurrentPage(1); // Reset to first page when searching
  }, [searchQuery, appointments, appointmentsPerPage]);

  // Pagination logic
  const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = filteredAppointments.slice(
    indexOfFirstAppointment,
    indexOfLastAppointment
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleShow = (id) => {
    setShow(id);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />

            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header
                title="Patients List"
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
                actionButton={
                  userRole === "doctor" && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => setOpenPatientModal(true)}
                      style={{ marginRight: "15px" }}
                    >
                      Add Patient
                    </button>
                  )
                }
              />
              <AddPatient
                openPatientModal={openPatientModal}
                setOpenPatientModal={setOpenPatientModal}
              />
              {/* Search Box */}
              <div className="mb-4 mt-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by patient name..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>

              <div className="row">
                {currentAppointments.length > 0 ? (
                  currentAppointments.map((item, index) => (
                    <div
                      className="col-xl-3 col-lg-6 col-12 mt-4 pt-2"
                      key={index}
                    >
                      <div className="card border-0 shadow rounded p-4">
                        <div className="d-flex justify-content-between">
                          <img
                            src={item.image || client1}
                            className="avatar avatar-md-md rounded-pill shadow"
                            alt=""
                          />
                          <div className="dropdown dropdown-primary">
                            <button
                              type="button"
                              className="btn btn-icon btn-pills btn-soft-primary dropdown-toggle p-0"
                              onClick={() => handleShow(item.id)}
                            >
                              <FaEllipsisH />
                            </button>
                            <div
                              className={`${
                                show === item.id ? "show" : ""
                              } dropdown-menu dd-menu dropdown-menu-end shadow border-0 mt-3 py-3`}
                              style={{ right: "0" }}
                            >
                              <Link
                                className="dropdown-item text-dark mb-1"
                                to="/patient-details"
                                state={{ item: item }}
                              >
                                <span className="mb-0 d-inline-flex align-items-center me-1">
                                  <FiUser className="align-middle h6 mb-0" />
                                </span>{" "}
                                <b>Profile</b>
                              </Link>
                              <Link
                                className="dropdown-item text-dark mb-1"
                                to="#"
                              >
                                <span className="mb-0 d-inline-flex align-items-center me-1">
                                  <FiSettings className="align-middle h6 mb-0" />
                                </span>{" "}
                                Profile Settings
                              </Link>
                              <Link className="dropdown-item text-dark" to="#">
                                <span className="mb-0 d-inline-flex align-items-center me-1">
                                  <FiTrash className="align-middle h6 mb-0" />
                                </span>{" "}
                                Delete
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="card-body p-0 pt-3">
                          <Link to="#" className="text-dark h6">
                            {item.patient_name}
                          </Link>
                          <ul className="mb-0 list-unstyled mt-2">
                            <li className="mt-1 ms-0">
                              <span className="text-muted me-2">Gender:</span>
                              {item.gender}
                            </li>
                            <li className="mt-1 ms-0">
                              <span className="text-muted me-2">Age:</span>
                              {item.age}
                            </li>
                            <li className="mt-1 ms-0">
                              <span className="text-muted me-2">Reason:</span>
                              {item.reason}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No appointments found.</p>
                )}
              </div>

              <div className="row text-center">
                <div className="col-12 mt-4 pt-2">
                  <Paging
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AdminFooter />
      <ScrollTop />
    </>
  );
}
