

// import React from "react";
// import { Navigate } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
// import logoIcon from '../../assets/images/logo-icon.png';  // Import the logo icon

// const ProtectedRoute = ({ children }) => {
//     const { isAuthenticated, isLoading } = useAuth0();
//     // const token = localStorage.getItem("authToken"); // Check for the authentication token
//     const token = localStorage.getItem("authToken") || localStorage.getItem("accessToken");


//     // Add keyframes for blinking animation to the document
//     React.useEffect(() => {
//         const styleSheet = document.styleSheets[0];
//         styleSheet.insertRule(`
//             @keyframes blinking {
//                 0% { opacity: 1; }
//                 50% { opacity: 0; }
//                 100% { opacity: 1; }
//             }
//         `, styleSheet.cssRules.length);
//     }, []);

//     // Inline styles for the blinking effect and container
//     const styles = {
//         loadingContainer: {
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             height: '100vh',  // Full viewport height
//         },
//         blinkingLogo: {
//             height: '50px',
//             animation: 'blinking 1.5s infinite',  // Apply blinking animation
//         },
//     };

//     if (isLoading) {
//         return (
//             <div style={styles.loadingContainer}>
//                 <img src={logoIcon} alt="Logo Icon" style={styles.blinkingLogo} />
//             </div>
//         );
//     }

//     // Allow access if either `isAuthenticated` or `authToken` is present
//     return isAuthenticated || token ? children : <Navigate to="/login" />;
// };

// export default ProtectedRoute;


// this is the role based login and route

// import React from "react";
// import { Navigate } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
// import logoIcon from '../../assets/images/logo-icon.png'; 

// const ProtectedRoute = ({ children, allowedRoles }) => {
//     const { isAuthenticated, isLoading } = useAuth0();
//     const token = localStorage.getItem("authToken") || localStorage.getItem("accessToken");
//     const userRole = localStorage.getItem("userRole")
//     console.log(userRole)

//     React.useEffect(() => {
//         const styleSheet = document.styleSheets[0];
//         styleSheet.insertRule(`
//             @keyframes blinking {
//                 0% { opacity: 1; }
//                 50% { opacity: 0; }
//                 100% { opacity: 1; }
//             }
//         `, styleSheet.cssRules.length);
//     }, []);

//     const styles = {
//         loadingContainer: {
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             height: '100vh',
//         },
//         blinkingLogo: {
//             height: '50px',
//             animation: 'blinking 1.5s infinite',
//         },
//     };

//     if (isLoading) {
//         return (
//             <div style={styles.loadingContainer}>
//                 <img src={logoIcon} alt="Logo Icon" style={styles.blinkingLogo} />
//             </div>
//         );
//     }

//     if (!token || !isAuthenticated || !allowedRoles.includes(userRole)) {
//         return <Navigate to="/login" />;
//     }

//     return children;
// };

// export default ProtectedRoute;

import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import logoIcon from '../../assets/images/logo-icon.png'; 

const ProtectedRoute = ({ children, allowedRoles = [] }) => {
    const { isAuthenticated: auth0Authenticated, isLoading: auth0Loading } = useAuth0();
    const token = localStorage.getItem("authToken") || localStorage.getItem("accessToken");
    const userRole = localStorage.getItem("userRole");

    React.useEffect(() => {
        const styleSheet = document.styleSheets[0];
        styleSheet.insertRule(`
            @keyframes blinking {
                0% { opacity: 1; }
                50% { opacity: 0; }
                100% { opacity: 1; }
            }
        `, styleSheet.cssRules.length);
    }, []);

    const styles = {
        loadingContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        },
        blinkingLogo: {
            height: '50px',
            animation: 'blinking 1.5s infinite',
        },
    };

    if (auth0Loading) {
        return (
            <div style={styles.loadingContainer}>
                <img src={logoIcon} alt="Logo Icon" style={styles.blinkingLogo} />
            </div>
        );
    }

    // Allow access if:
    // - The user is authenticated via Auth0
    // - OR if there's a valid token
    // - AND the user's role is in the allowed roles
    if (!auth0Authenticated && !token) {
        return <Navigate to="/login" />;
    }

    if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute;




// src/components/ProtectedRoute.js





// This is the token passing for the Auth0 social login
// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
// import logoIcon from '../../assets/images/logo-icon.png';
// import { useAuthToken } from '../AuthContext';
// // import { useAuthToken } from '../../context/AuthTokenContext';

// const ProtectedRoute = ({ children }) => {
//     const { isAuthenticated, isLoading } = useAuth0();
//     const token = useAuthToken();

//     React.useEffect(() => {
//         const styleSheet = document.styleSheets[0];
//         styleSheet.insertRule(`
//             @keyframes blinking {
//                 0% { opacity: 1; }
//                 50% { opacity: 0; }
//                 100% { opacity: 1; }
//             }
//         `, styleSheet.cssRules.length);
//     }, []);

//     const styles = {
//         loadingContainer: {
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             height: '100vh',
//         },
//         blinkingLogo: {
//             height: '50px',
//             animation: 'blinking 1.5s infinite',
//         },
//     };

//     if (isLoading) {
//         return (
//             <div style={styles.loadingContainer}>
//                 <img src={logoIcon} alt="Logo Icon" style={styles.blinkingLogo} />
//             </div>
//         );
//     }

//     return isAuthenticated || token ? children : <Navigate to="/login" />;
// };

// export default ProtectedRoute;
