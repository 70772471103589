import { RESET, STORED_USER_GEO_LOCATION } from "./actionTypes";

export const storeUserLocation = (payload) => {
  return {
    type: STORED_USER_GEO_LOCATION,
    payload: payload,
  };
};

export const reset = () => {
  return {
    type: RESET,
  };
};
