// import React, { useCallback, useState } from 'react';
// import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

// const libraries = ['places']; // Specify any libraries you need

// const MyMapContainerNew = ({ id,onLocationSelect,returnLatLong = true }) => {
//     const { isLoaded, loadError } = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//         libraries,
//     });
// console.log("doctorId",id)
//     const [markerPosition, setMarkerPosition] = useState(null);

//     const onMapClick = useCallback((event) => {
//         const latLng = {
//             lat: event.latLng.lat(),
//             lng: event.latLng.lng(),
//         };
//         setMarkerPosition(latLng);
//         if (returnLatLong && onLocationSelect) {
//             onLocationSelect(latLng); // Notify parent component of the selected location
//         }
//         // setMarkerPosition(latLng);
//         // onLocationSelect(latLng); // Notify parent component of the selected location
//     }, [onLocationSelect,returnLatLong]);

//     if (loadError) {
//         console.error("Google Maps API Error:", loadError);
//         return <div>Error loading maps. Please check the console for more details.</div>;
//     }

//     if (!isLoaded) {
//         return <div>Loading Maps...</div>;
//     }

//     return (
//         <div style={{ height: '400px', width: '100%' }}>
//             <GoogleMap
//                 mapContainerStyle={{ height: '100%', width: '100%' }}
//                 zoom={10}
//                 center={markerPosition || { lat: 19.076090, lng: 72.877426 }} // Default center
//                 onClick={onMapClick}
//             >
//                 {markerPosition && (
//                     <Marker position={markerPosition} />
//                 )}
//             </GoogleMap>
//         </div>
//     );
// };

// export default MyMapContainerNew;


// import React, { useCallback, useState, useEffect } from 'react';
// import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

// const libraries = ['places']; // Specify any libraries you need

// const MyMapContainerNew = ({ id, onLocationSelect, returnLatLong = true }) => {
//     const { isLoaded, loadError } = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//         libraries,
//     });

//     const [clinics, setClinics] = useState([]); // Initialize as empty array
//     const [selectedClinic, setSelectedClinic] = useState(null); // Track selected clinic
//     const [markerPosition, setMarkerPosition] = useState({ lat: 19.076090, lng: 72.877426 }); // Default location
//     const token = localStorage.getItem("accessToken"); 
//     // Fetch clinic data when the component mounts
//     useEffect(() => {
//         const fetchClinics = async () => {
//             const token = localStorage.getItem('accessToken'); // Get token from localStorage

//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/${id}`, {
//                     method: 'GET',
//                     headers: {
//                         'Authorization': `Bearer ${token}`, // Include token in the Authorization header
//                         'Content-Type': 'application/json',
//                     },
//                 });

//                 const data = await response.json();
//                 if (data && data.data) {
//                     setClinics(data.data); // Update clinics state
//                 }
//             } catch (error) {
//                 console.error('Error fetching clinics:', error);
//             }
//         };
//         fetchClinics();
//     }, [id]);

//     // Handle clinic selection
//     const handleClinicChange = (event) => {
//         const clinicId = event.target.value;
//         const clinic = clinics.find(clinic => clinic.id === parseInt(clinicId));
//         if (clinic) {
//             const { latitude, longitude } = clinic;
//             const newLocation = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
//             setMarkerPosition(newLocation); // Update the marker position on the map
//             setSelectedClinic(clinic); // Store the selected clinic
//             if (returnLatLong && onLocationSelect) {
//                 onLocationSelect(newLocation); // Notify parent component with lat/long
//             }
//         }
//     };

//     if (loadError) {
//         console.error("Google Maps API Error:", loadError);
//         return <div>Error loading maps. Please check the console for more details.</div>;
//     }

//     if (!isLoaded) {
//         return <div>Loading Maps...</div>;
//     }

//     return (
//         <div>
//             {/* Clinic Dropdown */}
//             <div style={{ marginBottom: '10px' }}>
//                 <select onChange={handleClinicChange} style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}>
//                     <option value="">Select a Clinic</option>
//                     {clinics.length > 0 && clinics.map((clinic) => (
//                         <option key={clinic.id} value={clinic.id}>
//                             {clinic.name}
//                         </option>
//                     ))}
//                 </select>
//             </div>

//             {/* Google Map */}
//             <div style={{ height: '400px', width: '100%' }}>
//                 <GoogleMap
//                     mapContainerStyle={{ height: '100%', width: '100%' }}
//                     zoom={12}
//                     center={markerPosition}
//                 >
//                     {markerPosition && <Marker position={markerPosition} />}
//                 </GoogleMap>
//             </div>
//         </div>
//     );
// };

// export default MyMapContainerNew;


// import React, { useState, useEffect } from 'react';
// import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

// const libraries = ['places']; // Specify any libraries you need

// const MyMapContainerNew = ({ id, onLocationSelect, returnLatLong = true }) => {
//     const { isLoaded, loadError } = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//         libraries,
//     });

//     const [clinics, setClinics] = useState([]); // Initialize clinics array
//     const [markerPosition, setMarkerPosition] = useState({ lat: 19.076090, lng: 72.877426 }); // Default center position
//     const [selectedClinicId, setSelectedClinicId] = useState(null); // Track the selected clinic ID

//     // Fetch clinics data
//     useEffect(() => {
//         const fetchClinics = async () => {
//             const token = localStorage.getItem('accessToken'); // Get token from localStorage

//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/doctor/${id}`, {
//                     method: 'GET',
//                     headers: {
//                         'Authorization': `Bearer ${token}`, // Include token in the Authorization header
//                         'Content-Type': 'application/json',
//                     },
//                 });

//                 const data = await response.json();
                
//                 // Validate that data is an array
//                 if (Array.isArray(data)) {
//                     setClinics(data); // Set clinics data
//                 } else {
//                     console.error('Unexpected response format:', data);
//                     setClinics([]); // Reset to empty array if data is not an array
//                 }
//             } catch (error) {
//                 console.error('Error fetching clinics:', error);
//                 setClinics([]); // Reset to empty array on error
//             }
//         };

//         fetchClinics();
//     }, [id]);

//     // Handle clinic click to move the map and show marker
//     const handleClinicClick = (clinic) => {
//         const { latitude, longitude, id } = clinic;
//         const newLocation = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
//         setMarkerPosition(newLocation); // Update map marker position
//         setSelectedClinicId(id); // Highlight the selected marker
//         if (returnLatLong && onLocationSelect) {
//             onLocationSelect(newLocation); // Notify parent component with lat/long
//         }
//     };

//     if (loadError) {
//         console.error("Google Maps API Error:", loadError);
//         return <div>Error loading maps. Please check the console for more details.</div>;
//     }

//     if (!isLoaded) {
//         return <div>Loading Maps...</div>;
//     }

//     return (
//         <div style={{ display: 'flex', height: '100%' }}>
//             {/* Clinic List */}
//             <div style={{ flex: 1, overflowY: 'scroll', padding: '10px', borderRight: '1px solid #ccc' }}>
//                 {clinics.length > 0 ? (
//                     clinics.map((clinic) => (
//                         <div
//                             key={clinic.id}
//                             onClick={() => handleClinicClick(clinic)}
//                             style={{
//                                 cursor: 'pointer',
//                                 padding: '10px',
//                                 marginBottom: '10px',
//                                 backgroundColor: selectedClinicId === clinic.id ? '#d1ecf1' : '#f9f9f9', // Highlight selected clinic
//                                 border: '1px solid #ddd',
//                                 borderRadius: '5px',
//                                 boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
//                             }}
//                         >
//                             <h5 style={{ margin: 0 }}>{clinic.name}</h5>
//                             <p style={{ margin: 0 }}>{clinic.address}</p>
//                         </div>
//                     ))
//                 ) : (
//                     <p>No clinics available.</p>
//                 )}
//             </div>

//             {/* Google Map */}
//             <div style={{ flex: 1, height: '400px' }}>
//                 <GoogleMap
//                     mapContainerStyle={{ height: '100%', width: '100%' }}
//                     zoom={12}
//                     center={markerPosition}
//                 >
//                     {/* Show markers for all clinics */}
//                     {clinics.map((clinic) => (
//                         <Marker
//                             key={clinic.id}
//                             position={{ lat: parseFloat(clinic.latitude), lng: parseFloat(clinic.longitude) }}
//                             icon={
//                                 selectedClinicId === clinic.id
//                                     ? {
//                                         url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png', // Highlighted marker
//                                     }
//                                     : undefined
//                             }
//                         />
//                     ))}
//                 </GoogleMap>
//             </div>
//         </div>
//     );
// };

// export default MyMapContainerNew;


// import React, { useState, useEffect } from 'react';
// import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

// const libraries = ['places']; // Specify any libraries you need

// const MyMapContainerNew = ({ id, onLocationSelect, returnLatLong = true }) => {
//     const { isLoaded, loadError } = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
//         libraries,
//     });

//     const [clinics, setClinics] = useState([]); // Initialize clinics array
//     const [markerPosition, setMarkerPosition] = useState({ lat: 19.076090, lng: 72.877426 }); // Default center position
//     const [selectedClinicId, setSelectedClinicId] = useState(null); // Track the selected clinic ID

//     // Fetch clinics data
//     useEffect(() => {
//         const fetchClinics = async () => {
//             const token = localStorage.getItem('accessToken'); // Get token from localStorage

//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/doctor/${id}`, {
//                     method: 'GET',
//                     headers: {
//                         'Authorization': `Bearer ${token}`, // Include token in the Authorization header
//                         'Content-Type': 'application/json',
//                     },
//                 });

//                 const data = await response.json();
                
//                 // Validate that data is an array
//                 if (Array.isArray(data)) {
//                     setClinics(data); // Set clinics data
//                 } else {
//                     console.error('Unexpected response format:', data);
//                     setClinics([]); // Reset to empty array if data is not an array
//                 }
//             } catch (error) {
//                 console.error('Error fetching clinics:', error);
//                 setClinics([]); // Reset to empty array on error
//             }
//         };

//         fetchClinics();
//     }, [id]);

//     // Handle clinic click to move the map and show marker
//     const handleClinicClick = (clinic) => {
//         const { latitude, longitude, id } = clinic;
//         const newLocation = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
//         setMarkerPosition(newLocation); // Update map marker position
//         setSelectedClinicId(id); // Highlight the selected marker
//         if (returnLatLong && onLocationSelect) {
//             onLocationSelect(newLocation); // Notify parent component with lat/long
//         }
//     };

//     if (loadError) {
//         console.error("Google Maps API Error:", loadError);
//         return <div>Error loading maps. Please check the console for more details.</div>;
//     }

//     if (!isLoaded) {
//         return <div>Loading Maps...</div>;
//     }

//     return (
//         <div style={{ display: 'flex', height: '100%' }}>
//             {/* Clinic List */}
//             <div style={{ flex: '0 0 30%', overflowY: 'scroll', padding: '10px', borderRight: '1px solid #ccc' }}>
//                 {clinics.length > 0 ? (
//                     clinics.map((clinic) => (
//                         <div
//                             key={clinic.id}
//                             onClick={() => handleClinicClick(clinic)}
//                             style={{
//                                 cursor: 'pointer',
//                                 padding: '10px',
//                                 marginBottom: '10px',
//                                 backgroundColor: selectedClinicId === clinic.id ? '#d1ecf1' : '#f9f9f9', // Highlight selected clinic
//                                 border: '1px solid #ddd',
//                                 borderRadius: '5px',
//                                 boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
//                             }}
//                         >
//                             <h5 style={{ margin: 0 }}>{clinic.name}</h5>
//                             <p style={{ margin: 0 }}>{clinic.address}</p>
//                         </div>
//                     ))
//                 ) : (
//                     <p>No clinics available.</p>
//                 )}
//             </div>

//             {/* Google Map */}
//             <div style={{ flex: '1', height: '400px' }}>
//                 <GoogleMap
//                     mapContainerStyle={{ height: '100%', width: '100%' }}
//                     zoom={12}
//                     center={markerPosition}
//                 >
//                     {/* Show markers for all clinics */}
//                     {clinics.map((clinic) => (
//                         <Marker
//                             key={clinic.id}
//                             position={{ lat: parseFloat(clinic.latitude), lng: parseFloat(clinic.longitude) }}
//                             icon={
//                                 selectedClinicId === clinic.id
//                                     ? {
//                                         url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png', // Highlighted marker
//                                     }
//                                     : undefined
//                             }
//                         />
//                     ))}
//                 </GoogleMap>
//             </div>
//         </div>
//     );
// };

// export default MyMapContainerNew;

import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';

const libraries = ['places']; // Specify any libraries you need

const MyMapContainerNew = ({ id, onLocationSelect, returnLatLong = true }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const [clinics, setClinics] = useState([]); // Initialize clinics array
    const [markerPosition, setMarkerPosition] = useState({ lat: 19.076090, lng: 72.877426 }); // Default center position
    const [selectedClinic, setSelectedClinic] = useState(null); // Track the selected clinic

    // Fetch clinics data
    useEffect(() => {
        const fetchClinics = async () => {
            const token = localStorage.getItem('accessToken'); // Get token from localStorage

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/doctor/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include token in the Authorization header
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                if (Array.isArray(data)) {
                    setClinics(data); // Set clinics data
                } else {
                    console.error('Unexpected response format:', data);
                    setClinics([]); // Reset to empty array if data is not an array
                }
            } catch (error) {
                console.error('Error fetching clinics:', error);
                setClinics([]); // Reset to empty array on error
            }
        };

        fetchClinics();
    }, [id]);

    // Handle clinic click to move the map, show marker and InfoWindow
    const handleClinicClick = (clinic) => {
        const { latitude, longitude } = clinic;
        const newLocation = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
        setMarkerPosition(newLocation); // Update map marker position
        setSelectedClinic(clinic); // Show InfoWindow for the selected clinic
        if (returnLatLong && onLocationSelect) {
            onLocationSelect(newLocation); // Notify parent component with lat/long
        }
    };

    if (loadError) {
        console.error("Google Maps API Error:", loadError);
        return <div>Error loading maps. Please check the console for more details.</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps...</div>;
    }

    return (
        <div style={{ display: 'flex', height: '100%' }}>
            {/* Clinic List */}
            <div style={{ flex: '0 0 30%', overflowY: 'scroll', padding: '10px', borderRight: '1px solid #ccc' }}>
                {clinics.length > 0 ? (
                    clinics.map((clinic) => (
                        <div
                            key={clinic.id}
                            onClick={() => handleClinicClick(clinic)}
                            style={{
                                cursor: 'pointer',
                                padding: '10px',
                                marginBottom: '10px',
                                backgroundColor: selectedClinic?.id === clinic.id ? '#d1ecf1' : '#f9f9f9', // Highlight selected clinic
                                border: '1px solid #ddd',
                                borderRadius: '5px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <h5 style={{ margin: 0 }}>{clinic.name}</h5>
                            <p style={{ margin: 0 }}>{clinic.address}</p>
                        </div>
                    ))
                ) : (
                    <p>No clinics available.</p>
                )}
            </div>

            {/* Google Map */}
            <div style={{ flex: '0 0 70%', height: '400px' }}>
                <GoogleMap
                    mapContainerStyle={{ height: '100%', width: '100%' }}
                    zoom={12}
                    center={markerPosition}
                >
                    {/* Show markers for all clinics */}
                    {clinics.map((clinic) => (
                        <Marker
                            key={clinic.id}
                            position={{ lat: parseFloat(clinic.latitude), lng: parseFloat(clinic.longitude) }}
                            onClick={() => handleClinicClick(clinic)}
                        />
                    ))}

                    {/* Show single InfoWindow for the selected clinic */}
                    {selectedClinic && (
                        <InfoWindow
                            position={{
                                lat: parseFloat(selectedClinic.latitude),
                                lng: parseFloat(selectedClinic.longitude)
                            }}
                            onCloseClick={() => setSelectedClinic(null)} // Close InfoWindow when clicked
                        >
                            <div>
                                <h6>{selectedClinic.name}</h6>
                                <p>{selectedClinic.address}</p>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>
            </div>
        </div>
    );
};

export default MyMapContainerNew;
