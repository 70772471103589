// import React from "react";
// import { Link } from "react-router-dom";

// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import InvoiceView from "../../components/dashboard/invoiceView";

// import { invoiceData } from "../../data/data";

// export default function Invoice(){
//     return(
//         <>
//         <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container-fluid"/>
//         <section className="bg-dashboard">
//             <div className="container-fluid">
//                 <div className="row">
//                     <Sidebar colClass ="col-xl-3 col-lg-4 col-md-5 col-12"/>
//                     <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                         <h5 className="mb-0">Invoice List</h5>
//                         <div className="row">
//                             <div className="col-12 mt-4 pt-2">
//                                 <div className="table-responsive shadow rounded">
//                                     <table className="table table-center bg-white mb-0">
//                                         <thead>
//                                             <tr>
//                                                 <th className="border-bottom p-3">Invoice</th>
//                                                 <th className="border-bottom p-3" style={{minWidth:'220px'}}>Name</th>
//                                                 <th className="text-center border-bottom p-3" style={{minWidth:'180px'}}>Phone</th>
//                                                 <th className="text-center border-bottom p-3">Amount</th>
//                                                 <th className="text-center border-bottom p-3" style={{minWidth:'140px'}}>Generate(Dt.)</th>
//                                                 <th className="text-center border-bottom p-3">Status</th>
//                                                 <th className="text-end border-bottom p-3" style={{minWidth:'200px'}}></th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             {invoiceData.map((item, index) =>{
//                                                 return(
//                                                     <tr key={index}>
//                                                         <th className="p-3">{item.id}</th>
//                                                         <td className="p-3">
//                                                             <Link to="#" className="text-primary">
//                                                                 <div className="d-flex align-items-center">
//                                                                     <img src={item.image} className="avatar avatar-md-sm rounded-circle shadow" alt=""/>
//                                                                     <span className="ms-2">{item.name}</span>
//                                                                 </div>
//                                                             </Link>
//                                                         </td>
//                                                         <td className="text-center p-3">{item.phone}</td>
//                                                         <td className="text-center p-3">{item.amount}</td>
//                                                         <td className="text-center p-3">{item.date}</td>
//                                                         <td className="text-center p-3">
//                                                             <div className={`${item.status === 'Unpaid' ? 'bg-soft-danger' : 'bg-soft-success'} badge rounded px-3 py-1`}>
//                                                                 {item.status}
//                                                             </div>
//                                                         </td>
//                                                         <td className="text-end p-3">
//                                                             <InvoiceView/>
//                                                             <Link to="#" className="btn btn-sm btn-outline-primary ms-2">Print</Link>
//                                                         </td>
//                                                     </tr>
//                                                 )
//                                             })}
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>

//                         <div className="row text-center">
//                             <div className="col-12 mt-4">
//                                 <div className="d-md-flex align-items-center text-center justify-content-between">
//                                     <span className="text-muted me-3">Showing 1 - 10 out of 50</span>
//                                     <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
//                                         <li className="page-item"><Link className="page-link" href="#" aria-label="Previous">Prev</Link></li>
//                                         <li className="page-item active"><Link className="page-link" href="#">1</Link></li>
//                                         <li className="page-item"><Link className="page-link" href="#">2</Link></li>
//                                         <li className="page-item"><Link className="page-link" href="#">3</Link></li>
//                                         <li className="page-item"><Link className="page-link" href="#" aria-label="Next">Next</Link></li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//         <AdminFooter/>
//         <ScrollTop/>
//         </>
//     )
// }



// import React, { useEffect, useState } from "react";
// import { Link, useParams } from "react-router-dom";

// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import InvoiceView from "../../components/dashboard/invoiceView";
// import { getDoctorId } from "../../_helper/helper";

// export default function Invoice() {
//     const [invoiceData, setInvoiceData] = useState([]);
//     console.log("invoiceData",invoiceData)
//     const  doctorId  = getDoctorId(); // Assuming doctorId is a URL parameter
//     const baseURL = process.env.REACT_APP_API_URL;
//     const token = localStorage.getItem("accessToken");
//     useEffect(() => {
//         const fetchInvoices = async () => {
//             try {
//                 const response = await fetch(`${baseURL}/invoices/?doctor_id=${doctorId}`, {
//                     method: 'GET', // Use GET method
//                     headers: {
//                         'Authorization': `Bearer ${token}`, // Add the token in the Authorization header
//                         'Content-Type': 'application/json', // Optional: Set content type
//                     },
//                 });
    
//                 if (!response.ok) {
//                     throw new Error("Network response was not ok");
//                 }
//                 const data = await response.json();
//                 setInvoiceData(data); // Set fetched data to state
//             } catch (error) {
//                 console.error("Error fetching invoices:", error);
//             }
//         };
    
//         fetchInvoices();
//     }, [doctorId]);
    

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container-fluid" />
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                             <h5 className="mb-0">Invoice List</h5>
//                             <div className="row">
//                                 <div className="col-12 mt-4 pt-2">
//                                     <div className="table-responsive shadow rounded">
//                                         <table className="table table-center bg-white mb-0">
//                                             <thead>
//                                                 <tr>
//                                                     <th className="border-bottom p-3">Invoice</th>
//                                                     <th className="border-bottom p-3" style={{ minWidth: '220px' }}>Name</th>
//                                                     <th className="text-center border-bottom p-3" style={{ minWidth: '180px' }}>Phone</th>
//                                                     <th className="text-center border-bottom p-3">Amount</th>
//                                                     <th className="text-center border-bottom p-3" style={{ minWidth: '140px' }}>Generate(Dt.)</th>
//                                                     <th className="text-center border-bottom p-3">Status</th>
//                                                     <th className="text-end border-bottom p-3" style={{ minWidth: '200px' }}></th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {invoiceData.map((item, index) => (
//                                                     <tr key={index}>
//                                                         <th className="p-3">{item.id}</th>
//                                                         <td className="p-3">
//                                                             <Link to="#" className="text-primary">
//                                                                 <div className="d-flex align-items-center">
//                                                                     <img src={item.image} className="avatar avatar-md-sm rounded-circle shadow" alt="" />
//                                                                     <span className="ms-2">{item.name}</span>
//                                                                 </div>
//                                                             </Link>
//                                                         </td>
//                                                         <td className="text-center p-3">{item.phone}</td>
//                                                         <td className="text-center p-3">{item.amount}</td>
//                                                         <td className="text-center p-3">{item.date}</td>
//                                                         <td className="text-center p-3">
//                                                             <div className={`${item.status === 'Unpaid' ? 'bg-soft-danger' : 'bg-soft-success'} badge rounded px-3 py-1`}>
//                                                                 {item.status}
//                                                             </div>
//                                                         </td>
//                                                         <td className="text-end p-3">
//                                                             <InvoiceView />
//                                                             <Link to="#" className="btn btn-sm btn-outline-primary ms-2">Print</Link>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="row text-center">
//                                 <div className="col-12 mt-4">
//                                     <div className="d-md-flex align-items-center text-center justify-content-between">
//                                         <span className="text-muted me-3">Showing 1 - 10 out of {invoiceData.length}</span>
//                                         <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
//                                             <li className="page-item"><Link className="page-link" href="#" aria-label="Previous">Prev</Link></li>
//                                             <li className="page-item active"><Link className="page-link" href="#">1</Link></li>
//                                             <li className="page-item"><Link className="page-link" href="#">2</Link></li>
//                                             <li className="page-item"><Link className="page-link" href="#">3</Link></li>
//                                             <li className="page-item"><Link className="page-link" href="#" aria-label="Next">Next</Link></li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <AdminFooter />
//             <ScrollTop />
//         </>
//     );
// }


// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import InvoiceView from "../../components/dashboard/invoiceView";
// import { getDoctorId } from "../../_helper/helper";

// export default function Invoice() {
//     const [invoiceData, setInvoiceData] = useState([]);
//     const [searchTerm, setSearchTerm] = useState("");
//     const doctorId = getDoctorId();
//     const baseURL = process.env.REACT_APP_API_URL;
//     const token = localStorage.getItem("accessToken");

//     useEffect(() => {
//         const fetchInvoices = async () => {
//             try {
//                 const response = await fetch(`${baseURL}/invoices/?doctor_id=${doctorId}`, {
//                     method: 'GET',
//                     headers: {
//                         'Authorization': `Bearer ${token}`,
//                         'Content-Type': 'application/json',
//                     },
//                 });

//                 if (!response.ok) {
//                     throw new Error("Network response was not ok");
//                 }
//                 const data = await response.json();
//                 setInvoiceData(data);
//             } catch (error) {
//                 console.error("Error fetching invoices:", error);
//             }
//         };

//         fetchInvoices();
//     }, [doctorId]);

//     // Filter invoices based on search term
//     const filteredInvoices = invoiceData.filter(item => 
//         item.invoice_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         item.patient_info.patient_name.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         if (isNaN(date.getTime())) return dateString; // Return original if invalid date
//         const day = String(date.getDate()).padStart(2, '0');
//         const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
//         const year = date.getFullYear();
//         return `${day}/${month}/${year}`;
//     };
//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container-fluid" />
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                             <h5 className="mb-0">Invoice List</h5>
//                             {/* Search Input */}
//                             <div className="mt-3">
//                                 <input 
//                                     type="text" 
//                                     placeholder="Search by Name or Invoice ID"
//                                     value={searchTerm}
//                                     onChange={e => setSearchTerm(e.target.value)}
//                                     className="form-control"
//                                 />
//                             </div>
//                             <div className="row">
//                                 <div className="col-12 mt-4 pt-2">
//                                     <div className="table-responsive shadow rounded">
//                                         <table className="table table-center bg-white mb-0">
//                                             <thead>
//                                                 <tr>
//                                                     <th className="border-bottom p-3">Invoice</th>
//                                                     <th className="border-bottom p-3" style={{ minWidth: '220px' }}>Name</th>
//                                                     <th className="text-center border-bottom p-3">Amount</th>
//                                                     <th className="text-center border-bottom p-3" style={{ minWidth: '140px' }}>Generate(Dt.)</th>
//                                                     <th className="text-center border-bottom p-3">Status</th>
//                                                     <th className="text-end border-bottom p-3" style={{ minWidth: '200px' }}></th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {filteredInvoices.map((item) => (
//                                                     <tr key={item.invoice_id}>
//                                                         <th className="p-3">{item.invoice_id}</th>
//                                                         <td className="p-3">
//                                                             {/* <Link to="#" className="text-primary"> */}
//                                                                 <div className="d-flex align-items-center">
//                                                                     <span className="ms-2">{item.patient_info.patient_name}</span>
//                                                                 </div>
//                                                             {/* </Link> */}
//                                                         </td>
//                                                         <td className="text-center p-3">₹{item.payment_info.amount}</td>
//                                                         <td className="text-center p-3">{formatDate(item.invoice_generated_date).toLocaleDateString()}</td>
//                                                         <td className="text-center p-3">
//                                                             <div className={`${item.payment_info.payment_status === 'successful' ? 'bg-soft-success' : 'bg-soft-danger'} badge rounded px-3 py-1`}>
//                                                                 {item.payment_info.payment_status}
//                                                             </div>
//                                                         </td>
//                                                         <td className="text-end p-3">
//                                                             <InvoiceView />
//                                                             <Link to="#" className="btn btn-sm btn-outline-primary ms-2">Print</Link>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="row text-center">
//                                 <div className="col-12 mt-4">
//                                     <div className="d-md-flex align-items-center text-center justify-content-between">
//                                         <span className="text-muted me-3">Showing 1 - {filteredInvoices.length} out of {invoiceData.length}</span>
//                                         <ul className="pagination justify-content-center mb-0 mt-3 mt-sm-0">
//                                             <li className="page-item"><Link className="page-link" to="#" aria-label="Previous">Prev</Link></li>
//                                             <li className="page-item active"><Link className="page-link" to="#">1</Link></li>
//                                             <li className="page-item"><Link className="page-link" to="#">2</Link></li>
//                                             <li className="page-item"><Link className="page-link" to="#">3</Link></li>
//                                             <li className="page-item"><Link className="page-link" to="#" aria-label="Next">Next</Link></li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <AdminFooter />
//             <ScrollTop />
//         </>
//     );
// }


// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";

// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import AdminFooter from "../../components/dashboard/adminFooter";
// import ScrollTop from "../../components/scrollTop";
// import InvoiceView from "../../components/dashboard/invoiceView";
// import { getDoctorId } from "../../_helper/helper";
// import Paging from "../../components/CommonComponents/Paging";

// export default function Invoice() {
//     const [invoiceData, setInvoiceData] = useState([]);
//     const [searchTerm, setSearchTerm] = useState("");
//     const [currentPage, setCurrentPage] = useState(1);
//     const [pageSize, setPageSize] = useState(50);
//     const doctorId = getDoctorId();
//     const baseURL = process.env.REACT_APP_API_URL;
//     const token = localStorage.getItem("accessToken");

//     useEffect(() => {
//         const fetchInvoices = async () => {
//             try {
//                 const response = await fetch(`${baseURL}/invoices/?doctor_id=${doctorId}`, {
//                     method: 'GET',
//                     headers: {
//                         'Authorization': `Bearer ${token}`,
//                         'Content-Type': 'application/json',
//                     },
//                 });

//                 if (!response.ok) {
//                     throw new Error("Network response was not ok");
//                 }
//                 const data = await response.json();
//                 setInvoiceData(data);
//             } catch (error) {
//                 console.error("Error fetching invoices:", error);
//             }
//         };

//         fetchInvoices();
//     }, [doctorId]);

//     const filteredInvoices = invoiceData.filter(item => 
//         item.invoice_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         item.patient_info.patient_name.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     const totalInvoices = filteredInvoices.length;
//     const totalPages = Math.ceil(totalInvoices / pageSize);

//     const handlePageChange = (page) => {
//         setCurrentPage(page);
//     };

//     // Get current invoices for the page
//     const startIndex = (currentPage - 1) * pageSize;
//     const currentInvoices = filteredInvoices.slice(startIndex, startIndex + pageSize);

//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         if (isNaN(date.getTime())) return dateString; // Return original if invalid date
//         const day = String(date.getDate()).padStart(2, '0');
//         const month = String(date.getMonth() + 1).padStart(2, '0');
//         const year = date.getFullYear();
//         return `${day}/${month}/${year}`;
//     };

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container-fluid" />
//             <section className="bg-dashboard">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//                         <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//                             <h5 className="mb-0">Invoice List</h5>
//                             <div className="mt-3">
//                                 <input 
//                                     type="text" 
//                                     placeholder="Search by Name or Invoice ID"
//                                     value={searchTerm}
//                                     onChange={e => setSearchTerm(e.target.value)}
//                                     className="form-control"
//                                 />
//                             </div>
//                             <div className="row">
//                                 <div className="col-12 mt-4 pt-2">
//                                     <div className="table-responsive shadow rounded">
//                                         <table className="table table-center bg-white mb-0">
//                                             <thead>
//                                                 <tr>
//                                                     <th className="border-bottom p-3">Invoice</th>
//                                                     <th className="border-bottom p-3" style={{ minWidth: '220px' }}>Name</th>
//                                                     <th className="text-center border-bottom p-3">Amount</th>
//                                                     <th className="text-center border-bottom p-3" style={{ minWidth: '140px' }}>Generate(Dt.)</th>
//                                                     <th className="text-center border-bottom p-3">Status</th>
//                                                     <th className="text-end border-bottom p-3" style={{ minWidth: '200px' }}></th>
//                                                 </tr>
//                                             </thead>
//                                             <tbody>
//                                                 {currentInvoices.map((item) => (
//                                                     <tr key={item.invoice_id}>
//                                                         <th className="p-3">{item.invoice_id}</th>
//                                                         <td className="p-3">
//                                                             <Link to="#" className="text-primary">
//                                                                 <div className="d-flex align-items-center">
//                                                                     <span className="ms-2">{item.patient_info.patient_name}</span>
//                                                                 </div>
//                                                             </Link>
//                                                         </td>
//                                                         <td className="text-center p-3">₹{item.payment_info.amount}</td>
//                                                         <td className="text-center p-3">{formatDate(item.invoice_generated_date)}</td>
//                                                         <td className="text-center p-3">
//                                                             <div className={`${item.payment_info.payment_status === 'successful' ? 'bg-soft-success' : 'bg-soft-danger'} badge rounded px-3 py-1`}>
//                                                                 {item.payment_info.payment_status}
//                                                             </div>
//                                                         </td>
//                                                         <td className="text-end p-3">
//                                                             <InvoiceView />
//                                                             <Link to="#" className="btn btn-sm btn-outline-primary ms-2">Print</Link>
//                                                         </td>
//                                                     </tr>
//                                                 ))}
//                                             </tbody>
//                                         </table>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className="text-center mb-3">
//                                 <Paging
//                                     currentPage={currentPage}
//                                     totalPages={totalPages}
//                                     onPageChange={handlePageChange}
//                                 />
//                                 <p className="mt-2 mb-0">
//                                     Showing {currentInvoices.length} of {totalInvoices} invoices
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <AdminFooter />
//             <ScrollTop />
//         </>
//     );
// }






import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import InvoiceView from "../../components/dashboard/invoiceView";
import { getDoctorId } from "../../_helper/helper";
import Paging from "../../components/CommonComponents/Paging";
import Header from "../../components/CommonComponents/Header";

export default function Invoice() {
    // const [invoiceData, setInvoiceData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [shouldFilter, setShouldFilter] = useState(false); // New state variable
    const [invoiceData, setInvoiceData] = useState([]);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    console.log("selectedInvoiceId",selectedInvoiceId)
    const handleViewInvoice = (invoiceId) => {
        setSelectedInvoiceId(invoiceId); // Set the selected invoice ID
        setShowInvoiceModal(true); // Show the modal
    };
    const doctorId = getDoctorId();
    const baseURL = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem("accessToken");

    const [filteredInvoices, setFilteredInvoices] = useState([]); // New state for filtered invoices

    useEffect(() => {
        const fetchInvoices = async () => {
            try {
                const response = await fetch(`${baseURL}/invoices/?doctor_id=${doctorId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
    
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const data = await response.json();
                setInvoiceData(data);
                setFilteredInvoices(data); // Initialize filtered data
            } catch (error) {
                console.error("Error fetching invoices:", error);
            }
        };
    
        fetchInvoices();
    }, [doctorId]);
    
    const handleSearchClick = () => {
        if (searchTerm.trim() === "") {
            setFilteredInvoices(invoiceData); // If search term is empty, show all invoices
        } else {
            const newFilteredInvoices = invoiceData.filter(item => 
                item.invoice_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.patient_info.patient_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredInvoices(newFilteredInvoices); // Set filtered invoices
        }
        setCurrentPage(1); // Reset to the first page on new search
    };
    
    // Get total invoices and current invoices based on filtered data
    const totalInvoices = filteredInvoices.length;
    const totalPages = Math.ceil(totalInvoices / pageSize);
    const startIndex = (currentPage - 1) * pageSize;
    const currentInvoices = filteredInvoices.slice(startIndex, startIndex + pageSize);
    
    useEffect(() => {
        if (searchTerm.trim() === "") {
            setFilteredInvoices(invoiceData); // Automatically show all invoices if the search term is cleared
        }
    }, [searchTerm, invoiceData]);
    



    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

  

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return dateString; // Return original if invalid date
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const navigate = useNavigate();
    const location = useLocation();
    const showBackButton = location.key !== "default";

    return (
        <>
            <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
            <section className="bg-dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
                        <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <Header
                title="Invoices"
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
                           
                           <div className="mt-3 d-flex">
    <input 
        type="text" 
        placeholder="Search by Name or Invoice ID"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        className="form-control me-2" // Add margin-end for spacing
    />
    <button 
        onClick={handleSearchClick} 
        className="btn btn-primary"
    >
        Search
    </button>
</div>

                            <div className="row">
                                <div className="col-12 mt-4 pt-2">
                                    <div className="table-responsive shadow rounded">
                                        <table className="table table-center bg-white mb-0">
                                            <thead>
                                                <tr>
                                                    <th className="border-bottom p-3">Invoice</th>
                                                    <th className="border-bottom p-3" style={{ minWidth: '220px' }}>Name</th>
                                                    <th className="text-center border-bottom p-3">Amount</th>
                                                    <th className="text-center border-bottom p-3" style={{ minWidth: '140px' }}>Generate(Dt.)</th>
                                                    <th className="text-center border-bottom p-3">Status</th>
                                                    <th className="text-end border-bottom p-3" style={{ minWidth: '200px' }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentInvoices.map((item) => (
                                                    <tr key={item.invoice_id}>
                                                        <th className="p-3">{item.invoice_id}</th>
                                                        <td className="p-3">
                                                            <Link to="#" className="text-primary">
                                                                <div className="d-flex align-items-center">
                                                                    <span className="ms-2">{item.patient_info.patient_name}</span>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td className="text-center p-3">₹{item.payment_info.amount}</td>
                                                        <td className="text-center p-3">{formatDate(item.invoice_generated_date)}</td>
                                                        <td className="text-center p-3">
                                                        <div className={`${item.payment_info.payment_status === 'successful' ? 'bg-soft-success' : 'bg-soft-danger'} badge rounded px-3 py-1`}>
    {item.payment_info.payment_status === 'successful' ? 'Paid' : item.payment_info.payment_status}
</div>

                                                        </td>
                                                        <td className="text-end p-3">
                                                        {/* <InvoiceView invoiceId={item.invoice_id} />  */}
    <button className="btn btn-sm btn-primary" onClick={() => handleViewInvoice(item.invoice_id)}>
        View
    </button>

                                                            {/* <InvoiceView /> */}
                                                            {/* <Link to="#" className="btn btn-sm btn-outline-primary ms-2">Print</Link> */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <InvoiceView invoiceId={selectedInvoiceId} show={showInvoiceModal} onHide={() => setShowInvoiceModal(false)}  />

                            <div className="text-center mb-3">
                                <Paging
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                />
                                <p className="mt-2 mb-0">
                                    Showing {currentInvoices.length} of {totalInvoices} invoices
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AdminFooter />
            <ScrollTop />
        </>
    );
}
