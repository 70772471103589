import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import Crud from "../../components/dashboard/crud";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import dr1 from "../../assets/images/doctors/01.jpg";
import SkeletonLoader from "./SkeltonLoader";
import { getUserData } from "../../_helper/helper";
import PatientDetailPage from "./PatientDetailPage";
import { Badge } from "react-bootstrap"; // Import Badge from React Bootstrap
import Paging from "../../components/CommonComponents/Paging";
import { FaArrowLeft } from "react-icons/fa";
import Header from "../../components/CommonComponents/Header";
import CancelAppointmentsModal from "./CancelAppointmentsModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaClock,
  FaCalendarAlt,
  FaCheck,
  FaUserPlus,
  FaCheckCircle,
  FaTimes,
  FaBan,
  FaList,
  FaTimesCircle,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { useLocation } from "react-router-dom";

export default function DoctorAppointment() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [appointmentData, setAppointmentData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const { id } = useParams();
  // const [filterStatus, setFilterStatus] = useState(
  //   query.get("status") || "confirmed"
  // );
  const [filterStatus, setFilterStatus] = useState(
    query.get("status") 
  );
  const currentDate = new Date().toISOString().split("T")[0];
  // const [filterDate, setFilterDate] = useState(
  //   query.get("date") || currentDate
  // );
  const [filterDate, setFilterDate] = useState(
    query.get("date")
  );

  const [selectedPatient, setSelectedPatient] = useState({});
  const showBackButton = location.key !== "default";
  const [searchQuery, setSearchQuery] = useState("");
  const [showCancelButton, setShowCancelButton] = useState(false);

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const dateFromLocation = params.get("date");

  //   if (dateFromLocation) {
  //     setFilterDate(dateFromLocation);
  //   } else {
  //     const today = new Date().toISOString().split("T")[0];
  //     setFilterDate(today);
  //   }
  // }, []);

  const fetchAppointments = async (date = "") => {
    const { role, assigned_doctor_id, doctor_id } = getUserData();
    const doctorId = role === "staff" ? assigned_doctor_id : doctor_id;
    const token = localStorage.getItem("accessToken");

    try {
      setLoading(true);
      let url;

      if (date) {
        const params = new URLSearchParams();
        params.append("appointment_date", date);
        params.append("doctor_id", doctorId);
        url = `${
          process.env.REACT_APP_API_URL
        }/appointments/filter/?${params.toString()}`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/doctor/appointments/${id}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setAppointmentData(data);
      setTotalItems(data.length);

      const sortedData = data.sort((a, b) => {
        const dateA = new Date(`${a.appointment_date}T${a.appointment_time}`);
        const dateB = new Date(`${b.appointment_date}T${b.appointment_time}`);
        return dateA - dateB;
      });

      setDisplayedData(
        sortedData.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        )
      );
    } catch (error) {
      setError(error.message);
      console.error("Error fetching appointment data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointments(filterDate);
  }, [id, filterDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `patientAppointment/search/?search=${searchQuery}`
        );
        setAppointments(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (searchQuery) {
      fetchData();
    }
  }, [searchQuery]);

  useEffect(() => {
    fetchAppointments(filterDate);
  }, [id, filterDate]);
  useEffect(() => {
    let filteredData = appointmentData;
    if (searchQuery) {
      filteredData = filteredData.filter(
        (item) =>
          item.patient_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
          (filterStatus ? item.status === filterStatus : true)
      );
    }
    // if (searchQuery) {
    //   // Apply name filter and respect the current status filter
    //   filteredData = filteredData.filter(
    //     (item) =>
    //       item.patient_name.toLowerCase().includes(searchQuery.toLowerCase()) &&
    //       (filterStatus ? item.status === filterStatus : true)
    //   );
    // }
    else {
      // Apply date and status filters
      if (filterDate) {
        filteredData = filteredData.filter(
          (item) => item.appointment_date === filterDate
        );
      }

      if (filterStatus) {
        filteredData = filteredData.filter(
          (item) => item.status === filterStatus
        );
      }
    }

    // Sort data by appointment date and time
    filteredData.sort((a, b) => {
      const dateA = new Date(`${a.appointment_date}T${a.appointment_time}`);
      const dateB = new Date(`${b.appointment_date}T${b.appointment_time}`);
      return dateA - dateB;
    });

    setTotalItems(filteredData.length);
    setDisplayedData(
      filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      )
    );
  }, [
    currentPage,
    appointmentData,
    itemsPerPage,
    filterStatus,
    filterDate,
    searchQuery,
  ]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOpenDailog = (item) => {
    setSelectedPatient(item);
    setShow(true);
  };
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // "en-GB" gives the format "dd/mm/yyyy"
  };
  // const handleFilterChange = (status) => {
  //   setFilterStatus(status);
  //   setCurrentPage(1); // Reset to the first page when filtering
  //   // setShowCancelButton(status === "confirmed");
  //   navigate(`?status=${status}`);  // Using React Router's navigate function

  // };
  
  // const handleFilterChange = (status) => {
  //   if (status === "all") {
  //     setFilterStatus(null); // Or setFilterStatus("") if you prefer an empty string
  //   } else {
  //     setFilterStatus(status);
  //   }
  //   setCurrentPage(1); // Reset to the first page when changing filter
  //   navigate(`?status=${status === "all" ? "" : status}`); // Update URL, remove 'status' if it's "all"
  // };
  const handleFilterChange = (status) => {
    if (status === "all") {
        setFilterStatus(null); // Clear status filter if "all" is selected
    } else {
        setFilterStatus(status);
    }
    setCurrentPage(1); // Reset to the first page when changing filter

    // Use filterDate in the URL if it exists
    navigate(`?status=${status === "all" ? "" : status}&date=${filterDate || ""}`);
};

  
  const clearFilters = () => {
    setFilterStatus("");
    // setSearchQuery(''); // Clear the search query

    // setFilterDate("");
    setCurrentPage(1); // Reset to the first page when clearing filters
  };
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const initialStatus = queryParams.get('status'); // Retrieve the 'status' query param
  console.log("initialStatus",initialStatus)
  // Use this value to set the initial state
  useEffect(() => {
    if (initialStatus) {
      setFilterStatus(initialStatus);  // Set the filter status based on the URL
    }
  }, [initialStatus]);

  
  const handleToday = () => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date
    setFilterDate(today);
  };


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const dateFromLocation = params.get("date");

    if (dateFromLocation) {
      setFilterDate(dateFromLocation);
    } else {
      // const today = new Date().toISOString().split("T")[0];
      // setFilterDate(today);
      return;
    }
  }, [location.search]); // Add location.search to dependencies
  const clearDate = () => {
    setFilterDate(null);
    const params = new URLSearchParams(location.search);
    params.delete("date"); // Remove date from URL
    navigate(`?${params.toString()}`);
  };
  const handleDateChange = (date) => {
    if (date) {
      const newDate = date.toISOString().split("T")[0]; // Format date as yyyy-mm-dd

      setFilterDate(newDate);
      // setFilterStatus("confirmed");
      setCurrentPage(1); // Reset to the first page when filtering by date

      // Update URL with the selected date
      const params = new URLSearchParams(location.search);
      params.set("date", newDate);
      navigate(`?${params.toString()}`);
    } else {
      // Handle case when no date is selected (if needed)
      setFilterDate(null);
    }
  };


  const handlePreviousDay = () => {
    if (!filterDate) return; // Prevents the error if filterDate is not set

    const newDate = new Date(filterDate);
    newDate.setDate(newDate.getDate() - 1);
    setFilterDate(newDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD

    // Update URL with the new date
    const params = new URLSearchParams(location.search);
    params.set("date", newDate.toISOString().split("T")[0]);
    navigate(`?${params.toString()}`);
  };

  const handleNextDay = () => {
    if (!filterDate) return; // Prevents the error if filterDate is not set

    const newDate = new Date(filterDate);
    newDate.setDate(newDate.getDate() + 1);
    setFilterDate(newDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD

    // Update URL with the new date
    const params = new URLSearchParams(location.search);
    params.set("date", newDate.toISOString().split("T")[0]);
    navigate(`?${params.toString()}`);
  };

 



  const getBadgeVariant = (status) => {
    switch (status) {
      case "pending":
        return "primary";
      case "confirmed":
        return "success";
      case "rejected":
        return "danger";
      case "rescheduled":
        return "warning";
      case "completed":
        return "dark";
      case "referred":
        return "dark";
      default:
        return "secondary";
    }
  };
  const [loadingAction, setLoadingAction] = useState(null);
  const [modalShow, setModalShow] = useState(false); // Define modalShow state

  const handleCancelAppointments = async () => {
    const token = localStorage.getItem("accessToken"); // Retrieve the token from local storage

    setLoading(true); // Set loading state to true when API call starts

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/All/appointments/cancel/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Add the Authorization header
          },
          body: JSON.stringify({ appointment_date: filterDate }), // Use the current filterDate
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Handle success
      toast.success("Appointments cancelled successfully.");

      fetchAppointments(filterDate); // Refresh the appointment list
    } catch (error) {
      toast.error("Failed to cancel appointments.");
    } finally {
      setLoading(false); // Reset loading state
      setModalShow(false); // Close the modal
    }
  };
  // const confirmedAppointments=appointmentData.filter((item)=>item.status==="confirmed").length

  const [confirmedAppointments, setConfiremdAppointments] = useState(0);
  useEffect(() => {
    const today = new Date();

    const confirmedAppointments = appointmentData.filter((item) => {
      const appointmentDate = new Date(item.appointment_date);

      // Check if the appointment date is not in the past and status is "confirmed"
      return (
        appointmentDate >= today &&
        item.appointment_date === filterDate &&
        item.status === "confirmed"
      );
    }).length;

    setConfiremdAppointments(confirmedAppointments); // Update the state for confirmed appointments count
  }, [appointmentData, filterDate]);

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header
                title="Appointment" // Replace with the title you want
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />

              <div className="row mt-3">
                <div className="col-md-12 d-flex flex-column">
                  {/* First row: Date filter and Search input */}
                  <div className="d-flex flex-wrap align-items-center">
                    <button
                      className="btn btn-outline-primary me-2 mb-2 mb-md-0"
                      onClick={handlePreviousDay}
                    >
                      &lt; Previous
                    </button>
                    <button
                      className="btn btn-outline-primary me-2 mb-2 mb-md-0"
                      onClick={handleToday}
                    >
                      Today
                    </button>
                    <button
                      className="btn btn-outline-primary me-2 mb-2 mb-md-0"
                      onClick={handleNextDay}
                    >
                      Next &gt;
                    </button>
                    <div
                      className="form-group"
                      style={{ position: "relative" }}
                    >
                      <DatePicker
                        selected={filterDate ? new Date(filterDate) : null} // Convert string back to Date for DatePicker
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        className="form-control me-3 mb-2 mb-md-0"
                        placeholderText="dd/mm/yyyy" // Placeholder text
                        style={{ flex: 1, maxWidth: "100%" }} // Full width styling
                      />
                      {filterDate && (
                        <button
                          type="button"
                          onClick={clearDate}
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            padding: 0,
                            outline: "none",
                          }}
                        >
                          {/* Cross icon (can replace with your own SVG or Font Awesome icon) */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                            style={{ color: "#888" }}
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.293 1.293a1 1 0 011.414 0L8 7.586l5.293-6.293a1 1 0 011.414 1.414L9.414 8l5.293 5.293a1 1 0 01-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 01-1.414-1.414L6.586 8 1.293 2.707a1 1 0 010-1.414z"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                   

                    <input
                      type="text"
                      className="form-control mb-2 mb-md-0"
                      placeholder="Search by name..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      style={{ flex: 1, maxWidth: "100%" }} // Make input take full width on smaller screens
                    />
                  </div>

                  {/* Second row: Buttons */}

                  <div className="d-flex flex-wrap mt-3">
                    <button
                      style={{
                        backgroundColor:
                          filterStatus === "pending" ? "#007bff" : "#fff",
                        color: filterStatus === "pending" ? "#fff" : "#000",
                        padding: "0.5rem 1rem",
                        fontSize: "0.7rem",
                        border: "none",
                        borderRadius: "0",
                        margin: 0,
                        borderBottom: "2px solid transparent",
                      }}
                      className="btn btn-primary btn-sm"
                      onClick={() => handleFilterChange("pending")}
                    >
                      <FaClock style={{ fontSize: "1.2rem" }} /> Pending
                    </button>

                    <button
                      style={{
                        backgroundColor:
                          filterStatus === "rescheduled" ? "#ffc107" : "#fff",
                        color: filterStatus === "rescheduled" ? "#000" : "#000",
                        padding: "0.5rem 1rem",
                        fontSize: "0.7rem",
                        border: "none",
                        borderRadius: "0",
                        margin: 0,
                        borderBottom: "2px solid transparent",
                      }}
                      className="btn btn-warning btn-sm"
                      onClick={() => handleFilterChange("rescheduled")}
                    >
                      <FaCalendarAlt style={{ fontSize: "1.2rem" }} />{" "}
                      Rescheduled
                    </button>

                    <button
                      style={{
                        backgroundColor:
                          filterStatus === "confirmed" ? "#28a745" : "#fff",
                        color: filterStatus === "confirmed" ? "#fff" : "#000",
                        padding: "0.5rem 1rem",
                        fontSize: "0.7rem",
                        border: "none",
                        borderRadius: "0",
                        margin: 0,
                        borderBottom: "2px solid transparent",
                      }}
                      className="btn btn-success btn-sm"
                      onClick={() => handleFilterChange("confirmed")}
                    >
                      <FaCheck style={{ fontSize: "1.2rem" }} /> Confirmed
                    </button>

                    <button
                      style={{
                        backgroundColor:
                          filterStatus === "referred" ? "#17a2b8" : "#fff", // Unique color for Referred
                        color: filterStatus === "referred" ? "#fff" : "#000",
                        padding: "0.5rem 1rem",
                        fontSize: "0.7rem",
                        border: "none",
                        borderRadius: "0",
                        margin: 0,
                        borderBottom: "2px solid transparent",
                      }}
                      className="btn btn-info btn-sm"
                      onClick={() => handleFilterChange("referred")}
                    >
                      <FaUserPlus style={{ fontSize: "1.2rem" }} /> Referred
                    </button>

                    <button
                      style={{
                        backgroundColor:
                          filterStatus === "completed" ? "#343a40" : "#fff",
                        color: filterStatus === "completed" ? "#fff" : "#000",
                        padding: "0.5rem 1rem",
                        fontSize: "0.7rem",
                        border: "none",
                        borderRadius: "0",
                        margin: 0,
                        borderBottom: "2px solid transparent",
                      }}
                      className="btn btn-dark btn-sm"
                      onClick={() => handleFilterChange("completed")}
                    >
                      <FaCheckCircle style={{ fontSize: "1.2rem" }} /> Completed
                    </button>

                    <button
                      style={{
                        backgroundColor:
                          filterStatus === "rejected" ? "#dc3545" : "#fff",
                        color: filterStatus === "rejected" ? "#fff" : "#000",
                        padding: "0.5rem 1rem",
                        fontSize: "0.7rem",
                        border: "none",
                        borderRadius: "0",
                        margin: 0,
                        borderBottom: "2px solid transparent",
                      }}
                      className="btn btn-danger btn-sm"
                      onClick={() => handleFilterChange("rejected")}
                    >
                      <FaTimes style={{ fontSize: "1.2rem" }} /> Rejected
                    </button>

                    <button
                      style={{
                        backgroundColor:
                          filterStatus === "cancelled" ? "#6c757d" : "#fff",
                        color: filterStatus === "cancelled" ? "#fff" : "#000",
                        padding: "0.5rem 1rem",
                        fontSize: "0.7rem",
                        border: "none",
                        borderRadius: "0",
                        margin: 0,
                        borderBottom: "2px solid transparent",
                      }}
                      className="btn btn-secondary btn-sm"
                      onClick={() => handleFilterChange("cancelled")}
                    >
                      <FaBan style={{ fontSize: "1.2rem" }} /> Cancelled
                    </button>

                    <button
                      style={{
                        backgroundColor: !filterStatus ? "#6c757d" : "#fff",
                        color: !filterStatus ? "#fff" : "#000",
                        padding: "0.5rem 1rem",
                        fontSize: "0.7rem",
                        border: "none",
                        borderRadius: "0",
                        margin: 0,
                        borderBottom: "2px solid transparent",
                      }}
                      className="btn btn-secondary btn-sm"
                      // onClick={clearFilters}
                      onClick={ ()=>handleFilterChange("all")}
                    >
                      <FaList style={{ fontSize: "1.2rem" }} /> All
                    </button>

                    {showCancelButton && confirmedAppointments > 0 && (
                      <button
                        className="btn btn-danger"
                        onClick={() => setModalShow(true)}
                        disabled={loading}
                        style={{
                          backgroundColor: "#dc3545",
                          border: "none",
                          padding: "0.5rem 1rem",
                          fontSize: "0.7rem",
                          borderRadius: "0",
                          borderBottom: "2px solid transparent",
                        }}
                      >
                        <FaTimesCircle style={{ fontSize: "1.2rem" }} /> Cancel
                        Appointments
                      </button>
                    )}
                  </div>

                  <hr style={{ margin: "0", borderColor: "#ccc" }} />
                </div>

                <CancelAppointmentsModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  onConfirm={handleCancelAppointments}
                  loading={loading}
                  filterDate={filterDate}
                />
              </div>

              <div className="row">
                <div className="col-12 mt-4">
                  {loading ? (
                    <SkeletonLoader />
                  ) : error ? (
                    <div className="text-center text-danger">{error}</div>
                  ) : (
                    <div className="table-responsive bg-white shadow rounded">
                      <table className="table mb-0 table-center">
                        <thead>
                          <tr>
                            <th
                              className="border-bottom p-3"
                              style={{ minWidth: "50px" }}
                            >
                              #
                            </th>
                            <th
                              className="border-bottom p-3"
                              style={{ minWidth: "180px" }}
                            >
                              Name
                            </th>
                            <th className="border-bottom p-3">Age</th>
                            {/* <th className="border-bottom p-3">Gender</th> */}
                            <th className="border-bottom p-3">Reason</th>
                            <th
                              className="border-bottom p-3"
                              style={{ minWidth: "150px" }}
                            >
                              Date Time
                            </th>
                            {/* <th className="border-bottom p-3">Time</th> */}
                            <th className="border-bottom p-3">Status</th>
                            <th
                              className="border-bottom p-3"
                              style={{ minWidth: "150px" }}
                            ></th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayedData.length > 0 ? (
                            displayedData.map((item, index) => (
                              <tr key={item.id}>
                                <th className="p-3">
                                  {(currentPage - 1) * itemsPerPage + index + 1}
                                </th>
                                <td className="p-3">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={dr1}
                                      className="avatar avatar-md-sm rounded-circle shadow"
                                      alt=""
                                    />
                                    <span
                                      className="ms-2"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        navigate("/patient-details", {
                                          state: { item },
                                        })
                                      }
                                    >
                                      <div>{item.patient_name}</div>
                                      <Badge
                                        bg={
                                          item.payment_status.status ===
                                          "successful"
                                            ? "success"
                                            : item.payment_status.status ===
                                              "No Payment"
                                            ? "danger"
                                            : "secondary" // Fallback for other statuses
                                        }
                                      >
                                        {item.payment_status.status ===
                                        "successful"
                                          ? `Paid-${item.payment_status.payment_method}`
                                          : item.payment_status.status ===
                                            "No Payment"
                                          ? "Unpaid"
                                          : item.payment_status.status}{" "}
                                        {/* Display other statuses as they are */}
                                      </Badge>
                                    </span>
                                  </div>
                                </td>
                                <td
                                  className="p-3"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {item.age} {item.gender}
                                </td>

                                <td className="p-3" title={item.reason}>
                                  {item.reason.length > 15
                                    ? `${item.reason.slice(0, 15)}...`
                                    : item.reason}
                                </td>
                                <td
                                  className="p-3"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {formatDate(item.appointment_date)}{" "}
                                  {formatTime(item.appointment_time)}
                                </td>

                                <td className="p-3">
                                  <Badge
                                    bg={getBadgeVariant(item.status)}
                                    style={{
                                      borderRadius: "10px", // Makes the badge rounded
                                      fontSize: ".7rem", // Makes the badge text bigger
                                      padding: "0.5rem 1rem", // Adjusts padding for size
                                    }}
                                  >
                                    {item.status}
                                  </Badge>
                                </td>

                                <td className="p-3">
                                  <Crud
                                    appointment={item}
                                    fetchData={fetchAppointments}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="10" className="text-center">
                                No appointments found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
              <div className="row text-center">
                <div className="col-12 mt-4">
                  <div className="d-md-flex align-items-center text-center justify-content-between">
                    <span className="text-muted me-3">
                      Showing {(currentPage - 1) * itemsPerPage + 1} -{" "}
                      {Math.min(currentPage * itemsPerPage, totalItems)} out of{" "}
                      {totalItems}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Paging
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
          {show && (
            <PatientDetailPage
              // patient={selectedPatient}
              show={show}
              onHide={() => setShow(false)}
            />
          )}
        </div>
      </section>
      <ToastContainer />
      <AdminFooter />
      <ScrollTop />
    </>
  );
}
