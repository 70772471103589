// import React from 'react';
// import { Link } from "react-router-dom";
// import { FiArrowRight, RiUserSearchLine, RiCalendarCheckLine, RiShieldKeyholeLine, RiStethoscopeLine } from '../../assets/icons/vander';

// const iconMap = {
//     "Find the Right Doctor": RiUserSearchLine,
//     "Easy Appointments": RiCalendarCheckLine,
//     "Secure Consultations": RiShieldKeyholeLine,
//     "Comprehensive Care": RiStethoscopeLine
// };

// function FeatureSection() {
//   const features = [
//     {
//       title: "Find the Right Doctor",
//       description: "Search our extensive directory of verified healthcare professionals based on specialization, location, experience, and patient reviews.",
//       link: { href: "/doctors", text: "Learn more" }
//     },
//     {
//       title: "Easy Appointments",
//       description: "Schedule appointments at your convenience with just a few clicks. No more waiting on hold or dealing with long queues.",
//       link: { href: "/appointments", text: "Book now" }
//     },
//     {
//       title: "Secure Consultations",
//       description: "Get advice and treatment from trusted doctors through secure online consultations, ensuring your privacy and peace of mind.",
//       link: { href: "/consultations", text: "Start now" }
//     },
//     {
//       title: "Comprehensive Care",
//       description: "Access a wide range of healthcare services, from general consultations to specialist advice, all in one place.",
//       link: { href: "/services", text: "Explore services" }
//     }
//   ];

//   return (
//     <div className="container my-5">
//       <div className="row">
//         <div className="col-12 text-center">
//           <h4 className="title mb-4">
//             Why Choose healthBridge?
//           </h4>
//           <p style={{ fontSize: '1.25rem', color: '#666' }}>
//             Feature Highlights:
//           </p>
//         </div>
//       </div>

//       <div className="row mt-4">
//         <div className="col-12">
//           <div className="d-flex justify-content-between flex-wrap">
//             {features.map((feature, index) => {
//               const Icon = iconMap[feature.title];
//               return (
//                 <div key={index} className="card border-0 bg-light p-4 text-center mx-2 mb-4 feature-card">
//                   <div className="icon-container mb-2">
//                     <Icon className="text-primary h2" />
//                   </div>
//                   <h5 className="mt-2" style={{ fontSize: '1.1rem', fontWeight: 'bold', color: '#333' }}>
//                     {feature.title}
//                   </h5>
                  
//                   <p className="text-muted para-desc mb-0"
//                 //   style={{ fontSize: '1rem', color: '#666' }}
//                   >
//                     {feature.description}
//                   </p>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </div>

//       <style jsx>{`
//         .feature-card {
//           transition: transform 0.3s ease, box-shadow 0.3s ease;
//           flex: 1 1 22%; /* Adjusts the width of the cards to fit in a row */
//           max-width: 22%; /* Ensures cards fit within the container width */
//           min-width: 250px; /* Ensures cards have a minimum width */
//         }
//         .feature-card:hover {
//           transform: scale(1.05);
//           box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//         }
//         .icon-container {
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           height: 60px; /* Adjust height as needed */
//         }
//       `}</style>
//     </div>
//   );
// }

// export default FeatureSection;




// import React from 'react';
// import { Link } from "react-router-dom";
// import { FiArrowRight, RiUserSearchLine, RiCalendarCheckLine, RiShieldKeyholeLine, RiStethoscopeLine } from '../../assets/icons/vander';

// const iconMap = {
//     "Find the Right Doctor": RiUserSearchLine,
//     "Easy Appointments": RiCalendarCheckLine,
//     "Secure Consultations": RiShieldKeyholeLine,
//     "Comprehensive Care": RiStethoscopeLine
// };

// function FeatureSection() {
//   const features = [
//     {
//       title: "Find the Right Doctor",
//       description: "Search our extensive directory of verified healthcare professionals based on specialization, location, experience, and patient reviews.",
//       link: { href: "/doctors", text: "Learn more" }
//     },
//     {
//       title: "Easy Appointments",
//       description: "Schedule appointments at your convenience with just a few clicks. No more waiting on hold or dealing with long queues.",
//       link: { href: "/appointments", text: "Book now" }
//     },
//     {
//       title: "Secure Consultations",
//       description: "Get advice and treatment from trusted doctors through secure online consultations, ensuring your privacy and peace of mind.",
//       link: { href: "/consultations", text: "Start now" }
//     },
//     {
//       title: "Comprehensive Care",
//       description: "Access a wide range of healthcare services, from general consultations to specialist advice, all in one place.",
//       link: { href: "/services", text: "Explore services" }
//     }
//   ];

//   return (
//     <div className="container my-5">
//       <div className="row">
//         <div className="col-12 text-center">
//           <h4 className="title mb-4">
//             Why Choose healthBridge?
//           </h4>
//           <p className="text-muted" style={{ fontSize: '1.25rem' }}>
//             Feature Highlights:
//           </p>
//         </div>
//       </div>

//       <div className="row mt-4">
//         <div className="col-12">
//           <div className="d-flex flex-wrap justify-content-center">
//             {features.map((feature, index) => {
//               const Icon = iconMap[feature.title];
//               return (
//                 <div key={index} className="card border-0 bg-light p-4 text-center mx-2 mb-4 feature-card">
//                   <div className="icon-container mb-2">
//                     <Icon className="text-primary h2" />
//                   </div>
//                   <h5 className="mt-2" style={{ fontSize: '1.1rem', fontWeight: 'bold', color: '#333' }}>
//                     {feature.title}
//                   </h5>
//                   <p className="text-muted para-desc mb-0">
//                     {feature.description}
//                   </p>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </div>

//       <style jsx>{`
//         .feature-card {
//           transition: transform 0.3s ease, box-shadow 0.3s ease;
//           flex: 1 1 100%; /* Cards take full width on smaller screens */
//           max-width: 300px; /* Adjust max-width for better fit */
//         }
//         .feature-card:hover {
//           transform: scale(1.05);
//           box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
//         }
//         .icon-container {
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           height: 60px; /* Adjust height as needed */
//         }
//         .text-muted {
//           color: #666;
//         }
//         /* Responsive Styles */
//         @media (min-width: 576px) {
//           .feature-card {
//             flex: 1 1 45%; /* Two cards per row on small screens */
//           }
//         }
//         @media (min-width: 768px) {
//           .feature-card {
//             flex: 1 1 22%; /* Four cards per row on medium screens */
//           }
//         }
//       `}</style>
//     </div>
//   );
// }

// export default FeatureSection;


import React from 'react';
import { Link } from "react-router-dom";
import { FiArrowRight, RiUserSearchLine, RiCalendarCheckLine, RiShieldKeyholeLine, RiStethoscopeLine } from '../../assets/icons/vander';

const iconMap = {
    "Find the Right Doctor": RiUserSearchLine,
    "Easy Appointments": RiCalendarCheckLine,
    "Secure Consultations": RiShieldKeyholeLine,
    "Comprehensive Care": RiStethoscopeLine
};

function FeatureSection() {
  const features = [
    {
      title: "Find the Right Doctor",
      description: "Search our verified healthcare professionals based on specialization, location, experience, and patient reviews.",
      link: { href: "/doctors", text: "Learn more" }
    },
    {
      title: "Easy Appointments",
      description: "Schedule appointments at your convenience with just a few clicks. No more waiting on hold or dealing with long queues.",
      link: { href: "/appointments", text: "Book now" }
    },
    {
      title: "Secure Consultations",
      description: "Get advice and treatment from trusted doctors through secure online consultations, ensuring your privacy and peace of mind.",
      link: { href: "/consultations", text: "Start now" }
    },
    {
      title: "Comprehensive Care",
      description: "Access a wide range of healthcare services, from general consultations to specialist advice, all in one place.",
      link: { href: "/services", text: "Explore services" }
    }
  ];

  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-12 text-center">
          <h4 className="title mb-4">
            Why Choose healthBridge?
          </h4>
          <p className="text-muted" style={{ fontSize: '1.25rem' }}>
            Feature Highlights:
          </p>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="d-flex flex-wrap justify-content-center">
            {features.map((feature, index) => {
              const Icon = iconMap[feature.title];
              return (
                <div key={index} className="card border-0 bg-light p-4 text-center mx-2 mb-4 feature-card">
                  <div className="icon-container mb-2">
                    <Icon className="text-primary h2" />
                  </div>
                  <h5 className="mt-2" style={{ fontSize: '1.1rem', fontWeight: 'bold', color: '#333' }}>
                    {feature.title}
                  </h5>
                  <p className="text-muted para-desc mb-0">
                    {feature.description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <style jsx>{`
        .feature-card {
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          flex: 1 1 22%; /* Four cards per row on medium screens */
          max-width: 22%; /* Ensure cards fit in row */
          min-width: 220px; /* Minimum width for cards */
          text-align: center;
          margin: 0 10px; /* Margin for spacing */
          background: #f8f9fa; /* Background color */
          padding: 1.5rem; /* Padding for content */
          border-radius: 0.5rem; /* Rounded corners */
        }
        .feature-card:hover {
          transform: scale(1.05);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px; /* Adjust height as needed */
        }
        .text-muted {
          color: #666;
        }
      `}</style>
    </div>
  );
}

export default FeatureSection;
