import React from "react";
import { useState, useEffect, useRef } from "react";
// import { Alert } from "@mui/material";
import { Link } from "react-router-dom";
import dr1 from "../../assets/images/doctors/01.jpg";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, TextField } from "@mui/material";
import {
  getMaxDate,
  getUserData,
  ImageWithFallback,
} from "../../components/CommonComponents/CommonFunction";
import { Select, MenuItem, CircularProgress, Alert } from "@mui/material";

import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { ShowSucess, ShowError } from "../../_helper/helper";
import { Card, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimetableModal from "./TimetableModal.js";
import { Tab, Nav } from "react-bootstrap"; // Ensure you import these

export default function BookingAppointment({
  show,
  handleClose,
  selectedDoctor,
  // bookingType
  bookingType: initialBookingType,
}) {
  const nagative = useNavigate();
  const [doctors, setDoctors] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [filteredDoctors, setFilteredDoctors] = useState([]);
  const [isBooked, setIsBooked] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);
  const [amount, setAmount] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [razorpayKey, setRazorpayKey] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]); // New state for available slots
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clinicTimetables, setClinicTimetables] = useState([]);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const doctorId = selectedDoctor ? selectedDoctor.id : null;
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const userData = getUserData();
  console.log("userData", userData);
  const isFirstRender = useRef(true);
  const [formData, setFormData] = useState({
    name: "",
    department: "",
    doctor: "",
    email: "",
    phone: "",
    date: "",
    time: "",
    comments: "",
    age: "",
    gender: "",
    clinic: "",
    clinic_id: "",
  });
  const token = localStorage.getItem("accessToken");
  const baseURL = process.env.REACT_APP_API_URL;
  const {
    first_name,
    last_name,
    contact_no,
    charge,
    image,
    hospital_clinic_affiliation,
    experience,
    country,
    address,
    specialization,
    email,
    specialities,
    average_rating,
    description,
  } = selectedDoctor || {};
  // const [bookingType, setBookingType] = useState("clinic"); // Track whether Clinic Visit or Online Booking is selected
  const [bookingType, setBookingType] = useState(initialBookingType);

  useEffect(() => {
    setBookingType(initialBookingType);
  }, [initialBookingType]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    let newValue = value;

    // Handle specific cases
    if (name === "phone") {
      // Allow only numeric input for phone numbers
      newValue = value.replace(/\D/g, "");
    }

    if (name === "clinic") {
      const selectedClinic = clinicList.find(
        (clinic) => clinic.clinic_name === value
      );
      if (selectedClinic) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          clinic_id: selectedClinic.clinic_id, // Add clinic_id to formData
          date: "", // Clear the date when clinic changes
        }));
      }
    }

    // Set the new value in form data
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    // Fetch available slots if a valid date is selected
    if (name === "date" && newValue) {
      fetchAvailableSlots(formData.doctor, newValue);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      // Adjust the date to ensure it matches the local time zone
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      const formattedDate = localDate.toISOString().split("T")[0];

      // Update form data with the selected date
      setFormData((prevFormData) => ({
        ...prevFormData,
        date: formattedDate,
      }));
      const doctorId = selectedDoctor ? selectedDoctor.id : null;
      // Fetch available slots if the doctor is already selected
      if (doctorId) {
        fetchAvailableSlots(doctorId, formattedDate);
      }
    } else {
      // Handle the case when the date is cleared
      setFormData((prevFormData) => ({
        ...prevFormData,
        date: null, // Clear the date in form data
      }));
      setAvailableSlots([]);
    }
  };
  const [hoveredDate, setHoveredDate] = useState(null); // State to track hovered date

  // useEffect(() => {
  //   // Fetch unavailable dates if doctor is selected
  //   if (doctorId) {
  //     fetchUnavailableDates(doctorId);
  //   }
  // }, [doctorId]);

  const fetchUnavailableDates = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unavailable-dates/?doctor_id=${doctorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        // throw new Error("Network response was not ok");
        const errorData = await response.json(); // Parse the error response
        ShowError(errorData.detail || "Network response was not ok");
      }
      const result = await response.json();
      if (Array.isArray(result)) {
        setUnavailableDates(result.map((date) => new Date(date.date)));
      } else {
        console.error("Unexpected response structure:", result);
      }
    } catch (error) {
      console.error("Error fetching unavailable dates:", error);
    }
  };

  const handlePayment = async (event) => {
    event.preventDefault();
    handleClose();
    setIsBooked(false);
    try {
      const response = await axios.post(
        `${baseURL}/create-order/`,
        {
          amount: selectedDoctor?.charge,
          currency: "INR",
          order_type: "appointment",
          reference_id: appointmentId,
          appointment_id: appointmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const {
        order_id,
        order,
        razorpay_key,
        // amount: orderAmount,
        currency,
      } = response.data;

      const { id, amount: orderAmount } = order || {};
      setOrderId(id);
      setRazorpayKey(razorpay_key);
      setError(null);
      displayRazorpay(
        id,
        order_id,
        Number(orderAmount),
        currency,
        razorpay_key
      );
    } catch (err) {
      setError("Order creation failed. Please try again.");
    }
  };
  const displayRazorpay = async (id, order_id, amount, currency, key) => {
    const options = {
      key: key,
      amount: amount,
      currency: currency,
      order_id: order_id,
      handler: async function (response) {
        try {
          const result = await axios.put(
            `${baseURL}/verify-payment/${id}/`,
            {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (result.data.status === "Payment successful") {
            setSuccess(true);
            ShowSucess("Payment successful");
            setError(null);
          } else {
            ShowError("Payment verification failed. Please try again.");
          }
        } catch (err) {
          ShowError("Payment verification failed. Please try again.");
        }
      },
      prefill: {
        name: `${userData?.user?.first_name} ${userData?.user?.last_name}`,
        email: userData?.user?.email,
        contact: userData?.user?.contact_no,
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/doctors`)
      .then((response) => response.json())
      .then((data) => {
        setDoctors(data);
        const uniqueDepartments = [
          ...new Set(data.map((doc) => doc.specialization)),
        ];
        setDepartments(uniqueDepartments);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);
  const [clinicList, setClinicList] = useState("");
  console.log("clinicList", clinicList);
  useEffect(() => {
    if (selectedDepartment) {
      const filtered = doctors.filter(
        (doc) => doc.specialization === selectedDepartment
      );
      setFilteredDoctors(filtered);
    } else {
      setFilteredDoctors(doctors);
    }
  }, [selectedDepartment, doctors]);
  // useEffect(() => {
  //   if (doctorId) {
  //     // Get the user role
  //     const userRole = userData?.user?.role;

  //     // Define placeholder values
  //     const placeholder = "Please select";

  //     // Only set form data if the user is not staff
  //     if (userRole !== "staff") {
  //       setFormData({
  //         ...formData,
  //         name:
  //           userData?.user?.first_name && userData?.user?.last_name
  //             ? `${userData.user.first_name} ${userData.user.last_name}`
  //             : "Please Enter Your Name",
  //         email: userData?.user?.email || placeholder,
  //         phone: userData?.user?.phone_number || placeholder,
  //         gender: userData?.user?.gender || placeholder,
  //         age: userData?.user?.age || placeholder,
  //         doctor: selectedDoctor.id,
  //         department: selectedDoctor.specialization || placeholder,
  //       });
  //     }

  //     setSelectedDepartment(selectedDoctor.specialization || placeholder);
  //   }
  // }, [doctorId, userData, selectedDoctor]);

  useEffect(() => {
    if (doctorId) {
      // Get the user role
      const userRole = userData?.user?.role;

      // Only set form data if the user is not staff
      if (userRole !== "staff") {
        setFormData({
          ...formData,
          name: `${userData?.user?.first_name ?? ""} ${
            userData?.user?.last_name ?? ""
          }`,
          email: userData?.user?.email ?? "N/A",
          phone: userData?.user?.phone_number ?? "N/A",
          gender: userData?.user?.gender ?? "N/A",
          age: userData?.user?.age ?? "N/A",
          doctor: selectedDoctor?.id ?? "N/A",
          department: selectedDoctor?.specialization ?? "N/A",
        });
      }

      // if (userRole !== "staff") {
      //   setFormData({
      //     ...formData,
      //     name: `${userData?.user?.first_name} ${userData?.user?.last_name}`,
      //     email: userData?.user?.email,
      //     phone: userData?.user?.phone_number,
      //     gender: userData?.user?.gender,
      //     age: userData?.user?.age,
      //     doctor: selectedDoctor.id,
      //     department: selectedDoctor.specialization,
      //   });
      // }

      setSelectedDepartment(selectedDoctor.specialization);
    }
  }, [doctorId]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let tokenData = null;

    // If booking type is "online", generate the token first
    if (bookingType === "online") {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/video/generate-token/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              api_key:
                "vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/9c5df1",
              user_name: formData.name,
              user_email: formData.email,
              is_moderator: true,
              app_id: "vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a",
              avatar_url: "http://example.com/avatar.jpg",
            }),
          }
        );

        const data = await response.json();
        if (response.ok) {
          tokenData = data;
        } else {
          console.error("Failed to generate token:", data);
          toast.error("Failed to generate token. Please try again.");
          setLoading(false);
          return; // Stop submission if token generation fails
        }
      } catch (error) {
        console.error("Error generating token:", error);
        toast.error("Error generating token. Please try again.");
        setLoading(false);
        return; // Stop submission if token generation fails
      }
    }

    const selectedClinic = clinicList.find(
      (clinic) => clinic.clinic_name === formData.clinic
    );

    const clinicAddress = selectedClinic
      ? `${selectedClinic.clinic_name}, ${selectedClinic.clinic_address}`
      : formData.clinic;

    // Prepare the payload
    const payload = {
      doctor: doctorId,
      patient_name: formData.name,
      patient: localStorage.getItem("userId"),
      appointment_date: formData.date,
      appointment_time: formData.time,
      Clinicname_address: clinicAddress,
      status: "confirmed",
      reason: formData.comments,
      active: true,
      age: formData.age,
      gender: formData.gender,
      patient_email: formData.email,
      appointment_type: bookingType,
      patient_phoneno: formData.phone,
      // token: tokenData?.token,  // Include token if it was generated
    };

    // Only add clinic_id if booking type is not online
    if (bookingType !== "online") {
      payload.clinic_id = formData.clinic; // Ensure clinic_id is included in payload
    }

    // Submit the booking form
    fetch(`${process.env.REACT_APP_API_URL}/appointments/create/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.id) {
          setAppointmentId(data?.id);
          toast.success("Appointment booked successfully!");
          setIsBooked(true);
          setFormData({
            name: "",
            doctor: "",
            email: "",
            phone: "",
            date: "",
            time: "",
            comments: "",
            age: "",
            gender: "",
            clinic: "",
          });
        } else {
          ShowError(data?.detail);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error booking appointment:", error);
        toast.error("Failed to book appointment. Please try again.");
        setLoading(false);
      });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   let tokenData = null;

  //   // If booking type is "online", generate the token first
  //   if (bookingType === "online") {
  //     try {
  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_URL}/video/generate-token/`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({
  //             api_key:
  //               "vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/9c5df1",
  //             user_name: formData.name,
  //             user_email: formData.email,
  //             is_moderator: true,
  //             app_id: "vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a",
  //             avatar_url: "http://example.com/avatar.jpg",
  //           }),
  //         }
  //       );

  //       const data = await response.json();
  //       if (response.ok) {
  //         tokenData = data;
  //       } else {
  //         console.error("Failed to generate token:", data);
  //         toast.error("Failed to generate token. Please try again.");
  //         setLoading(false);
  //         return; // Stop submission if token generation fails
  //       }
  //     } catch (error) {
  //       console.error("Error generating token:", error);
  //       toast.error("Error generating token. Please try again.");
  //       setLoading(false);
  //       return; // Stop submission if token generation fails
  //     }
  //   }
  //   const selectedClinic = clinicList.find(
  //     (clinic) => clinic.clinic_name === formData.clinic
  //   );

  //   const clinicAddress = selectedClinic
  //     ? `${selectedClinic.clinic_name}, ${selectedClinic.clinic_address}`
  //     : formData.clinic;
  //   // Prepare the payload
  //   const payload = {
  //     doctor: doctorId,
  //     patient_name: formData.name,
  //     patient: localStorage.getItem("userId"),
  //     appointment_date: formData.date,
  //     appointment_time: formData.time,
  //     Clinicname_address: clinicAddress,
  //     status: "confirmed",
  //     reason: formData.comments,
  //     active: true,
  //     age: formData.age,
  //     gender: formData.gender,
  //     patient_email: formData.email,
  //     appointment_type: bookingType,
  //     patient_phoneno:formData.phone
  //     //   token: tokenData?.token,  // Include token if it was generated
  //   };
  //   if (bookingType !== "online") {
  //     payload.clinic_id = formData.clinic_id; // Only add clinic_id if not online
  //   }

  //   // Submit the booking form
  //   fetch(`${process.env.REACT_APP_API_URL}/appointments/create/`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //     body: JSON.stringify(payload),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data?.id) {
  //         setAppointmentId(data?.id);
  //         toast.success("Appointment booked successfully!");
  //         setIsBooked(true);
  //         setFormData({
  //           name: "",
  //           doctor: "",
  //           email: "",
  //           phone: "",
  //           date: "",
  //           time: "",
  //           comments: "",
  //           age: "",
  //           gender: "",
  //           clinic: "",
  //         });
  //       } else {
  //         ShowError(data?.detail);
  //       }

  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error booking appointment:", error);
  //       toast.error("Failed to book appointment. Please try again.");
  //       setLoading(false);
  //     });
  // };

  const modalRef = useRef();
  const resetForm = () => {
    setFormData({
      name: "",
      department: "",
      doctor: "",
      email: "",
      phone: "",
      date: "",
      time: "",
      comments: "",
      age: "",
      gender: "",
      clinic: "",
    });
    setSelectedDepartment("");
  };
  const handleModalClose = () => {
    resetForm(); // Clear the form data
    handleClose(); // Close the modal
  };
  const handleModalClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      e.stopPropagation(); // Prevent closing when clicking inside the modal
    }
  };

  // Function to get today's date in yyyy-mm-dd format
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchAvailableSlots = async (doctorId, date) => {
    try {
      const formattedDate = date.split("T")[0]; // Extract date part
      const today = new Date().toISOString().split("T")[0];
      const currentTime = new Date().toTimeString().split(" ")[0]; // Get current time in HH:MM:SS format

      const response = await axios.get(
        `${baseURL}/doctor/${doctorId}/available-slots/?date=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        // Filter slots based on time if today
        const isToday = formattedDate === today;
        const slots = isToday
          ? response.data.filter((slot) => slot.start_time > currentTime)
          : response.data;

        setAvailableSlots(slots);
      } else {
        // Clear available slots if no data is returned
        setAvailableSlots([]);
      }
    } catch (error) {
      console.error("Error fetching available slots:", error);
      // Clear available slots on error
      setAvailableSlots([]);
    }
  };

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = parseInt(hour, 10);
    const period = formattedHour >= 12 ? "PM" : "AM";
    const twelveHour = formattedHour % 12 || 12; // Converts 0 to 12
    return `${twelveHour}:${minute} ${period}`;
  };

  const handleBookingTypeSelect = (type) => {
    setBookingType(type);
  };

  const [availableDays, setAvailableDays] = useState([]);

  useEffect(() => {
    const fetchTimetables = async () => {
      if (!selectedDoctor.id) return;

      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/doctors/details/${selectedDoctor.id}/`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setClinicTimetables(data.timetables);

        // Extract available days of the week from timetable
        const availableDays = data.timetables
          .map((timetable) => timetable.day_of_week)
          .filter((day) => day); // Filter out any null or undefined days

        setAvailableDays(availableDays);
        setClinicList(
          data.timetables.map((timetable) => ({
            clinic_name: timetable.clinic_name,
            clinic_address: timetable.clinic_address,
            clinic_id: timetable.clinic,
            clinicday: timetable.day_of_week,
          }))
        );
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedDoctor) {
      fetchTimetables();
    }
  }, [selectedDoctor]);
  const [uniqueClinics, setUniqueClinics] = useState([]);

  useEffect(() => {
    const clinicsMap = new Map();

    // Aggregate unique clinics by name and address
    clinicTimetables.forEach((timetable) => {
      const { clinic_name, clinic_address } = timetable;
      const key = `${clinic_name}_${clinic_address}`;

      if (!clinicsMap.has(key)) {
        clinicsMap.set(key, {
          clinic_name,
          clinic_address,
          clinic_id: timetable.clinic, // Store the clinic ID if needed
        });
      }
    });

    // Convert map values to an array of unique clinics
    setUniqueClinics(Array.from(clinicsMap.values()));
  }, [clinicTimetables]);

  // const handleClinicChange = (event) => {
  //   const selectedClinic = event.target.value;

  //   // Find selected clinic details
  //   const selectedClinicData = uniqueClinics.find(
  //     (clinic) =>
  //       `${clinic.clinic_name}_${clinic.clinic_address}` === selectedClinic
  //   );

  //   // Update form data with selected clinic ID
  //   if (selectedClinicData) {
  //     // Update form data with selected clinic ID
  //     setFormData(prevState => ({
  //       ...prevState,
  //       clinic: selectedClinicData.clinic_id, // Use clinic ID
  //       date: "" // Reset date when a new clinic is selected
  //     }))}

  //   // Find all available days for the selected clinic
  //   const days = clinicTimetables
  //     .filter(
  //       (t) =>
  //         t.clinic_name === selectedClinicData.clinic_name &&
  //         t.clinic_address === selectedClinicData.clinic_address
  //     )
  //     .map((t) => t.day_of_week);

  //   setAvailableDays(days);
  // };
  const handleClinicChange = (event) => {
    const selectedClinic = event.target.value;
  
    // Find selected clinic details
    const selectedClinicData = uniqueClinics.find(
      clinic => `${clinic.clinic_name}_${clinic.clinic_address}` === selectedClinic
    );
  
    // Update form data with selected clinic ID
    setFormData(prevState => ({
      ...prevState,
      clinic: selectedClinicData.clinic_id, // Use clinic ID
      date: ""
    }));
  
    // Find all available days for the selected clinic
    const days = clinicTimetables
      .filter(t => t.clinic_name === selectedClinicData.clinic_name && t.clinic_address === selectedClinicData.clinic_address)
      .map(t => t.day_of_week);
  
    setAvailableDays(days);
  };
  
  const isDateSelectable = (date) => {
    const dayName = date.toLocaleString("en-US", { weekday: "long" });
    return availableDays.includes(dayName);
  };

  // const getUniqueClinics = (clinics) => {
  //   const uniqueClinics = [];
  //   const seenNames = new Set();

  //   clinics.forEach((clinic) => {
  //     if (!seenNames.has(clinic.clinic_name)) {
  //       seenNames.add(clinic.clinic_name);
  //       uniqueClinics.push(clinic);
  //     }
  //   });

  //   return uniqueClinics;
  // };

  // // Filter the clinic list to show only unique names
  // const uniqueClinics = getUniqueClinics(clinicTimetables);

  // const handleClinicChange = (event) => {
  //   const selectedClinic = event.target.value;
  //   setFormData((prevState) => ({ ...prevState, clinic: selectedClinic, date: "" }));

  //   const clinicData = clinicTimetables.find((clinic) => clinic.id === selectedClinic);
  //   setAvailableDays(clinicData ? clinicData.day_of_week : []);
  // };

  // const isDateSelectable = (date) => {
  //   const dayName = date.toLocaleString("en-US", { weekday: "long" });
  //   return availableDays.includes(dayName);
  // };

  // const handleClinicChange = (event) => {
  //   const selectedClinic = event.target.value;
  //   setFormData(prevState => ({ ...prevState, clinic: selectedClinic,date:"" }));

  //   const clinicData = clinicTimetables.find(clinic => clinic.id === selectedClinic);
  //   setAvailableDays(clinicData ? clinicData.day_of_week : []);
  // };
  // const isDateSelectable = (date) => {
  //   const dayName = date.toLocaleString("en-US", { weekday: "long" });
  //   return availableDays.includes(dayName);
  // };
  // Utility function to check if a date is selectable
  // const isDateSelectable = (date) => {
  //   const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, 2 = Tuesday, etc.
  //   const dayNames = [
  //     "Sunday",
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //   ];
  //   const dayName = dayNames[dayOfWeek];
  //   return availableDays.includes(dayName);
  // };

  const isDateUnavailable = (date) => {
    // Check if the date is not selectable based on the day of the week
    const selectable = isDateSelectable(date);

    // Check if the date is in the list of unavailable dates
    const unavailable = unavailableDates.some(
      (unavailableDate) =>
        unavailableDate.toDateString() === date.toDateString()
    );

    // A date is unavailable if it is not selectable or if it is in the list of unavailable dates
    return !selectable || unavailable;
  };
  const formatRating = (rating) => {
    const roundedRating = Math.round(rating * 10) / 10; // Round to one decimal
    return Number.isInteger(roundedRating)
      ? roundedRating.toString()
      : roundedRating.toFixed(1);
  };

  const renderStars = (averageRating) => {
    const totalStars = 5;
    const rating = Math.max(
      0,
      Math.min(totalStars, Number(averageRating) || 0)
    );
    const filledStars = Math.floor(rating);
    const halfStar = rating - filledStars >= 0.5 ? 1 : 0;
    const emptyStars = totalStars - filledStars - halfStar;

    return (
      <>
        {[...Array(filledStars)].map((_, index) => (
          <i className="mdi mdi-star text-warning" key={`filled-${index}`}></i>
        ))}
        {halfStar === 1 && <i className="mdi mdi-star-half text-warning"></i>}
        {[...Array(emptyStars)].map((_, index) => (
          <i
            className="mdi mdi-star-outline text-warning"
            key={`empty-${index}`}
          ></i>
        ))}
      </>
    );
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const imageStyle = {
    width: windowWidth < 768 ? "100px" : "150px",
    height: windowWidth < 768 ? "100px" : "150px",
    borderRadius: "50%",
    objectFit: "cover",
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleModalClose}
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Book an Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card className="p-3 shadow-sm" style={{ borderRadius: "10px" }}>
            <Card.Body style={{ padding: "0" }}>
              <div className="mt-0">
                <h5 className="text-primary">{hospital_clinic_affiliation}</h5>
              </div>
              <div className="row">
                {/* Doctor Image and Basic Info */}
                <div className="col-lg-8 col-md-6 d-flex align-items-center">
                  <div className="me-3">
                    <ImageWithFallback
                      src={selectedDoctor?.image || dr1}
                      defaultSrc={dr1}
                      // alt={selectedDoctor.first_name + " " + selectedDoctor.last_name}
                      style={imageStyle}
                    />
                    {/* <Card.Img
                      variant="top"
                      src={image || dr1}
                      alt="doctor-profile"
                      className="rounded-circle"
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      }}
                    /> */}
                  </div>
                  <div>
                    <Card.Text className="fw-bold text-dark mb-1">
                      {`${first_name} ${last_name}`}
                    </Card.Text>
                    <Card.Text className="text-muted mb-1">
                      {`${address}, ${country}`}
                    </Card.Text>
                    <Card.Text className="text-muted mb-3">
                      {description}
                      {/* {experience &&
                        experience.length > 0 &&
                        experience[0]?.description && (
                          <div>Experience: {experience[0].description}</div>
                        )} */}
                      {/* Experience: {experience && experience[0]?.description} */}
                    </Card.Text>
                    <div>
                      <a
                        href="#"
                        className="text-primary fw-bold"
                        style={{ textDecoration: "none" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenModal();
                        }}
                      >
                        View TimeTable
                      </a>
                      {isModalOpen && (
                        <TimetableModal
                          id={selectedDoctor.id}
                          handleCloseModal={handleCloseModal}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {/* Doctor Details */}
                <div className="col-lg-4 col-md-6 mt-4 mt-md-0 pt-3 pt-md-0">
                  <ul className="list-unstyled">
                    <li className="d-flex mb-2">
                      <small className="text-muted me-2">Specialization:</small>
                      <small className="fw-bold text-dark">
                        {specialization}
                      </small>
                    </li>

                    <li className="d-flex mb-2">
                      <small className="text-muted me-2">Avg Ratings:</small>
                      <small className="fw-bold text-dark">
                        {renderStars(average_rating)}{" "}
                        {formatRating(average_rating)}
                      </small>
                    </li>
                    <li className="d-flex mb-2">
                      <small className="text-muted me-2">Charge:</small>
                      <small className="fw-bold text-dark">₹ {charge}</small>
                    </li>
                    <li className="d-flex mb-2">
                      <small className="text-muted me-2">Email:</small>
                      <small className="fw-bold text-dark">{email}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </Card.Body>
          </Card>

          {/* Booking Type Selection */}
          <div className="row mb-4">
            <div className="col-6 p-0">
              <button
                className={`btn btn-outline-primary w-100 ${
                  bookingType === "clinic" ? "active" : ""
                }`}
                onClick={() => handleBookingTypeSelect("clinic")}
                style={{
                  borderRadius: "0",
                  marginRight: "0",
                  backgroundColor:
                    bookingType === "clinic" ? "#007bff" : "#f8f9fa", // Grey background
                  color: bookingType === "clinic" ? "#fff" : "#000", // White text when clicked, black otherwise
                  fontWeight: bookingType === "clinic" ? "bold" : "bold", // Bold text
                  borderColor: "transparent", // Remove border color
                }}
              >
                Clinic Visit
              </button>
            </div>
            <div className="col-6 p-0">
              <button
                className={`btn btn-outline-primary w-100 ${
                  bookingType === "online" ? "active" : ""
                }`}
                onClick={() => handleBookingTypeSelect("online")}
                style={{
                  borderRadius: "0",
                  marginLeft: "0",
                  backgroundColor:
                    bookingType === "online" ? "#007bff" : "#f8f9fa", // Grey background
                  color: bookingType === "online" ? "#fff" : "#000", // White text when clicked, black otherwise
                  fontWeight: bookingType === "online" ? "bold" : "bold", // Bold text
                  borderColor: "transparent", // Remove border color
                }}
              >
                Online Booking
              </button>
            </div>
          </div>

          {/* Conditionally Render Forms */}
          {bookingType && (
            <form onSubmit={handleSubmit}>
              <div className="row">
                {/* Common Fields for Both Booking Types */}
                <div className="col-md-4">
                  <div className="mb-3">
                    <TextField
                      label="Patient Name"
                      name="name"
                      id="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Patient Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      InputLabelProps={{
                        shrink: !!formData.name,
                      }}
                      InputProps={{
                        style: { height: "40px", padding: "0 10px" },
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <TextField
                      label="Patient Age"
                      name="age"
                      id="age"
                      variant="outlined"
                      fullWidth
                      type="number"
                      placeholder="Patient Age"
                      value={formData.age}
                      onChange={handleChange}
                      required
                      InputLabelProps={{
                        shrink: !!formData.age,
                      }}
                      InputProps={{
                        style: { height: "40px", padding: "0 10px" },
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-12 mb-3">
                  <FormControl fullWidth>
                    <InputLabel shrink={!!formData.gender}>Gender</InputLabel>
                    <Select
                      label="Gender"
                      name="gender"
                      id="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      fullWidth
                      required
                      displayEmpty
                      style={{ height: "40px", padding: "0 10px" }}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="M">Male</MenuItem>
                      <MenuItem value="F">Female</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className="col-md-6 col-12">
                  <div className="mb-3">
                    <TextField
                      label="Your Email"
                      name="email"
                      id="email"
                      variant="outlined"
                      fullWidth
                      type="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      // InputLabelProps={{ shrink: true }}
                      InputLabelProps={{
                        shrink: !!formData.email, // Show label only if there's a value
                      }}
                      InputProps={{
                        style: { height: "40px", padding: "0 10px" }, // Adjust height and padding
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <TextField
                      label="Your Phone"
                      name="phone"
                      id="phone"
                      variant="outlined"
                      fullWidth
                      type="tel"
                      placeholder="Your Phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      InputLabelProps={{
                        shrink: !!formData.phone,
                      }}
                      InputProps={{
                        style: { height: "40px", padding: "0 10px" },
                      }}
                    />
                  </div>
                </div>

                {/* Clinic Booking Specific Fields */}
                {bookingType === "clinic" && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="mb-3">
                      <FormControl fullWidth>
  <InputLabel shrink={!!formData.clinic}>
    Select Clinic
  </InputLabel>
  <Select
    value={formData.clinic ? `${uniqueClinics.find(clinic => clinic.clinic_id === formData.clinic)?.clinic_name}_${uniqueClinics.find(clinic => clinic.clinic_id === formData.clinic)?.clinic_address}` : ""}
    onChange={handleClinicChange}
    fullWidth
    required
    displayEmpty
    style={{ height: "40px", padding: "0 10px" }}
  >
    {uniqueClinics.map(clinic => (
      <MenuItem
        key={`${clinic.clinic_name}_${clinic.clinic_address}`}
        value={`${clinic.clinic_name}_${clinic.clinic_address}`}
      >
        {clinic.clinic_name} - {clinic.clinic_address}
      </MenuItem>
    ))}
  </Select>
</FormControl>

                        {/* <FormControl fullWidth>
                          <InputLabel shrink={!!formData.gender}>
                            Select Clinic
                          </InputLabel>
                          <Select value={formData.clinic} onChange={handleClinicChange} 
                                fullWidth
                                required
                                displayEmpty
                                style={{ height: "40px", padding: "0 10px" }} >
               {uniqueClinics.map(clinic => (
          <option key={`${clinic.clinic_name}_${clinic.clinic_address}`} value={`${clinic.clinic_name}_${clinic.clinic_address}`}>
            {clinic.clinic_name} - {clinic.clinic_address}
          </option>
        ))}
            </Select>
                        </FormControl> */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <DatePicker
                          selected={
                            formData.date ? new Date(formData.date) : undefined
                          }
                          onChange={handleDateChange}
                          // filterDate={(date) => {
                          //   const selectedClinic = clinicList.find(
                          //     (clinic) => clinic.clinic_name === formData.clinic
                          //   );
                          //   const clinicDay = selectedClinic
                          //     ? selectedClinic.clinicday
                          //     : null;

                          //   const clinicDayIndex = clinicDay
                          //     ? [
                          //         "Sunday",
                          //         "Monday",
                          //         "Tuesday",
                          //         "Wednesday",
                          //         "Thursday",
                          //         "Friday",
                          //         "Saturday",
                          //       ].indexOf(clinicDay)
                          //     : -1;

                          //   const dateDayIndex = date.getDay();
                          //   const today = new Date();
                          //   const maxSelectableDate = new Date(today);
                          //   maxSelectableDate.setDate(today.getDate() + 15);

                          //   // Check if the date is the current date
                          //   const isCurrentDate =
                          //     date.toDateString() === today.toDateString();

                          //   return (
                          //     // date >= today &&
                          //     date <= maxSelectableDate &&
                          //     (clinicDayIndex === -1 ||
                          //       dateDayIndex === clinicDayIndex) &&
                          //     !isDateUnavailable(date) && // Allow the current date unless it's unavailable
                          //     (isCurrentDate || !isDateUnavailable(date)) // Allow the current date if it's selectable
                          //   );
                          // }}
                          filterDate={(date) => {
                            const selectedClinic = clinicList.find(
                              (clinic) => clinic.clinic_name === formData.clinic
                            );
                            const clinicDay = selectedClinic
                              ? selectedClinic.clinicday
                              : null;

                            const clinicDayIndex = clinicDay
                              ? [
                                  "Sunday",
                                  "Monday",
                                  "Tuesday",
                                  "Wednesday",
                                  "Thursday",
                                  "Friday",
                                  "Saturday",
                                ].indexOf(clinicDay)
                              : -1;

                            const dateDayIndex = date.getDay();
                            const today = new Date();
                            const maxSelectableDate = new Date(today);
                            maxSelectableDate.setDate(today.getDate() + 15);

                            // Check if the date is the current date
                            const isCurrentDate =
                              date.toDateString() === today.toDateString();
                            const isCurrentDateSelectable =
                              clinicDayIndex === dateDayIndex;

                            return (
                              date <= maxSelectableDate &&
                              (clinicDayIndex === -1 ||
                                dateDayIndex === clinicDayIndex) &&
                              !isDateUnavailable(date) &&
                              (isCurrentDate ? isCurrentDateSelectable : true)
                            );
                          }}
                          minDate={new Date()} // No past dates
                          maxDate={
                            new Date(
                              new Date().setDate(new Date().getDate() + 15)
                            )
                          } // 15 days from today
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          placeholderText="dd-mm-yyyy"
                          required
                          customInput={
                            <TextField
                              label={!formData.date ? "" : "Select Date"}
                              variant="outlined"
                              fullWidth
                              placeholder="DD-MM-YYYY"
                              InputLabelProps={{
                                shrink: !!formData.date,
                              }}
                              InputProps={{
                                style: { height: "40px", padding: "0 10px" },
                              }}
                            />
                          }
                          renderDayContents={(day, date) => {
                            const today = new Date();
                            const maxSelectableDate = new Date(today);
                            maxSelectableDate.setDate(today.getDate() + 15);

                            const selectedClinic = clinicList.find(
                              (clinic) => clinic.clinic_name === formData.clinic
                            );
                            const clinicDay = selectedClinic
                              ? selectedClinic.clinicday
                              : null;
                            const clinicDayIndex = clinicDay
                              ? [
                                  "Sunday",
                                  "Monday",
                                  "Tuesday",
                                  "Wednesday",
                                  "Thursday",
                                  "Friday",
                                  "Saturday",
                                ].indexOf(clinicDay)
                              : -1;
                            const dateDayIndex = date.getDay();

                            const isPastDate = date < today;
                            const isExceedingDate = date > maxSelectableDate;
                            const isWithinRange =
                              date >= today && date <= maxSelectableDate;
                            const isSelectable =
                              isWithinRange &&
                              (clinicDayIndex === -1 ||
                                dateDayIndex === clinicDayIndex) &&
                              !isDateUnavailable(date);

                            const isSelected =
                              date.toDateString() ===
                              (formData.date
                                ? new Date(formData.date).toDateString()
                                : null);
                            const isHovered =
                              hoveredDate &&
                              hoveredDate.toDateString() ===
                                date.toDateString();

                            const styles = {
                              display: "inline-flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              cursor:
                                isPastDate || isExceedingDate
                                  ? "not-allowed"
                                  : isSelectable
                                  ? "pointer"
                                  : "default",
                              backgroundColor: isSelected
                                ? "skyblue"
                                : isHovered && isSelectable
                                ? "skyblue"
                                : isSelectable
                                ? "blue"
                                : "transparent",
                              color:
                                isSelected || isHovered
                                  ? "white"
                                  : isSelectable
                                  ? "white"
                                  : "gray",
                            };

                            return (
                              <span
                                onMouseEnter={
                                  isSelectable
                                    ? () => setHoveredDate(date)
                                    : undefined
                                }
                                onMouseLeave={
                                  isSelectable
                                    ? () => setHoveredDate(null)
                                    : undefined
                                }
                                style={styles}
                              >
                                {day}
                              </span>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="mb-3">
                        <FormControl fullWidth>
                          <InputLabel shrink={!!formData.gender}>
                            Time
                          </InputLabel>
                          <Select
                            label="Time"
                            name="time"
                            value={formData.time}
                            onChange={handleChange}
                            fullWidth
                            required
                            displayEmpty
                            style={{ height: "40px", padding: "0 10px" }} // Adjust height and padding
                          >
                            <MenuItem value="" disabled>
                              {availableSlots.length > 0
                                ? "Select slot"
                                : "No available slots"}
                            </MenuItem>
                            {availableSlots.length > 0
                              ? availableSlots.map((slot, index) => (
                                  <MenuItem key={index} value={slot.start_time}>
                                    {convertTo12HourFormat(slot.start_time)}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                )}

                {/* Online Booking Specific Fields */}
                {bookingType === "online" && (
                  <>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <DatePicker
                          placeholderText="dd/mm/yyyy"
                          selected={
                            formData.date ? new Date(formData.date) : undefined
                          }
                          onChange={handleDateChange}
                          filterDate={(date) => {
                            const today = new Date();
                            const maxSelectableDate = new Date(today);
                            maxSelectableDate.setDate(today.getDate() + 15);

                            // Allow today if it is a valid day of the week
                            const isTodaySelectable = availableDays.includes(
                              today.toLocaleString("en-US", { weekday: "long" })
                            );

                            return (
                              (date > today ||
                                (date.toDateString() === today.toDateString() &&
                                  isTodaySelectable)) &&
                              date <= maxSelectableDate &&
                              !isDateUnavailable(date) // Ensure unavailable dates are not selectable
                            );
                          }}
                          // filterDate={(date) => {
                          //   const today = new Date();
                          //   const maxSelectableDate = new Date(today);
                          //   maxSelectableDate.setDate(today.getDate() + 15);

                          //   return (
                          //     date >= today &&
                          //     date <= maxSelectableDate &&
                          //     !isDateUnavailable(date) // Ensure unavailable dates are not selectable
                          //   );
                          // }}
                          minDate={new Date()} // No past dates
                          maxDate={
                            new Date(
                              new Date().setDate(new Date().getDate() + 15)
                            )
                          } // 15 days from today
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          required
                          customInput={
                            <TextField
                              label={!formData.date ? "" : "Select Date"}
                              variant="outlined"
                              fullWidth
                              placeholder="DD-MM-YYYY"
                              InputLabelProps={{
                                shrink: !!formData.date,
                              }}
                              InputProps={{
                                style: { height: "40px", padding: "0 10px" },
                              }}
                            />
                          }
                          renderDayContents={(day, date) => {
                            const today = new Date();
                            const maxSelectableDate = new Date(today);
                            maxSelectableDate.setDate(today.getDate() + 15);
                            const isPastDate = date < today;
                            const isExceedingDate = date > maxSelectableDate;
                            const isWithinRange =
                              date >= today && date <= maxSelectableDate;
                            const isSelectable =
                              isDateSelectable(date) &&
                              !unavailableDates.some(
                                (unavailableDate) =>
                                  unavailableDate.toDateString() ===
                                  date.toDateString()
                              );
                            const isSelected =
                              date.toDateString() ===
                              (formData.date
                                ? new Date(formData.date).toDateString()
                                : null);
                            const isHovered =
                              hoveredDate &&
                              hoveredDate.toDateString() ===
                                date.toDateString();

                            const styles = {
                              display: "inline-flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%",
                              cursor:
                                isPastDate || isExceedingDate || !isSelectable
                                  ? "not-allowed"
                                  : isWithinRange && isSelectable
                                  ? "pointer"
                                  : "default",
                              backgroundColor: isSelected
                                ? "skyblue"
                                : isHovered && isWithinRange && isSelectable
                                ? "skyblue"
                                : isWithinRange && isSelectable
                                ? "blue"
                                : "transparent",
                              color:
                                isSelected ||
                                (isHovered && isWithinRange && isSelectable)
                                  ? "white"
                                  : isWithinRange && isSelectable
                                  ? "white"
                                  : "gray",
                            };

                            return (
                              <span
                                onMouseEnter={
                                  isWithinRange && isSelectable
                                    ? () => setHoveredDate(date)
                                    : undefined
                                }
                                onMouseLeave={
                                  isWithinRange && isSelectable
                                    ? () => setHoveredDate(null)
                                    : undefined
                                }
                                style={styles}
                              >
                                {day}
                              </span>
                            );
                          }}
                        />

                        {/* <DatePicker
                          selected={
                            formData.date ? new Date(formData.date) : undefined
                          }
                          onChange={handleDateChange}
                          filterDate={(date) => {
                            const today = new Date();
                            const maxSelectableDate = new Date(today);
                            maxSelectableDate.setDate(today.getDate() + 15);

                            return (
                              date >= today &&
                              date <= maxSelectableDate &&
                              !isDateUnavailable(date)
                            );
                          }}
                          minDate={new Date()} // No past dates
                          maxDate={
                            new Date(
                              new Date().setDate(new Date().getDate() + 15)
                            )
                          } // 15 days from today
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          placeholderText="dd-mm-yyyy"
                          required
                          customInput={
                            <TextField
                              label={!formData.date ? "" : "Select Date"}
                              variant="outlined"
                              fullWidth
                              placeholder="DD-MM-YYYY"
                              InputLabelProps={{
                                shrink: !!formData.date,
                              }}
                              InputProps={{
                                style: { height: "40px", padding: "0 10px" },
                              }}
                            />
                          }
                          renderDayContents={(day, date) => {
                            const today = new Date();
                            const maxSelectableDate = new Date(today);
                            maxSelectableDate.setDate(today.getDate() + 15);
                            const isPastDate = date < today;
                            const isExceedingDate = date > maxSelectableDate;
                            const isWithinRange =
                              date >= today && date <= maxSelectableDate;
                            const isSelectable = isDateSelectable(date);
                            const isSelected =
                              date.toDateString() ===
                              (formData.date
                                ? new Date(formData.date).toDateString()
                                : null);
                            const isHovered =
                              hoveredDate &&
                              hoveredDate.toDateString() ===
                                date.toDateString();

                            const styles = {
                              display: "inline-flex", // Use inline-flex for better centering
                              justifyContent: "center", // Center content horizontally
                              alignItems: "center", // Center content vertically
                              width: "30px",
                              height: "30px",
                              borderRadius: "50%", // Make it round
                              cursor:
                                isPastDate || isExceedingDate
                                  ? "not-allowed"
                                  : isWithinRange && isSelectable
                                  ? "pointer"
                                  : "default",
                              backgroundColor: isSelected
                                ? "skyblue" // Sky blue for the selected date
                                : isHovered && isWithinRange && isSelectable
                                ? "skyblue" // Sky blue on hover for selectable dates
                                : isWithinRange && isSelectable
                                ? "blue" // Blue for other selectable dates
                                : "transparent", // Transparent for non-selectable dates
                              color:
                                isSelected ||
                                (isHovered && isWithinRange && isSelectable)
                                  ? "white" // White text for selected or hovered date
                                  : isWithinRange && isSelectable
                                  ? "white" // White text for other selectable dates
                                  : "gray", // Gray for non-selectable dates
                            };

                            return (
                              <span
                                onMouseEnter={
                                  isWithinRange && isSelectable
                                    ? () => setHoveredDate(date)
                                    : undefined
                                }
                                onMouseLeave={
                                  isWithinRange && isSelectable
                                    ? () => setHoveredDate(null)
                                    : undefined
                                }
                                style={styles}
                              >
                                {day}
                              </span>
                            );
                          }}
                        /> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <FormControl fullWidth>
                          <InputLabel shrink={!!formData.gender}>
                            Time
                          </InputLabel>
                          <Select
                            label="Time"
                            name="time"
                            value={formData.time}
                            onChange={handleChange}
                            fullWidth
                            required
                            displayEmpty
                            style={{ height: "40px", padding: "0 10px" }} // Adjust height and padding
                          >
                            <MenuItem value="" disabled>
                              {availableSlots.length > 0
                                ? "Select slot"
                                : "No available slots"}
                            </MenuItem>
                            {availableSlots.length > 0
                              ? availableSlots.map((slot, index) => (
                                  <MenuItem key={index} value={slot.start_time}>
                                    {convertTo12HourFormat(slot.start_time)}
                                  </MenuItem>
                                ))
                              : null}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </>
                )}

                {/* Common Fields */}
                <div className="col-lg-12">
                  <div className="mb-3">
                    <TextField
                      label="Reason for Appointment"
                      name="comments"
                      id="comments"
                      multiline
                      rows={4}
                      fullWidth
                      placeholder="Your Message"
                      value={formData.comments}
                      onChange={handleChange}
                      required
                      InputLabelProps={{
                        shrink: !!formData.comments,
                      }}
                    />
                  </div>
                </div>

                {/* Submission Button */}
                <div className="col-12 d-flex flex-column align-items-center">
                  {isBooked && (
                    <div className="mb-3">
                      <Alert severity="success" style={{ margin: "10px 0px" }}>
                        Your appointment has been successfully booked. Please
                        proceed with the payment.
                      </Alert>
                    </div>
                  )}

                  <button
                    type="submit"
                    onClick={isBooked ? (e) => handlePayment(e) : undefined}
                    className="btn btn-primary"
                    disabled={loading}
                    style={{ width: "100%", maxWidth: "300px" }}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          animation="border"
                          size="sm"
                          className="me-2"
                        />
                        Processing...
                      </>
                    ) : isBooked ? (
                      "Make a Payment"
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
