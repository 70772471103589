import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { MdOutlineCancel } from 'react-icons/md';
import { format } from 'date-fns';

const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

const parseDate = (date) => {
  const parsedDate = new Date(date);
  return isValidDate(parsedDate) ? parsedDate : new Date(); // Return current date if invalid
};

const CancelAppointmentsModal = ({ show, onHide, onConfirm, loading, filterDate }) => {
  const formattedDate = filterDate
    ? format(parseDate(filterDate), 'dd/MM/yyyy')
    : "N/A"; // Or some default value

  return (
    <Modal show={show} onHide={onHide} animation={false} centered>
      <Modal.Body>
        <div className="modal-body py-5">
          <div className="text-center">
            <div
              className="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
              style={{ height: "95px", width: "95px" }}
            >
              <span className="mb-0">
                <MdOutlineCancel className="h1" />
              </span>
            </div>
            <div className="mt-4">
              <h4>Cancel All Appointments</h4>
              <p className="para-desc mx-auto text-muted mb-0">
                You are about to cancel all appointments for {formattedDate}. This action cannot be undone.
              </p>
              <div className="mt-4">
                <button
                  className="btn btn-soft-danger"
                  onClick={onConfirm}
                  disabled={loading}
                >
                  {loading ? (
                    <div className="d-flex align-items-center">
                      <Spinner animation="border" role="status" size="sm" />
                      <span className="ml-2">Cancelling...</span>
                    </div>
                  ) : (
                    "Yes, Cancel All"
                  )}
                </button>
                <button
                  className="btn btn-secondary ml-2"
                  onClick={onHide}
                >
                  No, Keep Appointments
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelAppointmentsModal;
