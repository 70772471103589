// import React, { useState } from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// const containerStyle = {
//   width: '100%',
//   height: '400px'
// };

// // Pre-filled location
// const initialLocation = {
//   lat: 19.076090,
//   lng: 72.877426
// };

// const MyMapComponent = ({ onLocationSelect }) => {
//   const [selectedLocation, setSelectedLocation] = useState(initialLocation);

//   const handleMapClick = (event) => {
//     const { latLng } = event;
//     const lat = latLng.lat();
//     const lng = latLng.lng();
//     const newLocation = { lat, lng };

//     setSelectedLocation(newLocation);
//     onLocationSelect(newLocation); // Notify parent component of the selected location
//   };

//   return (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={selectedLocation}
//       zoom={12}
//       onClick={handleMapClick}
//     >
//       <Marker position={selectedLocation} />
//     </GoogleMap>
//   );
// };

// const MyMapContainer = () => {
//   const handleLocationSelect = async (location) => {
//     console.log('Selected location:', location);
  
//     // Retrieve the access token from localStorage
//     const token = localStorage.getItem('accessToken');
  
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/location/`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
//         },
//         body: JSON.stringify({
//           locations: [
//             {
//               latitude: location.lat.toFixed(6).toString(), // Convert to string with 6 decimal places
//               longitude: location.lng.toFixed(6).toString(), // Convert to string with 6 decimal places
//             },
//           ],
//         }),
//       });
  
//       if (response.ok) {
//         console.log('Location sent successfully');
//       } else {
//         console.error('Failed to send location');
//       }
//     } catch (error) {
//       console.error('Error sending location:', error);
//     }
//   };

//   return (
//     <LoadScript googleMapsApiKey="AIzaSyC7RMT5p_5l8ip_quzJnbwzd51VtlpnVoA">
//       <MyMapComponent onLocationSelect={handleLocationSelect} />
//     </LoadScript>
//   );
// };

// export default MyMapContainer;
// import React, { useState } from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// const containerStyle = {
//   width: '100%',
//   height: '400px'
// };

// // Pre-filled location
// const initialLocation = {
//   lat: 19.076090,
//   lng: 72.877426
// };

// const MyMapComponent = ({ onLocationSelect }) => {
//   const [selectedLocation, setSelectedLocation] = useState(initialLocation);

//   const handleMapClick = (event) => {
//     const { latLng } = event;
//     const lat = latLng.lat();
//     const lng = latLng.lng();
//     const newLocation = { lat, lng };

//     // Ask for confirmation before updating the location
//     const userConfirmed = window.confirm('Do you want to select this location?');

//     if (userConfirmed) {
//       setSelectedLocation(newLocation);
//       onLocationSelect(newLocation); // Notify parent component of the selected location
//     }
//   };

//   return (
//     <GoogleMap
//       mapContainerStyle={containerStyle}
//       center={selectedLocation}
//       zoom={12}
//       onClick={handleMapClick}
//     >
//       <Marker position={selectedLocation} />
//     </GoogleMap>
//   );
// };

// const MyMapContainer = () => {
//   const handleLocationSelect = async (location) => {
//     console.log('Selected location:', location);
  
//     // Retrieve the access token from localStorage
//     const token = localStorage.getItem('accessToken');
  
//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/location/`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
//         },
//         body: JSON.stringify({
//           locations: [
//             {
//               latitude: location.lat.toFixed(6).toString(), // Convert to string with 6 decimal places
//               longitude: location.lng.toFixed(6).toString(), // Convert to string with 6 decimal places
//             },
//           ],
//         }),
//       });
  
//       if (response.ok) {
//         console.log('Location sent successfully');
//       } else {
//         console.error('Failed to send location');
//       }
//     } catch (error) {
//       console.error('Error sending location:', error);
//     }
//   };

//   return (
//     <LoadScript googleMapsApiKey="AIzaSyC7RMT5p_5l8ip_quzJnbwzd51VtlpnVoA">
//       <MyMapComponent onLocationSelect={handleLocationSelect} />
//     </LoadScript>
//   );
// };

// export default MyMapContainer;


// import React, { useState } from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// const containerStyle = {
//   width: '100%',
//   height: '400px'
// };

// // Pre-filled location
// const initialLocation = {
//   lat: 19.076090,
//   lng: 72.877426
// };

// const MyMapComponent = ({ onLocationSelect }) => {
//   const [selectedLocation, setSelectedLocation] = useState(initialLocation);
//   const [address, setAddress] = useState('');

//   const handleMapClick = (event) => {
//     const { latLng } = event;
//     const lat = latLng.lat();
//     const lng = latLng.lng();
//     const newLocation = { lat, lng };

//     // Ask for confirmation before updating the location
//     const userConfirmed = window.confirm('Do you want to select this location?');

//     if (userConfirmed) {
//       setSelectedLocation(newLocation);
//       onLocationSelect(newLocation); // Notify parent component of the selected location
//     }
//   };

//   const handleAddressChange = (event) => {
//     setAddress(event.target.value);
//   };

//   const handleAddressSubmit = async (event) => {
//     event.preventDefault();
//     if (!address) return;

//     try {
//       const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);
//       const data = await response.json();

//       if (data.status === 'OK' && data.results[0]) {
//         const location = data.results[0].geometry.location;
//         setSelectedLocation(location);
//         onLocationSelect(location); // Notify parent component of the selected location
//       } else {
//         window.alert('Geocoding failed: ' + data.status);
//       }
//     } catch (error) {
//       console.error('Error fetching geocoding data:', error);
//     }
//   };

//   return (
//     <div>
//       <form onSubmit={handleAddressSubmit} style={{ marginBottom: '10px' }}>
//         <input
//           type="text"
//           value={address}
//           onChange={handleAddressChange}
//           placeholder="Enter address"
//           style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
//         />
//         <button type="submit" style={{ marginTop: '10px', padding: '8px 16px', border: 'none', borderRadius: '4px', backgroundColor: '#007bff', color: '#fff' }}>
//           Find Location
//         </button>
//       </form>

//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={selectedLocation}
//         zoom={12}
//         onClick={handleMapClick}
//       >
//         <Marker position={selectedLocation} />
//       </GoogleMap>
//     </div>
//   );
// };

// const MyMapContainer = () => {
//   const handleLocationSelect = async (location) => {
//     console.log('Selected location:', location);

//     // Retrieve the access token from localStorage
//     const token = localStorage.getItem('accessToken');

//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_URL}/doctor/location/`, {
//         method: 'PUT',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
//         },
//         body: JSON.stringify({
//           locations: [
//             {
//               latitude: location.lat.toFixed(6).toString(), // Convert to string with 6 decimal places
//               longitude: location.lng.toFixed(6).toString(), // Convert to string with 6 decimal places
//             },
//           ],
//         }),
//       });

//       if (response.ok) {
//         console.log('Location sent successfully');
//       } else {
//         console.error('Failed to send location');
//       }
//     } catch (error) {
//       console.error('Error sending location:', error);
//     }
//   };

//   return (
//     <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
//       <MyMapComponent onLocationSelect={handleLocationSelect} />
//     </LoadScript>
//   );
// };

// export default MyMapContainer;








import React, { useCallback, useState, useEffect } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const libraries = ['places']; // Specify any libraries you need

const MyMapContainerNew = ({ id, onLocationSelect, returnLatLong = true }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const [clinics, setClinics] = useState([]); // Initialize clinics array
    const [markerPosition, setMarkerPosition] = useState({ lat: 19.076090, lng: 72.877426 }); // Default center position
    const [selectedClinicId, setSelectedClinicId] = useState(null); // Track the selected clinic ID

    // Fetch clinics data
    useEffect(() => {
        const fetchClinics = async () => {
            const token = localStorage.getItem('accessToken'); // Get token from localStorage

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/clinics/${id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include token in the Authorization header
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();
                if (data && data.data) {
                    setClinics(data.data); // Set clinics data
                }
            } catch (error) {
                console.error('Error fetching clinics:', error);
            }
        };

        fetchClinics();
    }, [id]);

    // Handle clinic click to move the map and show marker
    const handleClinicClick = (clinic) => {
        const { latitude, longitude, id } = clinic;
        const newLocation = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
        setMarkerPosition(newLocation); // Update map marker position
        setSelectedClinicId(id); // Highlight the selected marker
        if (returnLatLong && onLocationSelect) {
            onLocationSelect(newLocation); // Notify parent component with lat/long
        }
    };

    if (loadError) {
        console.error("Google Maps API Error:", loadError);
        return <div>Error loading maps. Please check the console for more details.</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps...</div>;
    }

    return (
        <div style={{ display: 'flex', height: '100%' }}>
            {/* Clinic List */}
            <div style={{ flex: 1, overflowY: 'scroll', padding: '10px', borderRight: '1px solid #ccc' }}>
                {clinics.length > 0 ? (
                    clinics.map((clinic) => (
                        <div
                            key={clinic.id}
                            onClick={() => handleClinicClick(clinic)}
                            style={{
                                cursor: 'pointer',
                                padding: '10px',
                                marginBottom: '10px',
                                backgroundColor: selectedClinicId === clinic.id ? '#d1ecf1' : '#f9f9f9', // Highlight selected clinic
                                border: '1px solid #ddd',
                                borderRadius: '5px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                            }}
                        >
                            <h5 style={{ margin: 0 }}>{clinic.name}</h5>
                            <p style={{ margin: 0 }}>{clinic.address}</p>
                        </div>
                    ))
                ) : (
                    <p>No clinics available.</p>
                )}
            </div>

            {/* Google Map */}
            <div style={{ flex: 1, height: '400px' }}>
                <GoogleMap
                    mapContainerStyle={{ height: '100%', width: '100%' }}
                    zoom={12}
                    center={markerPosition}
                >
                    {/* Show markers for all clinics */}
                    {clinics.map((clinic) => (
                        <Marker
                            key={clinic.id}
                            position={{ lat: parseFloat(clinic.latitude), lng: parseFloat(clinic.longitude) }}
                            icon={
                                selectedClinicId === clinic.id
                                    ? {
                                        url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png', // Highlighted marker
                                    }
                                    : undefined
                            }
                        />
                    ))}
                </GoogleMap>
            </div>
        </div>
    );
};

export default MyMapContainerNew;
