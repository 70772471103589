import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Box, Typography } from "@mui/material"; // Using Material-UI for styling
import VideoCallIcon from "@mui/icons-material/VideoCall";
// import PrescriptionModal from "./PrescriptionModal"; // Import the modal component
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { get, post } from "../../api_helper";
import moment from "moment";
import Header from "../../components/CommonComponents/Header";
import PrescriptionHistory from "../patient/PrescriptionHistory";
import LatestHealthMetrics from "../dashboard/LatestHealthMetrics";
import dayjs from "dayjs";
import axios from "axios";
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Spinner,
  Form,
} from "react-bootstrap";
import { CardBody, Table } from "reactstrap";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { FaExchangeAlt } from "react-icons/fa";
import jsPDF from "jspdf"; // PDF library
import "jspdf-autotable"; // Plugin for tables in PDF
// import Spinner from "react-bootstrap/Spinner"; // Import Spinner if needed
import { ShowSucess, ShowError, getDoctorId } from "../../_helper/helper";
import { TextField } from "@mui/material";
import DatePicker from "react-datepicker";
import {
  convertTo12HourFormat,
  getMaxDate,
  loadingPanel,
} from "../../components/CommonComponents/CommonFunction";
const baseURL = process.env.REACT_APP_API_URL;

const PatientDetailPage = ({ selectedPatient }) => {
  const [showPrescriptionHistory, setShowPrescriptionHistory] = useState(false);

  // if (!show) return null;
  const token = localStorage.getItem("accessToken");
  const [healthMetricsList, setHealthMetricsList] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [value, setValue] = useState("");
  const [copied, setCopied] = useState(false);
  const patient = location.state?.item;
  const patientId = patient?.patient; // Assuming patient ID is used as patient ID
  const doctorId = getDoctorId();
  const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);

  const [prescriptionDescription, setPrescriptionDescription] = useState("");
  const [medicines, setMedicines] = useState([
    { medicine_name: "", dosage: "", frequency: "", duration: "" },
  ]);
  const [showPaymentHistory, setShowPaymentHistory] = useState(false);
  const showBackButton = location.key !== "default";

  const [paymentHistory, setPaymentHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [prescriptionHistory, setPriscriptionHistory] = useState([]);

  const [selectedDate, setSelectedDate] = useState(""); // Add state for selected date
  const [selectedTime, setSelectedTime] = useState("");
  // const doctorId = getDoctorId();
  const [availableDays, setAvailableDays] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [error, setError] = useState(null);
  const [unavailableDates, setUnavailableDates] = useState([]);
  useEffect(() => {
    const fetchTimetables = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/doctors/details/${doctorId}/`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        const availableDays = data.timetables
          .map((timetable) => timetable.day_of_week)
          .filter((day) => day);

        setAvailableDays(availableDays);
        setClinicList(
          data.timetables.map((timetable) => ({
            clinic_name: timetable.clinic_name,
            clinic_address: timetable.clinic_address,
          }))
        );
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchTimetables();
  }, [doctorId]);
  const fetchUnavailableDates = async (doctorId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unavailable-dates/?doctor_id=${doctorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (Array.isArray(result)) {
        setUnavailableDates(result.map((date) => new Date(date.date)));
      } else {
        console.error("Unexpected response structure:", result);
      }
    } catch (error) {
      console.error("Error fetching unavailable dates:", error);
    }
  };

  useEffect(() => {
    fetchUnavailableDates(doctorId);
  }, [doctorId]);
  const isDateSelectable = (date) => {
    const dayOfWeek = date.getDay();
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = dayNames[dayOfWeek];
    return availableDays.includes(dayName);
  };

  const isDateUnavailable = (date) => {
    const selectable = isDateSelectable(date);

    const unavailable = unavailableDates.some(
      (unavailableDate) =>
        unavailableDate.toDateString() === date.toDateString()
    );

    return !selectable || unavailable;
  };
  const handleDateChange = (date) => {
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    const formattedDate = localDate.toISOString().split("T")[0];
    // const formattedDate = date.toISOString().split("T")[0];
    setSelectedDate(formattedDate);

    fetchAvailableSlots(doctorId, formattedDate);
  };

  // Existing fetchAvailableSlots function
  const fetchAvailableSlots = async (doctorId, date) => {
    try {
      const formattedDate = date.split("T")[0];
      const today = new Date().toISOString().split("T")[0];
      const currentTime = new Date().toTimeString().split(" ")[0];

      const response = await axios.get(
        `${baseURL}/doctor/${doctorId}/available-slots/?date=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const isToday = formattedDate === today;
        const slots = isToday
          ? response.data.filter((slot) => slot.start_time > currentTime)
          : response.data;

        setAvailableSlots(slots);
      } else {
        setAvailableSlots([]);
      }
    } catch (error) {
      console.error("Error fetching available slots:", error);
      setAvailableSlots([]);
    }
  };

  const handleAddMedicine = () => {
    setMedicines([
      ...medicines,
      { medicine_name: "", dosage: "", frequency: "", duration: "" },
    ]);
  };
  const onHide = () => {
    setShowPaymentHistory(false);
  };
  const healthMetricsByempId = async () => {
    try {
      setLoading(true);
      const resp = await get(
        `${baseURL}/health-metrics/list/?patient_id=${Number(patientId)}`
      );
      setHealthMetricsList(resp);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setHealthMetricsList([]);
    }
  };
  useEffect(() => {
    if (patientId) {
      healthMetricsByempId();
    }
  }, [patientId]);
  const downloadHealthMetricsPDF = () => {
    const data = healthMetricsList[0];
    const unit = healthMetricsList[0]?.metrics_with_lookup.map((d) => d?.unit);

    const doc = new jsPDF();

    // Set title
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 14; // Margin from the left side
    const centerX = pageWidth / 2;
    const titleX = centerX; // Center title
    const smallFontSize = 10; // Smaller font size for details
    const lineY = 20; // Y position for the line below the title
    const tableStartY = 48; // Y position to start the table

    // Title - Centered and Bold
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(16); // Larger size for title
    doc.text("Health Metrics", titleX, 16, { align: "center" });

    // Date in the top right corner of the title
    doc.setFontSize(smallFontSize);
    // doc.text(data.date_recorded.split("T")[0], pageWidth - margin, 16, {
    //   align: "right",
    // });

    // Draw separator line below the title
    doc.setLineWidth(0.5); // Line width
    doc.line(margin, lineY, pageWidth - margin, lineY); // Draw line from left to right

    // Reset font to normal and set smaller font size
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(smallFontSize);

    // Patient Name on the left (dummy patient name used for demo)
    const patientIDText = `Patient Name: ${patient?.patient_name}`;
    doc.text(patientIDText, margin, 24);

    // Doctor Information in one column on the right (dummy doctor info used for demo)
    const doctorInfo = [
      `Doctor Name: ${doctorDetailsResults?.first_name} ${doctorDetailsResults?.last_name}`,
      `Graduation Year: ${doctorDetailsResults?.graduation_year}`,
      `Medical School: ${doctorDetailsResults?.medical_school}`,
      `Specialization: ${doctorDetailsResults?.specialization}`,
      `Experience: ${doctorDetailsResults?.years_of_experience} Year`,
      `License Number: ${doctorDetailsResults?.medical_license_number}`,
    ];

    // Ensure that all doctor info is aligned
    const doctorTextYStart = 24; // Starting Y position for the doctor info
    let currentY = doctorTextYStart;
    const maxDoctorTextWidth = Math.max(
      ...doctorInfo.map((line) => doc.getTextWidth(line))
    );

    doctorInfo.forEach((line) => {
      doc.text(line, pageWidth - margin - maxDoctorTextWidth, currentY);
      currentY += 6; // Line spacing for doctor information
    });

    // Draw a separator line between the doctor/patient info and the table
    doc.setLineWidth(0.3); // Thinner line
    doc.line(margin, currentY + 5, pageWidth - margin, currentY + 5); // Draw line below doctor/patient info

    // Prepare the data for the PDF table
    const tableData = [];

    // Extract metrics and percentage differences
    const metrics = data.metrics;
    const percentageDifference = data.percentage_difference || {};

    // Add the header row
    const tableHeader = [
      "Date Recorded",
      "Metric",
      "Value",
      "Unit",
      ...(Object.keys(percentageDifference).length > 0
        ? ["Percentage Difference"]
        : []),
    ];

    tableData.push(tableHeader);

    // Add metrics data

    Object.keys(metrics).forEach((metricKey, index) => {
      const metricValue = metrics[metricKey];
      const percentageDiff =
        percentageDifference[metricKey] !== undefined
          ? `${percentageDifference[metricKey]}%`
          : "";

      tableData.push([
        dayjs(data.date_recorded).format("MM-DD-YYYY"), // Date only
        metricKey.replace(/_/g, " ").toUpperCase(),
        metricValue,
        unit[index],
        ...(percentageDiff ? [percentageDiff] : []),
      ]);
    });

    // Add table to the PDF
    doc.autoTable({
      head: [
        tableHeader.map((header) => ({
          content: header,
          styles: {
            fillColor: [220, 220, 220],
            textColor: [0, 0, 0],
            fontStyle: "bold",
          },
        })),
      ],
      body: tableData.slice(1), // Exclude header row from body
      startY: currentY + 10, // Adjust starting Y position to be below the separator line
      styles: {
        fillColor: [255, 255, 255], // Set the default background to white for rows
        textColor: [0, 0, 0], // Set text color to black
        lineWidth: 0.2, // Border line width
        lineColor: [0, 0, 0], // Border line color
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray background for alternate rows
      },
    });

    // Save the PDF
    doc.save("HealthMetrics.pdf");
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Enter" || e.key === "Tab") {
      e.preventDefault(); // Prevent default behavior of Enter or Tab
      const isAllFieldsFilled = Object.values(medicines[index]).every(
        (value) => value.trim() !== ""
      );
      if (isAllFieldsFilled) {
        handleAddMedicine();
      }
    }
  };
  const downloadPrescriptionHistory = () => {
    const data = prescriptionHistory;
    const doc = new jsPDF();

    // Set title
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 14; // Margin from the left side
    const centerX = pageWidth / 2;
    const titleX = centerX; // Center title
    const smallFontSize = 10; // Smaller font size for details
    const lineY = 20; // Y position for the line below the title
    const tableStartY = 48; // Y position to start the table

    // Title - Centered and Bold
    doc.setFont("Helvetica", "bold");
    doc.setFontSize(16); // Larger size for title
    doc.text("Prescription Details", titleX, 16, { align: "center" });

    // Draw separator line below the title
    doc.setLineWidth(0.5); // Line width
    doc.line(margin, lineY, pageWidth - margin, lineY); // Draw line from left to right

    // Reset font to normal and set smaller font size
    doc.setFont("Helvetica", "normal");
    doc.setFontSize(smallFontSize);

    // Patient Name on the left
    const patientIDText = `Patient Name: ${data[0]?.patient_name}`;
    doc.text(patientIDText, margin, 24);

    // Doctor Information in one column on the right
    const doctorInfo = [
      `Doctor Name: ${data[0]?.doctor_first_name} ${data[0]?.doctor_last_name}`,
      `Graduation Year: ${data[0]?.doctor_graduation_year}`,
      `Medical School: ${data[0]?.doctor_medical_school}`,
      `Specialization: ${data[0]?.doctor_specialization}`,
      `Experience: ${data[0]?.doctor_years_of_experience} Year`,
      `License Number: ${data[0]?.doctor_medical_license_number}`,
    ];

    // Ensure that all doctor info is aligned
    const doctorTextYStart = 24; // Starting Y position for the doctor info
    let currentY = doctorTextYStart;
    const maxDoctorTextWidth = Math.max(
      ...doctorInfo.map((line) => doc.getTextWidth(line))
    );

    doctorInfo.forEach((line) => {
      doc.text(line, pageWidth - margin - maxDoctorTextWidth, currentY);
      currentY += 6; // Line spacing for doctor information
    });

    // Draw a separator line between the doctor/patient info and the table
    doc.setLineWidth(0.3); // Thinner line
    doc.line(margin, currentY + 5, pageWidth - margin, currentY + 5); // Draw line below doctor/patient info

    // Prepare the data for the PDF table
    const tableData = [];

    data.forEach((entry) => {
      const entryData = [
        {
          content: entry.description,
          rowSpan: entry.items.length || 1, // span over the number of items
        },
        {
          content: entry.date,
          rowSpan: entry.items.length || 1, // span over the number of items
        },
        // Create the first row of items, or empty cells if no items
        ...(entry.items.length > 0
          ? [
              entry.items[0].medicine_name,
              entry.items[0].dosage,
              entry.items[0].frequency,
              entry.items[0].duration,
            ]
          : ["", "", "", ""]),
      ];

      tableData.push(entryData);

      // Add the remaining items as new rows
      for (let i = 1; i < entry.items.length; i++) {
        const item = entry.items[i];
        tableData.push([
          item.medicine_name,
          item.dosage,
          item.frequency,
          item.duration,
        ]);
      }
    });

    // Add table to the PDF
    doc.autoTable({
      head: [
        [
          {
            content: "Description",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
          {
            content: "Date",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
          {
            content: "Medicine Name",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
          {
            content: "Dosage",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
          {
            content: "Frequency",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
          {
            content: "Duration",
            styles: {
              fillColor: [220, 220, 220],
              textColor: [0, 0, 0],
              fontStyle: "bold",
            },
          },
        ],
      ],
      body: tableData,
      startY: currentY + 10, // Adjust starting Y position to be below the separator line
      styles: {
        fillColor: [255, 255, 255], // Set the default background to white for rows
        textColor: [0, 0, 0], // Set text color to black
        lineWidth: 0.2, // Border line width
        lineColor: [0, 0, 0], // Border line color
      },
      alternateRowStyles: {
        fillColor: [240, 240, 240], // Light gray background for alternate rows
      },
    });

    // Save the PDF
    doc.save("PrescriptionHistory.pdf");
  };
  const paymentRefund = async (payment_id, refund_amount) => {
    try {
      setLoading(true);
      const payload = {
        payment_id: payment_id,
        refund_amount: refund_amount,
        refund_reason: "Customer request",
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/refund/payment/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
      const resp = await response.json();

      // if (!response.ok) throw new Error("Network response was not ok");
      if (typeof resp === "string") {
        ShowSucess("Payment Successfully Refunded!");
        setLoading(false);
      } else {
        ShowError(resp?.error);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error creating prescription:", error);
      ShowError(error);
      setLoading(false);
    } finally {
      setLoading(false); // Reset loading action
    }
  };

  const handleMedicineChange = (index, field, value) => {
    const updatedMedicines = [...medicines];
    updatedMedicines[index][field] = value;
    setMedicines(updatedMedicines);
  };
  const handleRemoveMedicine = (index) => {
    const updatedMedicines = medicines.filter((_, i) => i !== index);
    setMedicines(updatedMedicines);
  };

  const handlePrescriptionSubmit = async () => {
    const prescriptionid = patient?.id;

    const isObjectFilled = (obj) => {
      return Object.keys(obj).every(
        (key) => obj[key] && obj[key].trim() !== ""
      );
    };

    const areAllObjectsFilled = () => {
      return medicines?.every(isObjectFilled);
    };

    // Construct the payload
    const payload = {
      doctor: doctorId,
      patient: patientId,
      date: new Date().toISOString().split("T")[0],
      description: prescriptionDescription,
      items: medicines,
      appointment: prescriptionid,
    };

    // Check if selectedDate and selectedTime exist and add them to the payload
    if (selectedDate && selectedTime) {
      payload.appointment_date = selectedDate; // Adjust format if needed
      // payload.appointment_time = selectedTime;
      payload.appointment_time = convertTo12HourFormat(selectedTime); // Adjust format if needed
    }

    if (
      doctorId &&
      patientId &&
      prescriptionDescription &&
      medicines &&
      medicines.length > 0 &&
      prescriptionid &&
      areAllObjectsFilled()
    ) {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/prescription/create/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          }
        );
        const resp = await response.json();

        if (response.ok && resp?.id) {
          toast(`Prescription created successfully !`, {
            theme: "light",
            position: "top-right",
          });
          // ShowSucess("Prescription created successfully");
          getPriscription();
          resetForm();
        } else if (resp?.detail) {
          ShowError(resp.detail);
        } else if (resp?.errors) {
          const errorMessages = Object.values(resp.errors).flat();
          errorMessages.forEach((error) => ShowError(error));
        } else {
          ShowError("Something went wrong!");
        }
      } catch (error) {
        console.error("Error creating prescription:", error);
        ShowError("Error creating prescription");
      } finally {
        setLoading(false);
        setSelectedDate();
        setSelectedTime();
      }
    } else {
      ShowError("Please fill in all required fields");
    }
  };

  // const handlePrescriptionSubmit = async () => {
  //   const prescriptionid = patient?.id;

  //   const isObjectFilled = (obj) => {
  //     return Object.keys(obj).every(
  //       (key) => obj[key] && obj[key].trim() !== ""
  //     );
  //   };

  //   const areAllObjectsFilled = () => {
  //     return medicines?.every(isObjectFilled);
  //   };

  //   const payload = {
  //     doctor: doctorId,
  //     patient: patientId,
  //     date: new Date().toISOString().split("T")[0],
  //     description: prescriptionDescription,
  //     items: medicines,
  //     appointment: prescriptionid,
  //   };

  //   if (
  //     doctorId &&
  //     patientId &&
  //     prescriptionDescription &&
  //     medicines &&
  //     medicines.length > 0 &&
  //     prescriptionid &&
  //     areAllObjectsFilled()
  //   ) {
  //     try {
  //       setLoading(true);
  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_URL}/prescription/create/`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify(payload),
  //         }
  //       );
  //       const resp = await response.json();

  //       if (response.ok && resp?.id) {
  //         ShowSucess("Prescription created successfully");
  //         getPriscription();
  //         resetForm();
  //       } else if (resp?.detail) {
  //         // If the API returns a "detail" field, show it as an error message
  //         ShowError(resp.detail);
  //       } else if (resp?.errors) {
  //         // If there are field-specific errors
  //         const errorMessages = Object.values(resp.errors).flat();
  //         errorMessages.forEach((error) => ShowError(error));
  //       } else {
  //         ShowError("Something went wrong!");
  //       }
  //     } catch (error) {
  //       console.error("Error creating prescription:", error);
  //       ShowError("Error creating prescription");
  //     } finally {
  //       setLoading(false);
  //     }
  //   } else {
  //     ShowError("Please fill in all required fields");
  //   }
  // };

  // const handlePrescriptionSubmit = async () => {
  //   // const doctorId = localStorage.getItem("doctorId");
  //   const prescriptionid = patient?.id;
  //   const isObjectFilled = (obj) => {
  //     return Object.keys(obj).every(
  //       (key) => obj[key] && obj[key].trim() !== ""
  //     );
  //   };
  //   const areAllObjectsFilled = () => {
  //     return medicines?.every(isObjectFilled);
  //   };
  //   const payload = {
  //     doctor: doctorId,
  //     patient: patientId,
  //     date: new Date().toISOString().split("T")[0],
  //     description: prescriptionDescription,
  //     items: medicines,
  //     appointment: prescriptionid,
  //   };
  //   if (
  //     doctorId &&
  //     patientId &&
  //     prescriptionDescription &&
  //     medicines &&
  //     medicines.length > 0 &&
  //     prescriptionid &&
  //     areAllObjectsFilled()
  //   ) {
  //     try {
  //       setLoading(true);
  //       const response = await fetch(
  //         `${process.env.REACT_APP_API_URL}/prescription/create/`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify(payload),
  //         }
  //       );
  //       const resp = await response.json();
  //       // if (!response.ok) throw new Error("Network response was not ok");
  //       if (resp?.id) {
  //         ShowSucess("Prescription created successfully");
  //         getPriscription();
  //         setLoading(false);
  //         resetForm();
  //       } else {
  //         ShowSucess("Something Went Wrong!");
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error creating prescription:", error);
  //       ShowError("Error creating prescription");
  //       setLoading(false);
  //     } finally {
  //       setLoading(false); // Reset loading action
  //     }
  //   } else {
  //     return;
  //   }
  // };
  const getPaymentHistory = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctor-payments/${patient?.doctor}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resp = await response.json();
      // if (!response.ok) throw new Error("Network response was not ok");
      if (resp && resp?.length > 0) {
        setPaymentHistory(
          resp?.filter((item) => item?.status === "successful")
        );
        setLoading(false);
        resetForm();
      }
    } catch (error) {
      setLoading(false);
      setPaymentHistory([]);
    }
  };

  const getPriscription = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctor-prescriptions/${patient?.patient}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resp = await response.json();
      // if (!response.ok) throw new Error("Network response was not ok");

      if (resp && resp?.length > 0) {
        if (patient?.id) {
          setPriscriptionHistory(
            resp?.filter((d) => d?.parent_appointment_id === patient?.id)
          );
        }

        setLoading(false);
        resetForm();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setPriscriptionHistory([]);
    }
  };
  const paymentUrl = `/razorpay/payment/create`;
  useEffect(() => {
    getPriscription();
    getPaymentHistory();
  }, []);
  const resetForm = () => {
    setPrescriptionDescription(""); // Reset description
    setMedicines([
      { medicine_name: "", dosage: "", frequency: "", duration: "" },
    ]); // Reset medicines to the initial state
    setSelectedTime("");
  };

  const truncateText = (text, length) => {
    if (text.length > length) {
      return text.slice(0, length) + "...";
    }
    return text;
  };
  const handleShowModal = (items) => {
    setCurrentItems(items);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container-fluid"
      />
      {/* <Container> */}
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 mt-sm-0">
              <Header
                title="Patient Details & Prescription" // Replace with the title you want
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              <Card>
                {loading && loadingPanel()}
                {/* <h4 className="mb-0" style={{ marginLeft: "22px" }}>
                  Patient Details & Prescription
                </h4> */}
                <Card.Body>
                  {patient && (
                    <>
                      <Row xs={12}>
                        <Col xs={4} className="d-flex">
                          <h6>Name:</h6>
                          <p className="text-muted ms-3">
                            {patient?.patient_name}
                          </p>
                        </Col>
                        <Col xs={4} className="d-flex">
                          <h6>Age:</h6>
                          <p className="text-muted ms-3">
                            {patient?.age} year old
                          </p>
                        </Col>
                        <Col xs={4} className="d-flex">
                          <h6>Gender:</h6>
                          <p className="text-muted ms-3">{patient?.gender}</p>
                        </Col>
                      </Row>
                      <Row xs={12}>
                        <Col xs={4} className="d-flex">
                          <h6 className="mb-0">Reason:</h6>
                          <p className="text-muted ms-3 mb-0">
                            {patient?.reason}
                          </p>
                        </Col>
                        <Col xs={4} className="d-flex">
                          <h6>Appointment Date:</h6>
                          <p className="text-muted ms-3">
                            {patient?.appointment_date}
                          </p>
                        </Col>
                        <Col xs={4} className="d-flex">
                          <h6>Appointment Time:</h6>
                          <p className="text-muted ms-3">
                            {patient?.appointment_time}
                          </p>
                        </Col>
                      </Row>
                      <hr />

                      {healthMetricsList && healthMetricsList?.length > 0 && (
                        <>
                          <LatestHealthMetrics
                            healthMetricsList={healthMetricsList[0]}
                            downloadHealthMetricsPDF={downloadHealthMetricsPDF}
                            patientData={patient}
                          />
                          <hr />
                        </>
                      )}
                      {patient?.room_name && (
                        <div>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px",
                              background:
                                "linear-gradient(90deg, #eef2f3, #8e9eab)",
                              borderRadius: "12px",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              // maxWidth: "800px",
                              margin: "10px auto",
                            }}
                          >
                            {/* Room Name Section */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <VideoCallIcon
                                sx={{
                                  fontSize: "32px",
                                  marginRight: "12px",
                                  color: "#0056b3",
                                }}
                              />
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                  color: "#333",
                                  letterSpacing: "0.5px",
                                }}
                              >
                                {patient?.room_name || "Unnamed Room"}
                              </Typography>
                            </Box>

                            {/* Button Section */}
                            <Button
                              onClick={() =>
                                navigate(`/videoconference/${patient?.id}`)
                              }
                              style={{
                                background:
                                  "linear-gradient(90deg, #28a745, #218838)",
                                color: "#fff",
                                padding: "10px 20px",
                                fontSize: "16px",
                                fontWeight: "bold",
                                textTransform: "none",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                border: "none",
                              }}
                              onMouseOver={(e) => {
                                e.target.style.background =
                                  "linear-gradient(90deg, #218838, #1e7e34)";
                              }}
                              onMouseOut={(e) => {
                                e.target.style.background =
                                  "linear-gradient(90deg, #28a745, #218838)";
                              }}
                            >
                              Start Video Call
                            </Button>
                          </Box>
                        </div>
                      )}

                      <div>
                        <Row className="mb-3">
                          <h5> Payment Link</h5>
                          <Col xs={6}>
                            <Box
                              style={{
                                border: "2px solid black", // Border around the link
                                padding: "8px", // Padding inside the border
                                display: "block", // Ensure the border wraps the text properly
                              }}
                            >
                              <Link
                                to={paymentUrl}
                                state={{ appointmentId: patient?.id }}
                                style={{
                                  color: "blue",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                {paymentUrl}
                              </Link>
                            </Box>
                          </Col>
                          <Col xs={2}>
                            <CopyToClipboard
                              text={paymentUrl}
                              onCopy={() => setCopied(true)}
                            >
                              <button>Copy to clipboard</button>
                            </CopyToClipboard>{" "}
                          </Col>
                          <Col xs={1}>
                            {copied && (
                              <span
                                style={{ color: "#00d000", fontSize: "21px" }}
                              >
                                Copied!
                              </span>
                            )}
                          </Col>
                          {paymentHistory && paymentHistory?.length > 0 && (
                            <Col xs={3}>
                              <Button
                                className="btn btn-secondary"
                                onClick={() => setShowPaymentHistory(true)}
                              >
                                View Payment History
                              </Button>
                            </Col>
                          )}
                        </Row>
                      </div>

                      <hr />

                      <div>
                        <div>
                          <Row className="mb-2">
                            <Col xs={8}>
                              {" "}
                              <h5>Create Prescription</h5>
                            </Col>
                            {prescriptionHistory &&
                              prescriptionHistory?.length > 0 && (
                                <Col>
                                  {" "}
                                  <Button
                                    className="btn btn-secondary"
                                    onClick={() =>
                                      setShowPrescriptionHistory(true)
                                    }
                                  >
                                    View Prescription History
                                  </Button>
                                </Col>
                              )}
                          </Row>
                          <Form>
                            <Form.Group className="mb-4">
                              <Form.Label htmlFor="prescriptionDescription">
                                Description
                              </Form.Label>
                              <TextField
                                type="textarea"
                                id="prescriptionDescription"
                                fullWidth
                                rows={4}
                                value={prescriptionDescription}
                                error={!prescriptionDescription}
                                onChange={(e) =>
                                  setPrescriptionDescription(e.target.value)
                                }
                                required
                              />
                            </Form.Group>
                            {medicines.map((medicine, index) => (
                              <div
                                key={index}
                                className="row mb-3 align-items-center"
                              >
                                <div className="col-5">
                                  {index === 0 && (
                                    <Form.Label className="form-label">
                                      Medicine Name
                                    </Form.Label>
                                  )}
                                  <TextField
                                    type="text"
                                    fullWidth
                                    size="small"
                                    placeholder="Medicine Name"
                                    value={medicine.medicine_name}
                                    error={!medicine.medicine_name}
                                    onChange={(e) =>
                                      handleMedicineChange(
                                        index,
                                        "medicine_name",
                                        e.target.value
                                      )
                                    }
                                    required
                                  />
                                </div>
                                <div className="col">
                                  {index === 0 && (
                                    <Form.Label className="form-label">
                                      Dosage
                                    </Form.Label>
                                  )}
                                  <TextField
                                    type="text"
                                    placeholder="Dosage"
                                    fullWidth
                                    size="small"
                                    value={medicine.dosage}
                                    error={!medicine.dosage}
                                    onChange={(e) =>
                                      handleMedicineChange(
                                        index,
                                        "dosage",
                                        e.target.value
                                      )
                                    }
                                    required
                                  />
                                </div>
                                <div className="col">
                                  {index === 0 && (
                                    <Form.Label className="form-label">
                                      Frequency
                                    </Form.Label>
                                  )}
                                  <TextField
                                    type="text"
                                    placeholder="Frequency"
                                    fullWidth
                                    size="small"
                                    value={medicine.frequency}
                                    error={!medicine?.frequency}
                                    onChange={(e) =>
                                      handleMedicineChange(
                                        index,
                                        "frequency",
                                        e.target.value
                                      )
                                    }
                                    required
                                  />
                                </div>
                                <div className="col">
                                  {index === 0 && (
                                    <Form.Label className="form-label">
                                      Duration
                                    </Form.Label>
                                  )}
                                  <TextField
                                    type="text"
                                    fullWidth
                                    size="small"
                                    placeholder="Duration"
                                    value={medicine.duration}
                                    error={!medicine.duration}
                                    onChange={(e) =>
                                      handleMedicineChange(
                                        index,
                                        "duration",
                                        e.target.value
                                      )
                                    }
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    required
                                  />
                                </div>
                                <div className="col d-flex align-items-end">
                                  {index !== 0 && (
                                    <Button
                                      type="button"
                                      variant="danger"
                                      size="sm"
                                      onClick={() =>
                                        handleRemoveMedicine(index)
                                      }
                                    >
                                      X
                                    </Button>
                                  )}
                                </div>
                              </div>
                            ))}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "1rem",
                              }}
                            >
                              <label
                                htmlFor="followUp"
                                style={{
                                  marginRight: "1rem",
                                  fontWeight: "bold",
                                }}
                              >
                                Follow Up Re-Appointment
                              </label>

                              <div style={{ marginRight: "1rem", flex: 1 }}>
                                <DatePicker
                                  selected={
                                    selectedDate ? new Date(selectedDate) : null
                                  }
                                  onChange={handleDateChange}
                                  filterDate={(date) =>
                                    !isDateUnavailable(date)
                                  }
                                  minDate={new Date()}
                                  maxDate={getMaxDate()}
                                  dateFormat="dd-MM-yyyy"
                                  required
                                  className="form-select form-control"
                                  renderDayContents={(day, date) => {
                                    const today = new Date();
                                    const maxSelectableDate = new Date(today);
                                    maxSelectableDate.setDate(
                                      today.getDate() + 15
                                    );
                                    const isPastDate = date < today;
                                    const isExceedingDate =
                                      date > maxSelectableDate;
                                    const isWithinRange =
                                      date >= today &&
                                      date <= maxSelectableDate;
                                    const isSelectable = isDateSelectable(date);
                                    const isSelected =
                                      date.toDateString() ===
                                      (selectedDate
                                        ? new Date(selectedDate).toDateString()
                                        : null);

                                    const styles = {
                                      display: "inline-flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      width: "30px",
                                      height: "30px",
                                      borderRadius: "50%",
                                      cursor:
                                        isPastDate || isExceedingDate
                                          ? "not-allowed"
                                          : isWithinRange && isSelectable
                                          ? "pointer"
                                          : "default",
                                      backgroundColor: isSelected
                                        ? "skyblue"
                                        : isWithinRange && isSelectable
                                        ? "blue"
                                        : "transparent",
                                      color:
                                        isSelected ||
                                        (isWithinRange && isSelectable)
                                          ? "white"
                                          : "gray",
                                    };

                                    return <span style={styles}>{day}</span>;
                                  }}
                                />

                                {/* <DatePicker
                                  selected={selectedDate ? new Date(selectedDate) : null}
                                  onChange={handleDateChange}
                                  filterDate={(date) =>
                                    !isDateUnavailable(date)
                                  }
                                  minDate={new Date()}
                                  maxDate={getMaxDate()}
                                  dateFormat="dd-MM-yyyy"
                                  style={{
                                    width: "100%",
                                    padding: "0.5rem",
                                    borderRadius: "0.25rem",
                                    border: "1px solid #ced4da",
                                  }}
                                  required
                                  className="form-select form-control"
                                /> */}
                              </div>

                              <div style={{ flex: 1 }}>
                                <select
                                  id="rescheduleTime"
                                  value={selectedTime}
                                  onChange={(e) => {
                                    console.log("event", e);
                                    setSelectedTime(e.target.value);
                                  }}
                                  required
                                  className="form-select form-control"
                                >
                                  <option value="">Select a time</option>
                                  {availableSlots.length > 0 ? (
                                    availableSlots.map((slot, index) => (
                                      <option
                                        key={index}
                                        value={slot.start_time}
                                      >
                                        {convertTo12HourFormat(slot.start_time)}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="" disabled>
                                      No available slots
                                    </option>
                                  )}
                                </select>
                              </div>
                            </div>

                            {/* <Button
                  variant="primary"
                  className="mt-3"
                  onClick={handleAddMedicine}
                >
                  Add Medicine
                </Button> */}
                          </Form>
                          <Button
                            variant="primary"
                            style={{
                              marginRight: "auto",
                              marginLeft: "auto",
                              display: "flex",
                            }}
                            onClick={handlePrescriptionSubmit}
                          >
                            Create Prescription
                          </Button>
                        </div>
                        <hr />
                      </div>
                    </>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
      {showPaymentHistory && paymentHistory && paymentHistory?.length > 0 && (
        <Modal show={showPaymentHistory} onHide={onHide} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              Payment History{" "}
              {patient?.status && (
                <b style={{ fontSize: "20px", marginLeft: "170px" }}>
                  Appointment Status - {patient?.status}
                </b>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ height: "500px", overflow: "auto" }}>
              <>
                <Table size="md">
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Currency</th>
                      <th>Order Type</th>
                      <th>Payment Date</th>
                      {patient?.status.toLowerCase() === "cancelled" ? (
                        <th>Refund</th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {paymentHistory?.map((item, index) => (
                      <tr key={`${index}+1`}>
                        <td>{item?.amount}</td>
                        <td>{item?.currency}</td>
                        <td>{item?.order_type || "N/A"}</td>
                        <td>
                          {dayjs(item?.created_at).format("MM/DD/YYYY hh:mm A")}
                        </td>
                        {patient?.status === "cancelled" ? (
                          <Button
                            variant="outline-danger"
                            style={{
                              marginLeft: "10px",
                              padding: "5px",
                              width: "50px",
                            }}
                            onClick={() =>
                              paymentRefund(item?.id, item?.amount)
                            }
                          >
                            <FaExchangeAlt />
                          </Button>
                        ) : (
                          ""
                        )}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showPrescriptionHistory && (
        <PrescriptionHistory
          prescription={prescriptionHistory}
          showPrescriptionHistory={showPrescriptionHistory}
          setShowPrescriptionHistory={setShowPrescriptionHistory}
          downloadPDF={downloadPrescriptionHistory}
          healthMetrics={healthMetricsList[0]}
        />
      )}
      {/* <PrescriptionModal
        show={showModal}
        onHide={handleCloseModal}
        items={currentItems}
      /> */}
      {/* </Container> */}
    </>

    // <Modal show={show} onHide={onHide} size="lg">

    // </Modal>
  );
};

export default PatientDetailPage;
