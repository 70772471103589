import React, { useState, useEffect, useRef } from "react";
// import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Modal, Table, Button, Row, Col, Container } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import { Line } from "react-chartjs-2";
import dr1 from "../../assets/images/doctors/01.jpg";
import { jsPDF } from "jspdf";
import * as htmlToImage from "html-to-image";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import {
  convertTo12HourFormat,
  loadingPanel,
} from "../../components/CommonComponents/CommonFunction";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register the required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function PrescriptionHistory({
  prescription,
  showPrescriptionHistory,
  setShowPrescriptionHistory,
  downloadPDF,
  healthMetrics,
}) {
  const [showModal, setShowModal] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [latestHealthMetrics, setLatestHealthMetrics] = useState([]);
  const [concatePresArr, setConcatePresArr] = useState({});
  const {
    doctor_first_name,
    doctor_last_name,
    date,
    description,
    patient_name,
    patient_phoneno,
    patient_gender,
    patient_email,
    patient_age,
    id,
    doctor_medical_license_number,
    Clinicname_address,
    Clinicname_details,
    follow_up_date,
  } = concatePresArr || {};
  const [loading, setLoading] = useState(false);
  const { clinic_name, clinic_address, timetables } = Clinicname_details || {};
  const { email, Education, contact_no, image } =
    useSelector((state) => state.DoctorReducer?.doctorDetailsResults) || {};
  const { metrics } = healthMetrics || {};
  const ref = useRef();

  const handleDownload = async (e) => {
    e.preventDefault();
    setLoading(true);
    const input = ref.current;

    // Temporarily remove scrolling and set auto height
    input.style.maxHeight = "none";
    input.style.overflowY = "visible";

    // Capture the content and generate PDF
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save("Prescription.pdf");

      // Reapply scrolling and maxHeight
      input.style.maxHeight = "800px"; // or whatever value you want
      input.style.overflowY = "auto";
      setLoading(false);
    });
  };

  // Prepare the data for the chart
  const data = {
    labels: [
      "Blood Pressure",
      "Hemoglobin",
      "Heart Rate",
      "Sugar Level",
      "Weight",
    ], // Labels for x-axis
    datasets: [
      {
        label: "Health Metrics",
        data: [metrics?.bp, metrics?.hb, metrics?.hr, metrics?.sg, metrics?.wt], // Corresponding data points
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderWidth: 2,
        fill: true,
        tension: 0.3, // Smoothing the line
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Health Metrics Overview",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  useEffect(() => {
    if (healthMetrics && healthMetrics?.metrics_with_lookup) {
      const extractedArr = healthMetrics?.metrics_with_lookup.map(
        (d) => `${d?.name}: ${d?.value} ${d?.unit}`
      );
      setLatestHealthMetrics(extractedArr);
    }
  }, [healthMetrics]);
  const handleShowModal = (items) => {
    setCurrentItems(items);
    setShowModal(true);
  };
  const handleCloseModal = (e) => {
    e?.preventDefault();
    setShowModal(false);
  };

  const mergedPrescription = () => {
    if (prescription && prescription.length > 1) {
      // Concatenate 'items' arrays from both objects
      const allItems = prescription.reduce((acc, curr) => {
        return acc.concat(curr.items || []);
      }, []);

      // Create a single object with concatenated items and common info
      const result = {
        ...prescription[1],
        items: allItems,
      };

      return result;
    } else {
      // Handle case where prescription length is less than 2
      return prescription[0] || {};
    }
  };
  useEffect(() => {
    if (prescription && prescription?.length > 0) {
      const newPrescriptionObj = mergedPrescription();
      setConcatePresArr(newPrescriptionObj);
    }
  }, [prescription]);
  // Now you can use mergedPrescription
  const P = ({ children, className }) => {
    return (
      <p className={className} style={{ marginBottom: 0 }}>
        {children}
      </p>
    );
  };

  return (
    <div>
      {" "}
      <Modal
        show={showPrescriptionHistory}
        onHide={() => setShowPrescriptionHistory(false)}
        animation={false}
        size="lg"
        centered
        dialogClassName="modal-fullwidth"
      >
        {loading && loadingPanel()}
        <Modal.Header closeButton>
          <Modal.Title className="h4">
            Prescription Details
            <a
              href="#"
              className="text-primary fw-bold"
              style={{
                textDecoration: "none",
                cursor: "pointer",
                marginLeft: "20px",
                fontSize: "22px",
              }}
              onClick={handleDownload}
            >
              Download PDF
            </a>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={ref} style={{ maxHeight: "800px", overflowY: "auto" }}>
          {prescription && prescription.length > 0 ? (
            <Container>
              {/* Header Section */}
              <Row xs={12}>
                <Col xs={5}>
                  <h5>
                    <span
                      style={{
                        backgroundColor: "#4e73df" /* Blue background */,
                        color: "white",
                        padding: "3px 3px",
                        fontSize: "20px",
                      }}
                    >
                      {" "}
                      Dr. {doctor_first_name} {doctor_last_name}
                    </span>
                  </h5>
                  {/* M.B.B.S, M.D, M.S */}
                  <P>{Education?.map((d) => d?.degree)?.join(",")}</P>
                  <P>
                    <small className="text-muted me-2"> Reg No: </small>
                    <small className="fw-bold text-dark">
                      {doctor_medical_license_number}
                    </small>{" "}
                  </P>
                  {contact_no && (
                    <P>
                      <small className="text-muted me-2">Mob:</small>
                      <small className="fw-bold text-dark">
                        {contact_no}
                      </small>{" "}
                    </P>
                  )}
                  {email && (
                    <P>
                      <small className="text-muted me-2">Email:</small>
                      <small className="fw-bold text-dark">{email}</small>
                    </P>
                  )}
                </Col>
                <Col xs={3}>
                  <img
                    src={image || dr1}
                    width={150}
                    height={150}
                    className="rounded-circle shadow-md avatar avatar-md-md"
                    alt="Profile"
                    style={{ borderRadius: "100px" }}
                  />
                </Col>
                <Col className="text-start" xs={4}>
                  {clinic_name && (
                    <h5 style={{ marginBottom: 0 }}>
                      {clinic_name || "Care Clinic"}
                    </h5>
                  )}{" "}
                  {clinic_address && (
                    <P>
                      {" "}
                      <small className="text-muted me-2">Address:</small>
                      <small className="fw-bold text-dark">
                        {clinic_address}
                      </small>
                    </P>
                  )}{" "}
                  {/* <P> */}
                  {timetables && timetables?.length > 0 && (
                    <P>
                      <small className="text-muted me-2">Timing:</small>
                      <small className="fw-bold text-dark">
                        {" "}
                        {convertTo12HourFormat(timetables[0]?.start_time)}-{" "}
                        {convertTo12HourFormat(timetables[0]?.end_time)}{" "}
                        {`(${timetables[0]?.day_of_week})`}
                      </small>
                    </P>
                    // <a
                    //   // href="#"
                    //   className="text-primary fw-bold"
                    //   style={{ textDecoration: "none", cursor: "pointer" }}
                    //   onClick={(e) => {
                    //     e.preventDefault();
                    //     setShowModal(true);
                    //   }}
                    // >
                    //   View timetable
                    // </a>
                  )}
                  {/* </P> */}
                  {/* <P>Timing: 09:00 AM - 02:00 PM | Closed: Thursday</P> */}
                </Col>
              </Row>
              {/* Patient Info */}
              <hr />
              <Row xs={12} className="my-1">
                <Col>
                  <h5>
                    <span
                      style={{
                        backgroundColor: "#4e73df",
                        /* Blue background */ color: "white",
                        padding: "3px 3px",
                        fontSize: "18px",
                      }}
                    >
                      {" "}
                      ID: {id}- {patient_name} ({patient_gender})
                    </span>
                  </h5>
                  {/* <P>Address: Pune</P> */}
                  {patient_age && (
                    <P>
                      <small className="text-muted me-2">Age:</small>
                      <small className="fw-bold text-dark">
                        {" "}
                        {patient_age}
                      </small>
                    </P>
                  )}
                  {patient_email && (
                    <P>
                      <small className="text-muted me-2">Email:</small>
                      <small className="fw-bold text-dark">
                        {patient_email}
                      </small>
                    </P>
                  )}
                  {patient_phoneno && (
                    <P>
                      <small className="text-muted me-2">Phone No:</small>
                      <small className="fw-bold text-dark">
                        {" "}
                        {patient_phoneno}
                      </small>
                    </P>
                  )}
                </Col>
                <Col className="text-end">
                  <P>
                    <small className="text-muted me-2"> Date: </small>
                    <small className="fw-bold text-dark">
                      {dayjs(date).format("MM/DD/YYYY")}
                    </small>{" "}
                  </P>
                </Col>
              </Row>
              <Row>
                <P>{latestHealthMetrics.join(",")}</P>
              </Row>
              <hr />
              {/* Medicine Table */}
              {prescription && prescription.length > 0 && (
                <div>
                  <Table hover borderless size="sm">
                    {/* <thead>
                      {/* <tr>
                        <th>Date</th>
                        <th>Medicine Name</th>
                        <th>Dosage</th>
                        <th>Frequency</th>
                        <th>Duration</th>
                        {/* <th>Description</th> */}
                    {/* </tr> */}
                    {/* </thead> */}
                    <tbody>
                      {prescription?.map((d) => {
                        return d.items?.map((item, i) => (
                          <>
                            <tr>
                              {" "}
                              <h6 style={{ margin: 0 }}>
                                {dayjs(d?.date)?.format("MM/DD/YYYY")}
                              </h6>
                            </tr>
                            <tr>
                              {" "}
                              <td colSpan={12}>
                                {" "}
                                <h6 style={{ margin: 0 }}>{d?.description}</h6>
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <th>{i + 1}.</th>
                              <td>{item?.medicine_name}</td>
                              <td>{item?.dosage}</td>
                              <td>{item?.frequency}</td>
                              <td>{item?.duration}</td>
                            </tr>
                            <tr>
                              <td colSpan={12}>
                                <hr />
                              </td>
                            </tr>
                          </>
                        ));
                      })}
                    </tbody>
                  </Table>
                </div>
              )}

              {/* Advice Section */}
              {/* <p>
                <strong>Advice Given:</strong> {description}
              </p> */}
              {follow_up_date && (
                <>
                  <p>
                    <strong>Follow-up:</strong>{" "}
                    {dayjs(follow_up_date).format("MM/DD/YYYY")}
                  </p>
                  <hr />
                </>
              )}

              {/* Charts Section */}
              {healthMetrics && Object.values(healthMetrics)?.length > 0 && (
                <>
                  <Row className="my-4">
                    <Col>
                      <h6>Chart</h6>
                      <Line data={data} options={options} />
                    </Col>
                  </Row>
                  <hr />
                </>
              )}

              {/* Footer Section */}
              <Row>
                <Col xs={7}></Col>
                <Col xs={5}>
                  <p>Signature: ____________________________</p>
                  <p>
                    Dr. {doctor_first_name} {doctor_last_name}
                  </p>
                </Col>
              </Row>
            </Container>
          ) : (
            <p>No prescription details available for this appointment.</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
