// App.js
import React, { useEffect, useState } from "react";
import { post } from "../../api_helper";
import { useParams } from "react-router-dom";
import {
  Container,
  Grid,
  TextField,
  Button,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { Card, CardBody } from "reactstrap";
import dayjs from "dayjs";
import { loadingPanel } from "../CommonComponents/CommonFunction";
import { ShowSucess, ShowError } from "../../_helper/helper";
const options = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const TimeTable = () => {
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [formData, setFormData] = React.useState({
    clinic_name: "",
    day_of_week: "",
    start_time: "",
    end_time: "",
  });
  const { id } = useParams();
  const baseURL = process.env.REACT_APP_API_URL;
  const getClinicList = async () => {
    const resp = await (
      await fetch(`${baseURL}/clinics/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).json();
    setClinics(resp);
  };
  useEffect(() => {
    getClinicList();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateField = (name, value) => {
    const startTime = dayjs(formData.start_time, "HH:mm");
    const endTime = dayjs(formData.end_time, "HH:mm");
    let error = "";
    if (startTime.isAfter(endTime)) {
      error = "Start time can't be more than End time";
    } else if (!value) {
      error = "This field is required";
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  const validateForm = () => {
    let valid = true;
    Object.keys(formData).forEach((key) => {
      validateField(key, formData[key]);
      if (!formData[key] || errors[key]) {
        valid = false;
      }
    });
    return valid;
  };
  const handleBlur = (event) => {
    const { name, value } = event.target;
    validateField(name, value);
  };
  const createDoctorRegistration = async () => {
    try {
      setLoading(true);
      // const payload = {
      //   ...formData,
      //   start_time: dayjs(formData?.start_time).format("HH:mm:ss"),
      //   end_time: dayjs(formData?.end_time).format("HH:mm:ss"),
      // };

      const res = await (
        await fetch(
          `https://monkfish-app-de9s7.ondigitalocean.app/timetable/${Number(
            id
          )}/`,

          {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
      ).json();
      if (res?.id) {
        console.log("res", res);
        setLoading(false);
        ShowSucess("SuccessFully Created!");
        // setRegisterDrModal(false);
      }
    } catch (error) {
      setLoading(false);
      ShowError("Something Went Wrong!");
      console.log("error", error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      createDoctorRegistration();
    } else {
      return false;
    }
    // You can add your form submission logic here
  };

  return (
    <Container>
      <Card>
        <CardBody>
          <h5>Time Table</h5>
          {loading && loadingPanel()}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  name="clinic_name"
                  disableClearable
                  options={clinics.map((option) => option.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Address"
                      size="small"
                      margin="dense"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      error={!!errors.clinic}
                      helperText={errors.clinic}
                      onBlur={handleBlur}
                    />
                  )}
                  onInputChange={(e) => {
                    setFormData({
                      ...formData,
                      clinic: e?.target.textContent,
                    });
                  }}
                />
                {/* <TextField
                  fullWidth
                  select={true}
                  id="outlined-select-requestType"
                  size="small"
                  label="Clinic"
                  margin="dense"
                  name="clinic"
                  value={formData.clinic}
                  onChange={handleChange}
                  error={!!errors.clinic}
                  helperText={errors.clinic}
                >
                  {options.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField> */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select={true}
                  id="outlined-select-requestType"
                  size="small"
                  label="Day of Week"
                  margin="dense"
                  name="day_of_week"
                  value={formData.day_of_week}
                  onChange={handleChange}
                  error={!!errors.day_of_week}
                  helperText={errors.day_of_week}
                  onBlur={handleBlur}
                >
                  {options.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>{" "}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="time"
                  name="start_time"
                  fullWidth
                  margin="dense"
                  label="Start Time"
                  size="small"
                  placeholder="Start Time"
                  value={formData.start_time}
                  onChange={handleChange}
                  error={!!errors.start_time}
                  helperText={errors.start_time}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="time"
                  name="end_time"
                  fullWidth
                  margin="dense"
                  label="End Time"
                  size="small"
                  placeholder="End Time"
                  value={formData.end_time}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!errors.end_time}
                  helperText={errors.end_time}
                />
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default TimeTable;
