import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify"; 
import 'react-toastify/dist/ReactToastify.css';

export default function EmailVerification() {
    const { token } = useParams();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await axios.post(`https://doctor-finder-backend.onrender.com/verify-email/`, {
                    token,
                });
                setSuccess("Email verification successful! You can now log in.");
                toast.success("Email verification successful! You can now log in.");
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
            } catch (err) {
                setError("Email verification failed. Please try again.");
                toast.error("Email verification failed. Please try again.");
            }
        };

        verifyEmail();
    }, [token, navigate]);

    return (
        <>
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-8">
                        {error && <p className="text-danger text-center">{error}</p>}
                        {success && <p className="text-success text-center">{success}</p>}
                        <Link to="/login" className="btn btn-primary d-block mt-3">Go to Login</Link>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}
