import React, { useState } from "react";
import { Box, Card, CardContent, Typography, Badge } from "@mui/material";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "react-bootstrap";
import {
  RiContrastDropLine,
  RiTestTubeLine,
  RiHeart3Fill,
  RiHeartPulseFill,
  RiDashboard3Line,
} from "../../assets/icons/vander";

export default function ViewHealthMetricChart({
  open,
  setOpen,
  healthMetricsList,
  downloadHealthMetricsPDF,
}) {
  const handleClose = () => setOpen(false);

  const renderCard = (title, value, unit, icon, colorClass, diff) => (
    <Card
      //   onClick={handleOpen}

      sx={{
        mb: 4,
        p: 2,
        m: 2,
        width: "100%",
        height: "150px",
        boxShadow: 1,
        "&:hover": {
          backgroundColor: "lightgrey",
          boxShadow: 3,
        },
      }}
    >
      {/* <CardContent> */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">{title}</Typography>
        {diff && (
          <Badge
            badgeContent={diff ? `${diff.toFixed(2)}%` : "N/A"}
            color={colorClass}
            //   sx={{ ml: 2 }}
          />
        )}

        <Box color={colorClass}>{icon}</Box>
      </Box>
      <Typography variant="h5" fontWeight="bold">
        {value ? `${value} ${unit}` : "N/A"}
      </Typography>
      {/* </CardContent> */}
    </Card>
  );

  return (
    <>
      <div>
        {open && (
          <Dialog
            title={
              <Button
                // style={{ marginRight: "50px" }}
                className="btn btn-primary"
                onClick={() => downloadHealthMetricsPDF()}
              >
                Download
              </Button>
            }
            onClose={handleClose}
            width={"70%"}
          >
            {/* <Box className="col-xl-3 col-lg-6 col-12">
              <Box className="row"> */}
            <div style={{ display: "flex" }}>
              {renderCard(
                "Weight Difference",
                healthMetricsList?.metrics?.wt,
                "kg",
                <RiContrastDropLine
                  fontSize="large"
                  className="text-primary h5"
                />,
                "primary",
                healthMetricsList?.percentage_difference?.wt
              )}
              {renderCard(
                "Hemoglobin Difference",
                healthMetricsList?.metrics?.hb,
                "g/dL",
                <RiTestTubeLine fontSize="large" className="text-success h5" />,
                "success",
                healthMetricsList?.percentage_difference?.hb
              )}
            </div>
            <div style={{ display: "flex" }}>
              {renderCard(
                "Heartbeat Difference",
                healthMetricsList?.metrics?.hr,
                "bpm",
                <RiHeart3Fill fontSize="large" className="text-danger h5" />,
                "error",
                healthMetricsList?.percentage_difference?.hr
              )}
              {renderCard(
                "Blood Pressure Difference",
                healthMetricsList?.metrics?.bp,
                "mmHg",
                <RiHeartPulseFill
                  fontSize="large"
                  className="text-danger h5"
                />,
                "error",
                healthMetricsList?.percentage_difference?.bp
              )}
              {renderCard(
                "Sugar Levels Difference",
                healthMetricsList?.metrics?.sg,
                "mg/dL",
                <RiDashboard3Line
                  fontSize="large"
                  className="text-warning h5"
                />,
                "warning",
                healthMetricsList?.percentage_difference?.sg
              )}
            </div>

            {/* </Box>
            </Box> */}
          </Dialog>
        )}
      </div>
    </>
  );
}
