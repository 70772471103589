import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import Toastify styles
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import AdminFooter from "../../components/dashboard/adminFooter";
import ScrollTop from "../../components/scrollTop";
import { getDoctorId } from "../../_helper/helper";
import Header from "../../components/CommonComponents/Header";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom/dist";

export default function UnavailableDates() {
  const navigate = useNavigate();
  const location = useLocation();
  const showBackButton = location.key !== "default";
  const [show, setShow] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false); // New state for confirmation modal
  const [date, setDate] = useState("");
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateToDelete, setDateToDelete] = useState(null); // New state for the date to delete
  const doctorId = getDoctorId();
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    fetchUnavailableDates();
  }, [doctorId, token]);

  const fetchUnavailableDates = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unavailable-dates/?doctor_id=${doctorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      console.log("API Response:", result); // Debugging line
      if (Array.isArray(result)) {
        setUnavailableDates(result);
      } else {
        console.error("Unexpected response structure:", result);
      }
    } catch (error) {
      console.error("Error fetching unavailable dates:", error);
    } finally {
      setLoading(false);
    }
  };
  const [dateError, setDateError] = useState("");
  const handleDateChange = (e) => {
    setDate(e.target.value);
    setDateError(""); // Clear error when a date is selected
  };
  
  // const handleDateChange = (e) => {
  //   setDate(e.target.value);
  // };
  // const handleSubmit = async () => {
  //   if (!date) {
  //     toast.error("Please select a date before submitting.");
  //     return;
  //   }
  
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/unavailable-dates/create/`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify({
  //           doctor: doctorId,
  //           date,
  //         }),
  //       }
  //     );
  //     if (!response.ok) {
  //       const errorDetails = await response.json();
  //       toast.error(errorDetails?.detail || "Network response was not ok");
  //       throw new Error("Network response was not ok");
  //     }
  //     toast.success("Date successfully added!");
  //     fetchUnavailableDates();
  //     handleCloseModal(); // Reset states and close modal
  //   } catch (error) {
  //     console.error("Error adding unavailable date:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleSubmit = async () => {
    if (!date) {
      setDateError("Please select a date before submitting.");
      return;
    } else {
      setDateError(""); // Clear error if date is valid
    }
  
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unavailable-dates/create/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            doctor: doctorId,
            date,
          }),
        }
      );
      if (!response.ok) {
        const errorDetails = await response.json();
        toast.error(errorDetails?.detail || "Network response was not ok");
        throw new Error("Network response was not ok");
      }
      toast.success("Date successfully added!");
      fetchUnavailableDates();
      handleCloseModal(); // Reset states and close modal
    } catch (error) {
      console.error("Error adding unavailable date:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleUpdate = async () => {
    if (!date) {
      setDateError("Please select a date before updating.");
      return;
    } else {
      setDateError(""); // Clear error if date is valid
    }
  
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unavailable-dates/${selectedDate.id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            doctor: doctorId,
            date,
          }),
        }
      );
      if (!response.ok) {
        const errorDetails = await response.json();
        toast.error(errorDetails?.detail || "Network response was not ok");
        throw new Error("Network response was not ok");
      }
      toast.success("Date successfully updated!");
      fetchUnavailableDates();
      handleCloseModal(); // Reset states and close modal
    } catch (error) {
      console.error("Error updating unavailable date:", error);
    } finally {
      setLoading(false);
    }
  };
  
  // const handleUpdate = async () => {
  //   if (!date) {
  //     toast.error("Please select a date before updating.");
  //     return;
  //   }
  
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/unavailable-dates/${selectedDate.id}/`,
  //       {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify({
  //           doctor: doctorId,
  //           date,
  //         }),
  //       }
  //     );
  //     if (!response.ok) {
  //       const errorDetails = await response.json();
  //       toast.error(errorDetails?.detail || "Network response was not ok");
  //       throw new Error("Network response was not ok");
  //     }
  //     toast.success("Date successfully updated!");
  //     fetchUnavailableDates();
  //     handleCloseModal(); // Reset states and close modal
  //   } catch (error) {
  //     console.error("Error updating unavailable date:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  
  // const handleSubmit = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/unavailable-dates/create/`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify({
  //           doctor: doctorId,
  //           date,
  //         }),
  //       }
  //     );
  //     if (!response.ok) {
  //       const errorDetails = await response.json();
  //       toast.error(errorDetails?.detail || "Network response was not ok");

  //       throw new Error("Network response was not ok");
  //     }
  //     toast.success("Date successfully added!");
  //     fetchUnavailableDates();
  //     handleCloseModal(); // Reset states and close modal
  //   } catch (error) {
  //     console.error("Error adding unavailable date:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleUpdate = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/unavailable-dates/${selectedDate.id}/`,
  //       {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify({
  //           doctor: doctorId,
  //           date,
  //         }),
  //       }
  //     );
  //     if (!response.ok) {
  //       const errorDetails = await response.json();
  //       toast.error(errorDetails?.detail || "Network response was not ok");

  //       throw new Error("Network response was not ok");
  //     }
  //     toast.success("Date successfully updated!");
  //     fetchUnavailableDates();
  //     handleCloseModal(); // Reset states and close modal
  //   } catch (error) {
  //     console.error("Error updating unavailable date:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unavailable-dates/${dateToDelete}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        const errorDetails = await response.json();
        toast.error(errorDetails?.detail || "Network response was not ok");
        throw new Error("Network response was not ok");
      }
      toast.success("Date successfully deleted!");
      fetchUnavailableDates();
    } catch (error) {
      console.error("Error deleting unavailable date:", error);
    } finally {
      setLoading(false);
      setShowConfirmDelete(false); // Close confirmation modal
    }
  };

  const handleView = (item) => {
    setSelectedDate(item);
    setDate(item.date);
    setShow(true);
  };

  const handleAdd = () => {
    setSelectedDate(null); // Clear selected date for add mode
    setDate(""); // Clear date input
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
    setSelectedDate(null); // Clear selected date
    setDate(""); // Clear date input
    setDateError()
  };

  const handleOpenConfirmDelete = (id) => {
    setDateToDelete(id); // Set the ID of the date to be deleted
    setShowConfirmDelete(true); // Show the confirmation modal
  };

  const handleCloseConfirmDelete = () => {
    setShowConfirmDelete(false); // Hide the confirmation modal
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const unavailableDatesSet = new Set(
    unavailableDates.map((dateItem) => dateItem.date)
  );

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header
                title="Unavailability"
                onBackClick={() => navigate(-1)}
                showBackButton={showBackButton}
              />
              <Button
                variant="primary"
                onClick={handleAdd}
                style={{ marginTop: "20px" }}
              >
                Add Unavailable Date
              </Button>
              <div className="row">
                <div className="col-12 mt-4 pt-2">
                  <div className="table-responsive shadow rounded">
                    <table className="table table-center bg-white mb-0">
                      <thead>
                        <tr>
                          <th className="border-bottom p-3">Date</th>
                          <th
                            className="text-end border-bottom p-3"
                            style={{ minWidth: "150px" }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
  {unavailableDates.length === 0 ? (
    <tr>
      <td colSpan="2" className="text-center p-3">
        No unavailable dates found.
      </td>
    </tr>
  ) : (
    unavailableDates.map((item) => (
      <tr key={item.id}>
        <td className="p-3">{formatDate(item.date)}</td>
        <td className="text-end p-3">
          <Button
            variant="info"
            onClick={() => handleView(item)}
            className="btn btn-icon btn-pills btn-soft-primary my-1"
          >
            <FaEdit />
          </Button>
          <Button
            variant="danger"
            onClick={() => handleOpenConfirmDelete(item.id)}
            className="btn btn-icon btn-pills btn-soft-danger my-1"
          >
            <FaTrashAlt />
          </Button>
        </td>
      </tr>
    ))
  )}
</tbody>

                      {/* <tbody>
                        {unavailableDates.map((item) => (
                          <tr key={item.id}>
                            <td className="p-3">{formatDate(item.date)}</td>
                            <td className="text-end p-3">
                              <Button
                                variant="info"
                                onClick={() => handleView(item)}
                                className="btn btn-icon btn-pills btn-soft-primary my-1"
                              >
                                <FaEdit />
                              </Button>
                              <Button
                                variant="danger"
                                onClick={() => handleOpenConfirmDelete(item.id)}
                                className="btn btn-icon btn-pills btn-soft-danger my-1"
                              >
                                <FaTrashAlt />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody> */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
      <Modal
        show={show}
        onHide={handleCloseModal}
        centered
        size="lg"
        backdrop="static"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedDate ? "Edit Unavailable Date" : "Add Unavailable Date"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <Form>
    <Form.Group controlId="formDate">
      <Form.Label>Select Date</Form.Label>
      <Form.Control
        type="date"
        value={date}
        onChange={handleDateChange}
        placeholder="Select Date"
        min={new Date().toISOString().split("T")[0]}
        isInvalid={!!dateError} // Indicates invalid input
      />
      <Form.Control.Feedback type="invalid">{dateError}</Form.Control.Feedback> {/* Displays error */}
    </Form.Group>
  </Form>
</Modal.Body>

        {/* <Modal.Body>
          <Form>
            <Form.Group controlId="formDate">
              <Form.Label>Select Date</Form.Label>
              <Form.Control
                type="date"
                value={date}
                onChange={handleDateChange}
                placeholder="Select Date"
                min={new Date().toISOString().split("T")[0]}
                disabledDates={Array.from(unavailableDatesSet)}
              />
            </Form.Group>
          </Form>
        </Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={selectedDate ? handleUpdate : handleSubmit}
          >
            {selectedDate ? "Update" : "Submit"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showConfirmDelete}
        onHide={handleCloseConfirmDelete}
        centered
        size="sm"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this date?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ScrollTop />
      <AdminFooter />
    </>
  );
}
