// import React, { useState, useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import Navbar from "../../components/navbar";
// import Footer from "../../components/footer";
// import ScrollTop from "../../components/scrollTop";
// import {
//   FiHeart,
//   FiPhone,
//   RiMoneyDollarCircleLine,
//   RiMapPinLine,
// } from "../../assets/icons/vander";
// import Pagination from "../../components/CommonComponents/Pagination";
// import BookingAppointment from "../patient/booking-appointment";
// import dr1 from "../../assets/images/doctors/Avatar.jpg";
// import SkeletonLoader from "./SkeletonLoader";
// import "./doctorstyle.css";
// import MobileLogin from "../../components/CommonComponents/MobileLogin";
// import LoginSignUp from "../../components/CommonComponents/LoginSignUp";

// export default function DoctorTeamTwo() {
//   const [searchQuery, setSearchQuery] = useState("");
//   const [doctors, setDoctors] = useState([]);
//   const [allDoctors, setAllDoctors] = useState([]);
//   const [searchResults, setSearchResults] = useState([]); // State for search results
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [pageSize, setPageSize] = useState(25);
//   const [totalPages, setTotalPages] = useState(1);

//   const [modalShow, setModalShow] = useState(false);
//   const [selectedDoctor, setSelectedDoctor] = useState(null);
//   const [showLoginPopup, setShowLoginPopup] = useState(false); // State for mobile login popup
//   const [showLoginSignUpModal, setShowLoginSignUpModal] = useState(false);
//   const [userRole, setUserRole] = useState(null);
//   const storedUserRole = localStorage.getItem("userRole");

//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     // Extract search query from URL parameters
//     const queryParams = new URLSearchParams(location.search);
//     const searchTerm = queryParams.get("search") || "";
//     setSearchQuery(searchTerm);
//   }, [location.search]);

//   useEffect(() => {
//     // Assume userRole is stored in localStorage after login
//     if (storedUserRole) {
//       setUserRole(storedUserRole);
//     }
//   }, [storedUserRole]);
//   useEffect(() => {
//     async function fetchDoctors() {
//       try {
//         setLoading(true);
//         const response = await fetch(
//           `${process.env.REACT_APP_API_URL}/getdoctorsdata/doctorslist/?page=${currentPage}&page_size=${pageSize}`
//         );
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         const data = await response.json();
//         setAllDoctors(data.results);
//         setDoctors(data.results);
//         setTotalPages(Math.ceil(data.count / pageSize));
//         setError(null);
//       } catch (error) {
//         console.error("Error fetching doctors:", error);
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     }

//     fetchDoctors();
//   }, [currentPage, pageSize]);

//   useEffect(() => {
//     if (searchQuery.trim() === "") {
//       setSearchResults([]);
//       setDoctors(allDoctors); // Reset to all doctors when search is cleared
//     } else {
//       searchDoctors(searchQuery);
//     }
//   }, [searchQuery, allDoctors]);

//   async function searchDoctors(query) {
//     try {
//       setLoading(true);
//       const response = await fetch(
//         `${
//           process.env.REACT_APP_API_URL
//         }/doctors/search/?search=${encodeURIComponent(query)}`
//       );
//       if (!response.ok) {
//         throw new Error("Network response was not ok");
//       }
//       const data = await response.json();
//       setSearchResults(data); // Update search results with the API response
//       setDoctors(data.length ? data : []); // Update doctors based on search results
//       setError(null);
//     } catch (error) {
//       console.error("Error fetching search results:", error);
//       setError(error.message);
//       setDoctors([]); // Clear doctors on error
//     } finally {
//       setLoading(false);
//     }
//   }

//   const handlePageChange = (pageNumber) => {
//     if (pageNumber > 0 && pageNumber <= totalPages) {
//       setCurrentPage(pageNumber);
//     }
//   };

//   const handlePageSizeChange = (event) => {
//     setPageSize(Number(event.target.value));
//     setCurrentPage(1);
//   };

//   const renderStars = (averageRating) => {
//     const totalStars = 5;
//     const rating = Math.max(0, Math.min(5, Number(averageRating) || 0));
//     const filledStars = Math.floor(rating);
//     const halfStar = rating - filledStars >= 0.5 ? 1 : 0;
//     const emptyStars = totalStars - filledStars - halfStar;

//     const starElements = [];
//     starElements.push(
//       ...Array(filledStars).fill(
//         <i
//           className="mdi mdi-star text-warning"
//           key={`filled-${starElements.length}`}
//         ></i>
//       )
//     );
//     if (halfStar) {
//       starElements.push(
//         <i
//           className="mdi mdi-star-half text-warning"
//           key={`half-${starElements.length}`}
//         ></i>
//       );
//     }
//     starElements.push(
//       ...Array(emptyStars).fill(
//         <i
//           className="mdi mdi-star-outline text-warning"
//           key={`empty-${starElements.length}`}
//         ></i>
//       )
//     );

//     return <>{starElements}</>;
//   };

//   const handleAppointmentClick = (doctor) => {
//     if (!userRole) {
//       // navigate("/login")
//       setShowLoginSignUpModal(true);
//       setSelectedDoctor(doctor);

//       // setShowLoginPopup(true);
//     } else {
//       setSelectedDoctor(doctor);
//       setModalShow(true);
//     }
//   };

//   const handleAppointmentButtonClick = () => {
//     if (!userRole) {
//       // navigate('/login')
//       setShowLoginPopup(true);
//     } else {
//       setSelectedDoctor(null);
//       setModalShow(true);
//     }
//   };
//   const renderExperience = (experience) => {
//     if (typeof experience === "string") {
//       return experience;
//     } else if (typeof experience === "object" && experience !== null) {
//       // If experience is an object, get the value from the first key
//       return Object.values(experience)[0] || "N/A";
//     }
//     return "N/A";
//   };

//   return (
//     <>
//       <Navbar
//         navDark={true}
//         manuClass="navigation-menu nav-left"
//         containerClass="container"
//       />

//       <section className="section">
//         <div className="container">
//           <div className="search-bar mt-4">
//             <input
//               type="text"
//               className="form-control"
//               placeholder="Search doctors..."
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//             />
//           </div>

//           <div className="row align-items-center">
//             {loading ? (
//               <SkeletonLoader />
//             ) : error ? (
//               <div className="col-12 text-center">
//                 <p className="text-muted">Error: {error}</p>
//               </div>
//             ) : doctors.length > 0 ? (
//               doctors.map((doctor) => (
//                 <div className="col-12 p-0" key={doctor.user}>
//                   <div
//                     className="card team border-0 rounded shadow overflow-hidden"
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       width: "100%",
//                       padding: "15px",
//                       marginBottom: "0",
//                     }}
//                   >
//                     <div className="team-person" style={{ flexShrink: 0 }}>
//                       <img
//                         src={doctor.image || dr1}
//                         alt={doctor.first_name + " " + doctor.last_name}
//                         style={{
//                           width: "150px",
//                           height: "150px",
//                           borderRadius: "50%",
//                           objectFit: "cover",
//                         }}
//                       />
//                     </div>
//                     <div
//                       className="card-body"
//                       style={{
//                         flex: 1,
//                         display: "flex",
//                         flexDirection: "row",
//                         alignItems: "stretch",
//                       }}
//                     >
//                       <div style={{ flex: 1 }}>
//                         <div style={{ marginBottom: "15px" }}>
//                           <Link
//                             to={`/doctor-profile/${doctor.id}`}
//                             className="title text-dark h5 d-block mb-0"
//                           >
//                             {doctor.first_name} {doctor.last_name}
//                           </Link>
//                           <small className="text-muted speciality">
//                             {doctor.specialization} <br />
//                             {doctor.years_of_experience} Years Experience
//                           </small>
//                           <div className="d-flex align-items-center mt-2">
//                             <ul className="list-unstyled mb-0 me-2">
//                               {renderStars(doctor.average_rating)}
//                             </ul>
//                             <p className="text-muted mb-0 me-2">
//                             {parseFloat(doctor.average_rating).toFixed(2).replace(/\.00$/, '')} Star
//                             </p>
//                           </div>
//                           <ul className="list-unstyled mt-2 mb-0">
//                             <li className="d-flex ms-0">
//                               <RiMapPinLine className="text-primary align-middle" />
//                               <small className="text-muted ms-2">
//                                 {doctor.location}
//                               </small>
//                             </li>
//                             <li className="d-flex ms-0 mt-2">
//                               <FiPhone className="text-primary align-middle" />
//                               <small className="text-muted ms-2">
//                                 {doctor.contact_no}
//                               </small>
//                             </li>
//                             <li className="d-flex ms-0 mt-2">
//                               <RiMoneyDollarCircleLine className="text-primary align-middle" />
//                               <small className="text-muted ms-2">
//                                 {doctor.charge} INR
//                               </small>
//                             </li>
//                             <li className="d-flex ms-0 mb-2">
//                               <small className="text-muted ms-2">
//                                 {doctor.description}
//                               </small>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div
//                         style={{
//                           width: "300px",
//                           marginLeft: "15px",
//                           display: "flex",
//                           flexDirection: "column",
//                           justifyContent: "space-between",
//                         }}
//                       >
//                         <ul className="list-unstyled mb-3">
//                           <li className="d-flex mb-2">
//                             <small className="text-muted ms-2">
//                               {doctor.hospital_clinic_affiliation}
//                             </small>
//                           </li>
//                           <li className="d-flex mb-2">
//                             <small className="text-muted ms-2">
//                               {doctor.medical_school}
//                             </small>
//                           </li>
//                           <li className="d-flex mb-2">
//                             <small className="text-muted ms-2">
//                               {doctor.street_address}
//                             </small>
//                           </li>
//                         </ul>
//                         <div className="text-end">
//                           <button
//                             className="btn btn-primary btn-3d"
//                             onClick={() => handleAppointmentClick(doctor)}
//                           >
//                             Appointment
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <div className="col-12 text-center">
//                 <p className="text-muted">No doctors found</p>
//               </div>
//             )}
//           </div>

//           {doctors.length > 0 && (
//             <Pagination
//               currentPage={currentPage}
//               totalPages={totalPages}
//               pageSize={pageSize}
//               onPageChange={handlePageChange}
//               onPageSizeChange={handlePageSizeChange}
//             />
//           )}
//           {modalShow && Object.values(selectedDoctor)?.length > 0 && (
//             <BookingAppointment
//               show={modalShow}
//               handleClose={() => setModalShow(false)}
//               selectedDoctor={selectedDoctor}
//             />
//           )}

//           <MobileLogin
//             show={showLoginPopup}
//             handleClose={() => setShowLoginPopup(false)}
//           />
//           <LoginSignUp
//             showLoginSignUpModal={showLoginSignUpModal}
//             setShowLoginSignUpModal={setShowLoginSignUpModal}
//             selectedDoctor={selectedDoctor}
//           />
//         </div>
//       </section>
//       <Footer />
//       <ScrollTop />
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import {
  FiHeart,
  FiPhone,
  RiMoneyDollarCircleLine,
  RiMapPinLine,
} from "../../assets/icons/vander";
import { FaPersonWalking } from "react-icons/fa6";
import Pagination from "../../components/CommonComponents/Pagination";
import BookingAppointment from "../patient/booking-appointment";
import dr1 from "../../assets/images/doctors/Avatar.jpg";
import SkeletonLoader from "./SkeletonLoader";
import MobileLogin from "../../components/CommonComponents/MobileLogin";
import LoginSignUp from "../../components/CommonComponents/LoginSignUp";
import SearchDoctor from "../../pages/index/SearchDoctor";
import { addDays, isAfter, isSameDay, format } from "date-fns";
import {
  FaHospital,
  FaUserMd,
  FaSchool,
  FaAddressCard,
  FaMoneyBillWave,
  FaCalendarAlt,
  FaVideo,
} from "react-icons/fa";
import { ImageWithFallback } from "../../components/CommonComponents/CommonFunction";
// const ImageWithFallback = ({ src, alt, defaultSrc, style }) => {
//   const handleImageError = (e) => {
//     e.target.src = defaultSrc; // Set the default image on error
//   };

//   return (
//     <img
//       src={src}
//       alt={alt}
//       onError={handleImageError}
//       style={style} // Example styling
//     />
//   );
// };
export default function DoctorTeamTwo() {
  const [searchQuery, setSearchQuery] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(1);
  const [bookingType, setBookingType] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showLoginSignUpModal, setShowLoginSignUpModal] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const storedUserRole = localStorage.getItem("userRole");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [totalDoctors, setTotalDoctors] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  const handleVideoConsultationClick = (doctor) => {
    if (!userRole) {
      // setBookingType("online");
      navigate(`/appointmentlogin/${doctor.id}`, {
        state: {
          bookingType: "online",
          selectedDoctor: doctor,
        },
      });
      // setShowLoginSignUpModal(true);
      // setSelectedDoctor(doctor);
      // setShowLoginPopup(true);
    } else {
      setSelectedDoctor(doctor);
      setModalShow(true);
      setBookingType("online");
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchTerms = queryParams.getAll("search");
    setSearchQuery(searchTerms.join("&"));
  }, [location.search]);

  useEffect(() => {
    if (storedUserRole) {
      setUserRole(storedUserRole);
    }
  }, [storedUserRole]);

  const daysOfWeekOrder = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Helper function to get the next occurrence of a given day
  const getNextDay = (dayOfWeek) => {
    const today = new Date();
    const dayIndex = daysOfWeekOrder.indexOf(dayOfWeek);
    const todayIndex = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const daysUntilNext = (dayIndex - todayIndex + 7) % 7;
    return addDays(today, daysUntilNext);
  };

  useEffect(() => {
    async function fetchDoctors() {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/getdoctorsdata/doctorslist/?page=${currentPage}&page_size=${pageSize}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setAllDoctors(data.results);
        setDoctors(data.results);
        setTotalPages(Math.ceil(data.count / pageSize));
        setTotalDoctors(data.count);
        setError(null);
      } catch (error) {
        console.error("Error fetching doctors:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchDoctors();
  }, [currentPage, pageSize]);
  const getFirstAvailability = (timetables) => {
    if (!timetables || timetables.length === 0) return null;

    const today = new Date();
    const todayDayIndex = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const daysOfWeekOrder = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Find the next available day from today's date
    for (let i = 1; i <= 7; i++) {
      const nextDayIndex = (todayDayIndex + i) % 7;
      const nextDayName = daysOfWeekOrder[nextDayIndex];

      // Check if there's a timetable for this day
      const nextDayTimetable = timetables.find(
        (t) => t.day_of_week === nextDayName
      );

      if (nextDayTimetable) {
        const nextAvailableDate = addDays(today, i);
        return format(nextAvailableDate, "dd-MMM-yyyy").toUpperCase(); // Return the next available date
      }
    }

    return null; // No availability found
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setSearchResults([]);
      setDoctors(allDoctors);
    } else {
      searchDoctors(searchQuery);
    }
  }, [searchQuery, allDoctors]);

  async function searchDoctors(query) {
    if (!query) {
      setDoctors(allDoctors); // Show all doctors when search query is empty
      return;
    }
    const splitArrQuery = query.split("&") || [];

    try {
      setLoading(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/doctors/search/list/?search=${encodeURIComponent(
          splitArrQuery && splitArrQuery?.length > 1 ? splitArrQuery[0] : query
        )}${
          splitArrQuery && splitArrQuery?.length > 1
            ? `&search=${encodeURIComponent(splitArrQuery[1])}`
            : ``
        }`
      );
      if (!response.ok) {
        const errorData = await response.json();
        console.log("errorData", errorData);
        const errorMessage = errorData?.detail || "Network response was not ok";
        throw new Error(errorMessage);
        // throw new Error("Network response was not ok");
        // l;
      }
      const data = await response.json();
      setSearchResults(data);
      setDoctors(data.length ? data : []);
      setError(null);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError(error.message);
      setDoctors([]);
    } finally {
      setLoading(false);
    }
  }

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1);
  };

  const renderStars = (averageRating) => {
    const totalStars = 5;
    const rating = Math.max(0, Math.min(5, Number(averageRating) || 0));
    const filledStars = Math.floor(rating);
    const halfStar = rating - filledStars >= 0.5 ? 1 : 0;
    const emptyStars = totalStars - filledStars - halfStar;

    const starElements = [];
    starElements.push(
      ...Array(filledStars).fill(
        <i
          className="mdi mdi-star text-warning"
          key={`filled-${starElements.length}`}
        ></i>
      )
    );
    if (halfStar) {
      starElements.push(
        <i
          className="mdi mdi-star-half text-warning"
          key={`half-${starElements.length}`}
        ></i>
      );
    }
    starElements.push(
      ...Array(emptyStars).fill(
        <i
          className="mdi mdi-star-outline text-warning"
          key={`empty-${starElements.length}`}
        ></i>
      )
    );

    return <>{starElements}</>;
  };

  const handleAppointmentClick = (doctor) => {
    if (!userRole) {
      // Navigate to the appointment login page and pass the booking type and selected doctor
      navigate(`/appointmentlogin/${doctor.id}`, {
        state: {
          bookingType: "clinic",
          selectedDoctor: doctor, // Pass the selected doctor
        },
      });
      // setSelectedDoctor(doctor);
      // setSelectedDoctorId(doctor.id);
      // setBookingType("clinic");
      // console.log(doctor, "selectedDoctorA");
    } else {
      setSelectedDoctor(doctor);
      setModalShow(true);
      setBookingType("clinic");
    }
  };

  const handleAppointmentButtonClick = () => {
    if (!userRole) {
      setShowLoginPopup(true);
    } else {
      setSelectedDoctor(null);
      setModalShow(true);
    }
  };

  const renderExperience = (experience) => {
    if (typeof experience === "string") {
      return experience;
    } else if (typeof experience === "object" && experience !== null) {
      return Object.values(experience)[0] || "N/A";
    }
    return "N/A";
  };

  // Define responsive styles
  const cardStyle = {
    display: "flex",
    flexDirection: windowWidth < 768 ? "column" : "row",
    width: "100%",
    padding: windowWidth < 768 ? "10px" : "15px",
    marginBottom: "0",
  };

  const imageStyle = {
    width: windowWidth < 768 ? "100px" : "150px",
    height: windowWidth < 768 ? "100px" : "150px",
    borderRadius: "50%",
    objectFit: "cover",
  };

  const bodyStyle = {
    flex: 1,
    display: "flex",
    flexDirection: windowWidth < 768 ? "column" : "row",
    alignItems: "stretch",
  };

  const sideBarStyle = {
    width: windowWidth < 768 ? "100%" : "300px",
    // marginLeft: windowWidth < 768 ? "0" : "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };
  const [triggerSearch, setTriggerSearch] = useState(false);
  useEffect(() => {
    if (triggerSearch) {
      // Perform search only if triggerSearch is true
      if (searchQuery.trim() === "") {
        setSearchResults([]);
        setDoctors(allDoctors); // Show all doctors when searchQuery is empty
      } else {
        searchDoctors(searchQuery);
      }
      // Reset trigger to false after searching
      setTriggerSearch(false);
    }
  }, [triggerSearch, searchQuery, allDoctors]);

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />

      {/* <section className="section"> */}
      <section
        className="section"
        style={{ marginTop: "20px", paddingTop: "30px" }}
      >
        <div className="container">
          <div className="search-bar mt-4 align-items-center">
            <SearchDoctor />
          </div>
          {/* <div className="search-bar mt-4 d-flex align-items-center">
            <input
              type="text"
              className="form-control"
              placeholder="Search doctors..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {/* <button
              className="btn btn-primary ms-2"
              onClick={() => setTriggerSearch(true)} // Trigger search on button click
            >
              Search
            </button> */}
          {/* <input
              type="text"
              className="form-control"
              placeholder="Search doctors..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            /> */}
          {/* </div> */}

          <div className="row align-items-center">
            {loading ? (
              <SkeletonLoader />
            ) : error ? (
              <div className="col-12 text-center">
                <p className="text-muted">Error: {error}</p>
              </div>
            ) : doctors.length > 0 ? (
              doctors.map((doctor) => (
                <div
                  className="col-12 col-md-6 col-lg-4 p-0 d-flex gap-1"
                  key={doctor.user}
                >
                  <div
                    className="card team border-0 rounded shadow overflow-hidden"
                    // style={cardStyle}
                  >
                    <div
                      className="team-person text-center p-1"
                      style={{ flexShrink: 0 }}
                    >
                      <ImageWithFallback
                        src={doctor?.image || dr1}
                        defaultSrc={dr1}
                        alt={doctor.first_name + " " + doctor.last_name}
                        style={imageStyle}
                      />
                    </div>
                    <div className="card-body">
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            marginBottom: "15px",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div className="title text-dark h5 d-block mb-0 text-center text-uppercase">
                            {doctor.first_name} {doctor.last_name}
                          </div>

                          <div className="text-center"></div>
                          <div>
                            <Link
                              to={`/doctor-profile/${doctor.id}`}
                              className="text-primary fw-bold"
                              style={{
                                textDecoration: "none",
                                fontSize: "0.7rem",
                              }}
                            >
                              View Full Profile
                            </Link>
                          </div>

                          <div className="d-flex align-items-center mt-2">
                            <ul className="list-unstyled mb-0 me-2">
                              {renderStars(doctor.average_rating)}
                            </ul>
                            <p className="text-muted mb-0 me-2">
                              {parseFloat(doctor.average_rating)
                                .toFixed(2)
                                .replace(/\.00$/, "")}{" "}
                              Star
                            </p>
                          </div>
                          <div>
                            <li className="d-flex mb-2 align-items-center">
                              <FaUserMd className="text-primary me-2" />
                              <small className="text-muted speciality">
                                {doctor.years_of_experience} Years Experience
                              </small>
                            </li>
                            <li className="d-flex mb-2 align-items-center">
                              <RiMapPinLine className="text-primary me-2" />
                              <small className="text-muted">
                                {doctor.location}
                              </small>
                            </li>
                            <li className="d-flex mb-2 align-items-center">
                              <FaSchool className="text-primary me-2" />
                              <small className="text-muted">
                                {doctor.medical_school}
                              </small>
                            </li>
                            <li className="d-flex mb-2 align-items-center">
                              <FaAddressCard className="text-primary me-2" />
                              <small className="text-muted">
                                {doctor.street_address}
                              </small>
                            </li>
                            <li className="d-flex mb-2 align-items-center">
                              <FaUserMd className="text-primary me-2" />
                              <small className="text-muted">
                                {doctor.specialization}
                              </small>
                            </li>
                            <li className="d-flex align-items-center ms-0 mt-2">
                              <FaMoneyBillWave className="text-primary me-2" />
                              <small className="text-muted">
                                Consultation Fees :
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#00008B",
                                  }}
                                >
                                  {" "}
                                  {doctor.charge} INR
                                </span>
                              </small>
                            </li>
                          </div>

                          <ul className="list-unstyled mt-2 mb-0"></ul>
                        </div>
                      </div>

                      <div
                        style={{
                          ...sideBarStyle,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <ul className="list-unstyled mb-3">
                          <li className="d-flex ms-0 mb-2">
                            {doctor.timetables &&
                            doctor.timetables.length > 0 ? (
                              <p>
                                <FaCalendarAlt className="text-primary me-2" />
                                <small className="text-muted ms-2">
                                  Doctor next available on:{" "}
                                </small>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    color: "#00008B",
                                  }}
                                >
                                  {getFirstAvailability(doctor.timetables)}
                                </span>
                              </p>
                            ) : (
                              <p>
                                <FaCalendarAlt className="text-primary me-2" />
                                <small className="text-muted ms-2">
                                  Currently, the doctor has no timetable.
                                </small>
                              </p>
                            )}
                          </li>
                          <div className="text-center">
                            <div className="align-items-center d-flex flex-column">
                              <button
                                className="btn btn-primary btn-3d mt-2"
                                style={{ width: "280px" }}
                                onClick={() => handleAppointmentClick(doctor)}
                              >
                                <FaPersonWalking size={25} /> In-Hospital
                                Consultation
                              </button>
                              <button
                                className="btn btn-primary btn-3d mt-2"
                                style={{
                                  width: "280px",
                                  fontSize: "0.9rem",
                                  padding: "0.5rem 1rem",
                                }}
                                onClick={() =>
                                  handleVideoConsultationClick(doctor)
                                }
                              >
                                <FaVideo size={25} /> Video Consultation
                              </button>
                            </div>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">
                <p className="text-muted">No doctors found</p>
              </div>
            )}
          </div>
          {doctors.length > 0 && (
            <div className="text-center mb-3">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                pageSize={pageSize}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
              />
              <p className="mt-2 mb-0">
                Showing {doctors.length} of {totalDoctors} doctors
              </p>
            </div>
          )}

          {modalShow && Object.values(selectedDoctor)?.length > 0 && (
            <BookingAppointment
              show={modalShow}
              handleClose={() => setModalShow(false)}
              selectedDoctor={selectedDoctor}
              bookingType={bookingType}
            />
          )}

          <MobileLogin
            show={showLoginPopup}
            handleClose={() => setShowLoginPopup(false)}
            bookingType={bookingType}
            selectedDoctor={selectedDoctor}
          />
          <LoginSignUp
            showLoginSignUpModal={showLoginSignUpModal}
            setShowLoginSignUpModal={setShowLoginSignUpModal}
            selectedDoctor={selectedDoctor}
          />
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
}
