// import React from 'react';
// import { useParams, Link } from 'react-router-dom';
// import { doctorData } from '../../data/data';
// import Navbar from '../../components/navbar';
// import Footer from '../../components/footer';
// import ScrollTop from '../../components/scrollTop';
// import { FiHeart, RiMapPinLine, RiTimeLine, RiMoneyDollarCircleLine, FiFacebook, FiLinkedin, FiGithub, FiTwitter } from '../../assets/icons/vander';

// const DoctorDetails = () => {
//     const { id } = useParams();
//     const doctor = doctorData.find(doctor => doctor.id === parseInt(id));

//     if (!doctor) {
//         return <div>Doctor not found</div>;
//     }

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />

//             <section className="bg-half-150 bg-light d-table w-100">
//                 <div className="container">
//                     <div className="row mt-5 justify-content-center">
//                         <div className="col-12">
//                             <div className="section-title text-center">
//                                 <h3 className="sub-title mb-4">{doctor.name}</h3>
//                                 <p className="para-desc mx-auto text-muted">{doctor.speciality}</p>
//                                 <nav aria-label="breadcrumb" className="d-inline-block mt-3">
//                                     <ul className="breadcrumb bg-transparent mb-0">
//                                         <li className="breadcrumb-item"><Link to="/">Home</Link></li>
//                                         <li className="breadcrumb-item"><Link to="/doctor-team-two">Doctors</Link></li>
//                                         <li className="breadcrumb-item active" aria-current="page">{doctor.name}</li>
//                                     </ul>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <section className="section">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="card team border-0 rounded shadow overflow-hidden">
//                                 <div className="team-person position-relative overflow-hidden">
//                                     <img src={doctor.image} className="img-fluid" alt="" />
//                                     <ul className="list-unstyled team-like">
//                                         <li><Link to="#" className="btn btn-icon btn-pills btn-soft-danger"><FiHeart className="icons" /></Link></li>
//                                     </ul>
//                                 </div>
//                                 <div className="card-body">
//                                     <h5 className="title text-dark mb-0">{doctor.name}</h5>
//                                     <small className="text-muted speciality">{doctor.speciality}</small>
//                                     <div className="d-flex justify-content-between align-items-center mt-2">
//                                         <ul className="list-unstyled mb-0">
//                                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                         </ul>
//                                         <p className="text-muted mb-0">5 Star</p>
//                                     </div>
//                                     <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="d-flex ms-0">
//                                             <RiMapPinLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.location}</small>
//                                         </li>
//                                         <li className="d-flex ms-0 mt-2">
//                                             <RiTimeLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.time}</small>
//                                         </li>
//                                         <li className="d-flex ms-0 mt-2">
//                                             <RiMoneyDollarCircleLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.charges}</small>
//                                         </li>
//                                     </ul>
//                                     <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="list-inline-item"><Link to={doctor.facebook} className="btn btn-icon btn-pills btn-soft-primary"><FiFacebook className="icons" /></Link></li>
//                                         <li className="mt-2 list-inline-item"><Link to={doctor.linkedin} className="btn btn-icon btn-pills btn-soft-primary"><FiLinkedin className="icons" /></Link></li>
//                                         <li className="mt-2 list-inline-item"><Link to={doctor.github} className="btn btn-icon btn-pills btn-soft-primary"><FiGithub className="icons" /></Link></li>
//                                         <li className="mt-2 list-inline-item"><Link to={doctor.twitter} className="btn btn-icon btn-pills btn-soft-primary"><FiTwitter className="icons" /></Link></li>
//                                     </ul>
//                                     {/* <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiFacebook className="icons" /></Link></li>
//                                         <li className="mt-2 list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiLinkedin className="icons" /></Link></li>
//                                         <li className="mt-2 list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiGithub className="icons" /></Link></li>
//                                         <li className="mt-2 list-inline-item"><Link to="#" className="btn btn-icon btn-pills btn-soft-primary"><FiTwitter className="icons" /></Link></li>
//                                     </ul> */}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <Footer />
//             <ScrollTop />
//         </>
//     );
// };

// export default DoctorDetails;

// import React from 'react';
// import { useParams, Link } from 'react-router-dom';
// import { doctorData } from '../../data/data';
// import Navbar from '../../components/navbar';
// import Footer from '../../components/footer';
// import ScrollTop from '../../components/scrollTop';
// import { FiHeart, RiMapPinLine, RiTimeLine, RiMoneyDollarCircleLine, FiFacebook, FiLinkedin, FiGithub, FiTwitter } from '../../assets/icons/vander';

// const DoctorDetails = () => {
//     const { id } = useParams();
//     const doctor = doctorData.find(doctor => doctor.id === parseInt(id));

//     if (!doctor) {
//         return <div>Doctor not found</div>;
//     }

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />

//             <section className="bg-half-150 bg-light d-table w-100">
//                 <div className="container">
//                     <div className="row mt-5 justify-content-center">
//                         <div className="col-12">
//                             <div className="section-title text-center">
//                                 <h3 className="sub-title mb-4">{doctor.name}</h3>
//                                 <p className="para-desc mx-auto text-muted">{doctor.speciality}</p>
//                                 <nav aria-label="breadcrumb" className="d-inline-block mt-3">
//                                     <ul className="breadcrumb bg-transparent mb-0">
//                                         <li className="breadcrumb-item"><Link to="/">Home</Link></li>
//                                         <li className="breadcrumb-item"><Link to="/doctor-team-two">Doctors</Link></li>
//                                         <li className="breadcrumb-item active" aria-current="page">{doctor.name}</li>
//                                     </ul>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <section className="section">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="card team border-0 rounded shadow overflow-hidden">
//                                 <div className="team-person position-relative overflow-hidden">
//                                     <img src={doctor.image} className="img-fluid" alt="" />
//                                     <ul className="list-unstyled team-like">
//                                         <li><Link to="#" className="btn btn-icon btn-pills btn-soft-danger"><FiHeart className="icons" /></Link></li>
//                                     </ul>
//                                 </div>
//                                 <div className="card-body">
//                                     <h5 className="title text-dark mb-0">{doctor.name}</h5>
//                                     <small className="text-muted speciality">{doctor.speciality}</small>
//                                     <div className="d-flex justify-content-between align-items-center mt-2">
//                                         <ul className="list-unstyled mb-0">
//                                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                             <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
//                                         </ul>
//                                         <p className="text-muted mb-0">5 Star</p>
//                                     </div>
//                                     <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="d-flex ms-0">
//                                             <RiMapPinLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.location}</small>
//                                         </li>
//                                         <li className="d-flex ms-0 mt-2">
//                                             <RiTimeLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.time}</small>
//                                         </li>
//                                         <li className="d-flex ms-0 mt-2">
//                                             <RiMoneyDollarCircleLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.charges}</small>
//                                         </li>
//                                     </ul>
//                                     <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="list-inline-item"><Link to={doctor.facebook} className="btn btn-icon btn-pills btn-soft-primary"><FiFacebook className="icons" /></Link></li>
//                                         <li className="mt-2 list-inline-item"><Link to={doctor.linkedin} className="btn btn-icon btn-pills btn-soft-primary"><FiLinkedin className="icons" /></Link></li>
//                                         <li className="mt-2 list-inline-item"><Link to={doctor.github} className="btn btn-icon btn-pills btn-soft-primary"><FiGithub className="icons" /></Link></li>
//                                         <li className="mt-2 list-inline-item"><Link to={doctor.twitter} className="btn btn-icon btn-pills btn-soft-primary"><FiTwitter className="icons" /></Link></li>
//                                     </ul>
//                                     <div className="mt-4">
//                                         <h5 className="title text-dark mb-3">About {doctor.name}</h5>
//                                         <p className="text-muted">
//                                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris.
//                                         </p>
//                                         <p className="text-muted">
//                                             Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur sodales ligula in libero.
//                                         </p>
//                                         <p className="text-muted">
//                                             Sed dignissim lacinia nunc. Curabitur tortor. Pellentesque nibh. Aenean quam. In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor. Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa.
//                                         </p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <Footer />
//             <ScrollTop />
//         </>
//     );
// };

// export default DoctorDetails;

// import React, { useState, useEffect } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import Navbar from '../../components/navbar';
// import Footer from '../../components/footer';
// import ScrollTop from '../../components/scrollTop';
// import { FiHeart, RiMapPinLine, RiTimeLine, RiMoneyDollarCircleLine, FiFacebook, FiLinkedin, FiGithub, FiTwitter } from '../../assets/icons/vander';

// const DoctorDetails = () => {
//     const { id } = useParams();
//     const [doctor, setDoctor] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         async function fetchDoctor() {
//             try {
//                 const response = await fetch(`https://doctor-finder-backend.onrender.com/doctors/${id}/`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();
//                 setDoctor(data);
//             } catch (error) {
//                 setError(error.message);
//             } finally {
//                 setLoading(false);
//             }
//         }

//         fetchDoctor();
//     }, [id]);

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     if (!doctor) {
//         return <div>Doctor not found</div>;
//     }

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />

//             <section className="bg-half-150 bg-light d-table w-100">
//                 <div className="container">
//                     <div className="row mt-5 justify-content-center">
//                         <div className="col-12">
//                             <div className="section-title text-center">
//                                 <h3 className="sub-title mb-4">{doctor.name}</h3>
//                                 <p className="para-desc mx-auto text-muted">{doctor.specialization}</p>
//                                 <nav aria-label="breadcrumb" className="d-inline-block mt-3">
//                                     <ul className="breadcrumb bg-transparent mb-0">
//                                         <li className="breadcrumb-item"><Link to="/">Home</Link></li>
//                                         <li className="breadcrumb-item"><Link to="/doctor-team-two">Doctors</Link></li>
//                                         <li className="breadcrumb-item active" aria-current="page">{doctor.name}</li>
//                                     </ul>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <section className="section">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="card team border-0 rounded shadow overflow-hidden">
//                                 <div className="team-person position-relative overflow-hidden">
//                                     <img
//                                         src={doctor.image || 'https://via.placeholder.com/150'} // Default image if no image is provided
//                                         className="img-fluid"
//                                         alt={doctor.name}
//                                     />
//                                     <ul className="list-unstyled team-like">
//                                         <li>
//                                             <Link to="#" className="btn btn-icon btn-pills btn-soft-danger">
//                                                 <FiHeart className="icons" />
//                                             </Link>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="card-body">
//                                     <h5 className="title text-dark mb-0">{doctor.name}</h5>
//                                     <small className="text-muted speciality">{doctor.specialization}</small>
//                                     <div className="d-flex justify-content-between align-items-center mt-2">
//                                         <ul className="list-unstyled mb-0">
//                                             <li className="list-inline-item">
//                                                 <i className="mdi mdi-star text-warning"></i>
//                                             </li>
//                                             <li className="list-inline-item">
//                                                 <i className="mdi mdi-star text-warning"></i>
//                                             </li>
//                                             <li className="list-inline-item">
//                                                 <i className="mdi mdi-star text-warning"></i>
//                                             </li>
//                                             <li className="list-inline-item">
//                                                 <i className="mdi mdi-star text-warning"></i>
//                                             </li>
//                                             <li className="list-inline-item">
//                                                 <i className="mdi mdi-star text-warning"></i>
//                                             </li>
//                                         </ul>
//                                         <p className="text-muted mb-0">5 Star</p>
//                                     </div>
//                                     <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="d-flex ms-0">
//                                             <RiMapPinLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.location}</small>
//                                         </li>
//                                         <li className="d-flex ms-0 mt-2">
//                                             <RiTimeLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.contact_number}</small>
//                                         </li>
//                                         <li className="d-flex ms-0 mt-2">
//                                             <RiMoneyDollarCircleLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.charge} INR</small>
//                                         </li>
//                                     </ul>
//                                     <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={`mailto:${doctor.email}`} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <i className="mdi mdi-email text-primary"></i>
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.facebook} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiFacebook className="icons" />
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.linkedin} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiLinkedin className="icons" />
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.github} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiGithub className="icons" />
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.twitter} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiTwitter className="icons" />
//                                             </a>
//                                         </li>
//                                     </ul>
//                                     <div className="mt-4">
//                                         <h5 className="title text-dark mb-3">About {doctor.name}</h5>
//                                         <p className="text-muted">
//                                             {doctor.description || 'No description available.'}
//                                         </p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <Footer />
//             <ScrollTop />
//         </>
//     );
// };

// export default DoctorDetails;

// import React, { useState, useEffect } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import Navbar from '../../components/navbar';
// import Footer from '../../components/footer';
// import ScrollTop from '../../components/scrollTop';
// import { FiHeart, RiMapPinLine, RiTimeLine, RiMoneyDollarCircleLine, FiFacebook, FiLinkedin, FiGithub, FiTwitter } from '../../assets/icons/vander';

// const DoctorDetails = () => {
//     const { id } = useParams();
//     const [doctor, setDoctor] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [isEditing, setIsEditing] = useState(false);
//     const [updatedDoctor, setUpdatedDoctor] = useState(null);

//     useEffect(() => {
//         async function fetchDoctor() {
//             try {
//                 const response = await fetch(`https://doctor-finder-backend.onrender.com/doctors/${id}/`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();
//                 setDoctor(data);
//                 setUpdatedDoctor(data);  // Initialize updatedDoctor with the fetched data
//             } catch (error) {
//                 setError(error.message);
//             } finally {
//                 setLoading(false);
//             }
//         }

//         fetchDoctor();
//     }, [id]);

//     const handleEdit = () => {
//         setIsEditing(true);
//     };
//     const handleSave = async () => {
//         try {
//             // Exclude the image field from the payload
//             const { image, ...payload } = updatedDoctor;

//             const response = await fetch(`https://doctor-finder-backend.onrender.com/doctors/update/${id}/`, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(payload),
//             });

//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             const data = await response.json();
//             setDoctor(data);
//             setIsEditing(false);
//         } catch (error) {
//             setError(error.message);
//         }
//     };
//     // const handleSave = async () => {
//     //     try {
//     //         const response = await fetch(`https://doctor-finder-backend.onrender.com/doctors/${id}/`, {
//     //             method: 'PUT',
//     //             headers: {
//     //                 'Content-Type': 'application/json',
//     //             },
//     //             body: JSON.stringify(updatedDoctor),
//     //         });
//     //         if (!response.ok) {
//     //             throw new Error('Network response was not ok');
//     //         }
//     //         const data = await response.json();
//     //         setDoctor(data);
//     //         setIsEditing(false);
//     //     } catch (error) {
//     //         setError(error.message);
//     //     }
//     // };

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     if (!doctor) {
//         return <div>Doctor not found</div>;
//     }

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />

//             <section className="bg-half-150 bg-light d-table w-100">
//                 <div className="container">
//                     <div className="row mt-5 justify-content-center">
//                         <div className="col-12">
//                             <div className="section-title text-center">
//                                 <h3 className="sub-title mb-4">{doctor.name}</h3>
//                                 <p className="para-desc mx-auto text-muted">{doctor.specialization}</p>
//                                 <nav aria-label="breadcrumb" className="d-inline-block mt-3">
//                                     <ul className="breadcrumb bg-transparent mb-0">
//                                         <li className="breadcrumb-item"><Link to="/">Home</Link></li>
//                                         <li className="breadcrumb-item"><Link to="/doctor-team-two">Doctors</Link></li>
//                                         <li className="breadcrumb-item active" aria-current="page">{doctor.name}</li>
//                                     </ul>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <section className="section">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="card team border-0 rounded shadow overflow-hidden">
//                                 <div className="team-person position-relative overflow-hidden">
//                                     <img
//                                         src={doctor.image || 'https://via.placeholder.com/150'}
//                                         className="img-fluid"
//                                         alt={doctor.name}
//                                     />
//                                     <ul className="list-unstyled team-like">
//                                         <li>
//                                             <Link to="#" className="btn btn-icon btn-pills btn-soft-danger">
//                                                 <FiHeart className="icons" />
//                                             </Link>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="card-body">
//                                     {isEditing ? (
//                                         <div>
//                                             <input
//                                                 type="text"
//                                                 value={updatedDoctor.name}
//                                                 onChange={(e) => setUpdatedDoctor({ ...updatedDoctor, name: e.target.value })}
//                                             />
//                                             <input
//                                                 type="text"
//                                                 value={updatedDoctor.specialization}
//                                                 onChange={(e) => setUpdatedDoctor({ ...updatedDoctor, specialization: e.target.value })}
//                                             />
//                                             <input
//                                                 type="text"
//                                                 value={updatedDoctor.location}
//                                                 onChange={(e) => setUpdatedDoctor({ ...updatedDoctor, location: e.target.value })}
//                                             />
//                                             <input
//                                                 type="text"
//                                                 value={updatedDoctor.contact_number}
//                                                 onChange={(e) => setUpdatedDoctor({ ...updatedDoctor, contact_number: e.target.value })}
//                                             />
//                                             <input
//                                                 type="text"
//                                                 value={updatedDoctor.charge}
//                                                 onChange={(e) => setUpdatedDoctor({ ...updatedDoctor, charge: e.target.value })}
//                                             />
//                                             <textarea
//                                                 value={updatedDoctor.description}
//                                                 onChange={(e) => setUpdatedDoctor({ ...updatedDoctor, description: e.target.value })}
//                                             />
//                                             <button onClick={handleSave} className="btn btn-primary">
//                                                 Save
//                                             </button>
//                                         </div>
//                                     ) : (
//                                         <div>
//                                             <h5 className="title text-dark mb-0">{doctor.name}</h5>
//                                             <small className="text-muted speciality">{doctor.specialization}</small>
//                                             <div className="d-flex justify-content-between align-items-center mt-2">
//                                                 <ul className="list-unstyled mb-0">
//                                                     <li className="list-inline-item">
//                                                         <i className="mdi mdi-star text-warning"></i>
//                                                     </li>
//                                                     <li className="list-inline-item">
//                                                         <i className="mdi mdi-star text-warning"></i>
//                                                     </li>
//                                                     <li className="list-inline-item">
//                                                         <i className="mdi mdi-star text-warning"></i>
//                                                     </li>
//                                                     <li className="list-inline-item">
//                                                         <i className="mdi mdi-star text-warning"></i>
//                                                     </li>
//                                                     <li className="list-inline-item">
//                                                         <i className="mdi mdi-star text-warning"></i>
//                                                     </li>
//                                                 </ul>
//                                                 <p className="text-muted mb-0">5 Star</p>
//                                             </div>
//                                             <ul className="list-unstyled mt-2 mb-0">
//                                                 <li className="d-flex ms-0">
//                                                     <RiMapPinLine className="text-primary align-middle" />
//                                                     <small className="text-muted ms-2">{doctor.location}</small>
//                                                 </li>
//                                                 <li className="d-flex ms-0 mt-2">
//                                                     <RiTimeLine className="text-primary align-middle" />
//                                                     <small className="text-muted ms-2">{doctor.contact_number}</small>
//                                                 </li>
//                                                 <li className="d-flex ms-0 mt-2">
//                                                     <RiMoneyDollarCircleLine className="text-primary align-middle" />
//                                                     <small className="text-muted ms-2">{doctor.charge} INR</small>
//                                                 </li>
//                                             </ul>
//                                             <ul className="list-unstyled mt-2 mb-0">
//                                                 <li className="mt-2 list-inline-item">
//                                                     <a href={`mailto:${doctor.email}`} className="btn btn-icon btn-pills btn-soft-primary">
//                                                         <i className="mdi mdi-email text-primary"></i>
//                                                     </a>
//                                                 </li>
//                                                 <li className="mt-2 list-inline-item">
//                                                     <a href={doctor.facebook} className="btn btn-icon btn-pills btn-soft-primary">
//                                                         <FiFacebook className="icons" />
//                                                     </a>
//                                                 </li>
//                                                 <li className="mt-2 list-inline-item">
//                                                     <a href={doctor.linkedin} className="btn btn-icon btn-pills btn-soft-primary">
//                                                         <FiLinkedin className="icons" />
//                                                     </a>
//                                                 </li>
//                                                 <li className="mt-2 list-inline-item">
//                                                     <a href={doctor.github} className="btn btn-icon btn-pills btn-soft-primary">
//                                                         <FiGithub className="icons" />
//                                                     </a>
//                                                 </li>
//                                                 <li className="mt-2 list-inline-item">
//                                                     <a href={doctor.twitter} className="btn btn-icon btn-pills btn-soft-primary">
//                                                         <FiTwitter className="icons" />
//                                                     </a>
//                                                 </li>
//                                             </ul>
//                                             <div className="mt-4">
//                                                 <h5 className="title text-dark mb-3">About {doctor.name}</h5>
//                                                 <p className="text-muted">
//                                                     {doctor.description || 'No description available.'}
//                                                 </p>
//                                             </div>
//                                             <button onClick={handleEdit} className="btn btn-primary">
//                                                 Edit Details
//                                             </button>
//                                         </div>
//                                     )}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <Footer />
//             <ScrollTop />
//         </>
//     );
// };

// export default DoctorDetails;

// import React, { useState, useEffect } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import Navbar from '../../components/navbar';
// import Footer from '../../components/footer';
// import ScrollTop from '../../components/scrollTop';
// import { FiHeart, RiMapPinLine, RiTimeLine, RiMoneyDollarCircleLine, FiFacebook, FiLinkedin, FiGithub, FiTwitter } from '../../assets/icons/vander';

// const DoctorDetails = () => {
//     const { id } = useParams();
//     const [doctor, setDoctor] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [isEditing, setIsEditing] = useState(false);
//     const [updatedDoctor, setUpdatedDoctor] = useState(null);

//     useEffect(() => {
//         async function fetchDoctor() {
//             try {
//                 const response = await fetch(`https://doctor-finder-backend.onrender.com/doctors/${id}/`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();
//                 setDoctor(data);
//                 console.log(data);
//                 setUpdatedDoctor(data);  // Initialize updatedDoctor with the fetched data
//             } catch (error) {
//                 setError(error.message);
//             } finally {
//                 setLoading(false);
//             }
//         }

//         fetchDoctor();
//     }, [id]);

//     const handleEdit = () => {
//         setIsEditing(true);
//     };

//     const handleSave = async () => {
//         try {
//             // Exclude the image field from the payload
//             const { image, ...payload } = updatedDoctor;

//             const response = await fetch(`https://doctor-finder-backend.onrender.com/doctors/${id}/`, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(payload),
//             });

//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             const data = await response.json();
//             setDoctor(data);
//             setIsEditing(false);
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     if (loading) {
//         return <div>Loading...</div>;
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     if (!doctor) {
//         return <div>Doctor not found</div>;
//     }

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />

//             <section className="bg-half-150 bg-light d-table w-100">
//                 <div className="container">
//                     <div className="row mt-5 justify-content-center">
//                         <div className="col-12">
//                             <div className="section-title text-center">
//                                 <h3 className="sub-title mb-4">{doctor.name}</h3>
//                                 <p className="para-desc mx-auto text-muted">{doctor.specialization}</p>
//                                 <nav aria-label="breadcrumb" className="d-inline-block mt-3">
//                                     <ul className="breadcrumb bg-transparent mb-0">
//                                         <li className="breadcrumb-item"><Link to="/">Home</Link></li>
//                                         <li className="breadcrumb-item"><Link to="/doctor-team-two">Doctors</Link></li>
//                                         <li className="breadcrumb-item active" aria-current="page">{doctor.name}</li>
//                                     </ul>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <section className="section">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">

//                                 <div className="card team border-0 rounded shadow overflow-hidden">
//                                     <div className="team-person position-relative overflow-hidden">
//                                         <img
//                                             src={doctor.image || 'https://via.placeholder.com/150'}
//                                             className="img-fluid"
//                                             alt={doctor.name}
//                                         />
//                                         <ul className="list-unstyled team-like">
//                                             <li>
//                                                 <Link to="#" className="btn btn-icon btn-pills btn-soft-danger">
//                                                     <FiHeart className="icons" />
//                                                 </Link>
//                                             </li>
//                                         </ul>
//                                     </div>
//                                     <div className="card-body">
//                                         <h5 className="title text-dark mb-0">{doctor.name}</h5>
//                                         <small className="text-muted speciality">{doctor.specialization}</small>
//                                         <div className="d-flex justify-content-between align-items-center mt-2">
//                                             <ul className="list-unstyled mb-0">
//                                                 <li className="list-inline-item">
//                                                     <i className="mdi mdi-star text-warning"></i>
//                                                 </li>
//                                                 <li className="list-inline-item">
//                                                     <i className="mdi mdi-star text-warning"></i>
//                                                 </li>
//                                                 <li className="list-inline-item">
//                                                     <i className="mdi mdi-star text-warning"></i>
//                                                 </li>
//                                                 <li className="list-inline-item">
//                                                     <i className="mdi mdi-star text-warning"></i>
//                                                 </li>
//                                                 <li className="list-inline-item">
//                                                     <i className="mdi mdi-star text-warning"></i>
//                                                 </li>
//                                             </ul>
//                                             <p className="text-muted mb-0">5 Star</p>
//                                         </div>
//                                         <ul className="list-unstyled mt-2 mb-0">
//                                             <li className="d-flex ms-0">
//                                                 <RiMapPinLine className="text-primary align-middle" />
//                                                 <small className="text-muted ms-2">{doctor.location}</small>
//                                             </li>
//                                             <li className="d-flex ms-0 mt-2">
//                                                 <RiTimeLine className="text-primary align-middle" />
//                                                 <small className="text-muted ms-2">{doctor.contact_number}</small>
//                                             </li>
//                                             <li className="d-flex ms-0 mt-2">
//                                                 <RiMoneyDollarCircleLine className="text-primary align-middle" />
//                                                 <small className="text-muted ms-2">{doctor.charge} INR</small>
//                                             </li>
//                                         </ul>
//                                         <ul className="list-unstyled mt-2 mb-0">
//                                             <li className="mt-2 list-inline-item">
//                                                 <a href={`mailto:${doctor.email}`} className="btn btn-icon btn-pills btn-soft-primary">
//                                                     <i className="mdi mdi-email text-primary"></i>
//                                                 </a>
//                                             </li>
//                                             <li className="mt-2 list-inline-item">
//                                                 <a href={doctor.facebook} className="btn btn-icon btn-pills btn-soft-primary">
//                                                     <FiFacebook className="icons" />
//                                                 </a>
//                                             </li>
//                                             <li className="mt-2 list-inline-item">
//                                                 <a href={doctor.linkedin} className="btn btn-icon btn-pills btn-soft-primary">
//                                                     <FiLinkedin className="icons" />
//                                                 </a>
//                                             </li>
//                                             <li className="mt-2 list-inline-item">
//                                                 <a href={doctor.github} className="btn btn-icon btn-pills btn-soft-primary">
//                                                     <FiGithub className="icons" />
//                                                 </a>
//                                             </li>
//                                             <li className="mt-2 list-inline-item">
//                                                 <a href={doctor.twitter} className="btn btn-icon btn-pills btn-soft-primary">
//                                                     <FiTwitter className="icons" />
//                                                 </a>
//                                             </li>
//                                         </ul>
//                                         <div className="mt-4">
//                                             <h5 className="title text-dark mb-3">About {doctor.name}</h5>
//                                             <p className="text-muted">
//                                                 {doctor.description || 'No description available.'}
//                                             </p>
//                                         </div>
//                                         {/* <button
//                                             type="button"
//                                             onClick={handleEdit}
//                                             className="btn btn-primary mt-4"
//                                         >
//                                             Edit Details
//                                         </button> */}
//                                     </div>
//                                 </div>

//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <Footer />
//             <ScrollTop />
//         </>
//     );
// };

// export default DoctorDetails;

// import React, { useState, useEffect } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import Navbar from '../../components/navbar';
// import Footer from '../../components/footer';
// import ScrollTop from '../../components/scrollTop';
// import { FiHeart, RiMapPinLine, RiTimeLine, RiMoneyDollarCircleLine, FiFacebook, FiLinkedin, FiGithub, FiTwitter } from '../../assets/icons/vander';
// import logoIcon from '../../assets/images/logo-icon.png';  // Import the logo icon

// const DoctorDetails = () => {
//     const { id } = useParams();
//     const [doctor, setDoctor] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [isEditing, setIsEditing] = useState(false);
//     const [updatedDoctor, setUpdatedDoctor] = useState(null);

//     useEffect(() => {
//         async function fetchDoctor() {
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/doctors/${id}/`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();
//                 setDoctor(data);
//                 setUpdatedDoctor(data);  // Initialize updatedDoctor with the fetched data
//             } catch (error) {
//                 setError(error.message);
//             } finally {
//                 setLoading(false);
//             }
//         }

//         fetchDoctor();
//     }, [id]);

//     const handleEdit = () => {
//         setIsEditing(true);
//     };

//     const handleSave = async () => {
//         try {
//             // Exclude the image field from the payload
//             const { image, ...payload } = updatedDoctor;

//             const response = await fetch(`https://doctor-finder-backend.onrender.com/doctors/${id}/`, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(payload),
//             });

//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }

//             const data = await response.json();
//             setDoctor(data);
//             setIsEditing(false);
//         } catch (error) {
//             setError(error.message);
//         }
//     };

//     if (loading) {
//         return (
//             <div style={styles.loaderOverlay}>
//                 <img src={logoIcon} height="50" alt="Logo Icon" style={styles.blinkingLogo} />
//             </div>
//         );
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     if (!doctor) {
//         return <div>Doctor not found</div>;
//     }

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />

//             <section className="bg-half-150 bg-light d-table w-100">
//                 <div className="container">
//                     <div className="row mt-5 justify-content-center">
//                         <div className="col-12">
//                             <div className="section-title text-center">
//                                 <h3 className="sub-title mb-4">{doctor.name}</h3>
//                                 <p className="para-desc mx-auto text-muted">{doctor.specialization}</p>
//                                 <nav aria-label="breadcrumb" className="d-inline-block mt-3">
//                                     <ul className="breadcrumb bg-transparent mb-0">
//                                         <li className="breadcrumb-item"><Link to="/">Home</Link></li>
//                                         <li className="breadcrumb-item"><Link to="/doctor-team-two">Doctors</Link></li>
//                                         <li className="breadcrumb-item active" aria-current="page">{doctor.name}</li>
//                                     </ul>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <section className="section">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="card team border-0 rounded shadow overflow-hidden">
//                                 <div className="team-person position-relative overflow-hidden">
//                                     <img
//                                         src={doctor.image || 'https://via.placeholder.com/150'}
//                                         className="img-fluid"
//                                         alt={doctor.name}
//                                     />
//                                     <ul className="list-unstyled team-like">
//                                         <li>
//                                             <Link to="#" className="btn btn-icon btn-pills btn-soft-danger">
//                                                 <FiHeart className="icons" />
//                                             </Link>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="card-body">
//                                     <h5 className="title text-dark mb-0">{doctor.name}</h5>
//                                     <small className="text-muted speciality">{doctor.specialization}</small>
//                                     <div className="d-flex justify-content-between align-items-center mt-2">
//                                         <ul className="list-unstyled mb-0">
//                                             <li className="list-inline-item">
//                                                 <i className="mdi mdi-star text-warning"></i>
//                                             </li>
//                                             <li className="list-inline-item">
//                                                 <i className="mdi mdi-star text-warning"></i>
//                                             </li>
//                                             <li className="list-inline-item">
//                                                 <i className="mdi mdi-star text-warning"></i>
//                                             </li>
//                                             <li className="list-inline-item">
//                                                 <i className="mdi mdi-star text-warning"></i>
//                                             </li>
//                                             <li className="list-inline-item">
//                                                 <i className="mdi mdi-star text-warning"></i>
//                                             </li>
//                                         </ul>
//                                         <p className="text-muted mb-0">5 Star</p>
//                                     </div>
//                                     <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="d-flex ms-0">
//                                             <RiMapPinLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.location}</small>
//                                         </li>
//                                         <li className="d-flex ms-0 mt-2">
//                                             <RiTimeLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.contact_number}</small>
//                                         </li>
//                                         <li className="d-flex ms-0 mt-2">
//                                             <RiMoneyDollarCircleLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.charge} INR</small>
//                                         </li>
//                                     </ul>
//                                     <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={`mailto:${doctor.email}`} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <i className="mdi mdi-email text-primary"></i>
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.facebook} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiFacebook className="icons" />
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.linkedin} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiLinkedin className="icons" />
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.github} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiGithub className="icons" />
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.twitter} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiTwitter className="icons" />
//                                             </a>
//                                         </li>
//                                     </ul>
//                                     <div className="mt-4">
//                                         <h5 className="title text-dark mb-3">About {doctor.name}</h5>
//                                         <p className="text-muted">
//                                             {doctor.description || 'No description available.'}
//                                         </p>
//                                     </div>
//                                     {/* <button
//                                         type="button"
//                                         onClick={handleEdit}
//                                         className="btn btn-primary mt-4"
//                                     >
//                                         Edit Details
//                                     </button> */}
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <Footer />
//             <ScrollTop />
//         </>
//     );
// };

// const styles = {
//     loaderOverlay: {
//         position: 'fixed', // Cover the entire viewport
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         zIndex: 1000, // Ensure it's above other content
//     },
//     blinkingLogo: {
//         animation: 'blinking 1.5s infinite',  // Apply blinking animation
//     },
// };

// // Add CSS for blinking animation
// const stylesSheet = document.createElement("style");
// stylesSheet.type = "text/css";
// stylesSheet.innerText = `
// @keyframes blinking {
//      0% { opacity: 1; }
//     50% { opacity: 0; }
//    100% { opacity: 1; }
// }
// `;
// document.head.appendChild(stylesSheet);

// export default DoctorDetails;

import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import {
  FiHeart,
  RiMapPinLine,
  RiTimeLine,
  RiMoneyDollarCircleLine,
  FiFacebook,
  FiLinkedin,
  FiGithub,
  FiTwitter,
} from "../../assets/icons/vander";
import logoIcon from "../../assets/images/logo-icon.png"; // Import the logo icon
import { FaStar } from "react-icons/fa6";

const DoctorDetails = () => {
  const { id } = useParams();
  const [doctor, setDoctor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedDoctor, setUpdatedDoctor] = useState(null);
  const [likes, setLikes] = useState(0);
  const [rating, setRating] = useState(0);

  useEffect(() => {
    async function fetchDoctor() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/doctors/${id}/`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setDoctor(data);
        setLikes(data.likes || 0);
        setRating(data.ratings || 0);
        setUpdatedDoctor(data); // Initialize updatedDoctor with the fetched data
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchDoctor();
  }, [id]);
  const handleLike = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctors/${id}/like`,
        {
          method: "POST",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setLikes(data.likes);
    } catch (error) {
      console.error(error);
    }
  };
  const handleRating = async (newRating) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/doctors/${id}/rating`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ newRating }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setRating(data.rating);
    } catch (error) {
      console.error(error);
    }
  };
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      // Exclude the image field from the payload
      const { image, ...payload } = updatedDoctor;

      const response = await fetch(
        `https://doctor-finder-backend.onrender.com/doctors/${id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setDoctor(data);
      setIsEditing(false);
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return (
      <div style={styles.loaderOverlay}>
        <img
          src={logoIcon}
          height="50"
          alt="Logo Icon"
          style={styles.blinkingLogo}
        />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!doctor) {
    return <div>Doctor not found</div>;
  }

  return (
    <>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container"
      />

      <section className="bg-half-150 bg-light d-table w-100">
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="section-title text-center">
                <h3 className="sub-title mb-4">{doctor.name}</h3>
                <p className="para-desc mx-auto text-muted">
                  {doctor.specialization}
                </p>
                <nav aria-label="breadcrumb" className="d-inline-block mt-3">
                  <ul className="breadcrumb bg-transparent mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/doctor-team-two">Doctors</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {doctor.name}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card team border-0 rounded shadow overflow-hidden">
                <div className="team-person position-relative overflow-hidden">
                  <img
                    src={doctor.image || "https://via.placeholder.com/150"}
                    className="img-fluid circle-img" // Added class for circular images
                    alt={doctor.name}
                  />
                  <ul className="list-unstyled team-like">
                    <li>
                      <Link
                        to="#"
                        className="btn btn-icon btn-pills btn-soft-danger"
                      >
                        <FiHeart className="icons" /> {likes}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <h5 className="title text-dark mb-0">{doctor.name}</h5>
                  <small className="text-muted speciality">
                    {doctor.specialization}
                  </small>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div>
                      {[1, 2, 3, 4, 5].map((star) => (
                        <FaStar
                          key={star}
                          color={star <= rating ? "gold" : "grey"}
                          onClick={() => handleRating(star)}
                          style={{ cursor: "pointer" }}
                        />
                      ))}
                    </div>
                    <p className="text-muted mb-0">{rating} Stars</p>
                    <ul className="list-unstyled mb-0">
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                    </ul>
                    <p className="text-muted mb-0">5 Star</p>
                  </div>
                  <ul className="list-unstyled mt-2 mb-0">
                    <li className="d-flex ms-0">
                      <RiMapPinLine className="text-primary align-middle" />
                      <small className="text-muted ms-2">
                        {doctor.location}
                      </small>
                    </li>
                    <li className="d-flex ms-0 mt-2">
                      <RiTimeLine className="text-primary align-middle" />
                      <small className="text-muted ms-2">
                        {doctor.contact_number}
                      </small>
                    </li>
                    <li className="d-flex ms-0 mt-2">
                      <RiMoneyDollarCircleLine className="text-primary align-middle" />
                      <small className="text-muted ms-2">
                        {doctor.charge} INR
                      </small>
                    </li>
                  </ul>
                  <ul className="list-unstyled mt-2 mb-0">
                    <li className="mt-2 list-inline-item">
                      <a
                        href={`mailto:${doctor.email}`}
                        className="btn btn-icon btn-pills btn-soft-primary"
                      >
                        <i className="mdi mdi-email text-primary"></i>
                      </a>
                    </li>
                    <li className="mt-2 list-inline-item">
                      <a
                        href={doctor.facebook}
                        className="btn btn-icon btn-pills btn-soft-primary"
                      >
                        <FiFacebook className="icons" />
                      </a>
                    </li>
                    <li className="mt-2 list-inline-item">
                      <a
                        href={doctor.linkedin}
                        className="btn btn-icon btn-pills btn-soft-primary"
                      >
                        <FiLinkedin className="icons" />
                      </a>
                    </li>
                    <li className="mt-2 list-inline-item">
                      <a
                        href={doctor.github}
                        className="btn btn-icon btn-pills btn-soft-primary"
                      >
                        <FiGithub className="icons" />
                      </a>
                    </li>
                    <li className="mt-2 list-inline-item">
                      <a
                        href={doctor.twitter}
                        className="btn btn-icon btn-pills btn-soft-primary"
                      >
                        <FiTwitter className="icons" />
                      </a>
                    </li>
                  </ul>
                  <div className="mt-4">
                    <h5 className="title text-dark mb-3">
                      About {doctor.name}
                    </h5>
                    <p className="text-muted">
                      {doctor.description || "No description available."}
                    </p>
                  </div>
                  {/* <button
                                        type="button"
                                        onClick={handleEdit}
                                        className="btn btn-primary mt-4"
                                    >
                                        Edit Details
                                    </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </>
  );
};

const styles = {
  loaderOverlay: {
    position: "fixed", // Cover the entire viewport
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Ensure it's above other content
  },
  blinkingLogo: {
    animation: "blinking 1.5s infinite", // Apply blinking animation
  },
};

// Add CSS for blinking animation and circular images
const stylesSheet = document.createElement("style");
stylesSheet.type = "text/css";
stylesSheet.innerText = `
@keyframes blinking {
     0% { opacity: 1; }
    50% { opacity: 0; }
   100% { opacity: 1; }
}

.circle-img {
    border-radius: 50%;
    width: 150px; /* Adjust as needed */
    height: 150px; /* Adjust as needed */
    object-fit: cover;
}
`;
document.head.appendChild(stylesSheet);

export default DoctorDetails;

// import React, { useState, useEffect } from 'react';
// import { useParams, Link } from 'react-router-dom';
// import Navbar from '../../components/navbar';
// import Footer from '../../components/footer';
// import ScrollTop from '../../components/scrollTop';
// import { FiHeart, RiMapPinLine, RiTimeLine, RiMoneyDollarCircleLine, FiFacebook, FiLinkedin, FiGithub, FiTwitter } from '../../assets/icons/vander';
// import logoIcon from '../../assets/images/logo-icon.png';  // Import the logo icon
// import { FaStar } from 'react-icons/fa';

// const DoctorDetails = () => {
//     const { id } = useParams();
//     const [doctor, setDoctor] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [likes, setLikes] = useState(0);
//     const [rating, setRating] = useState(0);
//     const token = localStorage.getItem('access_token');
//     useEffect(() => {
//         async function fetchDoctor() {
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/doctors/${id}/`);
//                 if (!response.ok) {
//                     throw new Error('Network response was not ok');
//                 }
//                 const data = await response.json();
//                 setDoctor(data);
//                 setLikes(data.likes || 0);
//                 setRating(data.rating || 0);
//             } catch (error) {
//                 setError(error.message);
//             } finally {
//                 setLoading(false);
//             }
//         }

//         fetchDoctor();
//     }, [id]);

//     const handleLike = async () => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/doctors/${id}/like/`, {
//                 method: 'POST',
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                 },
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             const data = await response.json();
//             setLikes(data.likes);
//         } catch (error) {
//             console.error('Error liking doctor:', error);
//         }
//     };

//     const handleRating = async (newRating) => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_API_URL}/doctors/${id}/rate/`, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${token}`,
//                 },
//                 body: JSON.stringify({ rating: newRating }),
//             });
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             const data = await response.json();
//             setRating(data.rating);
//         } catch (error) {
//             console.error('Error rating doctor:', error);
//         }
//     };

//     if (loading) {
//         return (
//             <div style={styles.loaderOverlay}>
//                 <img src={logoIcon} height="50" alt="Logo Icon" style={styles.blinkingLogo} />
//             </div>
//         );
//     }

//     if (error) {
//         return <div>Error: {error}</div>;
//     }

//     if (!doctor) {
//         return <div>Doctor not found</div>;
//     }

//     return (
//         <>
//             <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />

//             <section className="bg-half-150 bg-light d-table w-100">
//                 <div className="container">
//                     <div className="row mt-5 justify-content-center">
//                         <div className="col-12">
//                             <div className="section-title text-center">
//                                 <h3 className="sub-title mb-4">{doctor.name}</h3>
//                                 <p className="para-desc mx-auto text-muted">{doctor.specialization}</p>
//                                 <nav aria-label="breadcrumb" className="d-inline-block mt-3">
//                                     <ul className="breadcrumb bg-transparent mb-0">
//                                         <li className="breadcrumb-item"><Link to="/">Home</Link></li>
//                                         <li className="breadcrumb-item"><Link to="/doctor-team-two">Doctors</Link></li>
//                                         <li className="breadcrumb-item active" aria-current="page">{doctor.name}</li>
//                                     </ul>
//                                 </nav>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             <section className="section">
//                 <div className="container">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="card team border-0 rounded shadow overflow-hidden">
//                                 <div className="team-person position-relative overflow-hidden">
//                                     <img
//                                         src={doctor.image || 'https://via.placeholder.com/150'}
//                                         className="img-fluid circle-img"  // Added class for circular images
//                                         alt={doctor.name}
//                                     />
//                                     <ul className="list-unstyled team-like">
//                                         <li>
//                                             <button onClick={handleLike} className="btn btn-icon btn-pills btn-soft-danger">
//                                                 <FiHeart className="icons" /> {likes}
//                                             </button>
//                                         </li>
//                                     </ul>
//                                 </div>
//                                 <div className="card-body">
//                                     <h5 className="title text-dark mb-0">{doctor.name}</h5>
//                                     <small className="text-muted speciality">{doctor.specialization}</small>
//                                     <div className="d-flex justify-content-between align-items-center mt-2">
//                                         <div>
//                                             {[1, 2, 3, 4, 5].map(star => (
//                                                 <FaStar
//                                                     key={star}
//                                                     color={star <= rating ? "gold" : "grey"}
//                                                     onClick={() => handleRating(star)}
//                                                     style={{ cursor: 'pointer' }}
//                                                 />
//                                             ))}
//                                         </div>
//                                         <p className="text-muted mb-0">{rating} Stars</p>
//                                     </div>
//                                     <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="d-flex ms-0">
//                                             <RiMapPinLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.location}</small>
//                                         </li>
//                                         <li className="d-flex ms-0 mt-2">
//                                             <RiTimeLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.contact_number}</small>
//                                         </li>
//                                         <li className="d-flex ms-0 mt-2">
//                                             <RiMoneyDollarCircleLine className="text-primary align-middle" />
//                                             <small className="text-muted ms-2">{doctor.charge} INR</small>
//                                         </li>
//                                     </ul>
//                                     <ul className="list-unstyled mt-2 mb-0">
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={`mailto:${doctor.email}`} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <i className="mdi mdi-email text-primary"></i>
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.facebook} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiFacebook className="icons" />
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.linkedin} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiLinkedin className="icons" />
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.github} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiGithub className="icons" />
//                                             </a>
//                                         </li>
//                                         <li className="mt-2 list-inline-item">
//                                             <a href={doctor.twitter} className="btn btn-icon btn-pills btn-soft-primary">
//                                                 <FiTwitter className="icons" />
//                                             </a>
//                                         </li>
//                                     </ul>
//                                     <div className="mt-4">
//                                         <h5 className="title text-dark mb-3">About {doctor.name}</h5>
//                                         <p className="text-muted">
//                                             {doctor.description || 'No description available.'}
//                                         </p>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <Footer />
//             <ScrollTop />
//         </>
//     );
// };

// const styles = {
//     loaderOverlay: {
//         position: 'fixed', // Cover the entire viewport
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         zIndex: 1000, // Ensure it's above other content
//     },
//     blinkingLogo: {
//         animation: 'blinking 1.5s infinite',  // Apply blinking animation
//     },
// };

// // Add CSS for blinking animation and circular images
// const stylesSheet = document.createElement("style");
// stylesSheet.type = "text/css";
// stylesSheet.innerText = `
// @keyframes blinking {
//      0% { opacity: 1; }
//     50% { opacity: 0; }
//    100% { opacity: 1; }
// }

// .circle-img {
//     border-radius: 50%;
//     width: 150px; /* Adjust as needed */
//     height: 150px; /* Adjust as needed */
//     object-fit: cover;
// }
// `;
// document.head.appendChild(stylesSheet);

// export default DoctorDetails;
