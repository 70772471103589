import React from "react";

export const loadingPanel = () => {
  return (
    <div
      className="k-loading-mask"
      style={{
        position: `fixed`,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
      }}
    >
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
};
// export const getUserData = () => {
//   const userData = localStorage.getItem("userData");
//   if (userData) {
//     return JSON.parse(userData);
//   } else {
//     return null;
//   }
// };
export const getUserData = () => {
  const userData = localStorage.getItem("userData");
  if (userData) {
    return JSON.parse(userData); 
  } else {
    return null; 
  }
};


// Convert 24-hour time format to 12-hour format with AM/PM
export const convertTo12HourFormat = (timeStr) => {
  if (!timeStr) return ""; // Handle null or undefined timeStr
  const [hours, minutes] = timeStr.split(":").map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 || 12;
  return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
};

export const getMaxDate = () => {
  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 15); // Set max date to 15 days from today
  return maxDate;
};

export const ImageWithFallback = ({ src, alt, defaultSrc, style }) => {
  const handleImageError = (e) => {
    e.target.src = defaultSrc; 
  };

  return (
    <img
      src={src}
      alt={alt}
      onError={handleImageError}
      style={style} 
    />
  );
};
export const fetchPatientProfile = async () => {
  const token = localStorage.getItem("accessToken"); 
  const endpoint = 'patient/profile/display/';

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log("Fetched Data:", data);

    // Store only the image URL in local storage
    const imageUrl = data.image; // Extract the image URL
    console.log("imageUrl",imageUrl)
    if (imageUrl) {
      localStorage.setItem("profileImage", imageUrl); // Store the image URL
    }

    return imageUrl; // Return the image URL
  } catch (error) {
    console.error('Error fetching patient profile:', error);
    throw error; 
  }
};

// export const fetchPatientProfile = async () => {
//   const token = localStorage.getItem("accessToken"); 
//   const endpoint = 'patient/profile/display/';

//   try {
//     const response = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
//       method: 'GET',
//       headers: {
//         'Authorization': `Bearer ${token}`, 
//         'Content-Type': 'application/json',
//       },
//     });

//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }

//     const data = await response.json();
//     console.log("adataaaaa",data)
//     localStorage.setItem("userData", JSON.stringify(data)); 
//     return data; 
//   } catch (error) {
//     console.error('Error fetching patient profile:', error);
//     throw error; 
//   }
// };