import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {
  FiEye,
  BsFillHeartPulseFill,
  MdOutlineCheckCircleOutline,
  LiaTimesCircleSolid,
  FaCalendarAlt,
  FaCheckCircle,
  FaPrescriptionBottle,
  FaEllipsisH,
} from "../../assets/icons/vander";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client1 from "../../assets/images/client/01.jpg";
import { Spinner } from "react-bootstrap";
import { Tooltip, OverlayTrigger } from "react-bootstrap"; // Added import
import BookingAppointment from "../../pages/patient/booking-appointment";
import ReBookingAppointment from "../../pages/patient/ReBookingAppointment";
import { ShowSucess, getDoctorId } from "../../_helper/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import {
  convertTo12HourFormat,
  getMaxDate,
} from "../CommonComponents/CommonFunction";

export default function Crud({ appointment, fetchData }) {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [newDate, setNewDate] = useState(appointment.appointment_date);
  const [newTime, setNewTime] = useState(appointment.appointment_time);
  const [loadingAction, setLoadingAction] = useState(null);
  const [status, setStatus] = useState(appointment.status); // Track the current status
  const [comment, setComment] = useState(""); // Add state to track reason
  const [errors, setErrors] = useState({ comment: "" }); // Add state to track errors
  const [modalShow, setModalShow] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [showRefer, setShowRefer] = useState(false);
  const [referredDoctorFirstName, setReferredDoctorFirstName] = useState("");
  const [referredDoctorLastName, setReferredDoctorLastName] = useState("");
  const doctorId = getDoctorId();
  const token = localStorage.getItem("accessToken");
  const [availableDays, setAvailableDays] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (showReschedule) {
      const fetchTimetables = async () => {
        setLoading(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/doctors/details/${doctorId}/`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          const data = await response.json();

          const availableDays = data.timetables
            .map((timetable) => timetable.day_of_week)
            .filter((day) => day);

          setAvailableDays(availableDays);
          setClinicList(
            data.timetables.map((timetable) => ({
              clinic_name: timetable.clinic_name,
              clinic_address: timetable.clinic_address,
            }))
          );
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
      fetchTimetables();
    }
  }, [showReschedule, doctorId]);
  const fetchUnavailableDates = async (doctorId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/unavailable-dates/?doctor_id=${doctorId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (Array.isArray(result)) {
        setUnavailableDates(result.map((date) => new Date(date.date)));
      } else {
        console.error("Unexpected response structure:", result);
      }
    } catch (error) {
      console.error("Error fetching unavailable dates:", error);
    }
  };

  useEffect(() => {
    if (showReschedule) {
      fetchUnavailableDates(doctorId);
    }
  }, [showReschedule, doctorId]);
  const isDateSelectable = (date) => {
    const dayOfWeek = date.getDay();
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = dayNames[dayOfWeek];
    return availableDays.includes(dayName);
  };

  const isDateUnavailable = (date) => {
    const selectable = isDateSelectable(date);

    const unavailable = unavailableDates.some(
      (unavailableDate) =>
        unavailableDate.toDateString() === date.toDateString()
    );

    return !selectable || unavailable;
  };
  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split("T")[0];
    setNewDate(formattedDate);

    fetchAvailableSlots(doctorId, formattedDate);
  };

  // Existing fetchAvailableSlots function
  const fetchAvailableSlots = async (doctorId, date) => {
    try {
      const formattedDate = date.split("T")[0];
      const today = new Date().toISOString().split("T")[0];
      const currentTime = new Date().toTimeString().split(" ")[0];

      const response = await axios.get(
        `${baseURL}/doctor/${doctorId}/available-slots/?date=${formattedDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const isToday = formattedDate === today;
        const slots = isToday
          ? response.data.filter((slot) => slot.start_time > currentTime)
          : response.data;

        setAvailableSlots(slots);
      } else {
        setAvailableSlots([]);
      }
    } catch (error) {
      console.error("Error fetching available slots:", error);
      setAvailableSlots([]);
    }
  };

  const getToastMessage = (action) => {
    switch (action) {
      case "confirm":
        return "Appointment accepted successfully";
      case "reject":
        return "Appointment rejected";
      case "reschedule":
        return "Appointment rescheduled successfully";
      case "complete":
        return "Appointment marked as completed";
      case "refer":
        return "Appointment Reffered Sucessfully";
      default:
        return "Operation successful";
    }
  };

  const formatAppointmentDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleApiCall = async (
    action,
    newDate = null,
    newTime = null,
    comment = "",
    doctorId = null, // Add doctorId for the referring doctor
    referredDoctorFirstName = "", // Add referred doctor's first name
    referredDoctorLastName = "" // Add referred doctor's last name
  ) => {
    setErrors({ comment: "" });

    // Validation
    if (action === "reject" || action === "reschedule") {
      if (!comment.trim()) {
        setErrors({ comment: "This field is required" });
        return;
      }
    }
    setLoadingAction(action);
    const formattedTime = newTime ? newTime.slice(0, 5) : null;

    // Build the payload
    const payload = {
      action,
      appointment_date: newDate,
      appointment_time: formattedTime,
      comment: comment, // Include reason in API call
    };

    // Include referral information if action is "refer"
    if (action === "refer") {
      payload.referring_doctor_id = doctorId; // Current logged-in doctor's ID
      payload.referred_doctor_first_name = referredDoctorFirstName;
      payload.referred_doctor_last_name = referredDoctorLastName;
    }

    try {
      // First API call for updating appointment status
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/appointments/status/${appointment.id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload), // Use the dynamically constructed payload
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      if (action === "refer") {
        // Call the refer API when action is "refer"
        await handleRefer(
          doctorId,
          referredDoctorFirstName,
          referredDoctorLastName
        );
      }

      toast.success(getToastMessage(action));
      if (action === "completed") {
        setStatus("completed"); // Update the status to completed
      }
      setTimeout(() => {
        fetchData();
      }, 3000);
    } catch (error) {
      console.error("Error updating appointment status:", error);
      // Show dynamic error message, using the detail from the response
      toast.error(error.message || "Error updating appointment status");
    } finally {
      setLoadingAction(null);
      setShow(false);
      setShow2(false);
      setShow3(false);
      setShowReschedule(false);
      setShowCompleted(false);
      setComment("");
    }
  };

  // Separate function for handling refer API call
  const handleRefer = async (
    doctorId,
    referredDoctorFirstName,
    referredDoctorLastName
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/refer-doctor/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            referring_doctor_id: doctorId, // Current logged-in doctor's ID
            referred_doctor_first_name: referredDoctorFirstName,
            referred_doctor_last_name: referredDoctorLastName,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // toast.success("Patient referred successfully");
    } catch (error) {
      console.error("Error referring patient:", error);
      toast.error("Error referring patient");
    }
  };

  const getButtonClass = (status) => {
    switch (status) {
      case "confirmed":
        return "btn-soft-success";
      case "rescheduled":
        return "btn-soft-warning";
      case "rejected":
        return "btn-soft-danger";
      case "completed":
        return "btn-soft-secondary";
      default:
        return "btn-soft-primary";
    }
  };

  const { patient_name, id, doctor, patient } = appointment;
  return (
    <>
      <td className="text-end p-3">
        {status === "referred" ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-cancelled">View Details</Tooltip>}
          >
            <Link
              to="#"
              className="btn btn-icon btn-pills btn-soft-primary"
              onClick={() => setShow(!show)}
            >
              <FiEye />
            </Link>
          </OverlayTrigger>
        ) : status === "cancelled" ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-cancelled">View Details</Tooltip>}
          >
            <Link
              to="#"
              className="btn btn-icon btn-pills btn-soft-primary"
              onClick={() => setShow(!show)}
            >
              <FiEye />
            </Link>
          </OverlayTrigger>
        ) : (
          <>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-view">View Details</Tooltip>}
            >
              <Link
                to="#"
                className="btn btn-icon btn-pills btn-soft-primary"
                onClick={() => setShow(!show)}
              >
                <FiEye />
              </Link>
            </OverlayTrigger>
            {(status === "completed" ||
              status === "confirmed" ||
              status === "rescheduled") && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-view">View Health Metrics</Tooltip>
                }
              >
                <Link
                  to={`/patient/health-metrics/${patient}`}
                  state={{ patientData: appointment }}
                  className="btn btn-icon btn-pills btn-soft-primary mx-1"
                  // onClick={() => setShow(!show)}
                >
                  <BsFillHeartPulseFill />
                </Link>
              </OverlayTrigger>
            )}
            {status !== "completed" && (
              <>
                {status !== "confirmed" && status !== "rejected" && (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-accept">
                          Accept Appointment
                        </Tooltip>
                      }
                    >
                      <Link
                        to="#"
                        className="btn btn-icon btn-pills btn-soft-success mx-1"
                        onClick={() => setShow2(!show2)}
                      >
                        <MdOutlineCheckCircleOutline />
                      </Link>
                    </OverlayTrigger>
                    {status !== "rejected" && (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-reject">
                            Reject Appointment
                          </Tooltip>
                        }
                      >
                        <Link
                          to="#"
                          className="btn btn-icon btn-pills btn-soft-danger"
                          onClick={() => setShow3(!show3)}
                        >
                          <LiaTimesCircleSolid />
                        </Link>
                      </OverlayTrigger>
                    )}
                  </>
                )}
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-refer">Refer Patient</Tooltip>}
                >
                  <Link
                    to="#"
                    className="btn btn-icon btn-pills btn-soft-primary mx-1"
                    onClick={() => setShowRefer(true)}
                  >
                    <FaPrescriptionBottle />
                  </Link>
                </OverlayTrigger>

                {/* Show Reschedule icon only for current and future dates */}
                {status === "confirmed" &&
                  appointment?.appointment_date >=
                    new Date().toISOString().split("T")[0] && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-reschedule">
                          Reschedule Appointment
                        </Tooltip>
                      }
                    >
                      <Link
                        to="#"
                        className="btn btn-icon btn-pills btn-soft-warning"
                        onClick={() => setShowReschedule(true)}
                      >
                        <FaCalendarAlt />
                      </Link>
                    </OverlayTrigger>
                  )}
                {status === "confirmed" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-reject">Re Appointment</Tooltip>
                    }
                  >
                    <Link
                      to="#"
                      className="btn btn-icon btn-pills btn-soft-primary"
                      onClick={() => setModalShow(true)}
                    >
                      <FaEllipsisH />
                    </Link>
                  </OverlayTrigger>
                )}

                {status !== "completed" && (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-complete">Mark as Completed</Tooltip>
                    }
                  >
                    <Link
                      to="#"
                      className={`btn btn-icon btn-pills ${
                        status === "completed"
                          ? "btn-soft-secondary"
                          : "btn-soft-primary"
                      }`}
                      onClick={() => setShowCompleted(true)}
                    >
                      <FaCheckCircle />
                    </Link>
                  </OverlayTrigger>
                )}
              </>
            )}
          </>
        )}
      </td>

      {/* View Details Modal */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Appointment Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-3 pt-4">
            <div className="d-flex align-items-center">
              <img
                src={client1}
                className="avatar avatar-small rounded-pill"
                alt=""
              />
              <h5 className="mb-0 ms-3">{appointment.patient_name}</h5>
            </div>
            <ul className="list-unstyled mb-0 d-md-flex justify-content-between mt-4">
              <li>
                <ul className="list-unstyled mb-0">
                  <li className="d-flex">
                    <h6>Age:</h6>
                    <p className="text-muted ms-2">
                      {appointment.age} year old
                    </p>
                  </li>
                  <li className="d-flex">
                    <h6>Gender:</h6>
                    <p className="text-muted ms-2">{appointment.gender}</p>
                  </li>
                  <li className="d-flex">
                    <h6 className="mb-0">Reason:</h6>
                    <p className="text-muted ms-2 mb-0">{appointment.reason}</p>
                  </li>
                </ul>
              </li>
              <li>
                <ul className="list-unstyled mb-0">
                  <li className="d-flex">
                    <h6>Date:</h6>
                    <p className="text-muted ms-2">
                      {formatAppointmentDate(appointment.appointment_date)}
                    </p>
                  </li>
                  <li className="d-flex">
                    <h6>Time:</h6>
                    <p className="text-muted ms-2">
                      {appointment.appointment_time}
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      {/* Accept Modal */}
      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        animation={false}
        centered
      >
        <Modal.Body>
          <div className="modal-body py-5">
            <div className="text-center">
              <div
                className="icon d-flex align-items-center justify-content-center bg-soft-success rounded-circle mx-auto"
                style={{ height: "95px", width: "95px" }}
              >
                <span className="mb-0">
                  <MdOutlineCheckCircleOutline className="h1" />
                </span>
              </div>
              <div className="mt-4">
                <h4>Accept Appointment</h4>
                <p className="para-desc mx-auto text-muted mb-0">
                  You are about to accept the appointment.
                </p>
                <div className="mt-4">
                  <Link
                    to="#"
                    className="btn btn-soft-success"
                    onClick={() => handleApiCall("confirm")}
                    disabled={loadingAction === "confirm"}
                  >
                    {loadingAction === "confirm" ? "Saving..." : "Accept"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Cancel Modal */}
      <Modal
        show={show3}
        onHide={() => setShow3(false)}
        animation={false}
        centered
      >
        <Modal.Body>
          <div className="modal-body py-5">
            <div className="text-center">
              <div
                className="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
                style={{ height: "95px", width: "95px" }}
              >
                <span className="mb-0">
                  <LiaTimesCircleSolid className="h1" />
                </span>
              </div>
              <div className="mt-4">
                <h4>Cancel Appointment</h4>
                <p className="para-desc mx-auto text-muted mb-0">
                  You are about to cancel the appointment.
                </p>
                <textarea
                  className="form-control mt-3"
                  placeholder="Enter reason for cancellation"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required
                />
                {errors.comment && (
                  <div className="text-danger mt-2">{errors.comment}</div>
                )}
                <div className="mt-4">
                  <Link
                    to="#"
                    className="btn btn-soft-danger"
                    onClick={() => handleApiCall("reject", null, null, comment)}
                    disabled={loadingAction === "reject"}
                  >
                    {loadingAction === "reject" ? "Saving..." : "Cancel"}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* {Reffer Model} */}
      <Modal
        show={showRefer}
        onHide={() => setShowRefer(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            Refer Patient to Another Doctor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body p-3">
            <div className="mb-3">
              <label htmlFor="referredDoctorFirstName" className="form-label">
                Referred Doctor First Name
              </label>
              <input
                type="text"
                id="referredDoctorFirstName"
                className="form-control"
                value={referredDoctorFirstName}
                onChange={(e) => setReferredDoctorFirstName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="referredDoctorLastName" className="form-label">
                Referred Doctor Last Name
              </label>
              <input
                type="text"
                id="referredDoctorLastName"
                className="form-control"
                value={referredDoctorLastName}
                onChange={(e) => setReferredDoctorLastName(e.target.value)}
                required
              />
            </div>
            <div className="text-center mt-4">
              <button
                className="btn btn-primary"
                // onClick={handleRefer}
                onClick={() =>
                  handleApiCall(
                    "refer",
                    null,
                    null,
                    null,
                    doctorId,
                    referredDoctorFirstName,
                    referredDoctorLastName
                  )
                }
                disabled={loadingAction === "refer"}
              >
                {loadingAction === "refer" ? "Referring..." : "Refer"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Reschedule Modal */}
      <Modal
        show={showReschedule}
        onHide={() => setShowReschedule(false)}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">Reschedule Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body" style={{ padding: "1rem" }}>
            {/* New Date and Time Selection */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              {/* New Date */}
              <div style={{ flex: 1, marginRight: "0.5rem" }}>
                <label
                  htmlFor="rescheduleDate"
                  style={{
                    display: "block",
                    marginBottom: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  New Date
                </label>
                <DatePicker
                  selected={newDate ? new Date(newDate) : null}
                  onChange={handleDateChange}
                  filterDate={(date) => !isDateUnavailable(date)}
                  minDate={new Date()}
                  maxDate={getMaxDate()}
                  dateFormat="dd-MM-yyyy"
                  required
                  className="form-select form-control"
                  renderDayContents={(day, date) => {
                    const today = new Date();
                    const maxSelectableDate = new Date(today);
                    maxSelectableDate.setDate(today.getDate() + 15);
                    const isPastDate = date < today;
                    const isExceedingDate = date > maxSelectableDate;
                    const isWithinRange =
                      date >= today && date <= maxSelectableDate;
                    const isSelectable = isDateSelectable(date);
                    const isSelected =
                      date.toDateString() ===
                      (newDate ? new Date(newDate).toDateString() : null);

                    const styles = {
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      cursor:
                        isPastDate || isExceedingDate
                          ? "not-allowed"
                          : isWithinRange && isSelectable
                          ? "pointer"
                          : "default",
                      backgroundColor: isSelected
                        ? "skyblue"
                        : isWithinRange && isSelectable
                        ? "blue"
                        : "transparent",
                      color:
                        isSelected || (isWithinRange && isSelectable)
                          ? "white"
                          : "gray",
                      transition: "background-color 0.2s, color 0.2s", // Optional: for smooth transitions
                    };

                    return <span style={styles}>{day}</span>;
                  }}
                />

                {/* <DatePicker
            selected={newDate ? new Date(newDate) : null}
            onChange={handleDateChange}
            filterDate={(date) => !isDateUnavailable(date)}
            minDate={new Date()}
            maxDate={getMaxDate()}
            dateFormat="dd-MM-yyyy"
            required
            className="form-select form-control"
            style={{
              width: "100%",
              padding: "0.5rem",
              borderRadius: "0.25rem",
              border: "1px solid #ced4da",
            }}
          /> */}
              </div>

              {/* New Time */}
              <div style={{ flex: 1 }}>
                <label
                  htmlFor="rescheduleTime"
                  style={{
                    display: "block",
                    marginBottom: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  New Time
                </label>
                <select
                  id="rescheduleTime"
                  value={newTime}
                  onChange={(e) => setNewTime(e.target.value)}
                  required
                  className="form-select form-control"
                >
                  <option value="">Select a time</option>
                  {availableSlots.length > 0 ? (
                    availableSlots.map((slot, index) => (
                      <option key={index} value={slot.start_time}>
                        {convertTo12HourFormat(slot.start_time)}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No available slots
                    </option>
                  )}
                </select>
              </div>
            </div>

            {/* Reason for Rescheduling */}
            <div style={{ marginBottom: "1rem" }}>
              <label
                htmlFor="rescheduleReason"
                style={{
                  display: "block",
                  marginBottom: "0.5rem",
                  fontWeight: "bold",
                }}
              >
                Reason for Rescheduling
              </label>
              <textarea
                id="rescheduleReason"
                style={{
                  width: "100%",
                  padding: "0.5rem",
                  borderRadius: "0.25rem",
                  border: "1px solid #ced4da",
                }}
                placeholder="Enter reason for rescheduling"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
              />
              {errors.comment && (
                <div style={{ color: "red", marginTop: "0.5rem" }}>
                  {errors.comment}
                </div>
              )}
            </div>

            {/* Submit Button */}
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <button
                style={{
                  padding: "0.5rem 1rem",
                  fontSize: "1rem",
                  color: "#fff",
                  backgroundColor: "#007bff",
                  border: "none",
                  borderRadius: "0.25rem",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleApiCall("reschedule", newDate, newTime, comment)
                }
                disabled={loadingAction === "reschedule"}
              >
                {loadingAction === "reschedule" ? "Saving..." : "Reschedule"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Completed Modal */}
      <Modal
        show={showCompleted}
        onHide={() => setShowCompleted(false)}
        animation={false}
        centered
      >
        <Modal.Body>
          <div className="modal-body py-5">
            <div className="text-center">
              <div
                className="icon d-flex align-items-center justify-content-center bg-soft-secondary rounded-circle mx-auto"
                style={{ height: "95px", width: "95px" }}
              >
                <span className="mb-0">
                  <FaCheckCircle className="h1" />
                </span>
              </div>
              <div className="mt-4">
                <h4>Mark as Completed</h4>
                <p className="para-desc mx-auto text-muted mb-0">
                  You are about to mark the appointment as completed.
                </p>
                <div className="mt-4">
                  <button
                    className="btn btn-soft-secondary"
                    onClick={() => handleApiCall("complete", null, null, null)}
                    disabled={loadingAction === "completed"}
                  >
                    {loadingAction === "complete" ? "Saving..." : "Complete"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ReBookingAppointment
        show={modalShow}
        handleClose={() => setModalShow(false)}
        selectedDoctor={doctor} // Pass doctor ID
        selectedPatientName={patient_name} // Pass patient name
        // parentAppointmentId={id} // Pass appointment ID as parent_appointment
        parentAppointmentId={appointment.parent_appointment || id} // Pass parent_appointment if available, otherwise pass id
      />

      <ToastContainer />
    </>
  );
}
