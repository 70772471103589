// // Header.js
// import React from 'react';
// import { FaArrowLeft } from 'react-icons/fa';

// const Header = ({ title, onBackClick,showBackButton  }) => {
//   return (
//     <div className="row">
//       <div className="col-md-12 d-flex align-items-center">
//         <div
//           style={{
//             backgroundColor: '#0056b3', // Blue background color
//             color: '#fff', // White text color
//             padding: '1rem',
//             borderRadius: '0.25rem',
//             display: 'flex',
//             alignItems: 'center',
//             width: '100%',
//             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // White shadow
//           }}
//         >
//           {showBackButton  && (
//             <button
//               className="d-flex align-items-center me-3"
//               onClick={onBackClick} // Handle back button click
//               style={{
//                 backgroundColor: '#004494', // Darker blue for the button
//                 color: '#fff',
//                 border: 'none',
//                 padding: '0.5rem 1rem',
//                 borderRadius: '0.25rem',
//                 cursor: 'pointer',
//                 boxShadow: '0 4px 8px rgba(255, 255, 255, 0.3)', // White shadow for visibility
//               }}
//             //   style={{
//             //     backgroundColor: '#004494', // Darker blue for the button
//             //     color: '#fff',
//             //     border: 'none',
//             //     padding: '0.5rem 1rem',
//             //     borderRadius: '0.25rem',
//             //     cursor: 'pointer',
//             //     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Button shadow
//             //   }}
//             >
//               <FaArrowLeft className="me-2" />
//               Back
//             </button>
//           )}
//           <h5 style={{ margin: 0, fontSize: '1.25rem' }}>{title}</h5>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Header;

// Header.js
// import React from "react";
// import { FaArrowLeft } from "react-icons/fa";

// const Header = ({
//   title,
//   onBackClick,
//   showBackButton,
//   actionButton,
//   headerEnd,
// }) => {
//   return (
//     <div className="row">
//       <div className="col-md-12 d-flex align-items-center">
//         <div
//           style={{
//             backgroundColor: "#0056b3", // Blue background color
//             color: "#fff", // White text color
//             padding: "1rem",
//             borderRadius: "0.25rem",
//             display: "flex",
//             // alignItems: "center",
//             width: "100%",
//             boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // White shadow
//             position: "relative", // Allow positioning of button within header
//           }}
//         >
//           {showBackButton && (
//             <button
//               className="d-flex align-items-center me-3"
//               onClick={onBackClick} // Handle back button click
//               style={{
//                 backgroundColor: "#004494", // Darker blue for the button
//                 color: "#fff",
//                 border: "none",
//                 padding: "0.5rem 1rem",
//                 borderRadius: "0.25rem",
//                 cursor: "pointer",
//                 boxShadow: "0 4px 8px rgba(255, 255, 255, 0.3)", // White shadow for visibility
//               }}
//             >
//               <FaArrowLeft className="me-2" />
//               Back
//             </button>
//           )}
//           <h5 style={{ margin: 0, fontSize: "1.25rem" }}>{title}</h5>
//           {actionButton && (
//             <div
//               className="position-absolute"
//               style={{
//                 right: "15px", // Adjust this value as needed
//                 top: "50%",
//                 transform: "translateY(-50%)",
//               }}
//             >
//               {actionButton}
//             </div>
//           )}
//           <div>{headerEnd}</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Header;

import React from "react";
import { FaArrowLeft } from "react-icons/fa";

const Header = ({
  title,
  onBackClick,
  showBackButton,
  actionButton,
  headerEnd,
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div
          style={{
            backgroundColor: "#0056b3", // Blue background color
            color: "#fff", // White text color
            padding: "1rem",
            borderRadius: "0.25rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Space out elements between start and end
            width: "100%",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // White shadow
            position: "relative",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {showBackButton && (
              <button
                className="d-flex align-items-center me-3"
                onClick={onBackClick} // Handle back button click
                style={{
                  backgroundColor: "#004494", // Darker blue for the button
                  color: "#fff",
                  border: "none",
                  padding: "0.5rem 1rem",
                  borderRadius: "0.25rem",
                  cursor: "pointer",
                  boxShadow: "0 4px 8px rgba(255, 255, 255, 0.3)", // White shadow for visibility
                }}
              >
                <FaArrowLeft className="me-2" />
                Back
              </button>
            )}
            <h5 style={{ margin: 0, fontSize: "1.25rem" }}>{title}</h5>
            {actionButton && (
              <div
                className="position-absolute"
                style={{
                  right: "15px", // Adjust this value as needed
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                {actionButton}
              </div>
            )}
          </div>
          {headerEnd && <div>{headerEnd}</div>}
        </div>
      </div>
    </div>
  );
};

export default Header;
