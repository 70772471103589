// import React from "react";
// import { Link } from "react-router-dom";

// export default function AdminFooter() {
//   return (
//     <footer className="py-4">
//       <div className="container-fluid">
//         <div className="row justify-content-center">
//           <div className="col-auto text-center">
//             <p className="mb-0">
//               {new Date().getFullYear()} © Doctris. Design & Develop with{" "}
//               <i className="mdi mdi-heart text-danger"></i> by{" "}
//               <Link
//                 to="https://apexpath.com/"
//                 target="_blank"
//                 className="text-reset"
//               >
//                 APEXPATH
//               </Link>
//               .
//             </p>
//             <ul className="list-unstyled footer-list d-inline mb-0">
//               <li className="list-inline-item">
//                 <Link to="/terms" className="text-foot me-2">
//                   Terms
//                 </Link>
//               </li>
//               <li className="list-inline-item">
//                 <Link to="/privacy" className="text-foot me-2">
//                   Privacy
//                 </Link>
//               </li>
//               <li className="list-inline-item">
//                 <Link to="/aboutus" className="text-foot me-2">
//                   About
//                 </Link>
//               </li>
//               <li className="list-inline-item">
//                 <Link to="/contact" className="text-foot me-2">
//                   Contact
//                 </Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }

// import React from "react";
// import { Link } from "react-router-dom";

// export default function AdminFooter() {
//   return (
//     <footer style={{ padding: '1rem 0' }}>
//       <div className="container-fluid">
//         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//           <div style={{ flex: '1', textAlign: 'center', paddingLeft: '10rem' }}> {/* Added paddingLeft */}
//             <p style={{ marginBottom: '0' }}>
//               {new Date().getFullYear()} © healthBridge. Design & Develop with{" "}
//               <i className="mdi mdi-heart text-danger"></i> by{" "}
//               <Link
//                 to="https://apexpath.com/"
//                 target="_blank"
//                 style={{ color: 'inherit', textDecoration: 'none' }}
//               >
//                 APEXPATH
//               </Link>
//               .
//             </p>
//           </div>

//           <div style={{ textAlign: 'right' }}>
//             <ul style={{ listStyle: 'none', paddingLeft: '0', margin: '0', display: 'inline-flex' }}>
//               <li style={{ marginLeft: '1rem', marginRight: '1rem' }}>
//                 <Link to="/terms" style={{ color: 'inherit', textDecoration: 'none' }}>
//                   Terms
//                 </Link>
//               </li>
//               <li style={{ marginLeft: '1rem', marginRight: '1rem' }}>
//                 <Link to="/privacy" style={{ color: 'inherit', textDecoration: 'none' }}>
//                   Privacy
//                 </Link>
//               </li>
//               <li style={{ marginLeft: '1rem', marginRight: '1rem' }}>
//                 <Link to="/aboutus" style={{ color: 'inherit', textDecoration: 'none' }}>
//                   About
//                 </Link>
//               </li>
//               <li style={{ marginLeft: '1rem', marginRight: '1rem' }}>
//                 <Link to="/contact" style={{ color: 'inherit', textDecoration: 'none' }}>
//                   Contact
//                 </Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }




import React from "react";
import { Link } from "react-router-dom";

export default function AdminFooter() {
  return (
    <footer style={{ padding: '1rem 0' }}>
      <div className="container-fluid">
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          justifyContent: 'space-between', 
          textAlign: 'center' 
        }}>
          <div style={{ width: '100%', padding: '0 1rem' }}>
            <p style={{ marginBottom: '0' }}>
              {new Date().getFullYear()} © healthBridge. Design & Develop with{" "}
              <i className="mdi mdi-heart text-danger"></i> by{" "}
              <Link
                to="https://apexpath.com/"
                target="_blank"
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                APEXPATH
              </Link>
              .
            </p>
          </div>

          <div style={{ width: '100%' }}>
            <ul style={{ 
              listStyle: 'none', 
              paddingLeft: '0', 
              margin: '1rem 0 0', 
              display: 'flex', 
              justifyContent: 'center', 
              flexWrap: 'wrap' 
            }}>
              {['/terms', '/privacy', '/aboutus', '/contact'].map((path, index) => (
                <li key={index} style={{ margin: '0 1rem' }}>
                  <Link to={path} style={{ color: 'inherit', textDecoration: 'none' }}>
                    {path.replace('/', '') || 'Home'}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
