import React, { useCallback, useState } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const libraries = ['places']; // Specify any libraries you need

const MapContainerClinic = ({ onLocationSelect,returnLatLong = true }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const [markerPosition, setMarkerPosition] = useState(null);

    const onMapClick = useCallback((event) => {
        const latLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };
        setMarkerPosition(latLng);
        if (returnLatLong && onLocationSelect) {
            onLocationSelect(latLng); // Notify parent component of the selected location
        }
        // setMarkerPosition(latLng);
        // onLocationSelect(latLng); // Notify parent component of the selected location
    }, [onLocationSelect,returnLatLong]);

    if (loadError) {
        console.error("Google Maps API Error:", loadError);
        return <div>Error loading maps. Please check the console for more details.</div>;
    }

    if (!isLoaded) {
        return <div>Loading Maps...</div>;
    }

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <GoogleMap
                mapContainerStyle={{ height: '100%', width: '100%' }}
                zoom={10}
                center={markerPosition || { lat: 19.076090, lng: 72.877426 }} // Default center
                onClick={onMapClick}
            >
                {markerPosition && (
                    <Marker position={markerPosition} />
                )}
            </GoogleMap>
        </div>
    );
};

export default MapContainerClinic;
