import React, { useEffect, useState } from "react";
import { getUserData } from "../../components/CommonComponents/CommonFunction";
import { ShowSucess, ShowError } from "../../_helper/helper";
import { Row, Col, Container } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar";
import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function RazorPayPayment() {
  const userData = getUserData();
  const location = useLocation();

  const [paymentDetails, setPaymentDetails] = useState(null);
  const baseURL = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem("accessToken");
  const { appointmentId } = location?.state || {};

  const navigate = useNavigate();

  const handleBackClick = () => {
    if (window.history.length > 1) {
      navigate(-1); // Navigate back only if there's a history entry to go back to
    } else {
      // Optionally, navigate to a default route if there's no history
      navigate("/doctor-dashboard");
    }
  };

  const handlePayment = async () => {
    // handleClose();
    // setIsBooked(false);
    try {
      const response = await axios.post(
        `${baseURL}/create-order/`,
        {
          amount: 500,
          currency: "INR",
          order_type: "appointment",
          reference_id: appointmentId,
          appointment_id: appointmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const {
        order_id,
        order,
        razorpay_key,
        // amount: orderAmount,
        currency,
      } = response.data;
      const { id, amount: orderAmount } = order || {};
      setPaymentDetails(response?.data);
      // setOrderId(id);
      // setRazorpayKey(razorpay_key);
      // setError(null);
      displayRazorpay(
        id,
        order_id,
        Number(orderAmount),
        currency,
        razorpay_key
      );
    } catch (err) {
      ShowError("Order creation failed. Please try again.");
    }
  };
  const displayRazorpay = async (id, order_id, amount, currency, key) => {
    const options = {
      key: key,
      amount: amount,
      currency: currency,
      order_id: order_id,
      handler: async function (response) {
        try {
          const result = await axios.put(
            `${baseURL}/verify-payment/${id}/`,
            {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (result.data.status === "Payment successful") {
            // setSuccess(true);
            ShowSucess("Payment successful");
          } else {
            ShowError("Payment verification failed. Please try again.");
          }
        } catch (err) {
          ShowError("Payment verification failed. Please try again.");
        }
      },
      prefill: {
        name: `${userData?.user?.first_name} ${userData?.user?.last_name}`,
        email: userData?.user?.email,
        contact: userData?.user?.contact_no,
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  useEffect(() => {
    handlePayment();
  }, []);
  return (
    <div>
      <Navbar
        navDark={true}
        manuClass="navigation-menu nav-left"
        containerClass="container-fluid"
      />
      <Row style={{ marginTop: "6rem", marginLeft: "13px" }}>
        <Col xs={2}>
          <Button
            onClick={handleBackClick}
            variant="contained" // You can use "outlined" or "text" variant if you prefer a different style
            startIcon={<ArrowBackIcon />} // This adds the icon before the text
            color="primary" // Adjust the color as needed
          >
            Back
          </Button>{" "}
        </Col>
      </Row>

      {paymentDetails && (
        <>
          <Container>
            <Card>
              <CardBody>
                <Row style={{ marginBottom: "2rem", textAlign: "center" }}>
                  <h5>Payment Details</h5>
                </Row>

                <Row xs={12}>
                  <Col xs={3} className="d-flex">
                    <h6>User Name:</h6>
                    <p className="text-muted ms-3">{`${userData?.user?.first_name} ${userData?.user?.last_name}`}</p>
                  </Col>
                  <Col xs={3} className="d-flex">
                    <h6>Amount:</h6>
                    <p className="text-muted ms-3">
                      {paymentDetails?.order?.amount}
                    </p>
                  </Col>
                  <Col xs={3} className="d-flex">
                    <h6>currency:</h6>
                    <p className="text-muted ms-3">
                      {paymentDetails?.order?.currency}
                    </p>
                  </Col>
                  <Col xs={3} className="d-flex">
                    <h6>Order Type:</h6>
                    <p className="text-muted ms-3">
                      {paymentDetails?.order?.order_type}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </>
      )}
    </div>
  );
}
