// import React from 'react';
// import { Link } from "react-router-dom";

// // import logoLight from "../assets/images/logo-light.png"

// import logoLight from "../assets/images/healthBridge_transparent_logo.png"

// import {FiFacebook, FiLinkedin, FiInstagram, FiTwitter,FiMail, FiPhone, FiMapPin} from '../assets/icons/vander'

// export default function Footer(){
//     return(

//         <>
//         <footer className="">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-xl-5 col-lg-4 mb-0 mb-md-4 pb-0 pb-md-2 text-center">
//                         <Link to="#" className="logo-footer">
//                             <img src={logoLight} height="105" alt=""/>
//                         </Link>
//                         <p className="mt-4 me-xl-5">Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
//                     </div>

//                     <div className="col-xl-7 col-lg-8 col-md-12">
//                         <div className="row">
//                             {/* <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
//                                 <h5 className="footer-head">Company</h5>
//                                 <ul className="list-unstyled footer-list mt-4">
//                                     <li><Link to="/aboutus" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> About us</Link></li>
//                                     <li><Link to="/departments" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Services</Link></li>
//                                     <li><Link to="/doctor-team-two" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Team</Link></li>
//                                     <li><Link to="/blog-detail" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Project</Link></li>
//                                     <li><Link to="/blogs" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Blog</Link></li>
//                                     <li><Link to="/login" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Login</Link></li>
//                                 </ul>
//                             </div> */}

//                             {/* <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
//                                 <h5 className="footer-head">Departments</h5>
//                                 <ul className="list-unstyled footer-list mt-4">
//                                     <li><Link to="/departments" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Eye Care</Link></li>
//                                     <li><Link to="/departments" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Psychotherapy</Link></li>
//                                     <li><Link to="/departments" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Dental Care</Link></li>
//                                     <li><Link to="/departments" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Orthopedic</Link></li>
//                                     <li><Link to="/departments" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Cardiology</Link></li>
//                                     <li><Link to="/departments" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Gynecology</Link></li>
//                                     <li><Link to="/departments" className="text-foot"><i className="mdi mdi-chevron-right me-1"></i> Neurology</Link></li>
//                                 </ul>
//                             </div> */}

//                             <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 text-center">
//                                 <h5 className="footer-head">Contact us</h5>
//                                 <ul className="list-unstyled footer-list mt-4">
//                                     <li className="d-flex align-items-center">
//                                         <FiMail className="fea icon-sm text-foot align-middle"/>
//                                         <Link to="info@apexpath.com" className="text-foot ms-2">info@apexpath.com</Link>
//                                     </li>

//                                     <li className="d-flex align-items-center">
//                                         <FiPhone className="fea icon-sm text-foot align-middle"/>
//                                         <Link to="tel:+91 9268310732" className="text-foot ms-2">+91 9268310732</Link>
//                                     </li>

//                                     {/* <li className="d-flex align-items-center">
//                                         <FiMapPin className="fea icon-sm text-foot align-middle"/>
//                                         <Link to="#" className="video-play-icon text-foot ms-2">View on Google map</Link>
//                                     </li> */}
//                                 </ul>

//                                 <ul className="list-unstyled social-icon footer-social mb-0 mt-4">
//                                     <li className="list-inline-item"><Link to="#" className="rounded-pill"><FiFacebook className="fea icon-sm fea-social"/></Link></li>
//                                     <li className="list-inline-item"><Link to="#" className="rounded-pill"><FiInstagram className="fea icon-sm fea-social"/></Link></li>
//                                     <li className="list-inline-item"><Link to="#" className="rounded-pill"><FiTwitter className="fea icon-sm fea-social"/></Link></li>
//                                     <li className="list-inline-item"><Link to="#" className="rounded-pill"><FiLinkedin className="fea icon-sm fea-social"/></Link></li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <div className="container mt-5">
//                 <div className="pt-4 footer-bar">
//                     <div className="row align-items-center">
//                         <div className="col-sm-6">
//                             <div className="text-sm-start text-center">
//                                 <p className="mb-0">{new Date().getFullYear()}© healthBridge. Design & Develop with <i className="mdi mdi-heart text-danger"></i> by <Link to="https://apexpath.com/" target="_blank" className="text-reset">APEXPATH</Link>.</p>
//                             </div>
//                         </div>

//                         <div className="col-sm-6 mt-4 mt-sm-0">
//                             <ul className="list-unstyled footer-list text-sm-end text-center mb-0">
//                                 <li className="list-inline-item"><Link to="/terms" className="text-foot me-2">Terms</Link></li>
//                                 <li className="list-inline-item"><Link to="/privacy" className="text-foot me-2">Privacy</Link></li>
//                                 <li className="list-inline-item"><Link to="/aboutus" className="text-foot me-2">About</Link></li>
//                                 <li className="list-inline-item"><Link to="/contact" className="text-foot me-2">Contact</Link></li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </footer>
//         </>
//     )
// }

// import React from 'react';
// import { Link } from "react-router-dom";
// import logoLight from "../assets/images/healthBridge_transparent_logo.png";
// import { FiFacebook, FiLinkedin, FiInstagram, FiTwitter, FiMail, FiPhone } from '../assets/icons/vander';

// export default function Footer() {
//     return (
//         <footer className="">
//             <div className="container">
//                 <div className="row text-center text-md-center">
//                     <div className="col-lg-5 mb-4 mb-lg-0">
//                         <Link to="#" className="logo-footer">
//                             <img src={logoLight} height="105" alt="healthBridge logo" />
//                         </Link>
//                         <p className="mt-4">Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
//                     </div>

//                     <div className="col-lg-7 d-flex justify-content-lg-end">
//                         {/* <div className="row"> */}
//                             <div className="col-md-4 mt-4 mt-md-0 pt-2 pt-md-0 mx-auto mx-md-0">
//                                 <h5 className="footer-head">Contact us</h5>
//                                 <ul className="list-unstyled footer-list mt-4">
//                                     <li className="d-flex align-items-center justify-content-center justify-content-md-start">
//                                         <FiMail className="fea icon-sm text-foot align-middle" />
//                                         <Link to="mailto:info@apexpath.com" className="text-foot ms-2">info@apexpath.com</Link>
//                                     </li>
//                                     <li className="d-flex align-items-center justify-content-center justify-content-md-start mt-3">
//                                         <FiPhone className="fea icon-sm text-foot align-middle" />
//                                         <Link to="tel:+919268310732" className="text-foot ms-2">+91 9268310732</Link>
//                                     </li>
//                                 </ul>
//                                 <ul className="list-unstyled social-icon footer-social mt-4 text-center text-md-start">
//                                     <li className="list-inline-item">
//                                         <Link to="#" className="rounded-pill"><FiFacebook className="fea icon-sm fea-social" /></Link>
//                                     </li>
//                                     <li className="list-inline-item">
//                                         <Link to="#" className="rounded-pill"><FiInstagram className="fea icon-sm fea-social" /></Link>
//                                     </li>
//                                     <li className="list-inline-item">
//                                         <Link to="#" className="rounded-pill"><FiTwitter className="fea icon-sm fea-social" /></Link>
//                                     </li>
//                                     <li className="list-inline-item">
//                                         <Link to="#" className="rounded-pill"><FiLinkedin className="fea icon-sm fea-social" /></Link>
//                                     </li>
//                                 </ul>
//                             </div>
//                         {/* </div> */}
//                     </div>
//                 </div>

//                 <div className="footer-bar pt-4 mt-5">
//                     <div className="row align-items-center text-center text-md-start">
//                         <div className="col-md-6">
//                             <p className="mb-0">{new Date().getFullYear()} © healthBridge. Design & Develop with <i className="mdi mdi-heart text-danger"></i> by <Link to="https://apexpath.com/" target="_blank" className="text-reset">APEXPATH</Link>.</p>
//                         </div>
//                         <div className="col-md-6 mt-4 mt-md-0">
//                             <ul className="list-unstyled footer-list d-inline-flex justify-content-center justify-content-md-end mb-0">
//                                 <li className="list-inline-item"><Link to="/terms" className="text-foot me-3">Terms</Link></li>
//                                 <li className="list-inline-item"><Link to="/privacy" className="text-foot me-3">Privacy</Link></li>
//                                 <li className="list-inline-item"><Link to="/aboutus" className="text-foot me-3">About</Link></li>
//                                 <li className="list-inline-item"><Link to="/contact" className="text-foot me-3">Contact</Link></li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </footer>
//     );
// }

import React from "react";
import { Link } from "react-router-dom";
import logoLight from "../assets/images/healthBridge_transparent_logo.png";
import {
  FiFacebook,
  FiLinkedin,
  FiInstagram,
  FiTwitter,
  FiMail,
  FiPhone,
} from "../assets/icons/vander";

export default function Footer() {
  return (
    <footer className="">
      <div className="container">
        <div className="row text-center text-md-start justify-content-center">
          <div className="col-lg-5 mb-4 mb-lg-0 text-center">
            <Link to="#" className="logo-footer">
              <img src={logoLight} height="105" alt="healthBridge logo" />
            </Link>
            <p className="mt-4">
              Great doctor if you need your family member to get effective
              immediate assistance, emergency treatment or a simple
              consultation.
            </p>
          </div>

          <div className="col-lg-7 d-flex justify-content-center justify-content-lg-end">
            <div className="col-md-4 mt-4 mt-md-0 pt-2 pt-md-0">
              <h5 className="footer-head text-center text-md-start">
                Contact us
              </h5>
              <ul className="list-unstyled footer-list mt-4">
                <li className="d-flex align-items-center justify-content-center justify-content-md-start">
                  <FiMail className="fea icon-sm text-foot align-middle" />
                  <Link
                    to="mailto:info@apexpath.com"
                    className="text-foot ms-2"
                  >
                    info@apexpath.com
                  </Link>
                </li>
                <li className="d-flex align-items-center justify-content-center justify-content-md-start mt-3">
                  <FiPhone className="fea icon-sm text-foot align-middle" />
                  <Link to="tel:+919268310732" className="text-foot ms-2">
                    +91 9268310732
                  </Link>
                </li>
              </ul>
              <ul className="list-unstyled social-icon footer-social mt-4 text-center text-md-start">
                <li className="list-inline-item">
                  <Link to="#" className="rounded-pill">
                    <FiFacebook className="fea icon-sm fea-social" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="rounded-pill">
                    <FiInstagram className="fea icon-sm fea-social" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="rounded-pill">
                    <FiTwitter className="fea icon-sm fea-social" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="rounded-pill">
                    <FiLinkedin className="fea icon-sm fea-social" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bar pt-4 mt-5">
          <div className="row align-items-center text-center text-md-start">
            <div className="col-md-6">
              <p className="mb-0">
                {new Date().getFullYear()} © healthBridge. Design & Develop with{" "}
                <i className="mdi mdi-heart text-danger"></i> by{" "}
                <Link
                  to="https://apexpath.com/"
                  target="_blank"
                  className="text-reset"
                >
                  APEXPATH
                </Link>
                .
              </p>
            </div>
            <div className="col-md-6 mt-4 mt-md-0">
              <ul className="list-unstyled footer-list d-inline-flex justify-content-center justify-content-md-end mb-0">
                <li className="list-inline-item">
                  <Link to="/terms" className="text-foot me-3">
                    Terms
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/privacy" className="text-foot me-3">
                    Privacy
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/aboutus" className="text-foot me-3">
                    About
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/contact" className="text-foot me-3">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
