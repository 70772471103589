// import React, { useRef, useEffect } from 'react';

// const JitsiMeeting = ({ roomName }) => {
//   const jitsiContainerRef = useRef(null);

//   useEffect(() => {
//     const domain = 'meet.jit.si';
//     const options = {
//       roomName: roomName,
//       width: '100%',
//       height: '100%',
//       parentNode: jitsiContainerRef.current,
//       configOverwrite: {
//       },
//       interfaceConfigOverwrite: {
//       },
//     };

//     const api = new window.JitsiMeetExternalAPI(domain, options);

//     return () => {
//       api.dispose();
//     };
//   }, [roomName]);

//   return <div ref={jitsiContainerRef} style={{ height: '100vh', width: '100%' }} />;
// };

// export default JitsiMeeting;


// import React, { useRef, useEffect, useState } from 'react';

// const JitsiMeeting = ({ roomName }) => {
//   const jitsiContainerRef = useRef(null);

//   useEffect(() => {
//     const domain = 'meet.jit.si';
//     const options = {
//       roomName: roomName,
//       width: '100%',
//       height: '100%',
//       parentNode: jitsiContainerRef.current,
//       configOverwrite: {},
//       interfaceConfigOverwrite: {},
//     };

//     const api = new window.JitsiMeetExternalAPI(domain, options);

//     return () => {
//       api.dispose();
//     };
//   }, [roomName]);

//   return <div ref={jitsiContainerRef} style={{ height: '100vh', width: '100%' }} />;
// };

// const CreateMeeting = () => {
//   const [roomName, setRoomName] = useState('');
//   const [generatedUrl, setGeneratedUrl] = useState('');

//   // Generate a random room name or allow user to input one
//   const generateRoomName = () => {
//     const randomRoomName = `meeting-${Math.random().toString(36).substring(2, 7)}`;
//     setRoomName(randomRoomName);
//     setGeneratedUrl(`https://meet.jit.si/${randomRoomName}`);
//   };

//   // Allow the user to input a room name
//   const handleRoomNameChange = (e) => {
//     setRoomName(e.target.value);
//   };

//   const handleJoinMeeting = () => {
//     if (roomName.trim()) {
//       setGeneratedUrl(`https://meet.jit.si/${roomName}`);
//     }
//   };

//   const handleCopyUrl = () => {
//     navigator.clipboard.writeText(generatedUrl).then(() => {
//       alert('Meeting URL copied to clipboard!');
//     });
//   };

//   return (
//     <div>
//       <h1>Create or Join a Jitsi Meeting</h1>

//       {/* Input for room name or generate random */}
//       <div>
//         <input
//           type="text"
//           placeholder="Enter a custom room name"
//           value={roomName}
//           onChange={handleRoomNameChange}
//         />
//         <button onClick={handleJoinMeeting}>Create/Join Meeting</button>
//       </div>

//       {/* Generate a random room name */}
//       <button onClick={generateRoomName}>Generate Random Room</button>

//       {generatedUrl && (
//         <div>
//           <h2>Meeting URL:</h2>
//           <input type="text" value={generatedUrl} readOnly />
//           <button onClick={handleCopyUrl}>Copy URL</button>
//         </div>
//       )}

//       {/* JitsiMeeting Component */}
//       {roomName && <JitsiMeeting roomName={roomName} />}
//     </div>
//   );
// };

// export default CreateMeeting;


// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';

// const JitsiMeeting = ({ roomName, token }) => {
//   const jitsiContainerRef = useRef(null);

//   useEffect(() => {
//     const domain = 'meet.jit.si';
//     const options = {
//       roomName: roomName,
//       width: '100%',
//       height: '100%',
//       parentNode: jitsiContainerRef.current,
//       configOverwrite: {},
//       interfaceConfigOverwrite: {},
//       userInfo: {
//         email: 'janedoe@example.com', // Optional, you can dynamically populate from token
//         displayName: 'Jane Doe',      // Optional, you can dynamically populate from token
//       },
//       token: token, // Pass the token here
//     };

//     const api = new window.JitsiMeetExternalAPI(domain, options);

//     return () => {
//       api.dispose();
//     };
//   }, [roomName, token]);

//   return <div ref={jitsiContainerRef} style={{ height: '100vh', width: '100%' }} />;
// };

// const CreateMeeting = () => {
//   const [userName, setUserName] = useState('');
//   const [userEmail, setUserEmail] = useState('');
//   const [isModerator, setIsModerator] = useState(true);
//   const [avatarUrl, setAvatarUrl] = useState('');
//   const [roomName, setRoomName] = useState('');
//   const [token, setToken] = useState('');

//   // Fetch API URL from environment variable and token from localStorage
//   const baseURL = process.env.REACT_APP_API_URL;
//   const accessToken = localStorage.getItem("accessToken");

//   const handleCreateMeeting = async () => {
//     try {
//       if (!baseURL || !accessToken) {
//         alert('API URL or access token is missing!');
//         return;
//       }

//       // Prepare the payload for the token generation API
//       const payload = {
//         user_name: userName,
//         user_email: userEmail,
//         is_moderator: isModerator,
//         avatar_url: avatarUrl,
//       };

//       // Call the token generation API
//       const response = await axios.post(`${baseURL}/video/generate-token/`, payload, {
//         headers: {
//           Authorization: `Bearer ${accessToken}`, // Send the access token as a Bearer token
//         },
//       });

//       if (response.data && response.data.token) {
//         setToken(response.data.token);  // Save the token received
//         setRoomName(`meeting-${Math.random().toString(36).substring(2, 7)}`);  // Generate random room name
//       }
//     } catch (error) {
//       console.error('Error generating token:', error);
//       alert('There was an error generating the token. Please try again.');
//     }
//   };

//   return (
//     <div>
//       <h1>Create or Join a Jitsi Meeting</h1>

//       {/* User Details Form */}
//       <div>
//         <div>
//           <input
//             type="text"
//             placeholder="Enter your name"
//             value={userName}
//             onChange={(e) => setUserName(e.target.value)}
//           />
//         </div>
//         <div>
//           <input
//             type="email"
//             placeholder="Enter your email"
//             value={userEmail}
//             onChange={(e) => setUserEmail(e.target.value)}
//           />
//         </div>
//         <div>
//           <input
//             type="text"
//             placeholder="Enter avatar URL (optional)"
//             value={avatarUrl}
//             onChange={(e) => setAvatarUrl(e.target.value)}
//           />
//         </div>
//         <div>
//           <label>
//             <input
//               type="checkbox"
//               checked={isModerator}
//               onChange={(e) => setIsModerator(e.target.checked)}
//             />
//             Moderator
//           </label>
//         </div>

//         <button onClick={handleCreateMeeting}>Create Meeting</button>
//       </div>

//       {/* Display the generated URL and meeting details */}
//       {roomName && token && (
//         <div>
//           <h2>Meeting Created</h2>
//           <p>
//             <strong>Room Name: </strong>{roomName}
//           </p>
//           <p>
//             <strong>Join URL: </strong>
//             <a href={`https://meet.jit.si/${roomName}`} target="_blank" rel="noopener noreferrer">
//               {`https://meet.jit.si/${roomName}`}
//             </a>
//           </p>
//           <JitsiMeeting roomName={roomName} token={token} />
//         </div>
//       )}
//     </div>
//   );
// };

// export default CreateMeeting;


// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { doctorDetails } from '../../store/doctor/actions';

// const JitsiMeeting = ({ roomName, token, doctorDetailsResults }) => {
//   const jitsiContainerRef = useRef(null);

//   useEffect(() => {
//     const domain = 'meet.jit.si';

//     if (!doctorDetailsResults) return; // Ensure doctor details are loaded before rendering the meeting

//     const options = {
//       roomName: roomName,
//       width: '100%',
//       height: '100%',
//       parentNode: jitsiContainerRef.current,
//       configOverwrite: {},
//       interfaceConfigOverwrite: {},
//       userInfo: {
//         email: doctorDetailsResults.email,   // Dynamically set the doctor's email
//         displayName: `${doctorDetailsResults.first_name} ${doctorDetailsResults.last_name}`, // Dynamically set the doctor's name
//         avatarUrl: doctorDetailsResults.image, // Dynamically set the doctor's avatar
//       },
//       token: token, // Pass the token here
//     };

//     const api = new window.JitsiMeetExternalAPI(domain, options);

//     return () => {
//       api.dispose();
//     };
//   }, [roomName, token, doctorDetailsResults]);

//   return <div ref={jitsiContainerRef} style={{ height: '100vh', width: '100%' }} />;
// };

// const CreateMeeting = () => {
//   const [roomName, setRoomName] = useState('');
//   const [token, setToken] = useState('');
//   const [loading, setLoading] = useState(false);

//   // Fetch doctor details from Redux store
//   const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
//   const dispatch = useDispatch();
//   const doctorid = localStorage.getItem('doctorId');

//   // Fetch the doctor details when the component mounts or doctorId changes
//   useEffect(() => {
//     if (doctorid) {
//       dispatch(doctorDetails(doctorid));
//     }
//   }, [doctorid, dispatch]);

//   // Fetch API URL from environment variable and token from localStorage
//   const baseURL = process.env.REACT_APP_API_URL;
//   const accessToken = localStorage.getItem('accessToken');

//   useEffect(() => {
//     const createMeeting = async () => {
//       if (!baseURL || !accessToken) {
//         alert('API URL or access token is missing!');
//         return;
//       }

//       setLoading(true);

//       try {
//         // Prepare userDetails with the doctor data fetched from Redux
//         const userDetails = {
//           user_name: `${doctorDetailsResults.first_name} `,
//           user_email: doctorDetailsResults.email,
//           avatar_url: doctorDetailsResults.image,
//           is_moderator: true,
//         };

//         // Call the token generation API to create the meeting room
//         const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`, // Send the access token as a Bearer token
//           },
//         });

//         if (response.data && response.data.token) {
//           setToken(response.data.token);  // Save the token received
//           setRoomName(`meeting-${Math.random().toString(36).substring(2, 7)}`);  // Generate random room name
//         }
//       } catch (error) {
//         console.error('Error generating token:', error);
//         alert('There was an error generating the token. Please try again.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (doctorDetailsResults) {
//       createMeeting();
//     }
//   }, [baseURL, accessToken, doctorDetailsResults]);

//   return (
//     <div>
//       <h1>Doctor's Meeting Room</h1>

//       {/* Loading Indicator */}
//       {loading && <p>Loading the meeting room...</p>}

//       {/* Display the generated URL and meeting details */}
//       {roomName && token && (
//         <div>
//           <h2>Meeting Created</h2>
//           <p>
//             <strong>Room Name: </strong>{roomName}
//           </p>
//           <p>
//             <strong>Join URL: </strong>
//             <a href={`https://meet.jit.si/${roomName}`} target="_blank" rel="noopener noreferrer">
//               {`https://meet.jit.si/${roomName}`}
//             </a>
//           </p>
//           {/* <JitsiMeeting roomName={roomName} token={token} doctorDetailsResults={doctorDetailsResults} /> */}
//         </div>
//       )}
//     </div>
//   );
// };

// export default CreateMeeting;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { doctorDetails } from '../../store/doctor/actions';

// const CreateMeeting = () => {
//   const [roomName, setRoomName] = useState('');
//   const [token, setToken] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility

//   // Fetch doctor details from Redux store
//   const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
//   const dispatch = useDispatch();
//   const doctorid = localStorage.getItem('doctorId');

//   // Fetch the doctor details when the component mounts or doctorId changes
//   useEffect(() => {
//     if (doctorid) {
//       dispatch(doctorDetails(doctorid));
//     }
//   }, [doctorid, dispatch]);

//   // Fetch API URL from environment variable and token from localStorage
//   const baseURL = process.env.REACT_APP_API_URL;
//   const accessToken = localStorage.getItem('accessToken');

//   useEffect(() => {
//     const createMeeting = async () => {
//       if (!baseURL || !accessToken) {
//         alert('API URL or access token is missing!');
//         return;
//       }

//       setLoading(true);

//       try {
//         // Prepare userDetails with the doctor data fetched from Redux
//         const userDetails = {
//           user_name: `${doctorDetailsResults.first_name} `,
//           user_email: doctorDetailsResults.email,
//           avatar_url: doctorDetailsResults.image,
//           is_moderator: true,
//         };

//         // Call the token generation API to create the meeting room
//         const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`, // Send the access token as a Bearer token
//           },
//         });

//         if (response.data && response.data.token) {
//           setToken(response.data.token);  // Save the token received
//           const newRoomName = `meeting-${Math.random().toString(36).substring(2, 7)}`;  // Generate random room name
//           setRoomName(newRoomName);
//           setIsModalOpen(true);  // Open the modal after the room is created
//         }
//       } catch (error) {
//         console.error('Error generating token:', error);
//         alert('There was an error generating the token. Please try again.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (doctorDetailsResults) {
//       createMeeting();
//     }
//   }, [baseURL, accessToken, doctorDetailsResults]);

//   // Inline styles for modal
//   const modalStyles = {
//     overlay: {
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0,
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       zIndex: 9999,
//     },
//     content: {
//       backgroundColor: 'white',
//       padding: '20px',
//       borderRadius: '8px',
//       width: '400px',
//       textAlign: 'center',
//     },
//     button: {
//       marginTop: '20px',
//       padding: '10px 20px',
//       backgroundColor: '#007BFF',
//       color: 'white',
//       border: 'none',
//       cursor: 'pointer',
//       borderRadius: '4px',
//     },
//     buttonHover: {
//       backgroundColor: '#0056b3',
//     },
//   };

//   return (
//     <div>
//       <h1>Doctor's Meeting Room</h1>

//       {/* Loading Indicator */}
//       {loading && <p>Loading the meeting room...</p>}

//       {/* Display Meeting Details */}
//       {roomName && token && (
//         <div>
//           <h2>Meeting Created</h2>
//           <p>
//             <strong>Room Name: </strong>{roomName}
//           </p>
//           <p>
//             <strong>Join URL: </strong>
//             <a href={`https://meet.jit.si/${roomName}`} target="_blank" rel="noopener noreferrer">
//               {`https://meet.jit.si/${roomName}`}
//             </a>
//           </p>
//         </div>
//       )}

//       {/* Modal inline */}
//       {isModalOpen && (
//         <div style={modalStyles.overlay}>
//           <div style={modalStyles.content}>
//             <h2>Meeting Created</h2>
//             <p>
//               <strong>Room Name: </strong>{roomName}
//             </p>
//             <p>
//               <strong>Join URL: </strong>
//               <a href={`https://meet.jit.si/${roomName}`} target="_blank" rel="noopener noreferrer">
//                 {`https://meet.jit.si/${roomName}`}
//               </a>
//             </p>
//             <button
//               style={modalStyles.button}
//               onClick={() => setIsModalOpen(false)}
//               onMouseOver={(e) => e.target.style.backgroundColor = modalStyles.buttonHover.backgroundColor}
//               onMouseOut={(e) => e.target.style.backgroundColor = modalStyles.button.backgroundColor}
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CreateMeeting;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { doctorDetails } from '../../store/doctor/actions';

// const CreateMeeting = () => {
//   const [roomName, setRoomName] = useState('');
//   const [token, setToken] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
//   const [meetingStarted, setMeetingStarted] = useState(false); // Flag to check if the meeting has started

//   // Fetch doctor details from Redux store
//   const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
//   const dispatch = useDispatch();
//   const doctorid = localStorage.getItem('doctorId');

//   // Fetch the doctor details when the component mounts or doctorId changes
//   useEffect(() => {
//     if (doctorid) {
//       dispatch(doctorDetails(doctorid));
//     }
//   }, [doctorid, dispatch]);

//   // Fetch API URL from environment variable and token from localStorage
//   const baseURL = process.env.REACT_APP_API_URL;
//   const accessToken = localStorage.getItem('accessToken');

//   useEffect(() => {
//     const createMeeting = async () => {
//       if (!baseURL || !accessToken) {
//         alert('API URL or access token is missing!');
//         return;
//       }

//       setLoading(true);

//       try {
//         // Prepare userDetails with the doctor data fetched from Redux
//         const userDetails = {
//           user_name: `${doctorDetailsResults.first_name} `,
//           user_email: doctorDetailsResults.email,
//           avatar_url: doctorDetailsResults.image,
//           is_moderator: true,
//         };

//         // Call the token generation API to create the meeting room
//         const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`, // Send the access token as a Bearer token
//           },
//         });

//         if (response.data && response.data.token) {
//           setToken(response.data.token);  // Save the token received
//           const newRoomName = `meeting-${Math.random().toString(36).substring(2, 7)}`;  // Generate random room name
//           setRoomName(newRoomName);
//           setMeetingStarted(true);  // Start the meeting once token is received
//         }
//       } catch (error) {
//         console.error('Error generating token:', error);
//         alert('There was an error generating the token. Please try again.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (doctorDetailsResults) {
//       createMeeting();
//     }
//   }, [baseURL, accessToken, doctorDetailsResults]);

//   // Inline styles for modal
//   const modalStyles = {
//     overlay: {
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0,
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       zIndex: 9999,
//     },
//     content: {
//       backgroundColor: 'white',
//       padding: '20px',
//       borderRadius: '8px',
//       width: '400px',
//       textAlign: 'center',
//     },
//     button: {
//       marginTop: '20px',
//       padding: '10px 20px',
//       backgroundColor: '#007BFF',
//       color: 'white',
//       border: 'none',
//       cursor: 'pointer',
//       borderRadius: '4px',
//     },
//     buttonHover: {
//       backgroundColor: '#0056b3',
//     },
//   };

//   // Function to load the meeting iframe
//   const renderMeetingIframe = () => {
//     if (!token || !roomName) return null;

//     const iframeSrc = `https://meet.jit.si/${roomName}#config.token=${token}&config.startWithAudioMuted=true&config.startWithVideoMuted=false`;

//     return (
//       <iframe
//         src={iframeSrc}
//         style={{
//           width: '100%',
//           height: '600px',
//           border: 'none',
//         }}
//         allow="camera; microphone; fullscreen; display-capture"
//         title="Jitsi Meeting"
//       />
//     );
//   };

//   return (
//     <div>
//       <h1>Doctor's Meeting Room</h1>

//       {/* Loading Indicator */}
//       {loading && <p>Loading the meeting room...</p>}

//       {/* Modal Inline (Only for displaying the link, and meeting started flag) */}
//       {!meetingStarted && roomName && token && (
//         <div>
//           <h2>Meeting Created</h2>
//           <p>
//             <strong>Room Name: </strong>{roomName}
//           </p>
//           <p>
//             <strong>Join URL: </strong>
//             <a href={`https://meet.jit.si/${roomName}`} target="_blank" rel="noopener noreferrer">
//               {`https://meet.jit.si/${roomName}`}
//             </a>
//           </p>
//         </div>
//       )}

//       {/* Modal inline */}
//       {isModalOpen && (
//         <div style={modalStyles.overlay}>
//           <div style={modalStyles.content}>
//             <h2>Meeting Created</h2>
//             <p>
//               <strong>Room Name: </strong>{roomName}
//             </p>
//             <p>
//               <strong>Join URL: </strong>
//               <a href={`https://meet.jit.si/${roomName}`} target="_blank" rel="noopener noreferrer">
//                 {`https://meet.jit.si/${roomName}`}
//               </a>
//             </p>
//             <button
//               style={modalStyles.button}
//               onClick={() => setIsModalOpen(false)}
//               onMouseOver={(e) => e.target.style.backgroundColor = modalStyles.buttonHover.backgroundColor}
//               onMouseOut={(e) => e.target.style.backgroundColor = modalStyles.button.backgroundColor}
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Embed the meeting iframe directly into the page */}
//       {meetingStarted && renderMeetingIframe()}
//     </div>
//   );
// };

// export default CreateMeeting;


// import React, { useEffect, useState, useRef } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { doctorDetails } from '../../store/doctor/actions';

// const CreateMeeting = () => {
//   const [roomName, setRoomName] = useState('');
//   const [token, setToken] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [meetingStarted, setMeetingStarted] = useState(false);
//   const jitsiContainerRef = useRef(null);
//   const jitsiApiRef = useRef(null);

//   const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
//   const dispatch = useDispatch();
//   const doctorid = localStorage.getItem('doctorId');

//   const baseURL = process.env.REACT_APP_API_URL;
//   const jitsiServerURL = "8x8.vc"; 
//   const accessToken = localStorage.getItem('accessToken');

//   useEffect(() => {
//     if (doctorid) {
//       dispatch(doctorDetails(doctorid));
//     }
//   }, [doctorid, dispatch]);

//   useEffect(() => {
//     const createMeeting = async () => {
//       if (!baseURL || !accessToken) {
//         alert('API URL or access token is missing!');
//         return;
//       }

//       setLoading(true);

//       try {
//         const userDetails = {
//           user_name: `${doctorDetailsResults.first_name}`,
//           user_email: doctorDetailsResults.email,
//           avatar_url: doctorDetailsResults.image,
//           is_moderator: true,
//         };

//         const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });

//         if (response.data && response.data.token) {
//           setToken(response.data.token);
//           const newRoomName = `meeting-${Math.random().toString(36).substring(2, 7)}`;
//           setRoomName(newRoomName);
//           setMeetingStarted(true);
//         }
//       } catch (error) {
//         console.error('Error generating token:', error);
//         alert('There was an error generating the token. Please try again.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (doctorDetailsResults) {
//       createMeeting();
//     }
//   }, [baseURL, accessToken, doctorDetailsResults]);

//   useEffect(() => {
//     if (meetingStarted && token && roomName) {
//       const { JitsiMeetExternalAPI } = window;
//       if (JitsiMeetExternalAPI && jitsiContainerRef.current) {
//         jitsiApiRef.current = new JitsiMeetExternalAPI(jitsiServerURL, {
//           roomName: `vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/${roomName}`, // Use your complete room name
//           parentNode: jitsiContainerRef.current,
//           jwt: token, // Use the token from your API
//           configOverwrite: {
//             startWithAudioMuted: true,
//             startWithVideoMuted: false,
//           },
//           userInfo: {
//             email: doctorDetailsResults.email,
//             displayName: doctorDetailsResults.first_name,
//           },
//         });

//         jitsiApiRef.current.addEventListener('readyToClose', () => {
//           setMeetingStarted(false);
//         });
//       }
//     }

//     return () => {
//       if (jitsiApiRef.current) {
//         jitsiApiRef.current.dispose();
//       }
//     };
//   }, [meetingStarted, token, roomName, jitsiServerURL, doctorDetailsResults]);

//   return (
//     <div>
//       <h1>Doctor's Meeting Room</h1>

//       {loading && <p>Loading the meeting room...</p>}

//       {meetingStarted ? (
//         <div id="jaas-container" ref={jitsiContainerRef} style={{ height: '600px', width: '100%' }} />
//       ) : (
//         <div>
//           <h2>Meeting Created</h2>
//           <p>
//             <strong>Room Name: </strong>{roomName}
//           </p>
//           <p>
//             <strong>Join URL: </strong>
//             <a href={`https://${jitsiServerURL}/${roomName}`} target="_blank" rel="noopener noreferrer">
//               {`https://${jitsiServerURL}/${roomName}`}
//             </a>
//           </p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CreateMeeting;

// import React, { useEffect, useRef, useState } from 'react';
// import { doctorDetails } from '../../store/doctor/actions';
// import { useDispatch, useSelector } from 'react-redux';
// import axios from 'axios'; // Ensure axios is imported

// const JitsiMeeting = () => {
//   // const containerRef = useRef(null);
//   const [jwtToken, setJwtToken] = useState(null);
//   const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
//   const dispatch = useDispatch();
//   const doctorid = localStorage.getItem('doctorId');
//   const accessToken = localStorage.getItem('accessToken'); 
//   const baseURL = process.env.REACT_APP_API_URL;
//   // Fetch doctor details
//   console.log("jwtToken",jwtToken)
//   useEffect(() => {
//     if (doctorid) {
//       dispatch(doctorDetails(doctorid));
//     }
//   }, [doctorid, dispatch]);

//   // Fetch JWT token only when doctorDetailsResults is available
//   useEffect(() => {
//     const fetchJwtToken = async () => {
//       try {
//         const userDetails = {
//           user_name: doctorDetailsResults.first_name,
//           user_email: doctorDetailsResults.email,
//           avatar_url: doctorDetailsResults.image,
//           is_moderator: true,
//         };

//         const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });

//         setJwtToken(response.data.token); 
//       } catch (error) {
//         console.error("Failed to fetch JWT token:", error);
//       }
//     };

//     if (doctorDetailsResults) {
//       fetchJwtToken();
//     }
//   }, [doctorDetailsResults]); 

//   // Initialize Jitsi only when jwtToken is available
//   useEffect(() => {
//     if (jwtToken && window.JitsiMeetExternalAPI) {
//       const api = new window.JitsiMeetExternalAPI("8x8.vc", {
//         roomName: "vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/SampleAppGraphicSuitesGrantHeroically",
//         // parentNode: containerRef.current,
//         parentNode: document.querySelector('#jaas-container'), 


//         jwt: jwtToken,
//       });

//       return () => api.dispose();
//     } else if (!window.JitsiMeetExternalAPI) {
//       console.error("JitsiMeetExternalAPI not loaded.");
//     }
//   }, [jwtToken]); 

//   return (
//     <div style={{ height: '100vh', width: '100%' }}>
//       {/* <div ref={containerRef} style={{ height: '100%', width: '100%' }} /> */}
//       <div id="jaas-container" style={{ height: '100%', width: '100%' }} />

//     </div>
//   );
// };

// export default JitsiMeeting;

// src/components/JitsiMeet.js

// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { doctorDetails } from '../../store/doctor/actions';
// import axios from 'axios';

// const JitsiMeet = () => {
//   const [jwtToken, setJwtToken] = useState(null);
 
//   const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
//   const dispatch = useDispatch();
//   const doctorid = localStorage.getItem('doctorId');
//   const accessToken = localStorage.getItem('accessToken'); 
//   const baseURL = process.env.REACT_APP_API_URL;
//   // Fetch doctor details
//   console.log("jwtToken",jwtToken)
//   useEffect(() => {
//     if (doctorid) {
//       dispatch(doctorDetails(doctorid));
//     }
//   }, [doctorid, dispatch]);

//   // Fetch JWT token only when doctorDetailsResults is available
//   useEffect(() => {
//     const fetchJwtToken = async () => {
//       try {
//         const userDetails = {
//           user_name: doctorDetailsResults.first_name,
//           user_email: doctorDetailsResults.email,
//           avatar_url: doctorDetailsResults.image,
//           is_moderator: true,
//         };

//         const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });

//         setJwtToken(response.data.token); 
//       } catch (error) {
//         console.error("Failed to fetch JWT token:", error);
//       }
//     };

//     if (doctorDetailsResults) {
//       fetchJwtToken();
//     }
//   }, [doctorDetailsResults]); 
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://8x8.vc/vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/external_api.js';
//     script.async = true;
//     document.body.appendChild(script);

//     script.onload = () => {
//       const api = new window.JitsiMeetExternalAPI("8x8.vc", {
//         roomName: "vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/SampleAppGraphicSuitesGrantHeroically",
//         parentNode: document.querySelector('#jaas-container'),
//         // jwt: jwtToken
//         jwt : "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InZwYWFzLW1hZ2ljLWNvb2tpZS1hZTE2YTIzNWJiYmM0ZDZlYWQwZTAwYjhhMGJhOTQ3YS81YjIzMmMifQ.eyJleHAiOjE3MzE2NTkxNTIsIm5iZlRpbWUiOjE3MzE2NTE5NDIsInJvb20iOiIqIiwic3ViIjoidnBhYXMtbWFnaWMtY29va2llLWFlMTZhMjM1YmJiYzRkNmVhZDBlMDBiOGEwYmE5NDdhIiwiY29udGV4dCI6eyJ1c2VyIjp7Im1vZGVyYXRvciI6InRydWUiLCJpZCI6IjAwN2UwNmY2LTZmMmUtNGQ3Yi1iYmU5LWFjN2I0YWZkY2M4MCIsIm5hbWUiOiJEaHJ1diIsImVtYWlsIjoiRGhydXZSYXRoaUBnbWFpbC5jb20iLCJhdmF0YXIiOiJodHRwOi8vbW9ua2Zpc2gtYXBwLWRlOXM3Lm9uZGlnaXRhbG9jZWFuLmFwcC9tZWRpYS9pbWFnZS9tYWxlX3doaXRlLmpwZWcifSwiZmVhdHVyZXMiOnsibGl2ZXN0cmVhbWluZyI6InRydWUiLCJyZWNvcmRpbmciOiJ0cnVlIiwib3V0Ym91bmQtY2FsbCI6InRydWUiLCJzaXAtb3V0Ym91bmQtY2FsbCI6InRydWUiLCJ0cmFuc2NyaXB0aW9uIjoidHJ1ZSJ9fSwiaXNzIjoiY2hhdCIsImF1ZCI6ImppdHNpIn0.Y2x2DXGYF_-fOJrbtV97qqN6_eTVyoZAbEOD1wmNRBwdTQ6CJS1C1qr06lJM_GzP-FPtpeLtsfTAkKklP5-AYq0mAqJkZQJ3N9Cvy1WY7fGrxFk2C45xL8VjVK7HxOuQXWbLrEV9XbGv9BabZZS7b_EyehmQ-MpSbJ9Lg5NsVHoYzlkkZrsb3Y17PoaLpvhlGk5qDCNZYuCl9FnYpmHSAmtWrvX9Nj-ZUnDG4EHC8-EICP9KkK4USoacM6yFBAdoJd02keePD15NmoQZ978izr1tzXjVMn35y38ECPjVon2b6ZTx1-nKAOKBShK7-n6nq9T284JDatVmAELr-vOKQQ"
//         // jwt: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InZwYWFzLW1hZ2ljLWNvb2tpZS1hZTE2YTIzNWJiYmM0ZDZlYWQwZTAwYjhhMGJhOTQ3YS81YjIzMmMifQ.eyJhdWQiOiJqaXRzaSIsImlzcyI6ImNoYXQiLCJpYXQiOjE3MzE1OTA4MDYsImV4cCI6MTczMTU5ODAwNiwibmJmIjoxNzMxNTkwODAxLCJzdWIiOiJ2cGFhcy1tYWdpYy1jb29raWUtYWUxNmEyMzViYmJjNGQ2ZWFkMGUwMGI4YTBiYTk0N2EiLCJjb250ZXh0Ijp7ImZlYXR1cmVzIjp7ImxpdmVzdHJlYW1pbmciOmZhbHNlLCJvdXRib3VuZC1jYWxsIjpmYWxzZSwic2lwLW91dGJvdW5kLWNhbGwiOmZhbHNlLCJ0cmFuc2NyaXB0aW9uIjpmYWxzZSwicmVjb3JkaW5nIjpmYWxzZX0sInVzZXIiOnsiaGlkZGVuLWZyb20tcmVjb3JkZXIiOmZhbHNlLCJtb2RlcmF0b3IiOnRydWUsIm5hbWUiOiJUZXN0IFVzZXIiLCJpZCI6Imdvb2dsZS1vYXV0aDJ8MTA2NTQ4NDU4NDU2NTAzNDQxNjYzIiwiYXZhdGFyIjoiIiwiZW1haWwiOiJ0ZXN0LnVzZXJAY29tcGFueS5jb20ifX0sInJvb20iOiIqIn0.Cv6jMWGdQPQZEE8RPzKazA8vQHxQQoMF7b8u9D2ppkhBhd6Hf6NpHg_b5oIdtCvpskm2ErlsincLh8x9vOBCjIhLJO94jAiITaUtR7vVpKZetRJCl502d-RrZxEfILP9Gv0S6N6ZHOvY3D-CZoX3QNK0mPE6E8idhmxLp1nqvYaDFXpJxyLetmNqJ2LXIr38aUjlRB4japctMIt3EHSo3O6izUxlXLAlNjcisNJ3MIKN-97B8PDcqPnVLUwa4GYA_6fuEcOg2cdL6_oz3LLpXbLHjPBp0dxYK69NzqTtRX8PSKj0P9AfQQnb6CHJOif4KY0O1CzE24Shc-HVbybP7Q"
//       });
//     };

//     // Cleanup when component unmounts
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   return <div id="jaas-container" style={{ height: '100vh' }} />;
// };

// export default JitsiMeet;


// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';  // Make sure axios is imported
// import { doctorDetails } from '../../store/doctor/actions';

// const JitsiMeet = () => {
//   const location = useLocation();
//   const [jwtToken, setJwtToken] = useState(null);  // State for JWT token
//   const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
//   const dispatch = useDispatch();
//   const doctorid = localStorage.getItem('doctorId');
//   const accessToken = localStorage.getItem('accessToken');
//   const baseURL = process.env.REACT_APP_API_URL;

//   // Fetch doctor details
//   useEffect(() => {
//     if (doctorid) {
//       dispatch(doctorDetails(doctorid));  // Fetch doctor details if doctorId exists
//     }
//   }, [doctorid, dispatch]);

//   // Fetch JWT token after doctor details are available
//   useEffect(() => {
//     if (doctorDetailsResults && accessToken) {
//       const fetchJwtToken = async () => {
//         try {
//           const userDetails = {
//             user_name: doctorDetailsResults.first_name,
//             user_email: doctorDetailsResults.email,
//             avatar_url: doctorDetailsResults.image,
//             is_moderator: true,
//           };

//           const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
//             headers: {
//               Authorization: `Bearer ${accessToken}`,
//             },
//           });

//           // Clean the JWT token to remove the 'b' prefix and single quotes
//           const cleanedToken = response.data.token.slice(2, -1);  // Remove 'b' and the last quote
//           setJwtToken(cleanedToken);  // Set the cleaned JWT token
//         } catch (error) {
//           console.error('Failed to fetch JWT token:', error);
//         }
//       };

//       fetchJwtToken();  // Call the function to fetch the JWT token
//     }
//   }, [doctorDetailsResults, accessToken, baseURL]);  // Ensure these dependencies are updated

//   // Load the Jitsi Meet API when room name and JWT token are available
//   useEffect(() => {
//     const roomName = sessionStorage.getItem('roomName');

//     if (roomName && jwtToken) {
//       const script = document.createElement('script');
//       script.src = 'https://8x8.vc/vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/external_api.js';
//       script.async = true;
//       document.body.appendChild(script);

//       script.onload = () => {
//         const api = new window.JitsiMeetExternalAPI('8x8.vc', {
//           roomName: roomName,
//           parentNode: document.querySelector('#jaas-container'),
//           jwt: jwtToken,  // Pass the cleaned JWT token to Jitsi Meet API
//         });
//       };

//       return () => {
//         document.body.removeChild(script);  // Clean up the script when the component unmounts
//       };
//     } else {
//       console.error('Room name or JWT token is missing.');
//     }
//   }, []);  // Re-run this effect whenever jwtToken changes

//   return <div id="jaas-container" style={{ height: '100vh' }} />;
// };

// export default JitsiMeet;


// import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';

// const JitsiMeet = () => {
  
//   const [jwtToken, setJwtToken] = useState(null);
//   const [roomName, setRoomName] = useState(null);
//   const location = useLocation();
// console.log("jwtToken",jwtToken)
//   useEffect(() => {
//     const storedRoomName = sessionStorage.getItem('roomName');
//     const storedToken = sessionStorage.getItem('token');
    
//     if (storedRoomName && storedToken) {
//       setRoomName(storedRoomName);
//       setJwtToken(storedToken);
//     } else {
//       console.error('Room name or JWT token is missing.');
//     }
//   }, [location]);

//   useEffect(() => {
//     if (roomName && jwtToken) {
//       // Load the Jitsi Meet external API
//       const script = document.createElement('script');
//       script.src = 'https://8x8.vc/vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/external_api.js';
//       script.async = true;
//       document.body.appendChild(script);

//       script.onload = () => {
//         const api = new window.JitsiMeetExternalAPI('8x8.vc', {
//           roomName: roomName,
//           parentNode: document.querySelector('#jaas-container'),
//           jwt: jwtToken,  
//         });
//       };

//       return () => {
//         document.body.removeChild(script);  
//       };
//     }
//   }, [roomName, jwtToken]);

//   return <div id="jaas-container" style={{ height: '100vh' }} />;
// };

// export default JitsiMeet;


// import React, { useEffect, useState } from 'react';
// import { useNavigate, useLocation, useParams } from 'react-router-dom';
// import axios from 'axios';
// import { useSelector, useDispatch } from 'react-redux';
// import { doctorDetails } from '../../store/doctor/actions';

// const JitsiMeet = () => {
//   const { appointmentid } = useParams(); 
//   const [roomDetails, setRoomDetails] = useState(null);
//   console.log("roomDetails",roomDetails)
//   const [jwtToken, setJwtToken] = useState(null);
//   console.log("jwtToken",jwtToken)
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const storedUserRole = localStorage.getItem("userRole");
// console.log("storedUserRole",storedUserRole)
//   const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
//   const doctorId = localStorage.getItem('doctorId');
//   const baseURL = process.env.REACT_APP_API_URL;
//   const dispatch = useDispatch();

  
//   useEffect(() => {
//     if (doctorId) {
//       dispatch(doctorDetails(doctorId));  
//     }
//   }, [doctorId, dispatch]);

  
//   useEffect(() => {
//     const fetchRoomDetails = async () => {
//       setLoading(true); 
//       try {
//         const response = await axios.get(`${baseURL}/appointment/${appointmentid}/`);
//         if (response.data && response.data.room_name) {
//           setRoomDetails(response.data.room_name); 
//         } else {
//           console.error('Room name not found in response');
//         }
//       } catch (error) {
//         console.error("Error fetching room details:", error);
//       } finally {
//         setLoading(false); 
//       }
//     };

//     fetchRoomDetails();userEmail
//   }, [appointmentid]);
//   const username= localStorage.getItem("firstName");
//   const userEmail= localStorage.getItem("userEmail");
//   const Image = localStorage.getItem("Image");
//   const RoomName =`vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/${roomDetails}`

//   useEffect(() => {
//     const generateToken = async () => {
//       if (roomDetails && doctorDetailsResults) {
//         setLoading(true); 
//         try {
        
//           const userDetails = {
//             user_name: username,
//             user_email: userEmail,
//             avatar_url: Image,
//             is_moderator: storedUserRole === "doctor",
//             // is_moderator: true,
//             room_name: roomDetails.room_name, 
//           };

//           const accessToken = localStorage.getItem('accessToken');

//           const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
//             headers: {
//               Authorization: `Bearer ${accessToken}`, 
//             },
//           });

//           const cleanedToken = response.data.token.slice(2, -1);
//          console.log("cleanedToken",cleanedToken)

         
//           setJwtToken(cleanedToken);
          

//           // const prefixedRoomName = `vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/${roomDetails.room_name}`;
//           // sessionStorage.setItem('roomName', prefixedRoomName);

//         } catch (error) {
//           console.error("Error generating token:", error);
//         } finally {
//           setLoading(false); 
//         }
//       }
//     };

//     if (roomDetails) {
//       generateToken();
//     }
//   }, [roomDetails, doctorDetailsResults,storedUserRole]);
//   useEffect(() => {
//     if (roomDetails ) {
//       const script = document.createElement('script');
//       script.src = 'https://8x8.vc/vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/external_api.js';
//       script.async = true;
//       document.body.appendChild(script);

//       script.onload = () => {
//         const api = new window.JitsiMeetExternalAPI('8x8.vc', {
//           roomName: RoomName,
//           parentNode: document.querySelector('#jaas-container'),  
//           jwt: jwtToken,  
//         });
//       };

//       return () => {
//         document.body.removeChild(script); 
//       };
//     }
//   }, [roomDetails]);

//   return (
//     <div id="jaas-container" style={{ height: '100vh' }}>
//       {loading && <div>Loading...</div>} 
//     </div>
//   );
// };

// export default JitsiMeet;


import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { doctorDetails } from '../../store/doctor/actions';

const JitsiMeet = () => {
  const { appointmentid } = useParams();
  const [roomDetails, setRoomDetails] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [jitsiApi, setJitsiApi] = useState(null); // Track the Jitsi API instance
  const storedUserRole = localStorage.getItem("userRole");
  const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
  const doctorId = localStorage.getItem('doctorId');
  const baseURL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();

  useEffect(() => {
    if (doctorId) {
      dispatch(doctorDetails(doctorId));
    }
  }, [doctorId, dispatch]);

  useEffect(() => {
    const fetchRoomDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseURL}/appointment/${appointmentid}/`);
        if (response.data && response.data.room_name) {
          setRoomDetails(response.data.room_name);
        } else {
          console.error('Room name not found in response');
        }
      } catch (error) {
        console.error("Error fetching room details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRoomDetails();
  }, [appointmentid]);

  const username = localStorage.getItem("firstName");
  const userEmail = localStorage.getItem("userEmail");
  const Image = localStorage.getItem("Image");
  const RoomName = `vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/${roomDetails}`;

  useEffect(() => {
    const generateToken = async () => {
      if (!roomDetails || !doctorDetailsResults || jwtToken) return;

      setLoading(true);
      try {
        const userDetails = {
          user_name: username,
          user_email: userEmail,
          avatar_url: Image,
          is_moderator: storedUserRole === "doctor",
          room_name: roomDetails,
        };

        const accessToken = localStorage.getItem('accessToken');

        const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const cleanedToken = response.data.token.slice(2, -1);
        setJwtToken(cleanedToken);
      } catch (error) {
        console.error("Error generating token:", error);
      } finally {
        setLoading(false);
      }
    };

    generateToken();
  }, [roomDetails, doctorDetailsResults, jwtToken]);

  useEffect(() => {
    if (roomDetails && jwtToken && !jitsiApi) {
      const script = document.createElement('script');
      script.src = 'https://8x8.vc/vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/external_api.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        const api = new window.JitsiMeetExternalAPI('8x8.vc', {
          roomName: RoomName,
          parentNode: document.querySelector('#jaas-container'),
          jwt: jwtToken,
        });
        setJitsiApi(api); // Save the API instance to prevent duplicates
      };

      return () => {
        if (jitsiApi) {
          jitsiApi.dispose(); // Cleanup the Jitsi instance
        }
        document.body.removeChild(script); // Remove the script from DOM
      };
    }
  }, [roomDetails, jwtToken, jitsiApi, RoomName]);

  return (
    <div id="jaas-container" style={{ height: '100vh' }}>
      {loading && <div>Loading...</div>}
    </div>
  );
};

export default JitsiMeet;
