// import React from "react";
// import Header from "../../components/CommonComponents/Header";
// import { useLocation, useNavigate } from "react-router-dom";
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";

// const Videocall = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const showBackButton = location.key !== "default";

//   return (
//     <>
//       <Navbar
//         navDark={true}
//         manuClass="navigation-menu nav-left"
//         containerClass="container"
//       />
//       <section className="bg-dashboard">
//         <div className="container-fluid">
//           <div className="row">
//             <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//             <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//               <Header
//                 title="Video Call"
//                 onBackClick={() => navigate(-1)}
//                 showBackButton={showBackButton}
//               />
//               {/* Replace <div>index</div> with your actual content */}
//               <div>Your Video Call Content Here</div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Videocall;


// import React, { useState, useEffect } from 'react';
// import Header from '../../components/CommonComponents/Header';
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import { useNavigate } from 'react-router-dom';

// const Videopage = () => {
//   const [roomDetails, setRoomDetails] = useState(null);
//   const navigate = useNavigate();

//   const appointmentId = '12345'; 
//   const hardcodedRoomDetails = {
//     roomName: 'SampleRoom123',
//     token: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InZwYWFzLW1hZ2ljLWNvb2tpZS1hZTE2YTIzNWJiYmM0ZDZlYWQwZTAwYjhhMGJhOTQ3YS81YjIzMmMifQ.eyJleHAiOjE3MzE2NTc4NDEsIm5iZlRpbWUiOjE3MzE2NTA2MzEsInJvb20iOiIqIiwic3ViIjoidnBhYXMtbWFnaWMtY29va2llLWFlMTZhMjM1YmJiYzRkNmVhZDBlMDBiOGEwYmE5NDdhIiwiY29udGV4dCI6eyJ1c2VyIjp7Im1vZGVyYXRvciI6InRydWUiLCJpZCI6IjU2MTY0MWVlLWVkNWYtNGFjZC1hYTM4LWI2ZTAwZjBlYTU1YiIsIm5hbWUiOiJEaHJ1diIsImVtYWlsIjoiRGhydXZSYXRoaUBnbWFpbC5jb20iLCJhdmF0YXIiOiJodHRwOi8vbW9ua2Zpc2gtYXBwLWRlOXM3Lm9uZGlnaXRhbG9jZWFuLmFwcC9tZWRpYS9pbWFnZS9tYWxlX3doaXRlLmpwZWcifSwiZmVhdHVyZXMiOnsibGl2ZXN0cmVhbWluZyI6InRydWUiLCJyZWNvcmRpbmciOiJ0cnVlIiwib3V0Ym91bmQtY2FsbCI6InRydWUiLCJzaXAtb3V0Ym91bmQtY2FsbCI6InRydWUiLCJ0cmFuc2NyaXB0aW9uIjoidHJ1ZSJ9fSwiaXNzIjoiY2hhdCIsImF1ZCI6ImppdHNpIn0.gaop5xg5rntSg_Ky3BGH6UArj7WclzIXtCGukuJqEB0gIM3WUxMRcQVgBdpQvP8wq_MTXXm4gI63pO1a3osGLSgMqG8KNIZs9lMUZeFewubzPtLT-t65Ti4dg9UbxEIEUQsXxHeb4Wol9QrV4uM-IR9spWNMTTQse2XPVDOaZqYKdWSY__NpOiX8pL1HeBVv9BFfZR2_IoOpNHLSFj03K1IHAnd2uxFzA2I8IlToOTF8wepATWr48vFzalFA2_p90KftFcIl2L0fpLVshIUADQTsh3GH0zAptCJtNMjyyKY_C4EwhzDGEeyWM_BpTPbRDE49Ah-HdTcOSfYUDtl-jQ',
//   };

//   useEffect(() => {
//     setRoomDetails(hardcodedRoomDetails);
//   }, []);

//   return (
//     <>
//       <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
//       <section className="bg-dashboard">
//         <div className="container-fluid">
//           <div className="row">
//             <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//             <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//               <Header title="Video Call" onBackClick={() => navigate(-1)} showBackButton={true} />
//               {/* Render room details */}
//               {roomDetails ? (
//                 <div>
//                   <h3>Room Name: {roomDetails.roomName}</h3>
//                   <p>Token: {roomDetails.token}</p>
//                   {/* Add your video call component here, passing roomName and token */}
//                 </div>
//               ) : (
//                 <div>Loading...</div>
//               )}
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }

// export default Videopage;


// import React, { useState, useEffect } from 'react';
// import Header from '../../components/CommonComponents/Header';
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import { useNavigate } from 'react-router-dom';
// import { Button } from '@mui/material';

// const Videopage = () => {
//   const [roomDetails, setRoomDetails] = useState(null);
//   const navigate = useNavigate();

//   const appointmentId = '12345'; 
//   const hardcodedRoomDetails = {
//     roomName: 'SampleRoom1234',
//     // token: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InZwYWFzLW1hZ2ljLWNvb2tpZS1hZTE2YTIzNWJiYmM0ZDZlYWQwZTAwYjhhMGJhOTQ3YS81YjIzMmMifQ.eyJleHAiOjE3MzE2NTc4NDEsIm5iZlRpbWUiOjE3MzE2NTA2MzEsInJvb20iOiIqIiwic3ViIjoidnBhYXMtbWFnaWMtY29va2llLWFlMTZhMjM1YmJiYzRkNmVhZDBlMDBiOGEwYmE5NDdhIiwiY29udGV4dCI6eyJ1c2VyIjp7Im1vZGVyYXRvciI6InRydWUiLCJpZCI6IjU2MTY0MWVlLWVkNWYtNGFjZC1hYTM4LWI2ZTAwZjBlYTU1YiIsIm5hbWUiOiJEaHJ1diIsImVtYWlsIjoiRGhydXZSYXRoaUBnbWFpbC5jb20iLCJhdmF0YXIiOiJodHRwOi8vbW9ua2Zpc2gtYXBwLWRlOXM3Lm9uZGlnaXRhbG9jZWFuLmFwcC9tZWRpYS9pbWFnZS9tYWxlX3doaXRlLmpwZWcifSwiZmVhdHVyZXMiOnsibGl2ZXN0cmVhbWluZyI6InRydWUiLCJyZWNvcmRpbmciOiJ0cnVlIiwib3V0Ym91bmQtY2FsbCI6InRydWUiLCJzaXAtb3V0Ym91bmQtY2FsbCI6InRydWUiLCJ0cmFuc2NyaXB0aW9uIjoidHJ1ZSJ9fSwiaXNzIjoiY2hhdCIsImF1ZCI6ImppdHNpIn0.gaop5xg5rntSg_Ky3BGH6UArj7WclzIXtCGukuJqEB0gIM3WUxMRcQVgBdpQvP8wq_MTXXm4gI63pO1a3osGLSgMqG8KNIZs9lMUZeFewubzPtLT-t65Ti4dg9UbxEIEUQsXxHeb4Wol9QrV4uM-IR9spWNMTTQse2XPVDOaZqYKdWSY__NpOiX8pL1HeBVv9BFfZR2_IoOpNHLSFj03K1IHAnd2uxFzA2I8IlToOTF8wepATWr48vFzalFA2_p90KftFcIl2L0fpLVshIUADQTsh3GH0zAptCJtNMjyyKY_C4EwhzDGEeyWM_BpTPbRDE49Ah-HdTcOSfYUDtl-jQ',
//   };

//   useEffect(() => {
//     setRoomDetails(hardcodedRoomDetails);
//   }, []);

//   // const handleStartVideoCall = () => {
//   //   navigate(`/videoconference?roomName=${roomDetails.roomName}&token=${roomDetails.token}`);
//   // };
//   // const handleStartVideoCall = () => {
//   //   sessionStorage.setItem('roomName', roomDetails.roomName);
//   //   navigate('/videoconference/:12345'); // Navigate to Jitsi Meet page
//   // };
//   const handleStartVideoCall = () => {
//     sessionStorage.setItem('roomName', roomDetails.roomName);
//     navigate(`/videoconference/${appointmentId}`);  // Use dynamic appointmentId
//   };
  

//   return (
//     <>
//       <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
//       <section className="bg-dashboard">
//         <div className="container-fluid">
//           <div className="row">
//             <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//             <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//               <Header title="Video Call" onBackClick={() => navigate(-1)} showBackButton={true} />
//               {/* Render room details */}
//               {roomDetails ? (
//                 <div>
//                  <Button variant="contained" color="primary" onClick={handleStartVideoCall}>
//   Start Video Call
// </Button>
//                   {/* <button onClick={handleStartVideoCall}>Start Video Call</button> */}
//                 </div>
//               ) : (
//                 <div>Loading...</div>
//               )}
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Videopage;




// import React, { useState, useEffect } from 'react';
// import Header from '../../components/CommonComponents/Header';
// import Navbar from "../../components/navbar";
// import Sidebar from "../../components/sidebar";
// import { useNavigate } from 'react-router-dom';
// import { Button } from '@mui/material';
// import axios from 'axios';
// import { doctorDetails } from '../../store/doctor/actions';
// import { useDispatch, useSelector } from 'react-redux';

// const Videopage = () => {
//   const [roomDetails, setRoomDetails] = useState(null);
//   const [jwtToken, setJwtToken] = useState(null); 
//   console.log("jwtToken",jwtToken)
//   // sessionStorage.setItem('token', jwtToken);
//   const navigate = useNavigate();
//   const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
//   const appointmentId = '12345';  
//   const baseURL = process.env.REACT_APP_API_URL;
//   const doctorid = localStorage.getItem('doctorId');
//   const dispatch = useDispatch();
//   useEffect(() => {
//     if (doctorid) {
//       dispatch(doctorDetails(doctorid));  
//     }
//   }, [doctorid, dispatch]);
//   // const hardcodedRoomDetails = {
//   //   roomName: 'vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/SampleRoom1234',
//   // };

//   // useEffect(() => {
//   //   setRoomDetails(hardcodedRoomDetails);
//   // }, []);
//   useEffect(() => {
//     // Fetch room details based on appointment ID
//     const fetchRoomDetails = async () => {
//       try {
//         const response = await axios.get(`${baseURL}/appointments/${appointmentId}/room-details`);
//         if (response.data && response.data.roomName) {
//           setRoomDetails(response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching room details:", error);
//       }
//     };
    
//     fetchRoomDetails();
//   }, [appointmentId, baseURL]);
 
//   const handleStartVideoCall = async () => {
//     if (roomDetails) {
//       try {
//         const userDetails = {
//             user_name: doctorDetailsResults.first_name,
//             user_email: doctorDetailsResults.email,
//             avatar_url: doctorDetailsResults.image,
//             is_moderator: true,
//             room_name: roomDetails.roomName, 

//           };

//         const accessToken = localStorage.getItem('accessToken');

//         const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });

//         const cleanedToken = response.data.token.slice(2, -1); 
//         console.log("cleanedToken",cleanedToken)
//         setJwtToken(cleanedToken); 

//         sessionStorage.setItem('roomName', roomDetails.roomName);
//         // sessionStorage.setItem('token', cleanedToken);

//         navigate(`/videoconference/${appointmentId}`);
//       } catch (error) {
//         console.error('Failed to fetch JWT token:', error);
//       }
//     }
//   };
//   useEffect(() => {
//     if (jwtToken) {
//       sessionStorage.setItem('token', jwtToken); 
//     }
//   }, [jwtToken]);
//   return (
//     <>
//       <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
//       <section className="bg-dashboard">
//         <div className="container-fluid">
//           <div className="row">
//             <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
//             <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
//               <Header title="Video Call" onBackClick={() => navigate(-1)} showBackButton={true} />
            
//               {roomDetails ? (
//                 <div>
//                   <Button variant="contained" color="primary" onClick={handleStartVideoCall}>
//                     Start Video Call
//                   </Button>
//                 </div>
//               ) : (
//                 <div>Loading...</div>
//               )}
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default Videopage;



import React, { useState, useEffect } from 'react';
import Header from '../../components/CommonComponents/Header';
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography } from '@mui/material'; 
import axios from 'axios';
import { doctorDetails } from '../../store/doctor/actions';
import { useDispatch, useSelector } from 'react-redux';
import VideoCallIcon from "@mui/icons-material/VideoCall";

const Videopage = () => {
  const [roomDetails, setRoomDetails] = useState(null);
  const [jwtToken, setJwtToken] = useState(null);
  console.log("jwtToken",jwtToken)
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();
  const { doctorDetailsResults } = useSelector((state) => state.DoctorReducer);
  const appointmentId = '508'; 
  const baseURL = process.env.REACT_APP_API_URL;
  const doctorid = localStorage.getItem('doctorId');
  const dispatch = useDispatch();
  sessionStorage.setItem('token', jwtToken);
 
  useEffect(() => {
    if (doctorid) {
      dispatch(doctorDetails(doctorid));  
    }
  }, [doctorid, dispatch]);

 
  useEffect(() => {
    const fetchRoomDetails = async () => {
      setLoading(true); 
      try {
        const response = await axios.get(`${baseURL}/appointment/${appointmentId}/`);
        if (response.data && response.data.room_name) {
          setRoomDetails(response.data);
        }
      } catch (error) {
        console.error("Error fetching room details:", error);
      } finally {
        setLoading(false); 
      }
    };

    fetchRoomDetails();
  }, [appointmentId]); 


  useEffect(() => {
    if (jwtToken) {
      sessionStorage.setItem('token', jwtToken); 
    }
  }, [jwtToken]);

  // const handleStartVideoCall = async () => {
  //   if (roomDetails) {
  //     setLoading(true); 
  //     try {
  //       const userDetails = {
  //         user_name: doctorDetailsResults.first_name,
  //         user_email: doctorDetailsResults.email,
  //         avatar_url: doctorDetailsResults.image,
  //         is_moderator: true,
  //         room_name: roomDetails.room_name, 
  //       };

  //       const accessToken = localStorage.getItem('accessToken');

  //       const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       });

  //       const cleanedToken = response.data.token.slice(2, -1); 
  //       console.log("cleanedToken", cleanedToken);
  //       setJwtToken(cleanedToken); 

  //       sessionStorage.setItem('roomName', roomDetails.room_name);

     
  //       navigate(`/videoconference/${appointmentId}`);
  //     } catch (error) {
  //       console.error('Failed to fetch JWT token:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };
  const handleStartVideoCall = async () => {
    if (roomDetails) {
      setLoading(true); 
      try {
        const userDetails = {
          user_name: doctorDetailsResults.first_name,
          user_email: doctorDetailsResults.email,
          avatar_url: doctorDetailsResults.image,
          is_moderator: true,
          room_name: roomDetails.room_name, 
        };
  
        const accessToken = localStorage.getItem('accessToken');
  
        const response = await axios.post(`${baseURL}/video/generate-token/`, userDetails, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const cleanedToken = response.data.token.slice(2, -1); 
        console.log("cleanedToken", cleanedToken);
        setJwtToken(cleanedToken); 
        const prefixedRoomName = `vpaas-magic-cookie-ae16a235bbbc4d6ead0e00b8a0ba947a/${roomDetails.room_name}`;
      sessionStorage.setItem('roomName', prefixedRoomName);
        // sessionStorage.setItem('roomName', roomDetails.room_name);
      } catch (error) {
        console.error('Failed to fetch JWT token:', error);
      } finally {
        setLoading(false); 
      }
    }
  };
  
  // UseEffect to navigate when jwtToken is set
  useEffect(() => {
    if (jwtToken) {
      navigate(`/videoconference/${appointmentId}`);
    }
  }, [jwtToken, navigate, appointmentId]);
  
  return (
    <>
      <Navbar navDark={true} manuClass="navigation-menu nav-left" containerClass="container" />
      <section className="bg-dashboard">
        <div className="container-fluid">
          <div className="row">
            <Sidebar colClass="col-xl-3 col-lg-4 col-md-5 col-12" />
            <div className="col-xl-9 col-lg-8 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <Header title="Video Call" onBackClick={() => navigate(-1)} showBackButton={true} />
            
              {loading ? ( 
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                  <CircularProgress size={60} />
                </div>
              ) : roomDetails ? (
                
                         
                        <div>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: "10px",
                              background:
                                "linear-gradient(90deg, #eef2f3, #8e9eab)",
                              borderRadius: "12px",
                              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                              // maxWidth: "800px",
                              margin: "10px auto",
                            }}
                          >
                           
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <VideoCallIcon
                                sx={{
                                  fontSize: "32px",
                                  marginRight: "12px",
                                  color: "#0056b3",
                                }}
                              />
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                  color: "#333",
                                  letterSpacing: "0.5px",
                                }}
                              >
                                {/* {patient?.room_name || "Unnamed Room"} */}
                              </Typography>
                            </Box>

                            
                            <Button
                              onClick={handleStartVideoCall
                              }
                              style={{
                                background:
                                  "linear-gradient(90deg, #28a745, #218838)",
                                color: "#fff",
                                padding: "10px 20px",
                                fontSize: "16px",
                                fontWeight: "bold",
                                textTransform: "none",
                                borderRadius: "8px",
                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                                border: "none",
                              }}
                              onMouseOver={(e) => {
                                e.target.style.background =
                                  "linear-gradient(90deg, #218838, #1e7e34)";
                              }}
                              onMouseOut={(e) => {
                                e.target.style.background =
                                  "linear-gradient(90deg, #28a745, #218838)";
                              }}
                            >
                              Start Video Call
                            </Button>
                          </Box>
                        </div>
                     
                  // {/* <Button variant="contained" color="primary" onClick={handleStartVideoCall}>
                  //   Start Video Call
                  // </Button> */}
               
              ) : (
                <div>No Room Details Found</div> 
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Videopage;
