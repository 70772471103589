// src/components/SkeletonLoader.js

import React from "react";
import './SkeletonLoader.css'; // Create this file for CSS styling

const SkeletonLoader = () => {
  return (
    <div className="skeleton-container">
      {Array.from({ length: 4 }).map((_, index) => (
        <div className="skeleton-card" key={index}>
          <div className="skeleton-image"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text short"></div>
          <div className="skeleton-text short"></div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoader;
