// import React, { useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';

// const ResetPassword = () => {
//   const { token } = useParams();
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [message, setMessage] = useState('');
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (password !== confirmPassword) {
//       setMessage('Passwords do not match.');
//       return;
//     }
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/password-reset-confirm/${token}/`, { password });
//       setMessage('Password has been reset successfully.');
//       setTimeout(() => {
//         navigate('/login');
//       }, 2000);
//     } catch (error) {
//       setMessage('Error resetting password. Please try again.');
//     }
//   };

//   return (
//     <div>
//       <h2>Reset Password</h2>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="password"
//           placeholder="Enter new password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           required
//         />
//         <input
//           type="password"
//           placeholder="Confirm new password"
//           value={confirmPassword}
//           onChange={(e) => setConfirmPassword(e.target.value)}
//           required
//         />
//         <button type="submit">Reset Password</button>
//       </form>
//       {message && <p>{message}</p>}
//     </div>
//   );
// };

// export default ResetPassword;


// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import axios from 'axios';

// const ResetPassword = () => {
//   const { search } = useLocation();
//   const navigate = useNavigate();
//   const queryParams = new URLSearchParams(search);
//   const token = queryParams.get('token');

//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [message, setMessage] = useState('');

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (password !== confirmPassword) {
//       setMessage('Passwords do not match.');
//       return;
//     }
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/password-reset-confirm/${token}/`, { password });
//       setMessage('Password has been reset successfully.');
//       setTimeout(() => {
//         navigate('/login');
//       }, 2000);
//     } catch (error) {
//       setMessage('Error resetting password. Please try again.');
//     }
//   };

//   return (
//     <div>
//       <h2>Reset Password</h2>
//       <form onSubmit={handleSubmit}>
//         <input
//           type="password"
//           placeholder="Enter new password"
//           value={password}
//           onChange={(e) => setPassword(e.target.value)}
//           required
//         />
//         <input
//           type="password"
//           placeholder="Confirm new password"
//           value={confirmPassword}
//           onChange={(e) => setConfirmPassword(e.target.value)}
//           required
//         />
//         <button type="submit">Reset Password</button>
//       </form>
//       {message && <p>{message}</p>}
//     </div>
//   );
// };

// export default ResetPassword;


// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { Modal, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import bg1 from '../../assets/images/bg/bg-lines-one.png';
// import logoDark from '../../assets/images/logo-dark.png';

// export default function ResetPassword() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [message, setMessage] = useState('');
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);

//   // Function to extract token from URL or query string
//   const getTokenFromLocation = () => {
//     const searchParams = new URLSearchParams(location.search);
//     return searchParams.get('token') || ''; // Extract token from query string
//   };

//   const token = getTokenFromLocation(); // Get token from location
// console.log(token)
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');
//     setMessage('');
//     setLoading(true);

//     try {
//       if (password !== confirmPassword) {
//         setError('Passwords do not match.');
//         return;
//       }

//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/password-reset-confirm/${token}/`, {
//         password,
//       });

//       setMessage('Password has been reset successfully.');
//       setTimeout(() => {
//         navigate('/login');
//       }, 2000);
//     } catch (err) {
//       console.error('Error:', err);
//       if (err.response && err.response.data) {
//         setError(err.response.data.detail || 'Failed to reset password. Please try again.');
//       } else {
//         setError('Failed to reset password. Please try again.');
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <section
//         className="bg-home d-flex bg-light align-items-center"
//         style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}
//       >
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-lg-5 col-md-8">
//               <img src={logoDark} height="22" className="mx-auto d-block" alt="" />
//               <div className="card login-page shadow mt-4 rounded border-0">
//                 <div className="card-body">
//                   <h4 className="text-center">Reset Password</h4>
//                   <form className="login-form mt-4" onSubmit={handleSubmit}>
//                     <div className="mb-3">
//                       <label className="form-label">New Password</label>
//                       <input
//                         type="password"
//                         className="form-control"
//                         placeholder="Enter new password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                       />
//                     </div>
//                     <div className="mb-3">
//                       <label className="form-label">Confirm Password</label>
//                       <input
//                         type="password"
//                         className="form-control"
//                         placeholder="Confirm new password"
//                         value={confirmPassword}
//                         onChange={(e) => setConfirmPassword(e.target.value)}
//                         required
//                       />
//                     </div>
//                     <div className="mb-3">
//                       <button className="btn btn-primary w-100" type="submit" disabled={loading}>
//                         {loading ? (
//                           <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                         ) : (
//                           'Reset Password'
//                         )}
//                       </button>
//                     </div>
//                     {error && <p className="text-danger text-center">{error}</p>}
//                     {message && <p className="text-success text-center">{message}</p>}
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }

// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { Modal, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import bg1 from '../../assets/images/bg/bg-lines-one.png';
// import logoDark from '../../assets/images/logo-dark.png';

// export default function ResetPassword() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [message, setMessage] = useState('');
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);

//   // Function to extract token from URL or query string
//   const getTokenFromLocation = () => {
//     const searchParams = new URLSearchParams(location.search);
//     return searchParams.get('token') || ''; // Extract token from query string
//   };

//   const token = getTokenFromLocation(); // Get token from location

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');
//     setMessage('');
//     setLoading(true);

//     try {
//       if (password !== confirmPassword) {
//         setError('Password do not match.');
//         return;
//       }

//       const response = await axios.post(`${process.env.REACT_APP_API_URL}/password-reset-confirm/${token}/`, {
//         new_password: password, 
//       });
// console.log(response)
//       setMessage('Password has been reset successfully.');
//       setTimeout(() => {
//         navigate('/login');
//       }, 2000);
//     } catch (err) {
//       console.error('Error:', err);
//       if (err.response && err.response.data) {
//         setError(err.response.data.new_password[0] || 'Failed to reset password. Please try again.');
//         console.log(err.response.data);
//       } else {
//         setError('Failed to reset password. Please try again.');
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <>
//       <section
//         className="bg-home d-flex bg-light align-items-center"
//         style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}
//       >
//         <div className="container">
//           <div className="row justify-content-center">
//             <div className="col-lg-5 col-md-8">
//               <img src={logoDark} height="22" className="mx-auto d-block" alt="" />
//               <div className="card login-page shadow mt-4 rounded border-0">
//                 <div className="card-body">
//                   <h4 className="text-center">Reset Password</h4>
//                   <form className="login-form mt-4" onSubmit={handleSubmit}>
//                     <div className="mb-3">
//                       <label className="form-label">New Password</label>
//                       <input
//                         type="password"
//                         className="form-control"
//                         placeholder="Enter new password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                       />
//                     </div>
//                     <div className="mb-3">
//                       <label className="form-label">Confirm Password</label>
//                       <input
//                         type="password"
//                         className="form-control"
//                         placeholder="Confirm new password"
//                         value={confirmPassword}
//                         onChange={(e) => setConfirmPassword(e.target.value)}
//                         required
//                       />
//                     </div>
//                     <div className="mb-3">
//                       <button className="btn btn-primary w-100" type="submit" disabled={loading}>
//                         {loading ? (
//                           <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
//                         ) : (
//                           'Reset Password'
//                         )}
//                       </button>
//                     </div>
//                     {error && <p className="text-danger text-center">{error}</p>}
//                     {message && <p className="text-success text-center">{message}</p>}
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }


import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import bg1 from '../../assets/images/bg/bg-lines-one.png';
import logoDark from '../../assets/images/logo-dark.png';

export default function ResetPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  // Function to extract token from URL or query string
  const getTokenFromLocation = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('token') || ''; // Extract token from query string
  };

  const token = getTokenFromLocation(); // Get token from location

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (password !== confirmPassword) {
        toast.error('Password does not match.');
        return;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/password-reset-confirm/${token}/`, {
        new_password: password,
      });

      toast.success('Password has been reset successfully.');
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      console.error('Error:', err);
      if (err.response && err.response.data) {
        toast.error(err.response.data.new_password[0] || 'Failed to reset password. Please try again.');
      } else {
        toast.error('Failed to reset password. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <section
        className="bg-home d-flex bg-light align-items-center"
        style={{ backgroundImage: `url(${bg1})`, backgroundPosition: 'center' }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8">
              <img src={logoDark} height="22" className="mx-auto d-block" alt="" />
              <div className="card login-page shadow mt-4 rounded border-0">
                <div className="card-body">
                  <h4 className="text-center">Reset Password</h4>
                  <form className="login-form mt-4" onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter new password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Confirm Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm new password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                        {loading ? (
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        ) : (
                          'Reset Password'
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

