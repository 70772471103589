import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeUserLocation } from "../../store/common/actions";
import image1 from "../../assets/images/client/amazon.png";
import Navbar from "../../components/navbar";
import axios from "axios";
import { FaUserMd } from "react-icons/fa";
import index from "./index.css";
import bg1 from "../../assets/images/bg/01.jpg";
import logoIcon from "../../assets/images/logo-icon.png";
import FeatureOne from "../../components/features/featureSection";
import featureSection from "../../components/features/featureSection";
import AboutImage from "../../components/aboutImage";
import DrTimeTable from "../../components/drTimeTable";
import CtaOne from "../../components/cta/ctaOne";
import Patients from "../../components/patients";
import Footer from "../../components/footer";
import ScrollTop from "../../components/scrollTop";
import data from "../../assets/data.json";
import RegistrationDoctorForm from "../../components/RegisterDoctor/RegistrationDoctorForm";

import {
  RiArrowRightLine,
  FiFacebook,
  FiLinkedin,
  FiGithub,
  FiTwitter,
  FiCalendar,
  FiClock,
} from "../../assets/icons/vander";
import {
  RiEyeFill,
  RiPsychotherapyFill,
  RiStethoscopeFill,
  RiCapsuleFill,
  RiMicroscopeFill,
  RiPulseFill,
  RiEmpathizeFill,
  RiMindMap,
  RiMentalHealthLine,
  RiAppleLine,
  RiInfraredThermometerLine,
  RiCapsuleLine,
  GrUserPolice,
  RiMicroscopeLine,
  TbAmbulance,
  RiShieldCrossLine,
  RiBriefcase4Line,
  FiAirplay,
  MdOutlineFlip,
  PiTelegramLogoBold,
  RiMailStarLine,
  RiHourglassLine,
  RiHeartLine,
  RiHeartPulseLine,
  RiStethoscopeLine,
  RiVirusLine,
  RiDossierLine,
  RiEye2Line,
  FiUser,
  FiMail,
  VscBook,
  MdFormatItalic,
  GiMedicalDrip,
  FiMapPin,
  FiBriefcase,
  LuFileCheck,
  MdOutlineStorefront,
  LuAward,
  LiaFileInvoiceDollarSolid,
  FiThumbsUp,
  FiPhone,
} from "../../assets/icons/vander";
import BookingAppointment from "../patient/booking-appointment";
import dr1 from "../../assets/images/doctors/Avatar.jpg";
import JoinHealthBridge from "../../components/features/JoinHealthBridge";
import HowItWorks from "../../components/HowItWorks/index";
import {
  TextField,
  Box,
  IconButton,
  InputAdornment,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchDoctor from "./SearchDoctor";
// import { medicalServices,  blogData,partners } from "../../data/data";
const baseURL = process.env.REACT_APP_API_URL;

export default function Index() {
  const [doctorData, setDoctorData] = useState([]);
  const userRole = localStorage.getItem("userRole");
  const [searchQuery, setSearchQuery] = useState({ params1: "", params2: "" });
  const searchBoxRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [locationQuery, setLocationQuery] = useState("");
  const [showCardioSuggestions, setShowCardioSuggestions] = useState(false);
  const [showLocationSuggestions, setShowLocationSuggestions] = useState(false);
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [searchResults, setSearchResults] = useState([]); // State for search results
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [registerDrModal, setRegisterDrModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const dispatch = useDispatch();
  // const secondSectionRef = React.useRef(null);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (
  //     searchQuery?.params1?.trim() === "" ||
  //     searchQuery?.params2?.trim() === ""
  //   ) {
  //     fetchDoctorData();
  //   } else {
  //     // searchDoctors(searchQuery);
  //   }
  // }, [searchQuery]);

  const handleSuggestionClick = (name, suggestion) => {
    setSearchQuery({ ...searchQuery, [name]: suggestion });
    if (name === "params1") {
      setShowCardioSuggestions(false); // Hide suggestions after clicking
    } else {
      setShowLocationSuggestions(false);
    }
  };

  useEffect(() => {
    const requestLocationAccess = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Handle successful location access
            const { latitude, longitude } = position.coords;
            dispatch(
              storeUserLocation({ latitude: latitude, longitude: longitude })
            );
            console.log("Latitude:", latitude);
            console.log("Longitude:", longitude);
            // You can store this data in state or send it to the server
          },
          (error) => {
            // Handle location access error
            switch (error.code) {
              case error.PERMISSION_DENIED:
                console.log("User denied the request for Geolocation.");
                break;
              case error.POSITION_UNAVAILABLE:
                console.log("Location information is unavailable.");
                break;
              case error.TIMEOUT:
                console.log("The request to get user location timed out.");
                break;
              case error.UNKNOWN_ERROR:
                console.log("An unknown error occurred.");
                break;
              default:
                console.log("An unknown error occurred.");
            }
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };

    requestLocationAccess();
  }, []); // Empty dependency array to trigger only on component mount

  const fetchSuggestionList = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseURL}/specializations/list/`);
      if (
        response &&
        response?.data &&
        response?.data?.specializations &&
        response?.data?.specializations?.length > 0
      ) {
        setSuggestions(response?.data?.specializations);
      }
    } catch (error) {
      console.error("Error fetching doctor data:", error);
      setSuggestions([]);
    } finally {
      setLoading(false);
    }
  };

  const searchDoctors = async (query) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/doctors/search/?search=${encodeURIComponent(query)}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setDoctorData(data.length ? data : []);
      setError(null);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setError(error.message);
      setDoctorData([]);
    } finally {
      setLoading(false);
    }
  };

  // const handleSearchChange = (e) => {
  //   setSearchQuery(e.target.value);
  //   // Scroll to the second section if there is a search query
  //   // if (e.target.value) {
  //   //     secondSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  //   // }
  // };
  const handleSearchChange = async (e) => {
    const { name, value: query } = e.target;
    setSearchQuery({ ...searchQuery, [name]: query });
    //
    setShowLocationSuggestions(true);

    if (query?.length > 3) {
      // Fetch suggestions only if the input is more than 2 characters
      try {
        const response = await axios.get(
          `${baseURL}/doctors/autocomplete/?search=${query}`
        );

        setLocationSuggestions(response.data?.search_results || []);
      } catch (error) {
        toast.error(error?.message);
        console.error("Error fetching autocomplete suggestions:", error);
      }
    } else {
      setLocationSuggestions([]); // Clear suggestions if input is too short
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const {
    heroSection,
    doctordetails,
    logoIcon,
    sectionTitle,
    medicalServices,
    doctorData1,
    patient,
    news,
    blogData,
    partners,
  } = data;
  const icons = {
    RiEyeFill,
    RiPsychotherapyFill,
    RiStethoscopeFill,
    RiCapsuleFill,
    RiMicroscopeFill,
    RiPulseFill,
    RiEmpathizeFill,
    RiMindMap,
    RiMentalHealthLine,
    RiAppleLine,
    RiInfraredThermometerLine,
  };
  const bgImage = require(`../../assets/images/${heroSection.backgroundImage}.webp`);
  const handleSearchClick = (searchParams) => {
    // Navigate to the 'doctor-team-two' URL
    // navigate('/doctor-team-two');
    const { params1, params2 } = searchQuery || {};
    navigate(
      `/doctor-search?${
        params1 && params2
          ? `search=${encodeURIComponent(params1)}&search=${encodeURIComponent(
              params2
            )}`
          : `search=${encodeURIComponent(params1 ? params1 : params2)}`
      }`
    );
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target)
      ) {
        setShowCardioSuggestions(false);
        setShowLocationSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBoxRef]);

  useEffect(() => {
    fetchSuggestionList();
  }, []);
  return (
    <>
      <Navbar
        manuClass="navigation-menu nav-left nav-light"
        containerClass="container"
      />
      <section
        className="bg-half-260 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          marginBottom: "0",
          backgroundPosition: "top",
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
        <div className="container">
          <div className="row mt-5 mt-lg-0">
            <div className="col-12">
              <div className="heading-title">
                <h4
                  className="display-4 fw-bold text-white title-dark mt-3 mb-4"
                  style={{
                    fontSize:
                      window.innerWidth < 576
                        ? "1.5rem"
                        : window.innerWidth < 768
                        ? "1.8rem"
                        : "2.2rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {heroSection.title}
                </h4>
                <p
                  style={{
                    fontSize:
                      window.innerWidth < 576
                        ? "1rem"
                        : window.innerWidth < 768
                        ? "1.2rem"
                        : "1.5rem",
                    fontWeight: "300",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  {heroSection.description}
                </p>

                <div className="mt-4 pt-2">
                  <SearchDoctor />

                  {/* <Box sx={{ backgroundColor: "#002855", padding: 2 }}>
                    
                      {/* Cardiology Search Box */}

                  {/* Search Again Button */}

                  {/* </Grid>
                  </Box> */}

                  {/* <div class="input-group mb-3">
                   

                    
                    {suggestions.length > 0 && (
                      <ul className="suggestions-list">
                        {suggestions.map((suggestion, index) => {
                          const {
                            match_type,
                            doctor_id,
                            name,
                            specialization,
                            address,
                            location,
                          } = suggestion || {};
                          const searchParams =
                            match_type === "name"
                              ? name?.trim()
                              : match_type === "specialization"
                              ? specialization?.trim()
                              : match_type === "address"
                              ? address?.trim()
                              : location;
                          return (
                            <li
                              key={index}
                              onClick={() => {
                                if (match_type === "name" && doctor_id) {
                                  navigate(`doctor-profile/${doctor_id}`);
                                } else {
                                  handleSearchClick(searchParams);
                                }
                              }}
                            >
                              {match_type === "name" && (
                                <FaUserMd style={{ marginRight: "8px" }} />
                              )}
                              {searchParams}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div> */}

                  <p className="text-white-50 mb-0 mt-2">
                    {heroSection.terms.text}{" "}
                    <Link
                      to={heroSection.terms.link.href}
                      className="text-white-50"
                    >
                      {heroSection.terms.link.text}{" "}
                      <i className="ri-arrow-right-line align-middle"></i>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        className="bg-half-260 d-table w-100"
        style={{
          backgroundImage: `url(${bgImage})`,
          marginBottom: "0",
          backgroundPosition: "top",
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
        <div className="container">
          <div className="row mt-5 mt-lg-0">
            <div className="col-12">
              <div className="heading-title">
                
                <h4
                  className="display-4 fw-bold text-white title-dark mt-3 mb-4"
                  style={{
                    fontSize: "2.2rem",
                    // whiteSpace: 'nowrap',   
                    overflow: "hidden" ,
                    textOverflow:
                      "ellipsis" ,
                  }}
                >
                  {heroSection.title}
                </h4>
                <p
                  style={{
                    fontSize: "1.5rem" ,
                    fontWeight: "300" ,
                    color: "rgba(255, 255, 255, 0.8)" ,
                  }}
                >
                  {heroSection.description}
                </p>

                <div className="mt-4 pt-2">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search doctors..."
                      value={searchQuery}
                      onChange={handleSearchChange}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearchClick(); 
                        }
                      }}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        onClick={handleSearchClick}
                        style={{
                          backgroundColor: "#007bff",
                          color: "white",
                          cursor: "pointer",
                          padding: "10px 20px",
                          borderRadius: "5px",
                          transition:
                            "background-color 0.3s ease, transform 0.3s ease",
                        }}
                      >
                        Search
                      </span>
                    </div>
                  </div>
                 

                  <p className="text-white-50 mb-0 mt-2">
                    {heroSection.terms.text}{" "}
                    <Link
                      to={heroSection.terms.link.href}
                      className="text-white-50"
                    >
                      {heroSection.terms.link.text}{" "}
                      <i className="ri-arrow-right-line align-middle"></i>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section> */}
      <BookingAppointment
        show={modalShow}
        handleClose={() => setModalShow(false)}
        selectedDoctor={selectedDoctor}
      />

      <section className="section" style={{ marginTop: "0", paddingTop: "0" }}>
        {/* <FeatureOne /> */}

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <AboutImage colClass="col-lg-5 col-md-6" />
            <div className="col-lg-7 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
              <div className="ms-lg-4">
                <div className="section-title">
                  <h4 className="title mb-4">{data.aboutUs.title}</h4>
                  <p className="text-muted para-desc">
                    {data.aboutUs.description1}
                  </p>
                  <p className="text-muted para-desc mb-0">
                    {data.aboutUs.description2}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FeatureOne />
      </section>
      <section className="section" style={{ marginTop: "0", paddingTop: "0" }}>
        <HowItWorks />
      </section>

      {/* <DrTimeTable/> */}

      {/* <CtaOne /> */}

      <section className="section" style={{ marginTop: "0", paddingTop: "0" }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-4">{patient.title}</h4>
                <p className="text-muted mx-auto para-desc mb-0">
                  {patient.description}
                </p>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-8 mt-4 pt-2 text-center">
              <Patients />
            </div>
          </div>
        </div>
      </section>

      <JoinHealthBridge />
      <RegistrationDoctorForm
        registerDrModal={registerDrModal}
        setRegisterDrModal={setRegisterDrModal}
      />
      <Footer />
      <ScrollTop />
    </>
  );
}
