import {
  PATIENT_DETAILS,
  PATIENT_DETAILS_SUCCESS,
  PATIENT_DETAILS_FAIL,
} from "./actionTypes";

const initialState = {
  patientDetailsResults: {},
  loading: false,
  error: {
    message: "",
  },
};

const DoctorReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case PATIENT_DETAILS:
      state = { ...state, loading: true };
      break;
    case PATIENT_DETAILS_SUCCESS:
      state = {
        ...state,
        patientDetailsResults: action.payload,
        loading: false,
      };
      break;
    case PATIENT_DETAILS_FAIL:
      state = {
        ...state,
        patientDetailsResults: {},
        error: {
          message: "Error",
        },
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DoctorReducer;
