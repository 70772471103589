// import { put } from "redux-saga/effects";
import { get, post, remove, put } from "../api_helper";
import * as url from "../url_helper";

const baseURL = process.env.REACT_APP_API_URL;

export const getPositionDetails = (id) => get(`${baseURL + url.DOCTORS}/${id}`);
export const getDoctorDetails = (id) => get(`${baseURL}/${url.DOCTORS}/${id}`);
// export const updatePosition = (ID,payload) => put(url.POSITION_BASE+'/'+ID,payload);
export const updatePosition = (ID, payload) =>
  put(`${baseURL}/${ID}`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
// export const updatePositioStatus = (ID,payload) => put(url.POSITION_BASE_UPDATE+'/'+ID, payload);
// export const updatePositionStatusList = (payload) => put(url.POSITION_BASE_UPDATE,payload);
// export const createPosition = (payload) =>
//   post(baseURL + url.POSITION_BASE, payload);
// //Post
// export const deletePosition = (ID) =>
//   remove(baseURL + url.POSITION_BASE + "/" + ID);
// export const getLookupPositions = () =>
//   get(baseURL + url.POSITION_BASE + `/lookup`);
