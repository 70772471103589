// import React from "react";

// import Chart from 'react-apexcharts'

// export default function Charts(){
//     let options = {
//         series: [{
//             data: [20, 45, 40, 64, 35, 25, 35]
//         }],
//         chart: {
//             type: 'area',
//             height: 90,
//             width: '100%',
//             sparkline: {
//                 enabled: true
//             }
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 3,
//         },
//         colors: ['#396cf0'],
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shadeIntensity: 1,
//                 inverseColors: false,
//                 opacityFrom: 0.45,
//                 opacityTo: 0.05,
//                 stops: [20, 100, 100, 100]
//             },
//         },
//         tooltip: {
//             fixed: {
//                 enabled: false
//             },
//             x: {
//                 show: false
//             },
//             y: {
//                 title: {
//                     formatter: function (seriesName) {
//                         return ''
//                     }
//                 }
//             },
//             marker: {
//                 show: false
//             }
//         }
//     };
//     let options2 = {
//         series: [{
//             data: [10, 25, 30, 54, 45, 39, 15]
//         }],
//         chart: {
//             type: 'area',
//             height: 90,
//             width: '100%',
//             sparkline: {
//                 enabled: true
//             }
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 3,
//         },
//         colors: ['#53c797'],
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shadeIntensity: 1,
//                 inverseColors: false,
//                 opacityFrom: 0.45,
//                 opacityTo: 0.05,
//                 stops: [20, 100, 100, 100]
//             },
//         },
//         tooltip: {
//             fixed: {
//                 enabled: false
//             },
//             x: {
//                 show: false
//             },
//             y: {
//                 title: {
//                     formatter: function (seriesName) {
//                         return ''
//                     }
//                 }
//             },
//             marker: {
//                 show: false
//             }
//         }
//     };
//     let options3 = {
//         series: [{
//             data: [15, 20, 10, 45, 20, 10, 5]
//         }],
//         chart: {
//             type: 'area',
//             height: 90,
//             width: '100%',
//             sparkline: {
//                 enabled: true
//             }
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 3,
//         },
//         colors: ['#f1b561'],
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shadeIntensity: 1,
//                 inverseColors: false,
//                 opacityFrom: 0.45,
//                 opacityTo: 0.05,
//                 stops: [20, 100, 100, 100]
//             },
//         },
//         tooltip: {
//             fixed: {
//                 enabled: false
//             },
//             x: {
//                 show: false
//             },
//             y: {
//                 title: {
//                     formatter: function (seriesName) {
//                         return ''
//                     }
//                 }
//             },
//             marker: {
//                 show: false
//             }
//         }
//     };
//     let options4 = {
//         series: [{
//             data: [3, 5, 7, 11, 8, 5, 7]
//         }],
//         chart: {
//             type: 'area',
//             height: 90,
//             width: '100%',
//             sparkline: {
//                 enabled: true
//             }
//         },
//         stroke: {
//             curve: 'smooth',
//             width: 3,
//         },
//         colors: ['#f0735a'],
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shadeIntensity: 1,
//                 inverseColors: false,
//                 opacityFrom: 0.45,
//                 opacityTo: 0.05,
//                 stops: [20, 100, 100, 100]
//             },
//         },
//         tooltip: {
//             fixed: {
//                 enabled: false
//             },
//             x: {
//                 show: false
//             },
//             y: {
//                 title: {
//                     formatter: function (seriesName) {
//                         return ''
//                     }
//                 }
//             },
//             marker: {
//                 show: false
//             }
//         }
//     };
//     return(
//         <div className="row">
//             <div className="col-xl-3 col-lg-6 mt-4">
//                 <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                         <h6 className="align-items-center mb-0">Appointment <span className="badge rounded-pill bg-soft-primary ms-1">+15%</span></h6>
//                         <p className="mb-0 text-muted">220+ Week</p>
//                     </div>
//                     <Chart options={options} series={options.series} type="area" width='100%' height={90}/>
//                 </div>
//             </div>

//             <div className="col-xl-3 col-lg-6 mt-4">
//                 <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                         <h6 className="align-items-center mb-0">Patients <span className="badge rounded-pill bg-soft-success ms-1">+20%</span></h6>
//                         <p className="mb-0 text-muted">220+ Week</p>
//                     </div>
//                     <Chart options={options2} series={options2.series} type="area" width='100%' height={90}/>
//                 </div>
//             </div>

//             <div className="col-xl-3 col-lg-6 mt-4">
//                 <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                         <h6 className="align-items-center mb-0">Urgent <span className="badge rounded-pill bg-soft-warning ms-1">+5%</span></h6>
//                         <p className="mb-0 text-muted">220+ Week</p>
//                     </div>
//                     <Chart options={options3} series={options3.series} type="area" width='100%' height={90}/>
//                 </div>
//             </div>

//             <div className="col-xl-3 col-lg-6 mt-4">
//                 <div className="card shadow border-0 p-4">
//                     <div className="d-flex justify-content-between mb-3">
//                         <h6 className="align-items-center mb-0">Canceled <span className="badge rounded-pill bg-soft-danger ms-1">-5%</span></h6>
//                         <p className="mb-0 text-muted">220+ Week</p>
//                     </div>
//                     <Chart options={options4} series={options4.series} type="area" width='100%' height={90}/>
//                 </div>
//             </div>
//         </div>
//     )
// }

// import React, { useState, useEffect } from "react";
// import Chart from 'react-apexcharts';
// import axios from 'axios';
// import SkeletonLoader from "../../pages/dashboard/SkeltonLoader";

// export default function Charts() {
//     const [appointmentData, setAppointmentData] = useState([]);
//     const [loading, setLoading] = useState(true);

//     useEffect(() => {
//         const doctorId = localStorage.getItem("doctorId");
//         const fetchData = async () => {
//             try {
//                 const response = await axios.get(`${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`);
//                 setAppointmentData(response.data);
//                 setLoading(false);
//             } catch (error) {
//                 console.error("Error fetching appointment data:", error);
//                 setLoading(false);
//             }
//         };

//         fetchData();
//     }, []);

//     const getStatusData = () => {
//         const statusCounts = appointmentData.reduce((acc, appointment) => {
//             acc[appointment.status] = (acc[appointment.status] || 0) + 1;
//             return acc;
//         }, {});

//         const totalAppointments = appointmentData.length;
//         const statuses = ['confirmed', 'rescheduled', 'rejected', 'pending', 'completed'];
//         const series = statuses.map(status => statusCounts[status] || 0);
//         // const percentages = series.map(count => ((count / totalAppointments) * 100).toFixed(1));
//         const percentages = totalAppointments > 0
//         ? series.map(count => ((count / totalAppointments) * 100).toFixed(1))
//         : statuses.map(() => '0.0');
//         return { statuses, series, percentages };
//     };

//     const getDateStatusData = (status) => {
//         const filteredData = appointmentData.filter(appointment => appointment.status === status);
//         const dataByDate = filteredData.reduce((acc, appointment) => {
//             const date = appointment.appointment_date;
//             acc[date] = (acc[date] || 0) + 1;
//             return acc;
//         }, {});

//         const dates = Object.keys(dataByDate);
//         const values = Object.values(dataByDate);

//         return { dates, values };
//     };

//     const createPieChartOptions = () => {
//         const statusData = getStatusData();
//         return {
//             series: statusData.series,
//             chart: { type: 'pie', height: 350 },
//             labels: statusData.statuses.map((status, index) => `${status.charAt(0).toUpperCase() + status.slice(1)} (${statusData.percentages[index]}%)`),
//             colors: ['#28a745', '#f7b731', '#dc3545', '#007bff', '#6f42c1'], // Green, Orange, Red, Blue, Purple
//             legend: { position: 'bottom' },
//             responsive: [{
//                 breakpoint: 480,
//                 options: {
//                     chart: { width: '100%' },
//                     legend: { position: 'bottom' }
//                 }
//             }]
//         };
//     };

//     const createAreaChartOptions = (data, color, status, percentage) => ({
//         series: [{ data: data.values }],
//         chart: { type: 'area', height: 90, width: '100%', sparkline: { enabled: true } },
//         stroke: { curve: 'smooth', width: 3 },
//         colors: [color],
//         fill: {
//             type: 'gradient',
//             gradient: {
//                 shadeIntensity: 1,
//                 inverseColors: false,
//                 opacityFrom: 0.45,
//                 opacityTo: 0.05,
//                 stops: [0, 100]
//             }
//         },
//         tooltip: {
//             fixed: { enabled: false },
//             x: { show: false },
//             y: { title: { formatter: (seriesName) => '' } },
//             marker: { show: false }
//         },
//         xaxis: {
//             categories: data.dates,
//             labels: {
//                 show: true,
//                 rotate: -45
//             }
//         }
//     });

//     const statuses = getStatusData().statuses;
//     const percentages = getStatusData().percentages;
//     const colors = ['#28a745', '#f7b731', '#dc3545', '#007bff', '#6f42c1']; // Green, Orange, Red, Blue, Purple

//     return (
//         <div className="container">
//             {loading ? (
//                 <SkeletonLoader/>
//             ) : (
//                 <>
//                     <div className="row">
//                         {/* First Row: Area Charts */}
//                         {statuses.slice(0, 3).map((status, index) => {
//                             const data = getDateStatusData(status);
//                             return (
//                                 <div className="col-xl-4 col-lg-6 mt-4" key={status}>
//                                     <div className="card shadow border-0 p-4">
//                                         <div className="d-flex justify-content-between mb-3">
//                                             <h6 className="align-items-center mb-0">
//                                                 {status.charAt(0).toUpperCase() + status.slice(1)} ({percentages[index]}%)
//                                             </h6>
//                                             <p className="mb-0 text-muted">{data.values.reduce((a, b) => a + b, 0)}+ Week</p>
//                                         </div>
//                                         <Chart
//                                             options={createAreaChartOptions(data, colors[index], status, percentages[index])}
//                                             series={[{ data: data.values }]}
//                                             type="area"
//                                             width='100%'
//                                             height={90}
//                                         />
//                                     </div>
//                                 </div>
//                             );
//                         })}
//                     </div>

//                     <div className="row">
//                         {/* Second Row: Remaining Area Charts and Pie Chart */}
//                         {statuses.slice(3).map((status, index) => {
//                             const data = getDateStatusData(status);
//                             return (
//                                 <div className="col-xl-4 col-lg-6 mt-4" key={status}>
//                                     <div className="card shadow border-0 p-4">
//                                         <div className="d-flex justify-content-between mb-3">
//                                             <h6 className="align-items-center mb-0">
//                                                 {status.charAt(0).toUpperCase() + status.slice(1)} ({percentages[statuses.indexOf(status)]}%)
//                                             </h6>
//                                             <p className="mb-0 text-muted">{data.values.reduce((a, b) => a + b, 0)}+ Week</p>
//                                         </div>
//                                         <Chart
//                                             options={createAreaChartOptions(data, colors[statuses.indexOf(status)], status, percentages[index])}
//                                             series={[{ data: data.values }]}
//                                             type="area"
//                                             width='100%'
//                                             height={90}
//                                         />
//                                     </div>
//                                 </div>
//                             );
//                         })}

//                         <div className="col-xl-4 col-lg-6 mt-4">
//                             <div className="card shadow border-0 p-4">
//                                 <h6 className="mb-4">Appointment Status Distribution</h6>
//                                 <Chart
//                                     options={createPieChartOptions()}
//                                     series={getStatusData().series}
//                                     type="pie"
//                                     width='100%'
//                                     height={350}
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </>
//             )}
//         </div>
//     );
// }

import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import SkeletonLoader from "../../pages/dashboard/SkeltonLoader";
import { getDoctorId } from "../../_helper/helper";
import { get } from "../../api_helper";
import { getUserData } from "../CommonComponents/CommonFunction";
export default function Charts() {
  const [appointmentData, setAppointmentData] = useState([]);
  console.log("appointmentData",appointmentData)
  const [loading, setLoading] = useState(true);
  // const {accessToken, role, assigned_doctor_id, doctor_id } = getUserData();
  const token = localStorage.getItem("accessToken"); 
  const doctorId = getDoctorId()
console.log("doctoriiidd",token)
  const fetchData = async () => {
    try {
      const response = await get(
        `${process.env.REACT_APP_API_URL}/doctor/appointments/${doctorId}`
      );
      console.log("Fetched Data:", response); 
      setAppointmentData(response ||[]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching appointment data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (doctorId !== null && token) {
      fetchData();
    }
  }, [doctorId, token]);

  const getStatusData = () => {
    const statusCounts = appointmentData.reduce((acc, appointment) => {
      acc[appointment.status] = (acc[appointment.status] || 0) + 1;
      return acc;
    }, {});

    const totalAppointments = appointmentData.length;
    const statuses = [
      "confirmed",
      "rescheduled",
      "rejected",
      "pending",
      "completed",
      "cancelled",
    ];
    const series = statuses.map((status) => statusCounts[status] || 0);
    const percentages =
      totalAppointments > 0
        ? series.map((count) => ((count / totalAppointments) * 100).toFixed(1))
        : statuses.map(() => "0.0");
    return { statuses, series, percentages };
  };

  const getDateStatusData = (status) => {
    const filteredData = appointmentData.filter(
      (appointment) => appointment.status === status
    );
    const dataByDate = filteredData.reduce((acc, appointment) => {
      const date = appointment.appointment_date;
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const dates = Object.keys(dataByDate);
    const values = Object.values(dataByDate);

    return { dates, values };
  };

  const createPieChartOptions = () => {
    const statusData = getStatusData();
    return {
      series: statusData.series,
      chart: { type: "pie", height: 350 },
      labels: statusData.statuses.map(
        (status, index) =>
          `${status.charAt(0).toUpperCase() + status.slice(1)} (${
            statusData.percentages[index]
          }%)`
      ),
      colors: [
        "#28a745",
        "#f7b731",
        "#dc3545",
        "#007bff",
        "#6f42c1",
        "#6c757d",
      ], // Green, Orange, Red, Blue, Purple, Gray
      legend: { position: "bottom" },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: { width: "100%" },
            legend: { position: "bottom" },
          },
        },
      ],
    };
  };

  const createAreaChartOptions = (data, color, status, percentage) => ({
    series: [{ data: data.values }],
    chart: {
      type: "area",
      height: 90,
      width: "100%",
      sparkline: { enabled: true },
    },
    stroke: { curve: "smooth", width: 3 },
    colors: [color],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [0, 100],
      },
    },
    tooltip: {
      fixed: { enabled: false },
      x: { show: false },
      y: { title: { formatter: (seriesName) => "" } },
      marker: { show: false },
    },
    xaxis: {
      categories: data.dates,
      labels: {
        show: true,
        rotate: -45,
      },
    },
  });

  const statuses = getStatusData().statuses;
  const percentages = getStatusData().percentages;
  const colors = [
    "#28a745",
    "#f7b731",
    "#dc3545",
    "#007bff",
    "#6f42c1",
    "#6c757d",
  ]; // Green, Orange, Red, Blue, Purple, Gray

  return (
    <div className="container">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <>
          <div className="row">
            {/* First Row: Area Charts */}
            {statuses.slice(0, 3).map((status, index) => {
              const data = getDateStatusData(status);
              return (
                <div className="col-xl-4 col-lg-6 mt-4" key={status}>
                  <div className="card shadow border-0 p-4">
                    <div className="d-flex justify-content-between mb-3">
                      <h6 className="align-items-center mb-0">
                        {status.charAt(0).toUpperCase() + status.slice(1)} (
                        {percentages[index]}%)
                      </h6>
                      <p className="mb-0 text-muted">
                        {data.values.reduce((a, b) => a + b, 0)}+ Week
                      </p>
                    </div>
                    <Chart
                      options={createAreaChartOptions(
                        data,
                        colors[index],
                        status,
                        percentages[index]
                      )}
                      series={[{ data: data.values }]}
                      type="area"
                      width="100%"
                      height={90}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="row">
            {/* Second Row: Remaining Area Charts and Pie Chart */}
            {statuses.slice(3).map((status, index) => {
              const data = getDateStatusData(status);
              return (
                <div className="col-xl-4 col-lg-6 mt-4" key={status}>
                  <div className="card shadow border-0 p-4">
                    <div className="d-flex justify-content-between mb-3">
                      <h6 className="align-items-center mb-0">
                        {status.charAt(0).toUpperCase() + status.slice(1)} (
                        {percentages[statuses.indexOf(status)]}%)
                      </h6>
                      <p className="mb-0 text-muted">
                        {data.values.reduce((a, b) => a + b, 0)}+ Week
                      </p>
                    </div>
                    <Chart
                      options={createAreaChartOptions(
                        data,
                        colors[statuses.indexOf(status)],
                        status,
                        percentages[index]
                      )}
                      series={[{ data: data.values }]}
                      type="area"
                      width="100%"
                      height={90}
                    />
                  </div>
                </div>
              );
            })}

            <div className="col-lg-12 mt-4">
              <div className="card shadow border-0 p-4">
                <h6 className="mb-4">Appointment Status Distribution</h6>
                <Chart
                  options={createPieChartOptions()}
                  series={getStatusData().series}
                  type="pie"
                  width="100%"
                  height={350}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
