import React, { useState } from "react";
import { Box, Card, CardContent, Typography, Badge } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import {
  RiContrastDropLine,
  RiTestTubeLine,
  RiHeart3Fill,
  RiHeartPulseFill,
  RiDashboard3Line,
} from "../../assets/icons/vander";

export default function LatestHealthMetrics({
  healthMetricsList,
  downloadHealthMetricsPDF,
  patientData,
}) {
  const navigate = useNavigate();
  const renderCard = (title, value, unit, icon, colorClass, diff) => (
    <Card
      sx={{
        mb: 4,
        p: 2,
        m: 1,
        width: "100%",
        height: "150px",
        boxShadow: 1,
        "&:hover": {
          backgroundColor: "lightgrey",
          boxShadow: 3,
        },
      }}
    >
      {/* <CardContent> */}
      <div className="d-flex justify-content-between mb-3">
        <h6 className="align-items-center mb-0">
          {title}
          <span className={`badge rounded-pill bg-soft-${colorClass} ms-1`}>
            {diff ? `${diff.toFixed(2)}%` : "N/A"}
          </span>
        </h6>
        {icon}
        {/* <RiContrastDropLine className="text-primary h5" /> */}
      </div>
      <div className="font-weight-bold mb-2">
        <b> {value ? `${value} ${unit}` : "N/A"} </b>
      </div>
    </Card>
  );
  return (
    <>
      <div>
        <Row>
          <Col xs={8}>
            {" "}
            <h5>Latest Measured Health Metrics</h5>
          </Col>
          <Col style={{ textAlign: "end" }}>
            <Button
              // style={{ marginRight: "50px" }}
              className="btn btn-secondary"
              onClick={() =>
                navigate(`/patient/health-metrics/${patientData?.patient}`, {
                  state: { patientData: patientData },
                })
              }
            >
              Add New
            </Button>{" "}
          </Col>
          <Col>
            <Button
              // style={{ marginRight: "50px" }}
              className="btn btn-primary"
              onClick={() => downloadHealthMetricsPDF()}
            >
              Download
            </Button>{" "}
          </Col>
        </Row>

        {/* <Box className="col-xl-3 col-lg-6 col-12">
              <Box className="row"> */}
        <div style={{ display: "flex" }}>
          {renderCard(
            "Weight Difference",
            healthMetricsList?.metrics?.wt,
            "kg",
            <RiContrastDropLine fontSize="large" className="text-primary h5" />,
            "primary",
            healthMetricsList?.percentage_difference?.wt
          )}
          {renderCard(
            "Hemoglobin Difference",
            healthMetricsList?.metrics?.hb,
            "g/dL",
            <RiTestTubeLine fontSize="large" className="text-success h5" />,
            "success",
            healthMetricsList?.percentage_difference?.hb
          )}
        </div>
        <div style={{ display: "flex" }}>
          {renderCard(
            "Heartbeat Difference",
            healthMetricsList?.metrics?.hr,
            "bpm",
            <RiHeart3Fill fontSize="large" className="text-danger h5" />,
            "danger",
            healthMetricsList?.percentage_difference?.hr
          )}
          {renderCard(
            "Blood Pressure Difference",
            healthMetricsList?.metrics?.bp,
            "mmHg",
            <RiHeartPulseFill fontSize="large" className="text-danger h5" />,
            "danger",
            healthMetricsList?.percentage_difference?.bp
          )}
          {renderCard(
            "Sugar Levels Difference",
            healthMetricsList?.metrics?.sg,
            "mg/dL",
            <RiDashboard3Line fontSize="large" className="text-warning h5" />,
            "warning",
            healthMetricsList?.percentage_difference?.sg
          )}
        </div>
      </div>
    </>
  );
}
