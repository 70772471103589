import { RESET, STORED_USER_GEO_LOCATION } from "./actionTypes";

const initialState = {
  error: {
    message: "",
  },
  userGeoLocation: {},
};

const CommonReducer = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case STORED_USER_GEO_LOCATION:
      state = { ...state, userGeoLocation: action.payload, loading: true };
      break;

    case RESET:
      state = { ...state, position: {} };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CommonReducer;
