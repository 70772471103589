// App.js
import React, { useCallback, useState, useEffect } from "react";
import { post } from "../../api_helper";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  IconButton,
  TextField,
  Button,
  MenuItem,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Card, CardBody } from "reactstrap";
import { loadingPanel } from "../CommonComponents/CommonFunction";
import { ShowSucess, ShowError } from "../../_helper/helper";
import CloseIcon from "@mui/icons-material/Close";

const options = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "O", label: "Other" },
];
const baseURL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("accessToken");

const AddPatient = ({ openPatientModal, setOpenPatientModal }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [formData, setFormData] = React.useState({
    first_name: "",
    last_name: "",
    age: null,
    gender: "",
    phone_number: "",
    email: "",
    address: "",
    allergies: "",
    chronic_conditions: "",
    medications: "",
    blood_type: "",
    emergency_contact_name: "",
    emergency_contact_phone: "",
    emergency_contact_relationship: "",
  });
  const { id } = useParams();
  const userRole = localStorage.getItem("userRole");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const validateField = (name, value) => {
    let error = "";
    if (
      (name === "first_name" ||
        name === "last_name" ||
        name === "age" ||
        name === "gender" ||
        name === "email" ||
        name === "address" ||
        name === "phone_number") &&
      !value
    ) {
      error = "This field is required";
    } else {
      switch (name) {
        case "email":
          if (!/\S+@\S+\.\S+/.test(value)) {
            error = "Email address is invalid";
          }
          break;
        // case "contact_number":
        //   if (!/^\d{10}$/.test(value)) {
        //     error = "Contact number is invalid";
        //   }
        //   break;
        case "password":
          if (value.length < 6) {
            error = "Password must be at least 6 characters";
          }
          break;

        default:
          break;
      }
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  const validateForm = () => {
    let valid = true;
    Object.keys(formData).forEach((key) => {
      validateField(key, formData[key]);
      if (errors[key]) {
        valid = false;
      }
    });
    return valid;
  };
  const handleBlur = (event) => {
    const { name, value } = event.target;
    validateField(name, value);
  };
  const header = {
    Authorization: `Bearer ${token}`,
    "x-access-token": token,
    "Content-Type": "application/json",
  };
  const getPatientDetails = async () => {
    const resp = await (
      await fetch(`${baseURL}/patient/history/`, {
        method: "GET",
        headers: header,
      })
    ).json();
    setPatientDetails(resp);
  };
  useEffect(() => {
    if (userRole === "doctor") {
      getPatientDetails();
    }
  }, [userRole]);

  const createDoctorRegistration = async () => {
    try {
      setLoading(true);

      const res = await fetch(`${baseURL}/Creat-patient/${Number(id)}/`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: header,
      });

      const response = await res?.json();
      if (response?.id) {
        setLoading(false);
        ShowSucess("SuccessFully Created!");
        setTimeout(() => {
          navigate("/patient-list");
        }, 1500);
      }
    } catch (error) {
      setLoading(false);
      ShowError("Something Went Wrong!");
      console.log("error", error);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      createDoctorRegistration();
    } else {
      return false;
    }
    // You can add your form submission logic here
  };
  const handleReset = () => {
    setFormData({
      first_name: "",
      last_name: "",
      age: null,
      gender: "",
      phone_number: "",
      email: "",
      address: "",
      allergies: "",
      chronic_conditions: "",
      medications: "",
      blood_type: "",
      emergency_contact_name: "",
      emergency_contact_phone: "",
      emergency_contact_relationship: "",
    });
  };
  const handleClose = () => {
    setOpenPatientModal(false);
    handleReset();
  };
  const renderTextInput = useCallback(
    (
      type,
      state,
      label,
      disabled = false,
      required = false,
      multiline = false
    ) => {
      const showError = !!errors[state];
      const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      };
      return (
        <TextField
          required={required}
          size="small"
          fullWidth
          margin="dense"
          variant="outlined"
          label={label}
          name={state}
          id={state}
          type={state === "phone" ? "number" : type}
          disabled={disabled}
          error={showError}
          helperText={showError ? errors[state] : undefined}
          multiline={multiline}
          rows={multiline ? 3 : 1}
          value={formData[state] || ""}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    },
    [formData, errors, handleBlur]
  );
  return (
    <>
      {openPatientModal && (
        <Dialog
          open={openPatientModal}
          // onClose={() => setRegisterDrModal(false)}
          fullWidth
          maxWidth="lg"
        >
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ width: "99%" }}>
            <Card>
              <CardBody>
                <h4>Add Patient</h4>
                {loading && loadingPanel()}
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      {renderTextInput(
                        "text",
                        "first_name",
                        "First Name",
                        false
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderTextInput("text", "last_name", "Last Name", false)}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderTextInput("number", "age", "Age", false)}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        fullWidth
                        select={true}
                        id="outlined-select-requestType"
                        size="small"
                        label="Gender"
                        margin="dense"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                        error={!!errors.gender}
                        helperText={errors.gender}
                      >
                        {options.map((item) => (
                          <MenuItem value={item.value} key={item.value}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      {renderTextInput(
                        "text",
                        "blood_type",
                        "Blood Type",
                        false
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderTextInput(
                        "text",
                        "phone_number",
                        "Phone Number",
                        false
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderTextInput("text", "email", "Email", false)}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderTextInput(
                        "text",
                        "emergency_contact_name",
                        "Emergency Contact Name",
                        false
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderTextInput(
                        "text",
                        "emergency_contact_phone",
                        "Emergency Contact Phone",
                        false
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {renderTextInput(
                        "text",
                        "emergency_contact_relationship",
                        "Emergency Contact Relationship",
                        false
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {renderTextInput("text", "address", "Address", false)}
                    </Grid>
                    {/* <Grid item xs={12}>
                {renderTextInput(
                  "text",
                  "medical_record_number",
                  "Medical Record Number",
                  false
                )}
              </Grid> */}
                    <Grid item xs={12}>
                      {renderTextInput(
                        "text",
                        "allergies",
                        "Allergies",
                        false,
                        false,
                        true
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {renderTextInput(
                        "text",
                        "chronic_conditions",
                        "Chronic Conditions",
                        false,
                        false,
                        true
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {renderTextInput(
                        "text",
                        "medications",
                        "Medications",
                        false,
                        false,
                        true
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Button type="submit" variant="contained" color="primary">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardBody>
            </Card>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AddPatient;
